import * as yup from 'yup';

import { REQUIRED, NUMBER, POSITIVE, DATE, PROBABILITY, INTEGER } from 'forms/errorsText';

const MAX_PROBABILITY = 100;

const validationFields = {
  sold: yup.bool().required(REQUIRED),
  duration: yup.number().typeError(NUMBER).integer(INTEGER).positive(POSITIVE).required(REQUIRED),
  startedAt: yup.date().typeError(DATE).required(REQUIRED),
  probability: yup.number().when('sold', {
    is: false,
    then: schema => schema.typeError(NUMBER).positive(PROBABILITY).max(MAX_PROBABILITY, PROBABILITY).required(REQUIRED),
    otherwise: schema => schema.nullable(),
  }),
};

export const initialValues = {
  duration: '',
  startedAt: null,
  probability: '',
};

export const validationSchema = yup.object().shape(validationFields);
