import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import pluralize from 'pluralize';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';

import SectionTitle from 'components/SectionTitle';
import AddButton from 'components/AddButton';
import InvitationForm from 'components/InvitationForm';
import Notification from 'components/Notification';
import InfiniteScroll from 'components/InfiniteScroll';
import CompanyConnectionPresenter from 'presenters/CompanyConnectionPresenter';
import UserInvitationPresenter from 'presenters/UserInvitationPresenter';
import { INVITATION_VARIANTS } from 'constants/invitationForm';

import ContactsTable from './components/ContactsTable';
import useStyles from './useStyles';

const CLEAN_COUNT_TIMEOUT = 500;

const MyContacts = props => {
  const {
    connections,
    isConnectionsLoading,
    connectionsMeta,
    pendingInvitations,
    toolBarButtonText,
    isPendingInvitationsLoading,
    pendingInvitationsMeta,
    notificationInvitedName,
    invitationVariant,
    loadConnections,
    loadPendingInvitations,
    loadContacts,
    massInviteContacts,
    reinviteContact,
    resetContacts,
  } = props;
  const classes = useStyles();
  const [invitedSubcontractorsCount, setInvitedSubcontractorsCount] = useState(null);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [isInvitationNotificationOpen, setIsInvitationNotificationOpen] = useState(false);
  const timerId = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current);
    };
  }, []);

  const handleCreationModalShow = () => {
    setIsInvitationModalOpen(true);
  };

  const handleCreationModalHide = () => {
    setIsInvitationModalOpen(false);
  };

  const handleInvitationNotificationShow = invitedSubsCount => {
    setIsInvitationNotificationOpen(true);
    setInvitedSubcontractorsCount(invitedSubsCount);
  };

  const handleInvitationNotificationHide = () => {
    setIsInvitationNotificationOpen(false);
    timerId.current = setTimeout(() => setInvitedSubcontractorsCount(null), CLEAN_COUNT_TIMEOUT);
  };

  const handleMassInvite = async params => {
    const response = await massInviteContacts(params);

    resetContacts();
    handleCreationModalHide();
    handleInvitationNotificationShow(params.length);

    return response;
  };

  const handleInfiniteScroll = nextPage => {
    if (!isNil(connectionsMeta.nextPage)) {
      loadConnections(nextPage);
    }

    if (!isNil(pendingInvitationsMeta.nextPage)) {
      loadPendingInvitations(nextPage);
    }
  };

  const isLoading = isConnectionsLoading || isPendingInvitationsLoading;
  const pendingAndConnectionSum = pendingInvitationsMeta.totalCount + connectionsMeta.totalCount;
  const totalCount = isLoading || Number.isNaN(pendingAndConnectionSum) ? 0 : pendingAndConnectionSum;

  const notificationTitle = `${invitedSubcontractorsCount} ${pluralize(
    notificationInvitedName,
    invitedSubcontractorsCount,
  )} invited`;

  return (
    <>
      <SectionTitle className={classes.sectionTitle}>My Contacts</SectionTitle>
      <Paper className={classes.paper}>
        <div className={classes.toolbar}>
          <AddButton onClick={handleCreationModalShow}>{toolBarButtonText}</AddButton>
          <div className={classes.totalCount}>Total: {totalCount}</div>
        </div>
        <InfiniteScroll
          nextPage={pendingInvitationsMeta.nextPage || connectionsMeta.nextPage}
          isLoading={isLoading}
          onLoad={handleInfiniteScroll}
        >
          <ContactsTable
            connections={connections}
            pendingInvitations={pendingInvitations}
            isLoading={isLoading}
            onInviteResend={reinviteContact}
          />
        </InfiniteScroll>
      </Paper>
      <Dialog open={isInvitationModalOpen} onClose={handleCreationModalHide}>
        <InvitationForm
          variant={invitationVariant}
          onSubmit={handleMassInvite}
          onUsersLoad={loadContacts}
          onCancel={handleCreationModalHide}
        />
      </Dialog>
      <Notification
        open={isInvitationNotificationOpen}
        onClose={handleInvitationNotificationHide}
        title={notificationTitle}
        description="Invitation(s) sent successfully."
      />
    </>
  );
};

MyContacts.propTypes = {
  connectionsMeta: PropTypes.shape({
    totalCount: PropTypes.number,
    nextPage: PropTypes.number,
  }).isRequired,
  pendingInvitationsMeta: PropTypes.shape({
    totalCount: PropTypes.number,
    nextPage: PropTypes.number,
  }).isRequired,
  connections: PropTypes.arrayOf(CompanyConnectionPresenter.shape()).isRequired,
  isConnectionsLoading: PropTypes.bool.isRequired,
  pendingInvitations: PropTypes.arrayOf(UserInvitationPresenter.shape()).isRequired,
  isPendingInvitationsLoading: PropTypes.bool.isRequired,
  toolBarButtonText: PropTypes.string.isRequired,
  notificationInvitedName: PropTypes.string.isRequired,
  invitationVariant: PropTypes.oneOf(Object.values(INVITATION_VARIANTS)).isRequired,
  loadConnections: PropTypes.func.isRequired,
  loadPendingInvitations: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
  massInviteContacts: PropTypes.func.isRequired,
  reinviteContact: PropTypes.func.isRequired,
  resetContacts: PropTypes.func.isRequired,
};

export default MyContacts;
