import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

import LineItemForm from 'components/LineItemForm';
import LineItemPresenter from 'presenters/LineItemPresenter';
import { TABLE_VARIANTS } from 'constants/lineItems';

import LineItem from './components/LineItem';
import useStyles from './useStyles';

const LineItemsTable = props => {
  const { lineItems, isLoading, onRemove, onEdit, onCreate, isNewItemFormActive, onNewItemFormHide, variant } = props;
  const classes = useStyles();
  const [activeLineItemEditId, setActiveLineItemEditId] = useState(null);

  useEffect(() => {
    if (isEmpty(lineItems)) {
      setActiveLineItemEditId(null);
    }
  }, [lineItems]);
  useEffect(() => {
    if (isNewItemFormActive) {
      setActiveLineItemEditId(null);
    }
  }, [isNewItemFormActive]);

  const handleFormHide = () => {
    onNewItemFormHide();
    setActiveLineItemEditId(null);
  };

  const renderLoader = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell className={classes.tableEmpty} align="center" colSpan={4}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    return null;
  };

  const renderTableBody = () => {
    if (isEmpty(lineItems) && !isLoading) {
      return (
        <TableRow>
          <TableCell className={classes.tableEmpty} align="center" colSpan={4}>
            No line items added.
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {lineItems.map(item => (
          <LineItem
            key={item.id || LineItemPresenter.accountCode(item)}
            lineItem={item}
            onNewItemFormHide={handleFormHide}
            onLineItemEdit={onEdit}
            isEditFormActive={item.id === activeLineItemEditId}
            onActiveLineItemEditIdSet={setActiveLineItemEditId}
            onLineItemRemove={onRemove}
            lineItems={lineItems}
            variant={variant}
          />
        ))}
      </>
    );
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.codeHead}>Acc. Code</TableCell>
            <TableCell className={classes.categoryHead}>Category</TableCell>
            {variant === TABLE_VARIANTS.project && <TableCell className={classes.statusHead}>Status</TableCell>}
            <TableCell className={classes.stateHead} />
            <TableCell className={classes.binHead} />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          <LineItemForm
            isActive={isNewItemFormActive}
            onFormHide={handleFormHide}
            onSubmit={onCreate}
            lineItems={lineItems}
            variant={variant}
          />
          {renderTableBody()}
          {renderLoader()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

LineItemsTable.propTypes = {
  lineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isNewItemFormActive: PropTypes.bool.isRequired,
  onNewItemFormHide: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(TABLE_VARIANTS)),
};

LineItemsTable.defaultProps = {
  variant: TABLE_VARIANTS.default,
};

export default LineItemsTable;
