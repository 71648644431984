import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat, equals } from 'ramda';

import { apiContainer } from 'store';

import InvoicesRepository from 'repositories/builder/company/InvoicesRepository';
import { INVOICE_STATES } from 'presenters/InvoicePresenter';
import ProjectsRepository from 'repositories/builder/company/ProjectsRepository';
import LineItemsRepository from 'repositories/builder/company/LineItemsRepository';

const MAX_PER_PAGE = 5;

const initialState = {
  open: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  approved: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  closed: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  responseDialog: {
    open: false,
    invoice: null,
  },
  modalInvoice: {
    open: false,
    invoice: null,
  },
};

const invoicesSlice = createSlice({
  name: 'payableInvoices',
  initialState,
  reducers: {
    loadOpenInvoicesSuccess(state, { payload }) {
      state.open.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.open.data, payload.invoices);
      state.open.meta = payload.meta;
    },
    loadApprovedInvoicesSuccess(state, { payload }) {
      state.approved.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.approved.data, payload.invoices);
      state.approved.meta = payload.meta;
    },
    loadClosedInvoicesSuccess(state, { payload }) {
      state.closed.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.closed.data, payload.invoices);
      state.closed.meta = payload.meta;
    },
    openInvoiceResponseDialog(state, { payload }) {
      state.responseDialog.open = true;
      state.responseDialog.invoice = payload;
    },
    setModalInvoiceOpen(state, { payload }) {
      state.modalInvoice.open = payload;
    },
    setModalInvoiceData(state, { payload }) {
      state.modalInvoice.invoice = payload;
    },
    resetModalInvoice(state) {
      state.modalInvoice = initialState.modalInvoice;
    },
    resetInvoices() {
      return initialState;
    },
    resetOpenInvoices(state) {
      state.open = initialState.open;
    },
    resetResponseDialog(state) {
      state.responseDialog = initialState.responseDialog;
    },
  },
});

export const { actions } = invoicesSlice;
export default invoicesSlice.reducer;

export const usePayableInvoicesActions = () => {
  const dispatch = useDispatch();

  const loadOpenInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = {
      q: { stateIn: [INVOICE_STATES.pending, INVOICE_STATES.rejected], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadOpenInvoicesSuccess(data));

    return data;
  };

  const loadApprovedInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = {
      q: { stateEq: INVOICE_STATES.approved, s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadApprovedInvoicesSuccess(data));

    return data;
  };

  const loadClosedInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = {
      q: { stateIn: [INVOICE_STATES.paid, INVOICE_STATES.canceled], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadClosedInvoicesSuccess(data));

    return data;
  };

  const resetInvoices = () => {
    dispatch(actions.resetInvoices());
  };

  const loadProjects = async ({ name, state, approvalState }, customHeaders, cancelToken) => {
    const params = {
      q: {
        nameCont: name,
        stateEq: state,
        approvalStateEq: approvalState,
      },
    };
    const { data } = await ProjectsRepository.index(params, customHeaders, cancelToken);
    return data;
  };

  const loadLineItems = async (projectId, customHeaders, cancelToken) => {
    const params = {
      q: { projectsIdEq: projectId },
    };
    const { data } = await LineItemsRepository.index(params, customHeaders, cancelToken);
    return data;
  };

  const approveInvoice = async ({ id, projectInvoicesAttributes, invoiceLineItemsAttributes, projectedPaidAt }) => {
    const requestParams = {
      invoice: {
        projectInvoicesAttributes,
        invoiceLineItemsAttributes,
        projectedPaidAt,
      },
    };

    dispatch(
      apiContainer.util.invalidateTags([
        { type: 'BuilderCompanyInvoice', id: 'LIST' },
        { type: 'SubcontractorCompanyInvoice', id: 'LIST' },
      ]),
    );

    return InvoicesRepository.approve(id, requestParams);
  };

  const rejectInvoice = async ({ id, rejectionReason }) => {
    const requestParams = {
      invoice: {
        rejectionReason,
      },
    };

    dispatch(
      apiContainer.util.invalidateTags([
        { type: 'BuilderCompanyInvoice', id: 'LIST' },
        { type: 'SubcontractorCompanyInvoice', id: 'LIST' },
      ]),
    );

    return InvoicesRepository.reject(id, requestParams);
  };

  const openResponseDialog = invoice => {
    dispatch(actions.openInvoiceResponseDialog(invoice));
  };

  const resetResponseDialog = () => {
    dispatch(actions.resetResponseDialog());
  };

  const resetOpenInvoices = () => {
    dispatch(actions.resetOpenInvoices());
  };

  const setModalInvoiceOpen = open => {
    dispatch(actions.setModalInvoiceOpen(open));
  };

  const setModalInvoiceData = invoice => {
    dispatch(actions.setModalInvoiceData(invoice));
  };

  const resetModalInvoice = () => {
    dispatch(actions.resetModalInvoice());
  };

  return {
    loadOpenInvoices,
    loadApprovedInvoices,
    loadClosedInvoices,
    loadProjects,
    loadLineItems,
    approveInvoice,
    rejectInvoice,
    resetInvoices,
    openResponseDialog,
    resetResponseDialog,
    resetOpenInvoices,
    setModalInvoiceOpen,
    setModalInvoiceData,
    resetModalInvoice,
  };
};

export const selectors = {
  modalInvoice: state => state.PayableInvoicesSlice.modalInvoice,
  open: state => state.PayableInvoicesSlice.open,
  approved: state => state.PayableInvoicesSlice.approved,
  closed: state => state.PayableInvoicesSlice.closed,
};
