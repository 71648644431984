import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import Icon from 'components/Icon';

import ProjectBudget from './components/ProjectBudget';
import ProjectTerms from './components/ProjectTerms';
import RegularPayment from './components/RegularPayment';
import Team from './components/Team';
import Menu from './components/Menu';
import useStyles from './useStyles';

const MIN_FORM_STEP = 1;
const MAX_FORM_STEP = 4;

const ProjectSettings = props => {
  const {
    onFormHide,
    project,
    onProjectCreate,
    onProjectChange,
    regularPayments,
    companyManagers,
    onRegularPaymentsLoad,
    onSettingsHide,
    loadCompanyManagers,
    companyManagersMeta,
    isCompanyManagersLoading,
  } = props;
  const classes = useStyles();
  const [formStep, setFormStep] = useState(MIN_FORM_STEP);

  const handleFormStepIncrement = () => {
    if (formStep >= MAX_FORM_STEP) {
      return;
    }

    setFormStep(step => step + 1);
  };

  const handleFormStepDecrement = () => {
    if (formStep <= MIN_FORM_STEP) {
      onSettingsHide();
      return;
    }

    setFormStep(step => step - 1);
  };

  const renderForm = () => {
    switch (formStep) {
      case 1:
        return (
          <ProjectBudget
            project={project}
            onProjectChange={onProjectChange}
            onFormStepIncrement={handleFormStepIncrement}
            onFormStepDecrement={handleFormStepDecrement}
          />
        );
      case 2:
        return (
          <ProjectTerms
            project={project}
            onProjectChange={onProjectChange}
            onFormStepIncrement={handleFormStepIncrement}
            onRegularPaymentsLoad={onRegularPaymentsLoad}
            onFormStepDecrement={handleFormStepDecrement}
          />
        );
      case 3:
        return (
          <RegularPayment
            project={project}
            regularPayments={regularPayments}
            onProjectChange={onProjectChange}
            onFormStepIncrement={handleFormStepIncrement}
            onFormHide={onFormHide}
            onProjectCreate={onProjectCreate}
            onFormStepDecrement={handleFormStepDecrement}
          />
        );
      case 4:
        return (
          <Team
            project={project}
            companyManagers={companyManagers}
            onProjectCreate={onProjectCreate}
            onFormHide={onFormHide}
            onFormStepDecrement={handleFormStepDecrement}
            loadCompanyManagers={loadCompanyManagers}
            companyManagersMeta={companyManagersMeta}
            isCompanyManagersLoading={isCompanyManagersLoading}
          />
        );
      default:
        return null;
    }
  };

  const renderInfo = () => {
    if (project.sold) {
      return null;
    }

    return (
      <div className={classes.infoContainer}>
        <Icon name="info" width={20} height={20} className={classes.infoIcon} />
        <div className={classes.infoText}>
          You can use Funl to quote and plan for this project. Once you&apos;ve sold the contract you can begin managing
          this project with Funl.
        </div>
      </div>
    );
  };

  return (
    <Paper classes={{ root: classes.paper }}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <Icon name="gear" width={26} height={26} className={classes.gearIcon} />
          <Typography variant="h3">Project Settings</Typography>
        </div>
        <IconButton classes={{ root: classes.closeButton }} onClick={onFormHide} size="large">
          <CloseIcon classes={{ root: classes.closeIcon }} />
        </IconButton>
      </div>
      <div className={classes.main}>
        <div className={classes.sidebar}>
          <Menu step={formStep} project={project} />
        </div>
        <div className={classes.settings}>
          {renderInfo()}
          {renderForm()}
        </div>
      </div>
    </Paper>
  );
};

ProjectSettings.propTypes = {
  onFormHide: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  onProjectCreate: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  regularPayments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyManagers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRegularPaymentsLoad: PropTypes.func.isRequired,
  onSettingsHide: PropTypes.func.isRequired,
  loadCompanyManagers: PropTypes.func.isRequired,
  companyManagersMeta: PropTypes.shape().isRequired,
  isCompanyManagersLoading: PropTypes.bool.isRequired,
};

export default ProjectSettings;
