import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import SectionTitle from 'components/SectionTitle';
import { INVOICE_FORM_VARIANTS } from 'constants/invoices';

import InvoiceAddressee from './components/InvoiceAddressee';
import InvoiceProject from './components/InvoiceProject';
import InvoiceAttachments from './components/InvoiceAttachments';

import useStyles from './useStyles';

const InvoiceInfo = props => {
  const { formData, isAddressDisabled, variant, onCompaniesLoad } = props;
  const [companies, setCompanies] = useState([]);
  const { touched, errors, handleChange, values } = formData;
  const classes = useStyles();

  const loadCompanies = async value => {
    const { data } = await onCompaniesLoad(value);

    setCompanies(data.companies);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  return (
    <>
      <SectionTitle>Invoice Info</SectionTitle>
      <Paper classes={{ root: classes.paper }}>
        <InvoiceProject variant={variant} formData={formData} />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="name"
          id="name"
          label="Invoice Description"
          onChange={handleChange}
          value={values.name}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <div className={classes.inlineInputsContainer}>
          <TextField
            classes={{ root: classes.invoiceNumberInputRoot }}
            name="number"
            id="number"
            label="Invoice Number"
            placeholder="Optional"
            onChange={handleChange}
            value={values.number}
            error={touched.number && Boolean(errors.number)}
            helperText={touched.number && errors.number}
          />
          <TextField
            name="purchaseOrder"
            id="purchaseOrder"
            label="PO"
            placeholder="Optional"
            onChange={handleChange}
            value={values.purchaseOrder}
            error={touched.purchaseOrder && Boolean(errors.purchaseOrder)}
            helperText={touched.purchaseOrder && errors.purchaseOrder}
          />
        </div>
        <InvoiceAttachments formData={formData} />
        <InvoiceAddressee
          companies={companies}
          isAddressDisabled={isAddressDisabled}
          formData={formData}
          variant={variant}
          onCompaniesLoad={loadCompanies}
        />
        <TextField
          className={classes.inputRoot}
          name="note"
          id="note"
          label="Notes"
          placeholder="Optional"
          onChange={handleChange}
          value={values.note}
          error={touched.note && Boolean(errors.note)}
          helperText={touched.note && errors.note}
        />
      </Paper>
    </>
  );
};

InvoiceInfo.propTypes = {
  formData: PropTypes.shape().isRequired,
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  isAddressDisabled: PropTypes.bool.isRequired,
  onCompaniesLoad: PropTypes.func.isRequired,
};

export default InvoiceInfo;
