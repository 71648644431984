import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat, equals } from 'ramda';

import ProjectsRepository from 'repositories/builder/company/ProjectsRepository';
import { PROJECT_STATES } from 'presenters/ProjectPresenter';

const MAX_PER_PAGE = 5;

const initialState = {
  active: {
    data: [],
    nextPage: null,
    totalCount: 0,
    totalInformation: null,
  },
  draft: {
    data: [],
    nextPage: null,
    totalCount: 0,
    totalInformation: null,
  },
  completed: {
    data: [],
    nextPage: null,
    totalCount: 0,
    totalInformation: null,
  },
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    loadActiveProjectsSuccess(state, { payload }) {
      state.active.data = equals(payload.meta.currentPage, 1)
        ? payload.projects
        : concat(state.active.data, payload.projects);
      state.active.nextPage = payload.meta.nextPage;
      state.active.totalCount = payload.meta.totalCount;
      state.active.totalInformation = payload.meta.totalInformation;
    },
    loadDraftProjectsSuccess(state, { payload }) {
      state.draft.data = equals(payload.meta.currentPage, 1)
        ? payload.projects
        : concat(state.draft.data, payload.projects);
      state.draft.nextPage = payload.meta.nextPage;
      state.draft.totalCount = payload.meta.totalCount;
      state.draft.totalInformation = payload.meta.totalInformation;
    },
    loadCompletedProjectsSuccess(state, { payload }) {
      state.completed.data = equals(payload.meta.currentPage, 1)
        ? payload.projects
        : concat(state.completed.data, payload.projects);
      state.completed.nextPage = payload.meta.nextPage;
      state.completed.totalCount = payload.meta.totalCount;
      state.completed.totalInformation = payload.meta.totalInformation;
    },
    resetProjects() {
      return initialState;
    },
  },
});

export const { actions } = projectsSlice;
export default projectsSlice.reducer;

export const useProjectsActions = () => {
  const dispatch = useDispatch();

  const loadActiveProjects = async (params = {}) => {
    const { page = 1, sortingRule = 'id desc' } = params;
    const { data } = await ProjectsRepository.index({
      q: { stateEq: PROJECT_STATES.active, s: sortingRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadActiveProjectsSuccess(data));

    return Promise.resolve(data);
  };

  const loadDraftProjects = async (params = {}) => {
    const { page = 1, sortingRule = 'id desc' } = params;
    const { data } = await ProjectsRepository.index({
      q: { stateEq: PROJECT_STATES.draft, s: sortingRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadDraftProjectsSuccess(data));

    return Promise.resolve(data);
  };

  const loadCompletedProjects = async (params = {}) => {
    const { page = 1, sortingRule = 'id desc' } = params;
    const { data } = await ProjectsRepository.index({
      q: { stateEq: PROJECT_STATES.completed, s: sortingRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadCompletedProjectsSuccess(data));

    return Promise.resolve(data);
  };

  const resetProjects = () => {
    dispatch(actions.resetProjects());
  };

  return {
    loadActiveProjects,
    loadDraftProjects,
    loadCompletedProjects,
    resetProjects,
  };
};
