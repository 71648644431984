import { FC } from "react";

import muiSx from "mui-sx";
import { isNil } from "ramda";

import Div from "components/common/Div";
import Li from "components/common/Li";
import Ul from "components/common/Ul";
import Icon from "components/Icon";

import WelcomeLayout from "layouts/WelcomeLayout";

import ContentNavigation from "./ContentNavigation";
import { getFormOrderState } from "./service";
import styles from "./styles";
import { IOnboardingLayoutProps, OnboardingLayoutVariant } from "./types";

const OnboardingLayout: FC<IOnboardingLayoutProps> = (props) => {
  const { children, currentUser, onSignOut, variant } = props;

  const currentFormOrderState = getFormOrderState(variant, currentUser);

  const renderMenuItem = (order: number, label: string) => {
    if (isNil(currentFormOrderState)) {
      return null;
    }

    if (order < currentFormOrderState) {
      return (
        <Li sx={styles.menuItem}>
          <Icon width={15} height={15} name="containedCheckMark" /> {label}
        </Li>
      );
    }

    if (order === currentFormOrderState) {
      const sx = muiSx(styles.menuItem, styles.activeMenuItem);

      return (
        <Li sx={sx}>
          {order}. {label}
        </Li>
      );
    }

    return (
      <Li sx={styles.menuItem}>
        {order}. {label}
      </Li>
    );
  };

  const renderMenu = () => {
    switch (variant) {
      case OnboardingLayoutVariant.builder: {
        return (
          <>
            <Div sx={styles.titleDescription}>Let&apos;s set up your account.</Div>
            <Ul sx={styles.menu}>
              {renderMenuItem(1, "General Information")}
              {renderMenuItem(2, "Line Items")}
              {renderMenuItem(3, "Team")}
            </Ul>
          </>
        );
      }
      case OnboardingLayoutVariant.subcontractor: {
        return (
          <>
            <Div sx={styles.titleDescription}>Let&apos;s set up your account.</Div>
            <Ul sx={styles.menu}>
              {renderMenuItem(1, "General Information")}
              {renderMenuItem(2, "Team")}
            </Ul>
          </>
        );
      }
      case OnboardingLayoutVariant.customer: {
        return (
          <>
            <Div sx={styles.titleDescription}>Let&apos;s set up your account.</Div>
            <Ul sx={styles.menu}>
              {renderMenuItem(1, "Confirm the Project")}
              {renderMenuItem(2, "Payment Methods")}
            </Ul>
          </>
        );
      }
      case OnboardingLayoutVariant.roleChoice: {
        return <Div sx={styles.titleDescription}>Before we get started, choose how you would like to use Funl?</Div>;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <WelcomeLayout sidebarComponent={renderMenu()} onSignOut={onSignOut}>
      <Div sx={styles.formContainer}>
        <ContentNavigation currentUser={currentUser} variant={variant}>
          {children}
        </ContentNavigation>
      </Div>
    </WelcomeLayout>
  );
};

export default OnboardingLayout;
