import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import AutocompletePick from 'components/AutocompletePick';
import ProjectPresenter from 'presenters/ProjectPresenter';
import LineItemPresenter from 'presenters/LineItemPresenter';

import useStyles from './useStyles';

const VARIANTS = {
  project: 'project',
  lineItem: 'lineItem',
};

const InvoiceResponseAutocomplete = props => {
  const { variant, displayedValueSelectedOption, ...autoCompleteProps } = props;
  const classes = useStyles();

  const renderProject = (optionProps, option) => {
    return (
      <li className={classes.option} {...optionProps}>
        <Typography>{ProjectPresenter.name(option)}</Typography>
      </li>
    );
  };

  const renderLineItem = (optionProps, option) => {
    return (
      <li className={classes.option} {...optionProps}>
        <Typography>
          {LineItemPresenter.accountCode(option)} {LineItemPresenter.categoryName(option)}
        </Typography>
      </li>
    );
  };

  const OPTION_VARIANTS = {
    [VARIANTS.project]: renderProject,
    [VARIANTS.lineItem]: renderLineItem,
  };

  const getProjectOptionLabel = option => option.name;
  const getLineItemOptionLabel = option => option.accountCode;

  const LABEL_VARIANTS = {
    [VARIANTS.project]: getProjectOptionLabel,
    [VARIANTS.lineItem]: getLineItemOptionLabel,
  };

  return (
    <AutocompletePick
      displayedValueSelectedOption={displayedValueSelectedOption}
      renderOptions={OPTION_VARIANTS[variant]}
      getOptionLabel={LABEL_VARIANTS[variant]}
      {...autoCompleteProps}
    />
  );
};

InvoiceResponseAutocomplete.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
  displayedValueSelectedOption: PropTypes.string.isRequired,
};

export default InvoiceResponseAutocomplete;
