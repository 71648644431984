import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  show(id) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectPath(id);

    return FetchHelpers.get(path);
  },
  create(params) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectsPath();

    return FetchHelpers.post(path, params);
  },
  update(id, params) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectPath(id);

    return FetchHelpers.put(path, params);
  },
  complete(id) {
    const path = apiRoutes.completeSiteApiV1BuilderCompanyProjectPath(id);

    return FetchHelpers.put(path);
  },
};
