import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 3),
    width: 579,
  },
  titleContainer: {
    display: 'flex',
  },
  titleIcon: {
    marginTop: 4,
    fill: theme.palette.colors.green,
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    marginRight: theme.spacing(1),
  },
}));
