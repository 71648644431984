import { useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Paper } from "@mui/material";

import InvoiceTabs from "components/common/InvoiceTabs";
import { InvoiceTabsStatus } from "components/common/InvoiceTabs/types";

import ApprovedInvoices from "./ApprovedInvoices";
import ClosedInvoices from "./ClosedInvoices";
import Header from "./Header";
import OpenInvoices from "./OpenInvoices";
import styles from "./styles";
import useBuilderInvoicesCountByStatus from "./useBuilderInvoicesCountByStatus";

const Invoices = () => {
  const [value, setValue] = useState<InvoiceTabsStatus>("open");
  const { statusCount, changeStatusCount } = useBuilderInvoicesCountByStatus();

  const handleChange = (newValue: InvoiceTabsStatus) => {
    setValue(newValue);
  };

  return (
    <div>
      <Header />

      <Paper elevation={0} variant="outlined" sx={styles.paper}>
        <TabContext value={value}>
          <InvoiceTabs onChange={handleChange} statusCount={statusCount} />
          <TabPanel value="open">
            <OpenInvoices onStatusCountChange={changeStatusCount} />
          </TabPanel>
          <TabPanel value="approved">
            <ApprovedInvoices onStatusCountChange={changeStatusCount} />
          </TabPanel>
          <TabPanel value="closed">
            <ClosedInvoices onStatusCountChange={changeStatusCount} />
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
};

export default Invoices;
