import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiInputLabelType {
  defaultProps: ComponentsProps["MuiInputLabel"];
  styleOverrides: ComponentsOverrides["MuiInputLabel"];
}

const MuiInputLabel: IMuiInputLabelType = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      textTransform: "uppercase",
      color: baseTheme.palette.colors.deltaGray,
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 600,
      letterSpacing: 1.7,
      transform: "translateY(-1px) scale(0.75)",
      position: "static",
      "&.Mui-shrink": {
        color: baseTheme.palette.colors.deltaGray,
      },
    },
  },
};

export default MuiInputLabel;
