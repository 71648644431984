import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      minWidth: 700,
    },
  },
  invoiceContainer: {
    marginTop: theme.spacing(5),
  },
}));
