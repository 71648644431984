import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import useLoading from 'hooks/useLoading';
import TableRowsWithLoading from 'components/TableRowsWithLoading';
import { useCustomerPaymentsActions, selectors } from 'store/CustomerPaymentsSlice';
import TransactionPresenter from 'presenters/TransactionPresenter';

import PaymentHistoryTableRow from './components/PaymentHistoryTableRow';
import useStyles from './useStyles';

const MAX_COLS_COUNT = 6;

const PaymentHistoryTable = () => {
  const classes = useStyles();
  const { data, totalCount } = useSelector(selectors.transactions);
  const { loadTransactions, resetTransactions } = useCustomerPaymentsActions();
  const { func: sendLoadCustomerInvoices, isPending } = useLoading(loadTransactions);

  useEffect(() => {
    sendLoadCustomerInvoices();
    return resetTransactions;
  }, []);

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          Payment history
        </Typography>
        <Typography className={classes.totalCount}>Total: {totalCount}</Typography>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.headerCell} width="20.74%">
                Project Name
              </TableCell>
              <TableCell align="left" className={classes.headerCell} width="17.54%">
                Contractor
              </TableCell>
              <TableCell align="right" className={clsx(classes.headerCell, classes.invoiceHeaderCell)} width="13.91%">
                Invoice
              </TableCell>
              <TableCell align="left" className={classes.headerCell} width="10.27%">
                Date
              </TableCell>
              <TableCell align="right" className={classes.headerCell} width="12.11%">
                Amount
              </TableCell>
              <TableCell align="right" className={classes.headerCell} width="25.43%">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowsWithLoading
              isLoading={isPending}
              rows={data.map(transaction => (
                <PaymentHistoryTableRow key={TransactionPresenter.id(transaction)} transaction={transaction} />
              ))}
              isData={!isEmpty(data)}
              maxColsCount={MAX_COLS_COUNT}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentHistoryTable;
