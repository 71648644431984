import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 6,
    minWidth: '700px',
    padding: theme.spacing(1, 3),
  },
  headerCell: {
    padding: theme.spacing(2, 0),
    '&:first-child': {
      width: '20%',
    },
  },
}));
