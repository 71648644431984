import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Paper, CircularProgress } from '@mui/material';

import AppRoutes from 'routes/AppRoutes';
import InvoicePresenter from 'presenters/InvoicePresenter';
import { usePayableInvoicesActions } from 'store/PayableInvoicesSlice';
import { useLineItemsSettingsActions } from 'store/LineItemsSettingsSlice';
import useLoading from 'hooks/useLoading';
import { useInvoiceActions, selectors } from 'store/PayableInvoiceSlice';
import InvoiceCard from 'components/InvoiceCard';
import InvoiceResponseForm from 'components/InvoiceResponseForm';
import InvoiceAttachmentPreview from 'components/InvoiceAttachmentPreview';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';
import { useMarkInvoiceAsPaidMutation } from 'store/api/builder/company/invoice/apiSlice';

import InvoicePageHeader from './components/PayableInvoicePageHeader';

import useStyles from './useStyles';

const PayableInvoice = () => {
  const classes = useStyles();
  const { loadInvoice, resetInvoice } = useInvoiceActions();
  const routeParams = useParams();
  const history = useHistory();
  const invoiceId = Number(routeParams.id);
  const { func: sendLoadInvoice, isNotFinished } = useLoading(loadInvoice);
  const { loadProjects, approveInvoice, rejectInvoice } = usePayableInvoicesActions();
  const { loadActiveLineItems } = useLineItemsSettingsActions();
  const { createLineItem } = useBuilderInvoiceCreateActions();
  const [markInvoiceAsPaid, { isLoading: isMarkingAsPaid }] = useMarkInvoiceAsPaidMutation();

  const { data: invoice } = useSelector(selectors.invoice);

  useEffect(() => {
    sendLoadInvoice(invoiceId);
    return resetInvoice;
  }, [invoiceId]);

  const handleSuccessAction = () => {
    history.push(AppRoutes.invoicesPath());
  };

  const handleApproveInvoice = async (...params) => {
    await approveInvoice(...params);
    sendLoadInvoice(invoiceId);
  };

  const handleRejectInvoice = async (...params) => {
    await rejectInvoice(...params);
    handleSuccessAction();
  };

  const handleMarkAsPaid = async paidInvoiceId => {
    await markInvoiceAsPaid(paidInvoiceId);
    sendLoadInvoice(invoiceId);
  };

  if (isNotFinished) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  const isInvoiceCanEdit = InvoicePresenter.isAwaitingApproval(invoice);

  return (
    <main className={classes.root}>
      <InvoicePageHeader
        invoiceName={InvoicePresenter.name(invoice)}
        invoice={invoice}
        onMarkAsPaid={handleMarkAsPaid}
        loading={isMarkingAsPaid}
      />
      {!isNotFinished && isInvoiceCanEdit && (
        <Paper className={classes.invoiceResponseFormContainer}>
          <InvoiceResponseForm
            invoice={invoice}
            isAmountEdit={!InvoicePresenter.isAuthored(invoice)}
            isViewInvoiceButton={false}
            loadProjects={loadProjects}
            loadLineItems={loadActiveLineItems}
            approveInvoice={handleApproveInvoice}
            rejectInvoice={handleRejectInvoice}
            onLineItemItemCreate={createLineItem}
          />
        </Paper>
      )}
      <div className={classes.invoiceContainer}>
        <InvoiceCard
          variant="builder"
          isPreviewButtonVisible
          invoice={invoice}
          isLoading={isNotFinished}
          contactsTitle="Subcontractor Contacts"
        />
      </div>
      {InvoicePresenter.attachmentUrl(invoice) && (
        <div className={classes.invoiceContainer}>
          <InvoiceAttachmentPreview fileUrl={InvoicePresenter.attachmentUrl(invoice)} />
        </div>
      )}
    </main>
  );
};

export default PayableInvoice;
