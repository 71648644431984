import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    marginTop: 15,
  },
  headerCell: {
    padding: '10px 10px 5px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.colors.lemonGrass,
    border: 'none',
    '&:first-child': {
      width: 300,
    },
  },
  headerCellEmail: {
    padding: '10px 10px 5px',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.colors.lemonGrass,
    border: 'none',
    '&:first-child': {
      width: 300,
    },
  },
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
    '&:last-child $emailCell': {
      border: 'none',
    },
  },
  cell: {
    padding: '15px 10px',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
  },
  emailCell: {
    color: theme.palette.colors.green,
    padding: '15px 10px',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    paddingLeft: '0px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusContainer: {
    fontWeight: 400,
    display: 'flex',
    lineHeight: theme.typography.pxToRem(14),
  },
  checkIcon: {
    fill: theme.palette.colors.green,
    marginRight: 5,
  },
  sandglassIcon: {
    fill: theme.palette.colors.orange,
    marginRight: 7,
  },
  crossIcon: {
    fill: theme.palette.colors.red,
    marginRight: 5,
    fontSize: theme.typography.pxToRem(16),
  },
  emptyTableLabel: {
    color: theme.palette.colors.gunsmokeGray,
  },
}));
