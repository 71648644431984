import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import TableLoader from 'components/TableLoader';
import TableEmptyRow from 'components/TableEmptyRow';
import TableLoadMoreButton from 'components/TableLoadMoreButton';

const TableRowsWithLoading = props => {
  const { isLoading, isData, emptyText, nextPage, loadMore, maxColsCount, rows } = props;

  const handleMoreClick = () => {
    loadMore(nextPage);
  };

  if (isLoading && !isData) {
    return <TableLoader colSpan={maxColsCount} />;
  }

  if (!isData && !isLoading) {
    return <TableEmptyRow colSpan={maxColsCount} text={emptyText} />;
  }

  return (
    <>
      {rows}
      {isLoading && isData && <TableLoader colSpan={maxColsCount} />}
      {!isNil(nextPage) && (
        <TableLoadMoreButton colSpan={maxColsCount} onClick={handleMoreClick} isLoading={isLoading} />
      )}
    </>
  );
};

TableRowsWithLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  maxColsCount: PropTypes.number.isRequired,
  isData: PropTypes.bool.isRequired,
  rows: PropTypes.node.isRequired,
  nextPage: PropTypes.number,
  loadMore: PropTypes.func,
  emptyText: PropTypes.string,
};

TableRowsWithLoading.defaultProps = {
  nextPage: null,
  loadMore: Function.prototype,
  emptyText: 'No items',
};

export default TableRowsWithLoading;
