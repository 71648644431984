import { SxStyles } from "theme";

const styles: SxStyles = {
  headerContainer: {
    display: "flex",
    alignItems: "center",
    padding: "14px 0",
    borderBottom: (theme) => `1px solid ${theme.palette.colors.lightGray}`,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    wordBreak: "break-all",
  },
  icon: {
    marginRight: "16px",
  },
  name: {
    fontWeight: 700,
  },
};

export default styles;
