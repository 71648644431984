import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  userPanel: {
    display: 'flex',
  },
  menuButton: {
    minWidth: 'unset',
    width: 200,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    textTransform: 'none',
    padding: 15,
    paddingTop: 20,
  },
  userNameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userName: {
    display: 'inline-block',
    alignItems: 'center',
    color: theme.palette.colors.heavyMetalGray,
    marginLeft: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 145,
    textAlign: 'left',
    fontWeight: 600,
  },
}));
