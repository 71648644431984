import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  checkIconButton: {
    padding: theme.spacing(1),
    minWidth: 48,
    height: 32,
  },
  crossIconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: 48,
    height: 32,
  },
  checkIcon: {
    fill: theme.palette.colors.white,
  },
  crossIcon: {
    fill: theme.palette.colors.lemonGrass,
  },
  categoryInputContainer: {
    width: '100%',
    marginRight: theme.spacing(4),
  },
  container: {
    display: 'flex',
  },
  actions: {
    display: 'flex',
  },
  input: {
    padding: '6px 9px',
  },
  inputHelperText: {
    position: 'relative',
    top: 0,
  },
  row: {
    display: 'none',
  },
  cell: {
    padding: '6px 8px 8px 0',
    verticalAlign: 'top',
  },
  visibleRow: {
    display: 'table-row',
  },
}));
