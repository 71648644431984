import { FC } from "react";

import { Typography } from "@mui/material";
import { isEmpty } from "ramda";

import useBreakpoints from "hooks/useBreakpoints";

import { IInvoiceLineItemsNamesProps } from "./types";

const InvoiceLineItemsNames: FC<IInvoiceLineItemsNamesProps> = (props) => {
  const {
    invoice: { lineItems },
  } = props;
  const { screenWiderThan } = useBreakpoints();

  return (
    <>
      {lineItems.map((lineItem) => (
        <Typography noWrap={screenWiderThan.md} key={lineItem.id}>
          {[lineItem.accountCode, lineItem.categoryName].filter((string) => !isEmpty(string)).join(" ")}
        </Typography>
      ))}
    </>
  );
};

export default InvoiceLineItemsNames;
