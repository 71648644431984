import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      minHeight: 280,
    },
  },
  label: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.bud,
    marginBottom: '10px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '40px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  email: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0.2,
  },
  emailTip: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.gunsmokeGray,
  },
  roleContent: {
    display: 'flex',
    gap: '8px',
  },
  select: {
    flex: 1,
  },
  crossIcon: {
    fill: theme.palette.colors.lemonGrass,
  },
}));
