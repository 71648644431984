import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  titleMobile: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
  paper: {
    marginTop: 12,
    padding: 24,
  },
  inputRoot: {
    '&:not(:first-child)': {
      marginTop: 28,
    },
  },
  inlineInputsContainer: {
    marginTop: 28,
    display: 'flex',
  },
  invoiceNumberInputRoot: {
    marginRight: 8,
  },
}));
