import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useLoading from 'hooks/useLoading';
import ProjectPresenter from 'presenters/ProjectPresenter';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import AutocompletePick from 'components/AutocompletePick';
import Icon from 'components/Icon';
import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues, formPaths } from 'forms/Builder/IncomingPaymentUpdateForm';

import useStyles from './useStyles';

const AssignPayment = props => {
  const { loadProjects, loadCustomerInvoices, onAssigned, onClose } = props;
  const classes = useStyles();
  const { errors, setFieldValue, values, handleSubmit, resetForm, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async ({ projectId, projectName, customerInvoiceName, customerInvoiceId }) => {
      await onAssigned({ projectId, projectName, customerInvoiceName, customerInvoiceId });
    },
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const [customerInvoiceOptions, setCustomerInvoiceOptions] = useState([]);

  const { func: sendLoadProjects, isPending: isProjectPending } = useLoading(loadProjects);
  const { func: sendLoadCustomerInvoices, isPending: isCustomerInvoicePending } = useLoading(loadCustomerInvoices);

  const handleProjectsLoad = async name => {
    const { projects } = await sendLoadProjects(name);
    setProjectOptions(projects);
  };

  const handleCustomerInvoicesLoad = async name => {
    if (!values.projectId) return;

    const { customerInvoices } = await sendLoadCustomerInvoices({ name, projectId: values.projectId });
    setCustomerInvoiceOptions(customerInvoices);
  };

  const handleProjectFieldValueSet = (_, option) => {
    setFieldValue(formPaths.projectName, ProjectPresenter.name(option));
    setFieldValue(formPaths.projectId, ProjectPresenter.id(option));
    setFieldValue(formPaths.customerInvoiceName, '');
    setFieldValue(formPaths.customerInvoiceId, '');
  };

  const handleCustomerInvoiceFieldValueSet = (_, option) => {
    setFieldValue(formPaths.customerInvoiceName, CustomerInvoicePresenter.name(option));
    setFieldValue(formPaths.customerInvoiceId, CustomerInvoicePresenter.id(option));
  };

  const onFormHide = () => {
    resetForm();
    onClose();
  };

  const isAssignButtonDisabled = !values[formPaths.projectId] || !values[formPaths.customerInvoiceId] || isSubmitting;

  return (
    <form className={classes.assignProjectActionsContainer}>
      <AutocompletePick
        options={projectOptions}
        displayedValueSelectedOption={values[formPaths.projectName]}
        fieldName="projectName"
        isOnFieldValueSetWrap={false}
        isLoading={isProjectPending}
        classes={{ root: classes.autocompleteRoot, listbox: classes.lineItemOptions, input: classes.autocompleteInput }}
        error={Boolean(errors.projectId)}
        helperText={errors.projectId}
        renderOptions={(optionProps, option) => (
          <li className={classes.option} {...optionProps}>
            <Typography className={classes.optionName}>{ProjectPresenter.name(option)}</Typography>
          </li>
        )}
        disabled={isSubmitting}
        getOptionLabel={option => option.name}
        onLoad={handleProjectsLoad}
        onFieldValueSet={handleProjectFieldValueSet}
        placeholder="Required"
      />
      <AutocompletePick
        options={customerInvoiceOptions}
        displayedValueSelectedOption={values[formPaths.customerInvoiceName]}
        fieldName={formPaths.customerInvoiceName}
        isOnFieldValueSetWrap={false}
        isLoading={isCustomerInvoicePending}
        classes={{ root: classes.autocompleteRoot, listbox: classes.lineItemOptions, input: classes.autocompleteInput }}
        error={Boolean(errors.projectId)}
        helperText={errors.projectId}
        renderOptions={(optionProps, option) => (
          <li className={classes.option} {...optionProps}>
            <Typography className={classes.optionName}>{CustomerInvoicePresenter.name(option)}</Typography>
          </li>
        )}
        disabled={isSubmitting}
        getOptionLabel={option => option.name}
        onLoad={handleCustomerInvoicesLoad}
        onFieldValueSet={handleCustomerInvoiceFieldValueSet}
        placeholder="Required"
      />
      <div className={classes.actions}>
        <Button
          className={classes.checkIconButton}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isAssignButtonDisabled}
        >
          <Icon className={classes.checkIcon} name="checkMark" width={16} height={16} />
        </Button>
        <Button
          className={classes.crossIconButton}
          type="submit"
          onClick={onFormHide}
          variant="contained"
          disabled={isSubmitting}
        >
          <Icon className={classes.crossIcon} name="cross" width={16} height={16} />
        </Button>
      </div>
    </form>
  );
};

AssignPayment.propTypes = {
  loadProjects: PropTypes.func.isRequired,
  onAssigned: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loadCustomerInvoices: PropTypes.func.isRequired,
};

export default AssignPayment;
