import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';
import IncomingContacts from 'containers/ContactsPage/IncomingContacts';
import MyContacts from 'containers/ContactsPage/MyContacts';
import { useSubcontractorsActions } from 'store/SubcontractorsSlice';
import { INVITATION_VARIANTS } from 'constants/invitationForm';
import useLoading from 'hooks/useLoading';

import useStyles from './useStyles';

const Subcontractors = () => {
  const classes = useStyles();

  const {
    loadConnections,
    loadIncomingConnections,
    acceptIncomingConnection,
    rejectIncomingConnection,
    loadPendingInvitations,
    loadSubcontractors,
    massInviteSubcontractors,
    resetSubcontractors,
    reinviteSubcontractor,
  } = useSubcontractorsActions();
  const {
    connections: { data: connections, meta: connectionsMeta },
    incomingConnections: { data: incomingConnections },
    pendingInvitations: { data: pendingInvitations, meta: pendingInvitationsMeta },
  } = useSelector(state => state.SubcontractorsSlice);
  const { isPending: isConnectionsLoading, func: onConnectionsLoad } = useLoading(loadConnections);
  const { isPending: isIncomingConnectionsLoading, func: onIncomingConnectionsLoad } =
    useLoading(loadIncomingConnections);
  const { isPending: isPendingInvitationsLoading, func: onPendingInvitationsLoad } = useLoading(loadPendingInvitations);

  useEffect(() => {
    onIncomingConnectionsLoad();
    loadConnections();
    loadPendingInvitations();

    return () => {
      resetSubcontractors();
    };
  }, []);

  const reloadData = () => {
    resetSubcontractors();
    onIncomingConnectionsLoad();
    onPendingInvitationsLoad();
    onConnectionsLoad();
  };

  const handleIncomingConnectionAccept = async connectionId => {
    await acceptIncomingConnection(connectionId);

    reloadData();
  };

  const handleIncomingConnectionReject = async connectionId => {
    await rejectIncomingConnection(connectionId);

    reloadData();
  };

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="Subcontractors"
          icon={<Icon className={classes.titleIcon} width={32} height={32} name="couple" />}
        />
        <div className={classes.tip}>Inviting subcontractors and vendors will unlock Funl’s potential.</div>
      </header>
      {!isEmpty(incomingConnections) && (
        <IncomingContacts
          sectionTitle="Contact Requests"
          pageTitle="New Contact Requests"
          description="There are Contacts who are requesting the ability to send you Invoices. After you confirm these contacts, they
          will appear below in your Contact List."
          incomingConnections={incomingConnections}
          isIncomingConnectionsLoading={isIncomingConnectionsLoading}
          onIncomingConnectionAccept={handleIncomingConnectionAccept}
          onIncomingConnectionReject={handleIncomingConnectionReject}
        />
      )}
      <MyContacts
        connections={connections}
        isConnectionsLoading={isConnectionsLoading}
        connectionsMeta={connectionsMeta}
        pendingInvitations={pendingInvitations}
        isPendingInvitationsLoading={isPendingInvitationsLoading}
        pendingInvitationsMeta={pendingInvitationsMeta}
        toolBarButtonText="Invite Subcontractor"
        notificationInvitedName="subcontractor"
        invitationVariant={INVITATION_VARIANTS.subcontractor}
        loadConnections={onConnectionsLoad}
        loadPendingInvitations={onPendingInvitationsLoad}
        loadContacts={loadSubcontractors}
        massInviteContacts={massInviteSubcontractors}
        reinviteContact={reinviteSubcontractor}
        resetContacts={reloadData}
      />
    </main>
  );
};
export default Subcontractors;
