import { useEffect } from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import DatePicker from 'components/DatePicker';
import { initialValues, validationSchema } from 'forms/Builder/ProjectTermsForm';
import useFormik from 'hooks/useFormik';

import useStyles from './useStyles';

const ProjectTerms = props => {
  const { onFormStepIncrement, project, onProjectChange, onRegularPaymentsLoad, onFormStepDecrement } = props;
  const classes = useStyles();
  const { touched, errors, handleChange, values, setValues, setErrors, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ startedAt, ...newProjectValues }) => {
      const newProject = { ...project, ...newProjectValues, startedAt: new Date(startedAt).toJSON() };
      onProjectChange(newProject);
      await onRegularPaymentsLoad({ overheadExpenses: newProject.overheadExpenses, duration: newProject.duration });
      onFormStepIncrement();
    },
  });

  useEffect(() => {
    setValues({ ...values, ...project, ...(project.sold && { probability: '' }) });
  }, [project]);

  const handleStartedAtChange = date => {
    setValues({ ...values, startedAt: date });
    setErrors({});
  };

  const renderProbability = () => {
    if (project.sold) {
      return null;
    }

    return (
      <div className={classes.inputContainer}>
        <div className={classes.labelContainer}>
          <InputLabel classes={{ root: classes.label }} htmlFor="probability">
            Probability of Winning Project
          </InputLabel>
          <div className={classes.tip}>How likely the project will be sold.</div>
        </div>
        <div className={classes.textFieldContainer}>
          <TextField
            classes={{ root: classes.textFieldInput }}
            FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
            name="probability"
            id="probability"
            value={values.probability}
            onChange={handleChange}
            error={touched.probability && Boolean(errors.probability)}
            helperText={touched.probability && errors.probability}
          />
          <div className={classes.textFieldTip}>%</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.labelContainer}>
            <InputLabel classes={{ root: classes.label }} htmlFor="duration">
              Duration of Project
            </InputLabel>
            <div className={classes.tip}>How many months will the project take to complete?</div>
          </div>
          <div className={classes.textFieldContainer}>
            <TextField
              classes={{ root: classes.textFieldInput }}
              FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
              name="duration"
              id="duration"
              placeholder="0"
              value={values.duration}
              onChange={handleChange}
              error={touched.duration && Boolean(errors.duration)}
              helperText={touched.duration && errors.duration}
            />
            <div className={classes.textFieldTip}>months</div>
          </div>
        </div>
        {renderProbability()}
        <div className={classes.inputContainer}>
          <div className={classes.labelContainer}>
            <InputLabel classes={{ root: classes.label }} htmlFor="startedAt">
              Start Date
            </InputLabel>
            <div className={classes.tip}>What date is the start of work?</div>
          </div>
          <DatePicker
            FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
            format="MM/dd/yyyy"
            name="startedAt"
            id="startedAt"
            value={values.startedAt}
            onChange={handleStartedAtChange}
            error={touched.startedAt && Boolean(errors.startedAt)}
            helperText={touched.startedAt && errors.startedAt}
          />
        </div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onFormStepDecrement}>
            Back
          </Button>
          <Button variant="contained" color="primary" classes={{ root: classes.nextButton }} type="submit">
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

ProjectTerms.propTypes = {
  onFormStepIncrement: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  onProjectChange: PropTypes.func.isRequired,
  onRegularPaymentsLoad: PropTypes.func.isRequired,
  onFormStepDecrement: PropTypes.func.isRequired,
};

export default ProjectTerms;
