import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import AppRoutes from 'routes/AppRoutes';
import Icon from 'components/Icon';
import AddButton from 'components/AddButton';
import ProjectPresenter from 'presenters/ProjectPresenter';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';

import CompleteProjectConfirmation from './components/CompleteProjectConfirmation';
import ChangeOrderForm from './components/ChangeOrderForm';
import useStyles from './useStyles';

const ProjectDetailsActions = props => {
  const { project, onProjectUpdate, onProjectComplete } = props;
  const classes = useStyles();
  const [isChangeOrderFormOpen, setIsChangeOrderFormOpen] = useState(false);
  const [isCompleteProjectConfirmationOpen, setIsCompleteProjectConfirmationOpen] = useState(false);
  const history = useHistory();
  const { updatePredefinedProject } = useBuilderInvoiceCreateActions();

  const projectIsApproved = ProjectPresenter.isApproved(project);

  const handleChangeOrderModalOpen = () => {
    setIsChangeOrderFormOpen(true);
  };

  const handleChangeOrderModalClose = () => {
    setIsChangeOrderFormOpen(false);
  };

  const handleCompleteProjectModalOpen = () => {
    setIsCompleteProjectConfirmationOpen(true);
  };

  const handleCompleteProjectModalClose = () => {
    setIsCompleteProjectConfirmationOpen(false);
  };

  const handleProjectComplete = async () => {
    await onProjectComplete(project);
    handleChangeOrderModalClose();
  };

  const handleCreateInvoiceClick = () => {
    updatePredefinedProject(project);
    history.push(AppRoutes.invoicesCreatePath());
  };

  if (ProjectPresenter.isDraft(project)) {
    return (
      <div className={classes.draftContainer}>
        <Icon name="draft" width={17} height={21} className={classes.draftIcon} />
        <div className={classes.draftLabel}>Draft Project</div>
      </div>
    );
  }

  if (ProjectPresenter.isCompleted(project)) {
    return (
      <div className={classes.draftContainer}>
        <Icon name="draft" width={17} height={21} className={classes.draftIcon} />
        <div className={classes.draftLabel}>Completed Project</div>
      </div>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        className={classes.completeButton}
        onClick={handleCompleteProjectModalOpen}
      >
        Complete Project
      </Button>
      <Button variant="contained" className={classes.changeOrderButton} onClick={handleChangeOrderModalOpen}>
        Change Order
      </Button>
      <Tooltip
        title={projectIsApproved ? '' : 'This project has not been approved yet.'}
        classes={{ popper: classes.popover }}
      >
        <span className={classes.newInvoiceButtonContainer}>
          <AddButton variant="contained" onClick={handleCreateInvoiceClick} disabled={!projectIsApproved}>
            Create New Invoice
          </AddButton>
        </span>
      </Tooltip>
      <CompleteProjectConfirmation
        isOpen={isCompleteProjectConfirmationOpen}
        onClose={handleCompleteProjectModalClose}
        onProjectComplete={handleProjectComplete}
        project={project}
      />
      <ChangeOrderForm
        isOpen={isChangeOrderFormOpen}
        onClose={handleChangeOrderModalClose}
        project={project}
        onProjectUpdate={onProjectUpdate}
      />
    </>
  );
};

ProjectDetailsActions.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  onProjectComplete: PropTypes.func.isRequired,
  onProjectUpdate: PropTypes.func.isRequired,
};

export default ProjectDetailsActions;
