import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import MemberChoiceTable from 'components/MemberChoiceTable';
import UserPresenter from 'presenters/UserPresenter';

import useStyles from './useStyles';

const Team = props => {
  const {
    project,
    companyManagers,
    onProjectCreate,
    onFormHide,
    onFormStepDecrement,
    loadCompanyManagers,
    companyManagersMeta,
    isCompanyManagersLoading,
  } = props;
  const classes = useStyles();
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleFormSubmit = async () => {
    const selectedMembersIds = selectedMembers.map(({ id }) => ({ userId: id }));

    await onProjectCreate({ ...project, userProjectsAttributes: selectedMembersIds });
    onFormHide();
  };

  return (
    <>
      <Typography variant="h4" classes={{ root: classes.title }}>
        Who on your team can approve and pay invoices for this project?
      </Typography>
      <div className={classes.formContainer}>
        <MemberChoiceTable
          members={companyManagers}
          selectedMembers={selectedMembers}
          onMembersSelect={setSelectedMembers}
          onMembersLoad={loadCompanyManagers}
          membersMeta={companyManagersMeta}
          isLoading={isCompanyManagersLoading}
        />
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={onFormStepDecrement}>
          Back
        </Button>
        <Button variant="contained" color="primary" classes={{ root: classes.nextButton }} onClick={handleFormSubmit}>
          Continue
        </Button>
      </div>
    </>
  );
};

Team.propTypes = {
  onFormHide: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  companyManagers: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  onProjectCreate: PropTypes.func.isRequired,
  onFormStepDecrement: PropTypes.func.isRequired,
  loadCompanyManagers: PropTypes.func.isRequired,
  companyManagersMeta: PropTypes.shape().isRequired,
  isCompanyManagersLoading: PropTypes.bool.isRequired,
};

export default Team;
