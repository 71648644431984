import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    boxShadow: {
      inset: 'inset',
      x: 0,
      y: -1,
      blur: 0,
      color: theme.palette.colors.lightGray,
    },
  },
  cell: {
    padding: 0,
    paddingTop: 5,
    paddingBottom: 2,
    '&:first-child': {
      width: 52,
    },
    '&:nth-child(3)': {
      width: 30,
    },
  },
  avatarIcon: {
    marginLeft: 4,
    fill: theme.palette.colors.nurseGray,
  },
  name: {
    marginBottom: 4,
  },
}));
