import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  paper: {
    padding: '28px 24px 32px 24px',
    width: 800,
    minHeight: 512,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
  },
  closeButton: {
    marginTop: -10,
  },
  closeIcon: {
    fill: theme.palette.colors.gunsmokeGray,
  },
  gearIcon: {
    fill: theme.palette.colors.green,
    marginRight: 10,
    marginLeft: 2,
    marginTop: 2,
  },
  main: {
    marginTop: 28,
    display: 'flex',
  },
  sidebar: {
    boxShadow: 'inset -1px 0px 0px rgba(0, 48, 32, 0.1)',
    paddingRight: 24,
    height: 312,
  },
  settings: {
    paddingLeft: 32,
    paddingRight: 10,
    width: '100%',
  },
  infoContainer: {
    display: 'flex',
    marginBottom: 32,
  },
  infoIcon: {
    marginTop: 1,
    fill: theme.palette.colors.green,
  },
  infoText: {
    width: 456,
    marginLeft: 12,
    color: theme.palette.colors.green,
    fontSize: 13,
    letterSpacing: 0.2,
    lineHeight: '16px',
  },
}));
