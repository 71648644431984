import { FC } from "react";

import Div from "components/common/Div";

import styles from "./styles";
import { INoWrapProps } from "./types";

const NoWrap: FC<INoWrapProps> = (props) => {
  const { children, title } = props;

  return (
    <Div title={title} sx={styles.root}>
      {children}
    </Div>
  );
};

export default NoWrap;
