import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';

import InvoiceAttachmentPreview from 'components/InvoiceAttachmentPreview';
import InvoiceCard from 'components/InvoiceCard';
import AppRoutes from 'routes/AppRoutes';
import useLoading from 'hooks/useLoading';
import { useAdminInvoiceActions, selectors } from 'store/AdminInvoiceSlice';
import InvoicePresenter, { INVOICE_STATES } from 'presenters/InvoicePresenter';

import AdminInvoiceResponseForm from './components/AdminInvoiceResponseForm';
import useStyles from './useStyles';

const AdminInvoice = () => {
  const classes = useStyles();
  const routeParams = useParams();
  const history = useHistory();
  const invoiceId = Number(routeParams.id);
  const { loadInvoice, approveInvoice, rejectInvoice, resetInvoice } = useAdminInvoiceActions();
  const { func: handleInvoiceLoad, isNotFinished } = useLoading(loadInvoice);

  const { data: invoice } = useSelector(selectors.invoice);

  useEffect(() => {
    handleInvoiceLoad(invoiceId);
    return resetInvoice;
  }, [invoiceId]);

  const handleSuccessAction = () => {
    history.push(AppRoutes.adminInvoicesPath());
  };

  const handleApproveInvoice = async (...params) => {
    await approveInvoice(...params);
    handleSuccessAction();
  };

  const handleRejectInvoice = async (...params) => {
    await rejectInvoice(...params);
    handleSuccessAction();
  };

  const isInvoiceCanEdit = InvoicePresenter.state(invoice) === INVOICE_STATES.pending;

  return (
    <main className={classes.root}>
      {!isNotFinished && isInvoiceCanEdit && (
        <Paper className={classes.invoiceResponseFormContainer}>
          <AdminInvoiceResponseForm
            invoice={invoice}
            approveInvoice={handleApproveInvoice}
            rejectInvoice={handleRejectInvoice}
          />
        </Paper>
      )}
      <div className={classes.invoiceContainer}>
        <InvoiceCard
          isPreviewButtonVisible
          invoice={invoice}
          isLoading={isNotFinished}
          contactsTitle="General Contractor Contacts"
        />
      </div>
      {InvoicePresenter.attachmentUrl(invoice) && (
        <div className={classes.invoiceContainer}>
          <InvoiceAttachmentPreview fileUrl={InvoicePresenter.attachmentUrl(invoice)} />
        </div>
      )}
    </main>
  );
};

AdminInvoice.propTypes = {};

export default AdminInvoice;
