import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat, equals } from 'ramda';

import InvoicesRepository from 'repositories/subcontractor/company/InvoicesRepository';
import { INVOICE_STATES } from 'presenters/InvoicePresenter';

const MAX_PER_PAGE = 5;

const initialState = {
  open: {
    data: [],
    nextPage: null,
    totalCount: 0,
  },
  approved: {
    data: [],
    nextPage: null,
    totalCount: 0,
  },
  closed: {
    data: [],
    nextPage: null,
    totalCount: 0,
  },
  modalInvoice: {
    open: false,
    invoice: null,
  },
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    loadOpenInvoicesSuccess(state, { payload }) {
      state.open.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.open.data, payload.invoices);
      state.open.nextPage = payload.meta.nextPage;
      state.open.totalCount = payload.meta.totalCount;
    },
    loadApprovedInvoicesSuccess(state, { payload }) {
      state.approved.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.approved.data, payload.invoices);
      state.approved.nextPage = payload.meta.nextPage;
      state.approved.totalCount = payload.meta.totalCount;
    },
    loadClosedInvoicesSuccess(state, { payload }) {
      state.closed.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.closed.data, payload.invoices);
      state.closed.nextPage = payload.meta.nextPage;
      state.closed.totalCount = payload.meta.totalCount;
    },
    setModalInvoiceOpen(state, { payload }) {
      state.modalInvoice.open = payload;
    },
    setModalInvoiceData(state, { payload }) {
      state.modalInvoice.invoice = payload;
    },
    resetModalInvoice(state) {
      state.modalInvoice = initialState.modalInvoice;
    },
    resetInvoices() {
      return initialState;
    },
  },
});

export const { actions } = invoicesSlice;
export default invoicesSlice.reducer;

export const useInvoicesActions = () => {
  const dispatch = useDispatch();

  const loadOpenInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const { data } = await InvoicesRepository.index({
      q: { stateIn: [INVOICE_STATES.pending, INVOICE_STATES.rejected], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadOpenInvoicesSuccess(data));

    return data;
  };

  const loadApprovedInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const { data } = await InvoicesRepository.index({
      q: { stateEq: INVOICE_STATES.approved, s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadApprovedInvoicesSuccess(data));

    return data;
  };

  const loadClosedInvoices = async (queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const { data } = await InvoicesRepository.index({
      q: { stateIn: [INVOICE_STATES.paid, INVOICE_STATES.canceled], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadClosedInvoicesSuccess(data));

    return data;
  };

  const setModalInvoiceOpen = open => {
    dispatch(actions.setModalInvoiceOpen(open));
  };

  const setModalInvoiceData = invoice => {
    dispatch(actions.setModalInvoiceData(invoice));
  };

  const resetModalInvoice = () => {
    dispatch(actions.resetModalInvoice());
  };

  const resetInvoices = () => {
    dispatch(actions.resetInvoices());
  };

  return {
    loadOpenInvoices,
    loadApprovedInvoices,
    loadClosedInvoices,
    resetInvoices,
    setModalInvoiceOpen,
    setModalInvoiceData,
    resetModalInvoice,
  };
};

export const selectors = {
  modalInvoice: state => state.InvoicesSlice.modalInvoice,
};
