import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TransactionPresenter from 'presenters/TransactionPresenter';

import useStyles from './useStyles';

const TotalOutgoingPaymentTransactionsTableRow = props => {
  const { outgoingPaymentTransaction } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.name(outgoingPaymentTransaction)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TransactionPresenter.formattedUSDAmount(outgoingPaymentTransaction)}
      </TableCell>
    </TableRow>
  );
};

TotalOutgoingPaymentTransactionsTableRow.propTypes = {
  outgoingPaymentTransaction: TransactionPresenter.shape().isRequired,
};

export default TotalOutgoingPaymentTransactionsTableRow;
