export const formatter = (parameters = {}, locale = "en-US") => Intl.NumberFormat(locale, parameters);

export const formatUSD = (amount: number, maximumFractionDigits = 2, signDisplay = "auto") =>
  formatter({
    style: "currency",
    currency: "USD",
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
    signDisplay,
  }).format(amount);
