import { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, path } from 'ramda';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { initialValues, validationSchema } from 'forms/PasswordResetForm';
import PasswordTextField from 'components/PasswordTextField';
import useFormik from 'hooks/useFormik';

import useStyles from './useStyles';

const Form = props => {
  const { onFormSubmit } = props;
  const classes = useStyles();
  const [apiErrors, setApiErrors] = useState({});
  const { touched, errors, handleChange, values, isSubmitting, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async valuesToSubmit => {
      try {
        await onFormSubmit({ password: valuesToSubmit.password });
      } catch (e) {
        if (e.response.status === 401) {
          setApiErrors({ password: ['Invalid token.'] });
          return;
        }
        setApiErrors(e);
      }
    },
  });

  const getPasswordErrors = () => {
    if (isNil(apiErrors.password)) {
      return touched.password && errors.password;
    }

    return path(['password', 0], apiErrors);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">Reset your password</Typography>
      <div className={classes.tip}>Password must be at least 8 characters long.</div>
      <div className={classes.formContainer}>
        <form onSubmit={handleSubmit}>
          <PasswordTextField
            classes={{ root: classes.inputRoot }}
            name="password"
            label="New Password"
            onChange={handleChange}
            value={values.password}
            error={Boolean(apiErrors.password) || (touched.password && Boolean(errors.password))}
            helperText={getPasswordErrors()}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            classes={{ root: classes.submitRoot }}
            disabled={isSubmitting}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};

Form.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default Form;
