import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { CHECK_ADDRESS } from 'constants/paymentMethods';
import { copyToClipboard } from 'utils/navigatorUtils';
import { useCustomerOnboardingActions } from 'store/CustomerOnboardingSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';

import useStyles from './useStyles';

const ADDRESS_COPIED = 'Address copied successfully';

const CheckDetails = props => {
  const { onBackButtonClick } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { completeOnboarding } = useCustomerOnboardingActions();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();

  const handleCopy = () => {
    copyToClipboard(CHECK_ADDRESS);
    enqueueSnackbar(ADDRESS_COPIED, { variant: 'success' });
  };

  const handleContinue = async () => {
    await completeOnboarding();
    await loadCurrentUser();
  };

  return (
    <>
      <div className={classes.backButtonContainer}>
        <Button className={classes.backButton} onClick={onBackButtonClick}>
          <ArrowBackIcon />
        </Button>
      </div>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          Check
        </Typography>
        <Typography className={classes.subtitle}>Send your checks to the address below.</Typography>
        <Typography className={classes.subtitle}>
          You will see this address when the payment day comes and you log in.
        </Typography>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.checkAddressContainer}>
          <div className={classes.checkAddressDescription}>
            <Typography className={clsx(classes.address, classes.bold)}>{CHECK_ADDRESS}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.nextButtonContainer}>
        <Button variant="outlined" color="primary" onClick={handleCopy}>
          Copy address
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </>
  );
};

CheckDetails.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
};

export default CheckDetails;
