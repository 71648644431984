export default {
  title: "Payable Invoices ",
  createPayableInvoice: "Create Payable Invoice",
  paymentTerms: {
    oneThreeLabel: "1-3 business days",
    sevenTenLabel: "7-10 business days",
  },
  table: {
    headers: {
      subcontractor: "Subcontractor",
      projectName: "Project Name",
      assignedProjects: "Assigned Project(s)",
      lineItems: "Line Item(s)",
      invoiceNumber: "Invoice #",
      approvalDueDate: "Approval Due Date",
      paymentDueDate: "Payment Due Date",
      rejectedDate: "Rejected Date",
      paidDate: "Paid Date",
      status: "Status",
      earlyPaymentTerms: "Early Payment Terms",
      amount: "Amount",
      invoiceAmount: "Invoice Amount",
      acceptedAmount: "Accepted Amount",
    },
  },
};
