import { memo } from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';
import { TABLE_CELL_VARIANT } from './constants';

const TableStatus = props => {
  const { color, count, title } = props;

  const classes = useStyles({ color });

  return (
    <span className={classes.label}>
      {title} <span className={classes.count}>{count}</span>
    </span>
  );
};

TableStatus.propTypes = {
  color: PropTypes.oneOf(Object.values(TABLE_CELL_VARIANT)).isRequired,
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(TableStatus);
