import { FC, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import muiSx from "mui-sx";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { buildErrorMessage } from "libs/forms";

import styles from "./styles";
import { IPasswordTextFieldProps } from "./types";

const PasswordTextField: FC<IPasswordTextFieldProps> = (props) => {
  const { label, fieldName } = props;
  const { t } = useTranslation("common");
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const togglePasswordShown = () => {
    setIsPasswordShown((isShown) => !isShown);
  };

  const inputRootClassNames = muiSx({ sx: styles.inputRootPassword, condition: !isPasswordShown });

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          type={isPasswordShown ? "text" : "password"}
          label={label}
          error={Boolean(errors[fieldName])}
          helperText={buildErrorMessage(t, fieldState?.error)}
          InputProps={{
            sx: inputRootClassNames,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={styles.passwordVisibilityButton}
                  aria-label="toggle password visibility"
                  onClick={togglePasswordShown}
                  size="large"
                >
                  {isPasswordShown ? (
                    <VisibilityOff sx={styles.visibilityIcon} />
                  ) : (
                    <Visibility sx={styles.visibilityIcon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default PasswordTextField;
