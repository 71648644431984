import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
}));
