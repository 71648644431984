import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import useLoading from 'hooks/useLoading';
import Icon from 'components/Icon';
import { useAdminBuildersActions, selectors } from 'store/AdminBuildersSlice';
import PageHeader from 'components/PageHeader';
import UserPresenter from 'presenters/UserPresenter';

import BuildersTableRow from './components/BuildersTableRow';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 4;

const Builders = () => {
  const classes = useStyles();
  const { loadBuilders, resetBuilders } = useAdminBuildersActions();
  const { users, nextPage } = useSelector(selectors.builders);
  const { func: sendLoadBuilders, isPending } = useLoading(loadBuilders);

  useEffect(() => {
    sendLoadBuilders();
    return resetBuilders;
  }, []);

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="GC teams"
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="couple" />}
        />
      </header>
      <Paper className={classes.buildersTableContainer}>
        <div className={classes.tableContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.headerCell}>
                    Role
                  </TableCell>
                  <TableCell align="left" className={classes.headerCell}>
                    Name
                  </TableCell>
                  <TableCell align="left" className={classes.headerCell}>
                    Email
                  </TableCell>
                  <TableCell align="left" className={classes.headerCell}>
                    Company name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRowsWithLoading
                  nextPage={nextPage}
                  isLoading={isPending}
                  rows={users.map(builder => (
                    <BuildersTableRow key={UserPresenter.id(builder)} builder={builder} />
                  ))}
                  loadMore={loadBuilders}
                  isData={!isEmpty(users)}
                  maxColsCount={MAX_COLS_COUNT}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </main>
  );
};

export default Builders;
