import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableRowsWithLoading from 'components/TableRowsWithLoading';

import useStyles from './useStyles';

const IncomingPaymentsTable = props => {
  const { isLoading, nextPage, loadMore, rows, isData, maxColsCount } = props;

  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.headerCell} width="24.43%">
              Payer Name
            </TableCell>
            <TableCell align="left" className={classes.headerCell} width="17.43%">
              Date
            </TableCell>
            <TableCell align="right" className={clsx(classes.headerCell, classes.amountHeaderCell)} width="14.37%">
              Amount
            </TableCell>
            <TableCell align="left" className={classes.headerCell} width="16.77%">
              Project
            </TableCell>
            <TableCell align="left" className={classes.headerCell} width="27%">
              Client Invoice
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowsWithLoading
            nextPage={nextPage}
            isLoading={isLoading}
            rows={rows}
            loadMore={loadMore}
            isData={isData}
            maxColsCount={maxColsCount}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IncomingPaymentsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.node.isRequired,
  isData: PropTypes.bool.isRequired,
  maxColsCount: PropTypes.number.isRequired,
  nextPage: PropTypes.number,
  loadMore: PropTypes.func,
};

IncomingPaymentsTable.defaultProps = {
  nextPage: null,
  loadMore: Function.prototype,
};

export default memo(IncomingPaymentsTable);
