import { makeStyles } from '@mui/styles';

import { TABLE_CELL_VARIANT } from './constants';

export default makeStyles(theme => {
  const labelColors = {
    [TABLE_CELL_VARIANT.green]: {
      backgroundColor: theme.palette.colors.riceGreen,
      color: theme.palette.colors.kellyGreen,
    },
    [TABLE_CELL_VARIANT.orange]: {
      backgroundColor: theme.palette.colors.papayaYellow,
      color: theme.palette.colors.orange,
    },
    [TABLE_CELL_VARIANT.gray]: {
      backgroundColor: theme.palette.colors.blackSqueezeGray,
      color: theme.palette.colors.deltaGray,
    },
  };
  const borderColors = {
    [TABLE_CELL_VARIANT.green]: {
      borderColor: theme.palette.colors.suluGreen,
    },
    [TABLE_CELL_VARIANT.orange]: {
      borderColor: theme.palette.colors.salomieYellow,
    },
    [TABLE_CELL_VARIANT.gray]: {
      borderColor: theme.palette.colors.nurseGray,
    },
  };

  const countColors = {
    [TABLE_CELL_VARIANT.green]: {
      color: theme.palette.colors.riceGreen,
      backgroundColor: theme.palette.colors.kellyGreen,
    },
    [TABLE_CELL_VARIANT.orange]: {
      color: theme.palette.colors.papayaYellow,
      backgroundColor: theme.palette.colors.orange,
    },
    [TABLE_CELL_VARIANT.gray]: {
      color: theme.palette.colors.blackSqueezeGray,
      backgroundColor: theme.palette.colors.deltaGray,
    },
  };

  return {
    label: ({ color }) => ({
      display: 'inline-flex',
      alignItems: 'center',
      padding: theme.spacing(1, 3),
      border: '1px solid',
      borderRadius: 37,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
      ...labelColors[color],
      ...borderColors[color],
    }),
    count: ({ color }) => ({
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: theme.typography.pxToRem(12),
      borderRadius: 18,
      padding: '0 4px',
      height: 18,
      minWidth: 18,
      marginLeft: 4,
      ...countColors[color],
    }),
  };
});
