import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import CompanyConnectionPresenter from 'presenters/CompanyConnectionPresenter';
import TableRowsWithLoading from 'components/TableRowsWithLoading';

import IncomingContactsTableRows from './components/IncomingContactsTableRows';

const MAX_COLS_COUNT = 2;

const IncomingContactsTable = props => {
  const { isIncomingConnectionsLoading, incomingConnections, onConfirm, onReject, onRowClick } = props;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRowsWithLoading
            isLoading={isIncomingConnectionsLoading}
            isData={!isEmpty(incomingConnections)}
            rows={
              <IncomingContactsTableRows
                incomingConnections={incomingConnections}
                onConfirm={onConfirm}
                onReject={onReject}
                onRowClick={onRowClick}
              />
            }
            maxColsCount={MAX_COLS_COUNT}
            emptyText="No contact requests."
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

IncomingContactsTable.propTypes = {
  incomingConnections: PropTypes.arrayOf(CompanyConnectionPresenter.shape()).isRequired,
  isIncomingConnectionsLoading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default IncomingContactsTable;
