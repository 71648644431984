import TextField from '@mui/material/TextField';

import NumberFormat from './components/NumberFormat';

const MoneyTextField = props => {
  return (
    <TextField
      type="text"
      placeholder="$0.00"
      {...props}
      InputProps={{
        inputComponent: NumberFormat,
      }}
    />
  );
};

export default MoneyTextField;
