export const MAX_COLS_COUNT = 7;

export const headers = [
  {
    text: 'Builder',
    width: '16%',
  },
  {
    text: 'Customer',
    width: '16%',
  },
  {
    text: 'Project',
    width: '16%',
  },
  {
    text: 'Source',
    width: '8%',
  },
  {
    text: 'Status',
    width: '10%',
  },
  {
    text: 'Amount',
    width: '6%',
    align: 'right',
  },
];
