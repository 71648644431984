import { FC } from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Container } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import Div from "components/common/Div";
import Span from "components/common/Span";
import Icon from "components/Icon";

import useImpersonate from "hooks/useImpersonate";

import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import styles from "./styles";
import { IWelcomelayoutProps } from "./types";

const WelcomeLayout: FC<IWelcomelayoutProps> = (props) => {
  const { children, sidebarComponent, isLogOutShow = true, onSignOut } = props;
  const { t } = useTranslation("welcomeLayout");
  const { data: currentUser } = useGetCurrentUserQuery(undefined, { skip: true });
  const { stopImpersonating } = useImpersonate();

  return (
    <Div sx={styles.root}>
      <Div sx={styles.rootContainer}>
        <Div sx={styles.sidebar}>
          <Div>
            <Div sx={styles.handIconContainer}>
              <Icon name="hand" width={40} height={40} />
              <Div sx={styles.greet}>
                <Trans
                  t={t}
                  i18nKey="welcome"
                  components={{
                    1: <Span sx={styles.title} />,
                    2: <Span sx={styles.appName} />,
                  }}
                />
              </Div>
            </Div>
            {sidebarComponent}
          </Div>
        </Div>
        <Container sx={styles.mainContainer}>
          <main>{children}</main>
        </Container>
        <Div sx={styles.actions}>
          {isLogOutShow && (
            <div>
              <Button onClick={onSignOut} sx={styles.logoutButton}>
                <Icon name="logout" width={20} height={16} />
                <Div sx={styles.logoutLabel}>{t("logout")}</Div>
              </Button>
            </div>
          )}
          {currentUser?.isImpersonated && (
            <div>
              <Button onClick={stopImpersonating} sx={styles.logoutButton}>
                <HighlightOffIcon />
                <Div sx={styles.stopImpersonatingLabel}>{t("stopImpersonating")}</Div>
              </Button>
            </div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

export default WelcomeLayout;
