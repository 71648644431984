import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    marginTop: 5,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: 48,
    },
    '&:nth-child(3) + $actions': {
      marginTop: 95,
    },
  },
  label: {
    color: theme.palette.colors.black,
    textTransform: 'none',
    letterSpacing: -0.2,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '15px',
  },
  labelContainer: {
    maxWidth: 265,
  },
  textFieldInput: {
    marginTop: -2,
    width: 72,
  },
  inputHelperText: {
    top: 40,
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textFieldContainer: {
    width: 240,
    display: 'flex',
  },
  textFieldTip: {
    marginLeft: 14,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '40px',
  },
  tip: {
    marginTop: 10,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 188,
  },
  nextButton: {
    marginLeft: 12,
    width: 140,
  },
}));
