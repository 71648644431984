import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const SelectAllButton = props => {
  const { isEveryItemSelected, onDeselectAll, onSelectAll } = props;

  return isEveryItemSelected ? (
    <Button color="primary" onClick={onDeselectAll}>
      Deselect All
    </Button>
  ) : (
    <Button color="primary" onClick={onSelectAll}>
      Select All
    </Button>
  );
};

SelectAllButton.propTypes = {
  isEveryItemSelected: PropTypes.bool.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
};

export default SelectAllButton;
