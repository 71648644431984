import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import LineItemPresenter from 'presenters/LineItemPresenter';

export default new Presenter(
  {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lineItem: LineItemPresenter.shape(),
  },
  {},
);
