import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiDialogType {
  defaultProps: ComponentsProps["MuiDialog"];
  styleOverrides: ComponentsOverrides["MuiDialog"];
}

const MuiDialog: IMuiDialogType = {
  defaultProps: {
    maxWidth: false,
  },
  styleOverrides: {},
};

export default MuiDialog;
