import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  confirm(params) {
    const path = apiRoutes.confirmSiteApiV1CustomerOnboardingProjectsPath();

    return FetchHelpers.put(path, params);
  },
};
