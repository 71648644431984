import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  checkboxIcon: {
    fill: theme.palette.colors.green,
  },
  circleIcon: {
    stroke: theme.palette.colors.nurseGray,
    fill: theme.palette.colors.white,
  },
}));
