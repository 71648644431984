import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from 'clsx';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import useLoading from 'hooks/useLoading';
import { useCustomerPaymentsActions, selectors } from 'store/CustomerPaymentsSlice';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import { PAYMENT_METHODS } from 'constants/paymentMethods';

import CheckRequest from './components/CheckRequest';
import BankAccountRequest from './components/BankAccountRequest';
import OpenInvoicesTableRow from './components/OpenInvoicesTableRow';
import AchRequest from './components/AchRequest';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 6;

const OpenInvoicesTable = () => {
  const classes = useStyles();
  const { data } = useSelector(selectors.customerInvoices);
  const paymentMethod = useSelector(selectors.paymentMethod);
  const { loadCustomerInvoices, resetInvoices, loadPaymentMethod } = useCustomerPaymentsActions();
  const { func: sendLoadCustomerInvoices, isPending } = useLoading(loadCustomerInvoices);

  useEffect(() => {
    loadPaymentMethod();
    sendLoadCustomerInvoices();
    return resetInvoices;
  }, []);

  return (
    <>
      {paymentMethod === PAYMENT_METHODS.ach && <AchRequest />}
      {paymentMethod === PAYMENT_METHODS.check && <CheckRequest />}
      {paymentMethod === PAYMENT_METHODS.bankAccount && <BankAccountRequest />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.headerCell} width="20.74%">
                Project Name
              </TableCell>
              <TableCell align="left" className={classes.headerCell} width="17.54%">
                Contractor
              </TableCell>
              <TableCell align="right" className={clsx(classes.headerCell, classes.invoiceHeaderCell)} width="13.91%">
                Invoice
              </TableCell>
              <TableCell align="left" className={classes.headerCell} width="10.27%">
                Date
              </TableCell>
              <TableCell align="right" className={classes.headerCell} width="12.11%">
                Amount
              </TableCell>
              <TableCell align="right" className={classes.headerCell} width="25.43%">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowsWithLoading
              isLoading={isPending}
              rows={data.map(customerInvoice => (
                <OpenInvoicesTableRow
                  key={CustomerInvoicePresenter.id(customerInvoice)}
                  customerInvoice={customerInvoice}
                />
              ))}
              isData={!isEmpty(data)}
              maxColsCount={MAX_COLS_COUNT}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OpenInvoicesTable;
