import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import LineItemPresenter from 'presenters/LineItemPresenter';
import DeleteButton from 'components/DeleteButton';
import Icon from 'components/Icon';
import LineItemForm from 'components/LineItemForm';
import { TABLE_VARIANTS } from 'constants/lineItems';

import LineItemStatus from './components/LineItemStatus';
import useStyles from './useStyles';

const LineItem = props => {
  const {
    lineItem,
    onNewItemFormHide,
    onActiveLineItemEditIdSet,
    onLineItemEdit,
    lineItems,
    isEditFormActive,
    onLineItemRemove,
    variant,
  } = props;

  const classes = useStyles();

  const handleFormHide = () => {
    onActiveLineItemEditIdSet(null);
  };

  const handleFormShow = () => {
    onNewItemFormHide();
    onActiveLineItemEditIdSet(lineItem.id);
  };

  const handleFormSubmit = async (...params) => {
    await onLineItemEdit(...params);
    handleFormHide();
  };

  if (isEditFormActive) {
    const lineItemsWithoutCurrent = lineItems.filter(item => item.id !== lineItem.id);

    return (
      <LineItemForm
        isActive={isEditFormActive}
        onFormHide={handleFormHide}
        onSubmit={handleFormSubmit}
        lineItems={lineItemsWithoutCurrent}
        lineItem={lineItem}
        variant={variant}
      />
    );
  }

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell} onClick={handleFormShow}>
        <button type="button" onClick={handleFormShow} className={classes.button}>
          {LineItemPresenter.accountCode(lineItem)}
        </button>
      </TableCell>
      <TableCell classes={{ root: clsx(classes.cell, classes.categoryCell) }} onClick={handleFormShow}>
        <button type="button" onClick={handleFormShow} className={classes.button}>
          {LineItemPresenter.categoryName(lineItem)}
        </button>
      </TableCell>
      {variant === TABLE_VARIANTS.project && (
        <TableCell className={classes.cell}>
          <button type="button" onClick={handleFormShow} className={classes.button}>
            <LineItemStatus lineItem={lineItem} />
          </button>
        </TableCell>
      )}
      <TableCell classes={{ root: clsx(classes.cell, classes.stateCell) }}>
        <div className={classes.actionsContainer}>
          <button type="button" onClick={handleFormShow} className={classes.button}>
            <div className={clsx(classes.infoContainer, LineItemPresenter.isDraft(lineItem) && classes.visible)}>
              <Icon name="notice" className={classes.noticeIcon} width={19} height={15} />
              <div className={classes.info}>Acc. Code required</div>
            </div>
          </button>
        </div>
      </TableCell>
      <TableCell classes={{ root: clsx(classes.cell, classes.binCell) }} align="right">
        <div className={classes.actionsContainer}>
          <div className={classes.binContainer}>
            <DeleteButton
              classes={{ root: classes.binIconButton }}
              onClick={() => onLineItemRemove(lineItem)}
              align="right"
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

LineItem.propTypes = {
  lineItem: LineItemPresenter.shape().isRequired,
  onActiveLineItemEditIdSet: PropTypes.func.isRequired,
  onNewItemFormHide: PropTypes.func.isRequired,
  onLineItemEdit: PropTypes.func.isRequired,
  isEditFormActive: PropTypes.bool.isRequired,
  onLineItemRemove: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  variant: PropTypes.oneOf(Object.values(TABLE_VARIANTS)),
};

LineItem.defaultProps = {
  variant: TABLE_VARIANTS.default,
};

export default LineItem;
