import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import Icon from 'components/Icon';
import InvoicePresenter from 'presenters/InvoicePresenter';

import useStyles from './useStyles';

const PayableInvoicesTableRows = props => {
  const { invoice, onRowClick } = props;
  const classes = useStyles();

  const handleRowClick = () => {
    onRowClick(invoice);
  };

  return (
    <TableRow hover className={classes.row} onClick={handleRowClick}>
      <TableCell align="left" className={classes.cell}>
        <div className={classes.invoiceNameContainer}>
          <Icon name={InvoicePresenter.invoiceIconName(invoice)} width={38} height={38} />
          <span className={classes.invoiceName}>{InvoicePresenter.builderInvoiceTitle(invoice)}</span>
        </div>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {InvoicePresenter.assignedProjects(invoice).map(project => (
          <div key={project.id}>{project.name}</div>
        ))}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {InvoicePresenter.builderLineItemLabels(invoice)?.map((itemLabel, id) => (
          <Typography key={id}>{itemLabel}</Typography>
        ))}
      </TableCell>
      <TableCell align="left" className={classes.cell} />
      <TableCell align="right" className={classes.cell}>
        {InvoicePresenter.formattedUSDTotalAmount(invoice)}
      </TableCell>
    </TableRow>
  );
};

PayableInvoicesTableRows.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default PayableInvoicesTableRows;
