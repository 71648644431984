import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  invoiceResponseFormContainer: {
    padding: '8px 0 28px',
    minWidth: 600,
  },
  header: {
    padding: theme.spacing(0, 3),
  },
}));
