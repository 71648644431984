import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import GuestLayout from 'layouts/GuestLayout';
import { useAuthActions } from 'store/AuthSlice';
import { getUrlParam } from 'utils/location';
import { isBlank } from 'utils/storeUtils';
import AppRoutes from 'routes/AppRoutes';

import Form from './components/Form';
import Confirmation from './components/Confirmation';

const PasswordReset = () => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { resetPassword } = useAuthActions();
  const history = useHistory();

  const passwordResetToken = getUrlParam('password_reset_token');

  useEffect(() => {
    if (isBlank(passwordResetToken)) {
      history.push(AppRoutes.signInPath());
    }
  }, []);

  const handleFormSubmit = async ({ password }) => {
    await resetPassword({ passwordResetToken, passwordReset: { password } });
    setIsPasswordChanged(true);
  };

  const renderContent = () => (isPasswordChanged ? <Confirmation /> : <Form onFormSubmit={handleFormSubmit} />);

  return <GuestLayout>{renderContent()}</GuestLayout>;
};

export default PasswordReset;
