import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  massCreate(params) {
    const path = apiRoutes.massCreateSiteApiV1BuilderCompanyOnboardingMembersPath();

    return FetchHelpers.post(path, params);
  },
  checkAvailability(params) {
    const path = apiRoutes.checkAvailabilitySiteApiV1BuilderCompanyOnboardingMembersPath();

    return FetchHelpers.get(path, params);
  },
};
