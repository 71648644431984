import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    minHeight: 96,
    display: 'flex',
    borderRadius: 8,
  },
  iconContainer: {
    backgroundColor: theme.palette.colors.green,
    width: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'inherit',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  checkIcon: {
    fill: theme.palette.colors.white,
  },
  contentContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 'inherit',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 340,
    padding: '16px 32px 12px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 18,
  },
  description: {
    marginTop: 5,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
}));
