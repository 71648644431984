import { useState } from 'react';
import { isEmpty } from 'ramda';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';

import ProjectPresenter from 'presenters/ProjectPresenter';

import ClientInviteForm from './components/ClientInviteForm';
import ClientInformation from './components/ClientInformation';

import useStyles from './useStyles';

const InviteClientDetails = props => {
  const { project } = props;
  const classes = useStyles();
  const [isSoldDialogOpen, setIsSoldDialogOpen] = useState(false);

  const handleInviteButtonClick = () => {
    setIsSoldDialogOpen(true);
  };

  const handleClose = afterCloseCallback => {
    setIsSoldDialogOpen(false);
    if (afterCloseCallback) {
      afterCloseCallback();
    }
  };

  const isAnyClient = !isEmpty(ProjectPresenter.customers(project));
  const isProjectCompleted = ProjectPresenter.isCompleted(project);

  return (
    <>
      <div className={classes.label}>Client Details</div>
      {isAnyClient ? (
        <ClientInformation project={project} />
      ) : (
        <div className={classes.inviteButtonContainer}>
          {isProjectCompleted ? (
            <Tooltip title={"This is a completed project. You can't invite clients."}>
              <span>
                <Button variant="contained" color="primary" disabled onClick={handleInviteButtonClick}>
                  Invite Client
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button variant="contained" color="primary" onClick={handleInviteButtonClick}>
              Invite Client
            </Button>
          )}
        </div>
      )}
      <Dialog open={isSoldDialogOpen} onClose={() => handleClose()} maxWidth="sm" fullWidth>
        <ClientInviteForm project={project} onClose={handleClose} />
      </Dialog>
    </>
  );
};

InviteClientDetails.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default InviteClientDetails;
