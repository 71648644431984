import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { IUserActivationFormSubmitData } from "./schemas/activation";
import { CurrentUser } from "./types";

const userApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        url: apiRoutes.siteApiV1CurrentUserPath(),
        method: "GET",
      }),
      transformResponse: (response: { user: CurrentUser }) => response.user,
      providesTags: [CacheTag.currentUser],
    }),
    activateUser: builder.mutation<void, IUserActivationFormSubmitData>({
      query: (parameters) => ({
        url: apiRoutes.siteApiV1UserActivationPath(),
        method: "POST",
        data: parameters,
      }),
      invalidatesTags: [CacheTag.currentUser],
    }),
  }),
  overrideExisting: false,
});

export const { useActivateUserMutation, useGetCurrentUserQuery, useLazyGetCurrentUserQuery } = userApi;
