import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiFormHelperTextType {
  defaultProps: ComponentsProps["MuiFormHelperText"];
  styleOverrides: ComponentsOverrides["MuiFormHelperText"];
}

const MuiFormHelperText: IMuiFormHelperTextType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      position: "absolute",
      top: 60,
      right: 0,
      marginRight: 0,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
      "&.Mui-error": {
        color: baseTheme.palette.colors.red,
      },
    },
  },
};

export default MuiFormHelperText;
