import PropTypes from 'prop-types';

import TeamSettingsPage from 'containers/TeamSettingsPage';
import { TEAM_SETTINGS_VARIANTS } from 'constants/TeamSettings';
import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';

const ProjectTeam = props => {
  const {
    project,
    loadProjectManagers,
    loadCompanyManagers,
    resetProjectManagers,
    resetCompanyManagers,
    projectManagers,
    companyManagers,
    projectManagersMeta,
    companyManagersMeta,
    massCreateManagers,
    deleteManager,
  } = props;

  const handleCompanyManagersLoad = (page = 1) => loadCompanyManagers(project.id, page);

  const handleProjectManagersLoad = (page = 1) => loadProjectManagers(project.id, page);

  const handleMassCreateManagers = params => {
    return massCreateManagers(project.id, params);
  };

  const handleMemberDestroy = member => {
    return deleteManager(project.id, member.id);
  };

  const handleTeamSettingsReset = () => {
    resetProjectManagers();
    resetCompanyManagers();
  };

  return (
    <TeamSettingsPage
      members={projectManagers}
      membersMeta={projectManagersMeta}
      loadMembers={handleProjectManagersLoad}
      destroyMember={handleMemberDestroy}
      createMember={handleMassCreateManagers}
      resetTeamSettings={handleTeamSettingsReset}
      variant={TEAM_SETTINGS_VARIANTS.project}
      description="View your Funl project team."
      shouldRenderHeader={false}
      membersForAssignment={companyManagers}
      loadMembersForAssignment={handleCompanyManagersLoad}
      resetMembersForAssignment={resetCompanyManagers}
      membersForAssignmentMeta={companyManagersMeta}
    />
  );
};

ProjectTeam.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  loadProjectManagers: PropTypes.func.isRequired,
  loadCompanyManagers: PropTypes.func.isRequired,
  resetProjectManagers: PropTypes.func.isRequired,
  resetCompanyManagers: PropTypes.func.isRequired,
  projectManagers: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  companyManagers: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  projectManagersMeta: PropTypes.shape().isRequired,
  companyManagersMeta: PropTypes.shape().isRequired,
  massCreateManagers: PropTypes.func.isRequired,
  deleteManager: PropTypes.func.isRequired,
};

export default ProjectTeam;
