import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(projectId, params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectManagersPath(projectId);

    return FetchHelpers.get(path, params);
  },
  massCreate(projectId, params) {
    const path = apiRoutes.massCreateSiteApiV1BuilderCompanyProjectManagersPath(projectId);

    return FetchHelpers.post(path, params);
  },
  destroy(projectId, userId) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectManagerPath(projectId, userId);

    return FetchHelpers.delete(path);
  },
};
