import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Route, Switch, Redirect } from 'react-router-dom';
import { isNil } from 'ramda';

import CircularProgress from '@mui/material/CircularProgress';

import { useProjectActions, selectors } from 'store/ProjectSlice';
import useProjectLayout from 'hooks/useProjectLayout';
import useLoading from 'hooks/useLoading';
import AppRoutes from 'routes/AppRoutes';
import { PROJECT_LINE_ITEMS_STATE_EVENTS } from 'presenters/LineItemPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import ProjectDetailsActions from './components/ProjectDetailsActions';
import ProjectHeader from './components/ProjectHeader';
import ProjectDetails from './components/ProjectDetails';
import ProjectInvoices from './components/ProjectInvoices';
import ProjectTeam from './components/ProjectTeam';
import ProjectClientInvoices from './components/ProjectClientInvoices';

import useStyles from './useStyles';

const Project = () => {
  const classes = useStyles();
  const {
    loadProject,
    loadCompanyLineItems,
    loadProjectLineItems,
    createProjectLineItem,
    updateProjectLineItem,
    destroyProjectLineItem,
    massCreateProjectLineItem,
    updateProject,
    massCreateManagers,
    deleteManager,
    loadOpenInvoices,
    loadProjectManagers,
    loadCompanyManagers,
    loadApprovedInvoices,
    loadClosedInvoices,
    completeProject,
    resetInvoices,
    resetProjectManagers,
    resetCompanyManagers,
    resetCompanyLineItems,
    resetProjectLineItems,
    resetProject,
    resetResponseDialog,
    resetModalInvoice,
  } = useProjectActions();
  const { data: project } = useSelector(selectors.project);
  const { data: companyLineItems, meta: companyLineItemsMeta } = useSelector(selectors.companyLineItems);
  const { data: projectLineItems, meta: projectLineItemsMeta } = useSelector(selectors.projectLineItems);
  const { data: projectManagers, meta: projectManagersMeta } = useSelector(selectors.projectManagers);
  const { data: companyManagers, meta: companyManagersMeta } = useSelector(selectors.companyManagers);
  const { data: approvedInvoices, meta: approvedInvoicesMeta } = useSelector(selectors.approvedInvoices);
  const { data: openInvoices, meta: openInvoicesMeta } = useSelector(selectors.openInvoices);
  const { data: closedInvoices, meta: closedInvoicesMeta } = useSelector(selectors.closedInvoices);
  const { isPending: isOpenInvoicesLoading, func: onOpenInvoicesLoad } = useLoading(loadOpenInvoices);
  const { isPending: isApprovedInvoicesLoading, func: onApprovedInvoicesLoad } = useLoading(loadApprovedInvoices);
  const { isPending: isClosedInvoicesLoading, func: onClosedInvoicesLoad } = useLoading(loadClosedInvoices);
  const { isNotFinished: isProjectLoading, func: sendLoadProject } = useLoading(loadProject);
  const { isNotFinished: isCompanyLineItemsLoading, func: sendLoadCompanyLineItems } = useLoading(loadCompanyLineItems);
  const { isNotFinished: isProjectLineItemsLoading, func: sendLoadProjectLineItems } = useLoading(loadProjectLineItems);
  const routeParams = useParams();
  const projectId = Number(routeParams.id);
  const { data: currentUser } = useGetCurrentUserQuery();
  const isManager = UserPresenter.isManager(currentUser);

  useProjectLayout(projectId, project);

  const isProjectDraft = ProjectPresenter.isDraft(project);

  useEffect(() => {
    sendLoadProject(projectId);
    sendLoadProjectLineItems(projectId);

    return resetProject;
  }, []);

  if (isProjectLoading) {
    return (
      <div className={classes.loaderRoot}>
        <CircularProgress />
      </div>
    );
  }

  const handleCompanyLineItemsLoad = page => {
    sendLoadCompanyLineItems(projectId, page);
  };

  const handleProjectLineItemsLoad = page => {
    sendLoadProjectLineItems(projectId, page);
  };

  const handleProjectLineItemCreate = async params => {
    await createProjectLineItem(projectId, params);
    resetProjectLineItems();
    sendLoadProjectLineItems(projectId);
  };

  const handleProjectLineItemUpdate = (
    { projectLineItemId, id, projectLineItemState, ...params },
    lineItemBeforeChange,
  ) => {
    const stateEvent =
      lineItemBeforeChange.projectLineItemState === projectLineItemState
        ? null
        : PROJECT_LINE_ITEMS_STATE_EVENTS[projectLineItemState];
    const lineItemAttributes = params;

    const projectLineItemParams = isNil(stateEvent) ? { lineItemAttributes } : { stateEvent, lineItemAttributes };
    return updateProjectLineItem(projectId, projectLineItemId, projectLineItemParams);
  };

  const handleProjectLineItemDestroy = ({ projectLineItemId }) => destroyProjectLineItem(projectId, projectLineItemId);

  const handleProjectLineItemMassCreate = async params => {
    await massCreateProjectLineItem(projectId, params);
    resetProjectLineItems();
    sendLoadProjectLineItems(projectId);
  };

  const handleProjectUpdate = async params => {
    await updateProject(projectId, params);
    sendLoadProject(projectId);
  };

  const handleProjectComplete = async () => {
    await completeProject(projectId);
    sendLoadProject(projectId);
  };

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Switch>
          <Route path={AppRoutes.projectDetailsPath(':id')} exact>
            <ProjectHeader
              project={project}
              actions={
                <ProjectDetailsActions
                  project={project}
                  onProjectUpdate={handleProjectUpdate}
                  onProjectComplete={handleProjectComplete}
                />
              }
            />
            <ProjectDetails
              project={project}
              onCompanyLineItemsLoad={handleCompanyLineItemsLoad}
              onCompanyLineItemsReset={resetCompanyLineItems}
              companyLineItems={companyLineItems}
              isCompanyLineItemsLoading={isCompanyLineItemsLoading}
              companyLineItemsMeta={companyLineItemsMeta}
              onProjectLineItemsLoad={handleProjectLineItemsLoad}
              projectLineItems={projectLineItems}
              isProjectLineItemsLoading={isProjectLineItemsLoading}
              projectLineItemsMeta={projectLineItemsMeta}
              onProjectLineItemCreate={handleProjectLineItemCreate}
              onProjectLineItemUpdate={handleProjectLineItemUpdate}
              onProjectLineItemDestroy={handleProjectLineItemDestroy}
              onProjectLineItemMassCreate={handleProjectLineItemMassCreate}
            />
          </Route>
          <Route path={AppRoutes.projectTeamPath(':id')} exact>
            {!isManager && !isProjectDraft ? (
              <>
                <ProjectHeader project={project} />
                <ProjectTeam
                  project={project}
                  loadProjectManagers={loadProjectManagers}
                  loadCompanyManagers={loadCompanyManagers}
                  resetProjectManagers={resetProjectManagers}
                  resetCompanyManagers={resetCompanyManagers}
                  projectManagers={projectManagers}
                  companyManagers={companyManagers}
                  projectManagersMeta={projectManagersMeta}
                  companyManagersMeta={companyManagersMeta}
                  massCreateManagers={massCreateManagers}
                  deleteManager={deleteManager}
                />
              </>
            ) : (
              <Redirect to={AppRoutes.projectDetailsPath(projectId)} />
            )}
          </Route>
          <Route path={AppRoutes.projectInvoicesPath(':id')} exact>
            {!isProjectDraft ? (
              <>
                <ProjectHeader project={project} />
                <ProjectInvoices
                  project={project}
                  openInvoices={openInvoices}
                  openInvoicesMeta={openInvoicesMeta}
                  approvedInvoices={approvedInvoices}
                  approvedInvoicesMeta={approvedInvoicesMeta}
                  isApprovedInvoicesLoading={isApprovedInvoicesLoading}
                  closedInvoices={closedInvoices}
                  closedInvoicesMeta={closedInvoicesMeta}
                  isClosedInvoicesLoading={isClosedInvoicesLoading}
                  isOpenInvoicesLoading={isOpenInvoicesLoading}
                  loadOpenInvoices={onOpenInvoicesLoad}
                  loadApprovedInvoices={onApprovedInvoicesLoad}
                  loadClosedInvoices={onClosedInvoicesLoad}
                  resetInvoices={resetInvoices}
                  resetResponseDialog={resetResponseDialog}
                  resetModalInvoice={resetModalInvoice}
                />
              </>
            ) : (
              <Redirect to={AppRoutes.projectDetailsPath(projectId)} />
            )}
          </Route>
          <Route path={AppRoutes.projectClientPaymentsPath(':id')} exact>
            {!isProjectDraft ? (
              <ProjectClientInvoices project={project} />
            ) : (
              <Redirect to={AppRoutes.projectDetailsPath(projectId)} />
            )}
          </Route>
          <Redirect to={AppRoutes.projectDetailsPath(projectId)} />
        </Switch>
      </main>
    </div>
  );
};

export default Project;
