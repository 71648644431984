import * as yup from 'yup';

import { EMAIL, PASSWORD } from './errorsText';

const validationFields = {
  email: yup.string().email(EMAIL).required(EMAIL),
  password: yup.string().required(PASSWORD),
};

export const initialValues = {
  email: '',
  password: '',
};

export const validationSchema = yup.object().shape(validationFields);
