import { Route, Switch, Redirect } from 'react-router-dom';

import AdminInvoices from 'components/pages/admin/Invoices';

import AppRoutes from 'routes/AppRoutes';
import UserLayout from 'layouts/UserLayout';
import UserPresenter from 'presenters/UserPresenter';
import AdminProjects from 'containers/Admin/AdminProjects';
import Builders from 'containers/Admin/Builders';
import Subcontractors from 'containers/Admin/Subcontractors';
import Loans from 'containers/Admin/Loans';
import AdminInvoice from 'containers/Admin/AdminInvoice';
import AdminProject from 'containers/Admin/AdminProject';
import AdminPayments from 'containers/Admin/AdminPayments';

const AdminSwitch = props => {
  const { currentUser } = props;

  return (
    <UserLayout currentUser={currentUser}>
      <Switch>
        <Route path={AppRoutes.adminProjectsPath()} exact component={AdminProjects} />
        <Route path={AppRoutes.adminProjectPath(':id')} component={AdminProject} />
        <Route path={AppRoutes.adminBuildersPath()} exact component={Builders} />
        <Route path={AppRoutes.adminSubcontractorsPath()} exact component={Subcontractors} />
        <Route path={AppRoutes.adminLoansPath()} exact component={Loans} />
        <Route path={AppRoutes.adminInvoicesPath()} exact component={AdminInvoices} />
        <Route path={AppRoutes.adminInvoicePath(':id')} exact component={AdminInvoice} />
        <Route path={AppRoutes.adminPaymentsPath()} exact component={AdminPayments} />

        <Redirect to={AppRoutes.adminProjectsPath()} />
      </Switch>
    </UserLayout>
  );
};

AdminSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default AdminSwitch;
