import * as yup from 'yup';
import { nanoid } from 'nanoid';

import { sentencize } from 'utils/stringUtils';
import { joinMoreThanOne } from 'utils/arrayUtils';
import { isBlank } from 'utils/storeUtils';
import { EMAIL, REQUIRED, NUMBER, POSITIVE, maxAmountValue } from 'forms/errorsText';
import { formatUSD } from 'utils/numberUtils';
import InvoicePresenter from 'presenters/InvoicePresenter';
import { MAX_AMOUNT_PAYMENT } from 'constants/invoices';

const validationFields = {
  projectName: yup.string().required(REQUIRED),
  name: yup.string().required(REQUIRED),
  number: yup.string(),
  purchaseOrder: yup.string(),
  builderEmail: yup.string().email(EMAIL).required(REQUIRED),
  note: yup.string(),
  attachment: yup.mixed().nullable(),
  paymentKind: yup.mixed().oneOf(['default', 'early_payment']).default('default'),
  inboundLineItems: yup
    .array()
    .of(
      yup.object().shape({
        accountCode: yup.string(),
        categoryName: yup.string().required(REQUIRED),
        amount: yup
          .number()
          .typeError(NUMBER)
          .positive(POSITIVE)
          .max(MAX_AMOUNT_PAYMENT, maxAmountValue(formatUSD(MAX_AMOUNT_PAYMENT, 2)))
          .required(REQUIRED),
      }),
    )
    .min(1),
};

export const initialValues = {
  projectName: '',
  name: '',
  number: '',
  purchaseOrder: '',
  builderName: '',
  builderEmail: '',
  note: '',
  attachment: null,
  inboundLineItems: [{ key: nanoid(), accountCode: '', categoryName: '', amount: '' }],
  earlyPaymentId: null,
  termsOfService: false,
  paymentKind: 'default',
  serverData: {
    invoiceEarlyPayment: {
      id: null,
      earlyPaymentId: null,
    },
  },
};

export const validationSchema = yup.object().shape(validationFields);

export const formDataToApi = invoice => {
  const getEarlyPaymentsAttributes = () => {
    if (invoice.serverData.invoiceEarlyPayment.earlyPaymentId && !invoice.earlyPaymentId) {
      return {
        _destroy: true,
        id: invoice.serverData.invoiceEarlyPayment.id,
      };
    }

    if (invoice.serverData.invoiceEarlyPayment.earlyPaymentId && invoice.earlyPaymentId) {
      return {
        earlyPaymentId: invoice.earlyPaymentId,
        id: invoice.serverData.invoiceEarlyPayment.id,
      };
    }

    if (invoice.earlyPaymentId) {
      return {
        earlyPaymentId: invoice.earlyPaymentId,
      };
    }

    return {};
  };

  const getInboundLineItemsAttributes = () => {
    if (!isBlank(invoice.serverData.inboundLineItems)) {
      const destroyedLineItems = invoice.serverData.inboundLineItems
        .filter(item => invoice.inboundLineItems.every(({ id }) => id !== item.id))
        .map(item => ({ id: item.id, _destroy: true }));

      return [...invoice.inboundLineItems, ...destroyedLineItems];
    }

    return invoice.inboundLineItems;
  };

  const getDeleteAttachment = () => {
    if (!invoice.serverData.attachmentName) {
      return false;
    }

    return invoice.serverData.attachmentName !== invoice.attachmentName;
  };

  return {
    invoice: {
      name: invoice.name,
      builderEmail: invoice.builderEmail,
      projectName: invoice.projectName,
      number: invoice.number,
      purchaseOrder: invoice.purchaseOrder,
      note: invoice.note,
      paymentKind: invoice.paymentKind,
      deleteAttachment: getDeleteAttachment(),
      inboundLineItemsAttributes: getInboundLineItemsAttributes(),
      invoiceEarlyPaymentAttributes: getEarlyPaymentsAttributes(),
      ...(invoice.attachment && { attachment: invoice.attachment }),
    },
  };
};

export const apiErrorsToForm = errors => {
  const separator = ', ';
  const formErrors = {
    projectName: sentencize(joinMoreThanOne(errors.project, separator)),
    name: sentencize(joinMoreThanOne(errors.name, separator)),
    builderEmail: sentencize(joinMoreThanOne(errors.builderEmail, separator)),
  };

  return formErrors;
};

export const apiInvoiceToForm = invoice => {
  const inboundLineItems = InvoicePresenter.inboundLineItems(invoice);
  return {
    projectName: InvoicePresenter.projectName(invoice) || initialValues.name,
    name: InvoicePresenter.name(invoice) || initialValues.name,
    number: InvoicePresenter.number(invoice) || initialValues.number,
    purchaseOrder: InvoicePresenter.purchaseOrder(invoice) || initialValues.purchaseOrder,
    builderEmail: InvoicePresenter.builderEmail(invoice) || initialValues.builderEmail,
    builderName: InvoicePresenter.builderName(invoice) || initialValues.builderName,
    note: InvoicePresenter.note(invoice) || initialValues.note,
    attachment: null,
    attachmentName: InvoicePresenter.attachmentName(invoice) || null,
    inboundLineItems: inboundLineItems.length
      ? InvoicePresenter.inboundLineItems(invoice).map(item => ({ key: nanoid(), ...item }))
      : initialValues.inboundLineItems,
    earlyPaymentId: InvoicePresenter.invoiceEarlyPayment(invoice)
      ? InvoicePresenter.earlyPaymentId(invoice)
      : initialValues.earlyPaymentId,
    termsOfService: !isBlank(InvoicePresenter.earlyPaymentId(invoice)),
    paymentKind: invoice.paymentKind || 'default',
    serverData: {
      attachmentName: InvoicePresenter.attachmentName(invoice),
      inboundLineItems,
      invoiceEarlyPayment: {
        earlyPaymentId: InvoicePresenter.earlyPaymentId(invoice),
        id: InvoicePresenter.earlyPaymentOptionId(invoice),
      },
    },
  };
};
