export default {
  invoice: {
    selfPaidInvoiceTitle: "Funl Invoice",
    status: {
      pending: "Pending",
      awaitingApproval: "Awaiting Approval",
      rejected: "Rejected",
      canceled: "Canceled",
      approved: "Approved",
      yourActionIsRequired: "Your Action is Required",
      pendingActionFromSC: "Pending Action from SC",
      earlyPaymentRequested: "Early Payment Requested",
      paid: "Paid",
      selfPaid: {
        pending: "Awaiting Approval by Funl",
        awaitingApproval: "Awaiting Approval by Funl",
        rejected: "Rejected by Funl",
      },
    },
  },
  defaultSuccessNotificationText: "Success!",
  defaultErrorNotificationText: "Oops! Something went wrong!",
};
