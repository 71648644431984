import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiInputBaseType {
  defaultProps: ComponentsProps["MuiInputBase"];
  styleOverrides: ComponentsOverrides["MuiInputBase"];
}

const MuiInputBase: IMuiInputBaseType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: "100%",
    },
  },
};

export default MuiInputBase;
