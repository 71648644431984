import { isNil, isEmpty, or } from 'ramda';

export const isBlank = value => or(isEmpty(value), isNil(value));

export const mapObject = (object, callback) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    const [newKey, newValue] = callback(key, value);

    return { ...acc, [newKey]: newValue };
  }, {});
