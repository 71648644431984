import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  awaitingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  awaitingIcon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.colors.orange,
  },
  payButton: {
    height: theme.spacing(4),
  },
}));
