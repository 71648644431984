import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { nanoid } from 'nanoid';

import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';
import { format } from 'utils/dateUtils';
import { isBlank } from 'utils/storeUtils';
import { getInvoiceTotal } from 'utils/invoiceUtils';
import LineItemPresenter from 'presenters/LineItemPresenter';
import InvoiceLineItemPresenter from 'presenters/InvoiceLineItemPresenter';
import InboundLineItemPresenter from 'presenters/InboundLineItemPresenter';
import BuilderPresenter from 'presenters/BuilderPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import SubcontractorPresenter from 'presenters/SubcontractorPresenter';
import EarlyPaymentPresenter from 'presenters/EarlyPaymentPresenter';

export const INVOICE_STATES = {
  pending: 'pending',
  rejected: 'rejected',
  approved: 'approved',
  paid: 'paid',
  canceled: 'canceled',
  awaitingApproval: 'awaiting_approval',
};

export const INVOICE_KINDS = {
  requested: 'requested',
  selfPaid: 'self_paid',
};

const IMAGE_STATE_VARIANT = {
  [INVOICE_STATES.pending]: 'documentOpen',
  [INVOICE_STATES.rejected]: 'documentRejected',
  [INVOICE_STATES.approved]: 'documentApproved',
  [INVOICE_STATES.canceled]: 'documentCanceled',
  [INVOICE_STATES.paid]: 'documentClosed',
  [INVOICE_KINDS.selfPaid]: {
    [INVOICE_STATES.pending]: 'documentPendingApprovalBySystem',
  },
};

const INVOICE_STATE_TABLE_TEXT = {
  [INVOICE_STATES.pending]: 'Awaiting Approval',
  [INVOICE_STATES.rejected]: 'Rejected',
  [INVOICE_STATES.canceled]: 'Canceled',
  [INVOICE_KINDS.selfPaid]: {
    [INVOICE_STATES.pending]: 'Awaiting Approval by Funl',
    [INVOICE_STATES.rejected]: 'Rejected by Funl',
  },
};

const SELF_PAID_INVOICE_TITLE = 'Funl Invoice';

const NO_REASON_TEXT = "Invoice addressee didn't specify the reason.";

const ZERO_DAYS_TERMS_LABEL = 'Early';

export default new Presenter(
  {
    id: PropTypes.number,
    kind: PropTypes.oneOf(Object.values(INVOICE_KINDS)),
    name: PropTypes.string,
    projectName: PropTypes.string,
    projectedPaidAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    state: PropTypes.oneOf(Object.values(INVOICE_STATES)),
    totalAmount: PropTypes.string,
    note: PropTypes.string,
    number: PropTypes.string,
    purchaseOrder: PropTypes.string,
    builderEmail: PropTypes.string,
    inboundLineItems: PropTypes.arrayOf(InboundLineItemPresenter.shape()),
    invoiceLineItems: PropTypes.arrayOf(InvoiceLineItemPresenter.shape()),
    lineItems: PropTypes.arrayOf(LineItemPresenter.shape()),
    createdAt: PropTypes.string,
    paymentTerms: PropTypes.number,
    isAuthored: PropTypes.bool,
    builder: BuilderPresenter.shape(),
    projects: PropTypes.arrayOf(ProjectPresenter.shape()),
    subcontractor: SubcontractorPresenter.shape(),
    invoiceEarlyPayment: EarlyPaymentPresenter.shape(),
    rejectionReason: PropTypes.string,
    subcontractorEmail: PropTypes.string,
    attachmentUrl: PropTypes.string,
    attachmentName: PropTypes.string,
    paymentTermsDescription: PropTypes.string,
    automaticallyApproved: PropTypes.bool,
  },
  {
    stateTableText(invoice) {
      if (this.kind(invoice) === INVOICE_KINDS.selfPaid) {
        return INVOICE_STATE_TABLE_TEXT[INVOICE_KINDS.selfPaid][this.state(invoice)];
      }
      return INVOICE_STATE_TABLE_TEXT[this.state(invoice)];
    },
    formattedProjectedPaidAt(invoice) {
      return format(this.projectedPaidAt(invoice));
    },
    formattedCreatedAt(invoice) {
      return format(this.createdAt(invoice));
    },
    formattedUSDTotalAmount(invoice, maximumFractionDigits = 2) {
      if (isBlank(this.totalAmount(invoice))) {
        return formatUSD(getInvoiceTotal(this.inboundLineItems(invoice)), maximumFractionDigits);
      }

      return formatUSD(this.totalAmount(invoice), maximumFractionDigits);
    },
    builderName(invoice) {
      return BuilderPresenter.name(this.builder(invoice));
    },
    formattedPaymentTerms(invoice) {
      if (this.paymentTerms(invoice) === 0) {
        return ZERO_DAYS_TERMS_LABEL;
      }

      return pluralize('day', this.paymentTerms(invoice), true);
    },
    subcontractorName(invoice) {
      return SubcontractorPresenter.name(this.subcontractor(invoice));
    },
    assignedProjects(invoice) {
      return isBlank(this.projects(invoice))
        ? [{ id: nanoid(), name: this.projectName(invoice) }]
        : this.projects(invoice);
    },
    normalizedInvoiceLineItems(invoice) {
      return this.invoiceLineItems(invoice).map(item => ({
        ...InvoiceLineItemPresenter.lineItem(item),
        ...item,
      }));
    },
    pricedLineItems(invoice) {
      if (!isBlank(this.invoiceLineItems(invoice))) {
        return this.normalizedInvoiceLineItems(invoice);
      }

      if (!isBlank(this.inboundLineItems(invoice))) {
        return this.inboundLineItems(invoice);
      }

      return [];
    },
    builderLineItems(invoice) {
      if (!isBlank(this.invoiceLineItems(invoice))) {
        return this.normalizedInvoiceLineItems(invoice);
      }

      if (!isBlank(this.lineItems(invoice))) {
        return this.lineItems(invoice);
      }

      if (!isBlank(this.inboundLineItems(invoice))) {
        return this.inboundLineItems(invoice);
      }

      return [];
    },
    builderLineItemLabels(invoice) {
      const getItemLabel = item =>
        isBlank(item.accountCode) ? item.categoryName : `${item.accountCode} ${item.categoryName}`;

      return this.builderLineItems(invoice).map(getItemLabel);
    },
    assignedContact(invoice) {
      return this.builder(invoice) || this.subcontractor(invoice);
    },
    contactEmail(invoice) {
      if (this.assignedContact(invoice)) {
        return CompanyPresenter.companyOwnerEmail(this.assignedContact(invoice));
      }

      return this.builderEmail(invoice) || this.subcontractorEmail(invoice);
    },
    earlyPaymentId(invoice) {
      return (
        this.invoiceEarlyPayment(invoice) && EarlyPaymentPresenter.earlyPaymentId(this.invoiceEarlyPayment(invoice))
      );
    },
    earlyPaymentOptionId(invoice) {
      return this.invoiceEarlyPayment(invoice) && EarlyPaymentPresenter.id(this.invoiceEarlyPayment(invoice));
    },
    builderInvoiceTitle(invoice) {
      if (this.kind(invoice) === INVOICE_KINDS.selfPaid) {
        return SELF_PAID_INVOICE_TITLE;
      }

      const assignedSubcontractor = this.subcontractor(invoice);
      if (!isBlank(assignedSubcontractor)) {
        return SubcontractorPresenter.name(assignedSubcontractor);
      }

      return this.subcontractorEmail(invoice);
    },
    subcontractorInvoiceTitle(invoice) {
      const assignedBuilder = this.builder(invoice);
      if (!isBlank(assignedBuilder)) {
        return BuilderPresenter.name(assignedBuilder);
      }

      return this.builderEmail(invoice);
    },
    rejectionReasonText(invoice) {
      if (isBlank(this.rejectionReason(invoice))) {
        return NO_REASON_TEXT;
      }

      return this.rejectionReason(invoice);
    },
    invoiceIconName(invoice) {
      if (this.kind(invoice) === INVOICE_KINDS.selfPaid && this.state(invoice) === INVOICE_STATES.pending) {
        return IMAGE_STATE_VARIANT[INVOICE_KINDS.selfPaid][this.state(invoice)];
      }
      return IMAGE_STATE_VARIANT[this.state(invoice)];
    },
    isKindSelfPaid(invoice) {
      return this.kind(invoice) === INVOICE_KINDS.selfPaid;
    },
    isApprovable(invoice) {
      return this.state(invoice) === INVOICE_STATES.pending && !this.isKindSelfPaid(invoice);
    },
    isRejected(invoice) {
      return this.state(invoice) === INVOICE_STATES.rejected;
    },
    isAwaitingApproval(invoice) {
      return this.state(invoice) === INVOICE_STATES.awaitingApproval;
    },
    isApproved(invoice) {
      return this.state(invoice) === INVOICE_STATES.approved;
    },
  },
);
