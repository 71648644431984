import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  paper: {
    padding: '28px 24px 32px 24px',
    width: 528,
  },
  titleContainer: {
    display: 'flex',
  },
  plusIcon: {
    fill: theme.palette.colors.green,
    marginRight: 10,
    marginTop: 2,
  },
  formContainer: {
    marginTop: 36,
  },
  popover: {
    marginTop: -10,
  },
  checkboxContainer: {
    display: 'inline-flex',
  },
  checkboxLabel: {
    fontSize: 16,
  },
  helpIcon: {
    fill: theme.palette.colors.nurseGray,
    marginTop: 12,
    marginLeft: -10,
  },
  inputRoot: {
    '&:not(:first-child)': {
      marginTop: 37,
    },
  },
  selectContainer: {
    marginTop: 30,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  createButton: {
    marginLeft: 12,
  },
}));
