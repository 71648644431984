import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiAppBarType {
  defaultProps: ComponentsProps["MuiAppBar"];
  styleOverrides: ComponentsOverrides["MuiAppBar"];
}

const MuiAppBar: IMuiAppBarType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      backgroundColor: baseTheme.palette.colors.white,
      color: baseTheme.palette.colors.black,
      boxShadow: "none",
    },
  },
};

export default MuiAppBar;
