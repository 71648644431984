import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    maxHeight: 300,
    padding: theme.spacing(3, 2),
    overflowX: 'hidden',
  },
}));
