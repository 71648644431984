import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';

import Icon from 'components/Icon';
import AddButton from 'components/AddButton';
import InfiniteScroll from 'components/InfiniteScroll';
import PageHeader from 'components/PageHeader';
import UserPresenter from 'presenters/UserPresenter';
import { TEAM_SETTINGS_VARIANTS } from 'constants/TeamSettings';
import useLoading from 'hooks/useLoading';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import MembersTable from './components/MembersTable';
import NewMember from './components/NewMember';
import ProjectMembersAssignment from './components/ProjectMembersAssignment';
import useStyles from './useStyles';

const TeamSettingsPage = props => {
  const {
    members,
    membersMeta,
    loadMembers,
    updateMember,
    destroyMember,
    createMember,
    resetTeamSettings,
    description,
    variant,
    shouldRenderHeader,
    membersForAssignment,
    loadMembersForAssignment,
    resetMembersForAssignment,
    membersForAssignmentMeta,
  } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isPending, func: onMembersLoad } = useLoading(loadMembers);
  const { data: currentUser } = useGetCurrentUserQuery();
  const isManager = UserPresenter.isManager(currentUser);

  useEffect(() => {
    onMembersLoad();

    return resetTeamSettings;
  }, []);

  const handledDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handledDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleMemberCreate = async params => {
    await createMember(params);
    resetTeamSettings();
    onMembersLoad();
  };

  const handleMemberUpdate = (id, role) => {
    return updateMember(id, { userCompanyAttributes: { role } });
  };

  const handleMemberDestroy = async params => {
    await destroyMember(params);
    resetTeamSettings();
    onMembersLoad();
  };

  const isRoleManagementEnabled = variant === TEAM_SETTINGS_VARIANTS.global;
  const shouldRenderStatus = variant === TEAM_SETTINGS_VARIANTS.global;
  const addButtonLabel = variant === TEAM_SETTINGS_VARIANTS.global ? 'Add New User' : 'Add New Users';

  return (
    <div>
      {shouldRenderHeader ? (
        <header className={classes.header}>
          <PageHeader
            text="User Management"
            icon={<Icon className={classes.titleIcon} width={28} height={28} name="gear" />}
          />
          <div className={classes.headerDescription}>{description}</div>
        </header>
      ) : (
        <div className={classes.description}>{description}</div>
      )}
      <Paper className={classes.paper}>
        <div className={classes.toolbar}>
          {!isManager && <AddButton onClick={handledDialogOpen}>{addButtonLabel}</AddButton>}
          <div className={classes.total}>Total: {membersMeta.totalCount || 0}</div>
        </div>
        <div className={classes.tableContainer}>
          <InfiniteScroll nextPage={membersMeta.nextPage} isLoading={isPending} onLoad={onMembersLoad}>
            <MembersTable
              isLoading={isPending}
              members={members}
              onMemberUpdate={handleMemberUpdate}
              onMemberDestroy={handleMemberDestroy}
              isRoleManagementEnabled={isRoleManagementEnabled}
              shouldRenderStatus={shouldRenderStatus}
            />
          </InfiniteScroll>
        </div>
      </Paper>
      <Dialog open={isDialogOpen} onClose={handledDialogClose}>
        {variant === TEAM_SETTINGS_VARIANTS.global && (
          <NewMember onMemberCreate={handleMemberCreate} onClose={handledDialogClose} />
        )}
        {variant === TEAM_SETTINGS_VARIANTS.project && (
          <ProjectMembersAssignment
            onMembersMassCreate={handleMemberCreate}
            loadMembers={loadMembersForAssignment}
            onClose={handledDialogClose}
            members={membersForAssignment}
            resetMembers={resetMembersForAssignment}
            membersMeta={membersForAssignmentMeta}
          />
        )}
      </Dialog>
    </div>
  );
};

TeamSettingsPage.propTypes = {
  members: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  membersMeta: PropTypes.shape({
    totalCount: PropTypes.number,
    nextPage: PropTypes.number,
  }).isRequired,
  loadMembers: PropTypes.func.isRequired,
  destroyMember: PropTypes.func.isRequired,
  resetTeamSettings: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(TEAM_SETTINGS_VARIANTS)).isRequired,
  createMember: PropTypes.func,
  updateMember: PropTypes.func,
  shouldRenderHeader: PropTypes.bool,
  membersForAssignment: PropTypes.arrayOf(PropTypes.shape()),
  loadMembersForAssignment: PropTypes.func,
  resetMembersForAssignment: PropTypes.func,
  membersForAssignmentMeta: PropTypes.shape(),
};

TeamSettingsPage.defaultProps = {
  createMember: Function.prototype,
  updateMember: Function.prototype,
  membersForAssignment: [],
  shouldRenderHeader: true,
  loadMembersForAssignment: Function.prototype,
  resetMembersForAssignment: Function.prototype,
  membersForAssignmentMeta: {},
};

export default TeamSettingsPage;
