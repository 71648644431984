import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(9),
  },
  header: {
    marginTop: 10,
    width: '100%',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
  tip: {
    marginLeft: 43,
    marginTop: 6,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.colors.gunsmokeGray,
  },
}));
