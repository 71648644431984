import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import LineItemPresenter from 'presenters/LineItemPresenter';
import RoundCheckbox from 'components/RoundCheckbox';

import useStyles from './useStyles';

const TemplateLineItemsRows = props => {
  const { selectedLineItems, items, onLineItemSelectToggle } = props;
  const classes = useStyles();

  return items.map(lineItem => {
    const isChecked = Boolean(selectedLineItems.find(item => item.id === lineItem.id));

    return (
      <TableRow key={lineItem.id} className={classes.row} hover onClick={onLineItemSelectToggle(lineItem)}>
        <TableCell className={classes.nameCell}>{LineItemPresenter.categoryName(lineItem)}</TableCell>
        <TableCell className={classes.actionCell}>
          <RoundCheckbox checked={isChecked} color="primary" />
        </TableCell>
      </TableRow>
    );
  });
};

TemplateLineItemsRows.propTypes = {
  selectedLineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  items: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  onLineItemSelectToggle: PropTypes.func.isRequired,
};

export default TemplateLineItemsRows;
