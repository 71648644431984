import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  tittle: {
    transform: ['translate(0, 0)', 'scale(0.75)'],
    transformOrigin: ['top', 'left'],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.colors.deltaGray,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  attachmentsContainer: {
    marginTop: 28,
  },
  attachRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContainer: {
    padding: theme.spacing(4),
    minWidth: 400,
  },
  dialogTitleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.colors.green,
  },
}));
