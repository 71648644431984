import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  invoicesContainer: {
    marginTop: theme.spacing(2),
  },
}));
