import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  title: {
    marginBottom: '12px',
    letterSpacing: '-0.2px',
    textTransform: 'uppercase',
    color: theme.palette.colors.blackOlive,
    opacity: 0.5,
    fontWeight: 500,
  },
  content: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    marginBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentHeader: {
    marginBottom: '8px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: theme.palette.colors.blackOlive,
    opacity: 0.9,
  },
  text: {
    lineHeight: '20px',
    color: 'rgba(32, 36, 20, 0.6)',
  },
  buttons: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',
    marginBottom: '8px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    height: 'auto',
    padding: '16px',

    [theme.breakpoints.up('sm')]: {
      height: '48px',
      padding: '8px 24px',
    },
  },
  buttonActive: {
    color: 'primary.main',
    border: `1px solid ${theme.palette.primary.main}`,
    outline: `1px solid ${theme.palette.primary.main}`,
  },
  agreement: {
    padding: '24px',
  },
}));
