import * as yup from 'yup';

import { EMAIL, PASSWORD, REQUIRED, minLength } from './errorsText';

const PASSWORD_MIN_LENGTH = 8;

const validationFields = {
  fullName: yup.string().required(REQUIRED),
  email: yup.string().email(EMAIL).required(EMAIL),
  password: yup.string().min(PASSWORD_MIN_LENGTH, minLength(PASSWORD_MIN_LENGTH)).required(PASSWORD),
};

export const initialValues = {
  fullName: '',
  email: '',
  password: '',
};

export const validationSchema = yup.object().shape(validationFields);

export const formPaths = {
  email: 'email',
};
