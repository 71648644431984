import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import UserInvitationPresenter from 'presenters/UserInvitationPresenter';
import CompanyConnectionPresenter, { STATES } from 'presenters/CompanyConnectionPresenter';
import Icon from 'components/Icon';
import TableEmptyRow from 'components/TableEmptyRow';
import TableLoader from 'components/TableLoader';

import ResendInvite from './components/ResendInvite';
import useStyles from './useStyles';

const MAX_COL_SPAN = 3;

const ContactsTable = props => {
  const { connections, pendingInvitations, isLoading, onInviteResend } = props;
  const classes = useStyles();

  const isEmptyContacts = !isLoading && isEmpty(connections) && isEmpty(pendingInvitations);

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow className={classes.row}>
            <TableCell className={classes.headerCell}>Name</TableCell>
            <TableCell className={classes.headerCell}>Email</TableCell>
            <TableCell className={classes.headerCell}>Status</TableCell>
            <TableCell className={classes.headerCell} />
          </TableRow>
        </TableHead>

        <TableBody>
          {!isLoading && !isEmptyContacts ? (
            <>
              {connections.map(connection => (
                <TableRow className={classes.row} key={`connection[${CompanyConnectionPresenter.id(connection)}]`}>
                  <TableCell className={classes.cell}>{CompanyConnectionPresenter.companyName(connection)}</TableCell>
                  <TableCell
                    title={CompanyConnectionPresenter.companyOwnerEmail(connection)}
                    className={classes.emailCell}
                  >
                    {CompanyConnectionPresenter.companyOwnerEmail(connection)}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {CompanyConnectionPresenter.state(connection) === STATES.accepted && (
                      <div className={classes.statusContainer}>
                        <Icon width={14} height={14} name="roundCheckbox" className={classes.checkIcon} />
                        Confirmed
                      </div>
                    )}
                    {CompanyConnectionPresenter.state(connection) === STATES.pending && (
                      <div className={classes.statusContainer}>
                        <Icon width={11} height={15} name="sandglass" className={classes.sandglassIcon} />
                        Waiting Confirmation
                      </div>
                    )}
                    {CompanyConnectionPresenter.state(connection) === STATES.rejected && (
                      <div className={classes.statusContainer}>
                        <Icon width={14} height={14} name="containedCross" className={classes.crossIcon} />
                        Rejected
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={classes.cell} />
                </TableRow>
              ))}
              {pendingInvitations.map(invitation => (
                <TableRow className={classes.row} key={`invitation[${UserInvitationPresenter.id(invitation)}]`}>
                  <TableCell className={classes.cell} />
                  <TableCell title={UserInvitationPresenter.referralEmail(invitation)} className={classes.emailCell}>
                    {UserInvitationPresenter.referralEmail(invitation)}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <div className={classes.statusContainer}>
                      <Icon width={11} height={15} name="sandglass" className={classes.sandglassIcon} />
                      Waiting Confirmation
                    </div>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <ResendInvite
                      email={UserInvitationPresenter.referralEmail(invitation)}
                      onInviteResend={onInviteResend}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            isLoading && <TableLoader colSpan={MAX_COL_SPAN} />
          )}
          {isEmptyContacts && (
            <TableEmptyRow colSpan={MAX_COL_SPAN} text={<>You didn&apos;t send any invitations yet.</>} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ContactsTable.propTypes = {
  connections: PropTypes.arrayOf(CompanyConnectionPresenter.shape()).isRequired,
  pendingInvitations: PropTypes.arrayOf(UserInvitationPresenter.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInviteResend: PropTypes.func.isRequired,
};

export default ContactsTable;
