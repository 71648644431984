import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import InvoicesRepository from 'repositories/subcontractor/company/InvoicesRepository';

const initialState = {
  invoice: {
    data: {
      inboundLineItems: [],
      note: null,
      name: '',
    },
  },
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    loadInvoiceSuccess(state, { payload }) {
      state.invoice.data = payload.invoice;
    },
    resetInvoice() {
      return initialState;
    },
  },
});

export const { actions } = invoiceSlice;
export default invoiceSlice.reducer;

export const useInvoiceActions = () => {
  const dispatch = useDispatch();

  const loadInvoice = async id => {
    const { data } = await InvoicesRepository.show(id);
    dispatch(actions.loadInvoiceSuccess(data));

    return data;
  };

  const resetInvoice = () => {
    dispatch(actions.resetInvoice());
  };

  return {
    loadInvoice,
    resetInvoice,
  };
};
