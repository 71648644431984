import Typography from '@mui/material/Typography';

import useStyles from './useStyles';

const BankAccountRequest = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          A new request for payment of the invoice.
        </Typography>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.infoDescription}>
            <Typography className={classes.address}>
              The Bank will submit funds directly from the Bank’s account to Funl.{' '}
              <span className={classes.bold}>It can take up to 7 days.</span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccountRequest;
