import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  massInvite(params = {}) {
    const path = apiRoutes.massInviteSiteApiV1BuilderCompanySubcontractorsPath();

    return FetchHelpers.post(path, params);
  },

  reinvite(params = {}) {
    const path = apiRoutes.reinviteSiteApiV1BuilderCompanySubcontractorsPath();

    return FetchHelpers.post(path, params);
  },
};
