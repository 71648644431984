import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.colors.white,
    boxShadow: '0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)',
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '48px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '8px',
      minHeight: '560px',
    },
  },
  tip: {
    marginTop: 6,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  formContainer: {
    position: 'relative',
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '24px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  inputHelperText: {
    top: 40,
  },
  textButton: {
    textTransform: 'uppercase',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  submitRoot: {
    padding: '12px 24px',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
}));
