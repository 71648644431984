import { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, path } from 'ramda';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { initialValues, validationSchema } from 'forms/ForgotPasswordForm';
import AppRoutes from 'routes/AppRoutes';
import Link from 'components/Link';
import useFormik from 'hooks/useFormik';
import { sentencize } from 'utils/stringUtils';

import useStyles from './useStyles';

const Form = props => {
  const { onFormSubmit } = props;
  const classes = useStyles();
  const [apiErrors, setApiErrors] = useState({});
  const { touched, errors, handleChange, values, isSubmitting, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async valuesToSubmit => {
      try {
        await onFormSubmit({ passwordReset: { email: valuesToSubmit.email } });
      } catch (e) {
        setApiErrors(e);
      }
    },
  });

  const getEmailErrors = () => {
    if (isNil(apiErrors.email)) {
      return touched.email && errors.email;
    }

    return sentencize(path(['email', 0], apiErrors));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">Password Recovery</Typography>
      <div className={classes.tip}>We&apos;ll send a recovery link to your email.</div>
      <div className={classes.formContainer}>
        <form onSubmit={handleSubmit}>
          <TextField
            classes={{ root: classes.inputRoot }}
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            error={Boolean(apiErrors.email) || (touched.email && Boolean(errors.email))}
            helperText={getEmailErrors()}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            classes={{ root: classes.submitRoot }}
            disabled={isSubmitting}
          >
            Send Recovery Link
          </Button>
        </form>
        <div className={classes.signIn}>
          <Link to={AppRoutes.signInPath()}>Return to Sign In</Link>
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default Form;
