import { useEffect, useState } from "react";

import { InvoiceTabsStatus, InvoiceTabsStatusCount } from "components/common/InvoiceTabs/types";

import { InvoiceKind, InvoiceState } from "domain/invoice/enums";

import { useGetAdminInvoicesQuery } from "store/api/admin/invoice/apiSlice";

const useAdminInvoicesCountByStatus = () => {
  const [statusCount, setStatusCount] = useState<InvoiceTabsStatusCount>({
    open: null,
    approved: null,
    closed: null,
  });

  const { data: openData } = useGetAdminInvoicesQuery({
    q: { stateIn: [InvoiceState.pending, InvoiceState.awaitingApproval], kindIn: [InvoiceKind.selfPaid] },
  });
  const { data: approvedData } = useGetAdminInvoicesQuery({
    q: { stateIn: [InvoiceState.approved], kindIn: [InvoiceKind.selfPaid] },
  });
  const { data: closedData } = useGetAdminInvoicesQuery({
    q: { stateIn: [InvoiceState.canceled, InvoiceState.paid, InvoiceState.rejected], kindIn: [InvoiceKind.selfPaid] },
  });

  const changeStatusCount = (type: InvoiceTabsStatus, count: number | null) => {
    setStatusCount((previousCount) => {
      return { ...previousCount, [type]: count };
    });
  };

  useEffect(() => {
    const countValue = openData?.meta?.totalCount ?? null;
    changeStatusCount("open", countValue);
  }, [openData?.meta?.totalCount]);

  useEffect(() => {
    const countValue = approvedData?.meta?.totalCount ?? null;
    changeStatusCount("approved", countValue);
  }, [approvedData?.meta?.totalCount]);

  useEffect(() => {
    const countValue = closedData?.meta?.totalCount ?? null;
    changeStatusCount("closed", countValue);
  }, [closedData?.meta?.totalCount]);

  return {
    statusCount,
    changeStatusCount,
  };
};

export default useAdminInvoicesCountByStatus;
