import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { IUSAState } from "./types";

interface IUSAStatesResponse {
  states: Array<IUSAState>;
}

const USAStateApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getUSAStates: builder.query<Array<IUSAState>, void>({
      query: () => ({
        url: apiRoutes.siteApiV1StatesPath(),
        method: "GET",
      }),
      transformResponse: (response: IUSAStatesResponse) => response.states,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: CacheTag.usaState as const, id })),
              { type: CacheTag.usaState, id: "LIST" },
            ]
          : [{ type: CacheTag.usaState, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetUSAStatesQuery } = USAStateApi;
