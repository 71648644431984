import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  headerCell: {
    position: 'relative',
    padding: '16px 0',
    '&:first-child': {
      minWidth: 232,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  activeSort: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  sortArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fill: theme.palette.primary.main,
  },
  ascSortArrow: {
    transform: 'translateY(-50%) rotate(180deg)',
  },
}));
