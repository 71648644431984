import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  sectionTitle: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(1),
    maxWidth: 920,
    paddingTop: theme.spacing(4),
    padding: theme.spacing(3),
    minHeight: 600,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalCount: {
    marginTop: 6,
    fontSize: theme.typography.pxToRem(15),
  },
  tableContainer: {
    marginTop: 10,
  },
  headerCell: {
    paddingLeft: 10,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.colors.lemonGrass,
    border: 'none',
    '&:first-child': {
      width: 450,
    },
  },
}));
