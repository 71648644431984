import * as yup from 'yup';
import { nanoid } from 'nanoid';
import { startOfDay, endOfDay } from 'date-fns';

import { addDays } from 'date-fns';
import { differenceWith } from 'ramda';

import { EMAIL, REQUIRED, NUMBER, POSITIVE, maxAmountValue } from 'forms/errorsText';
import { sentencize } from 'utils/stringUtils';
import { formatUSD } from 'utils/numberUtils';
import InvoicePresenter, { INVOICE_KINDS } from 'presenters/InvoicePresenter';
import { joinMoreThanOne } from 'utils/arrayUtils';
import { DAYS_AFTER_TODAY, DAYS_AFTER_CREATED } from 'constants/invoicePaymentDate';
import { MAX_AMOUNT_PAYMENT } from 'constants/invoices';
import { dateTimeToUTC } from 'utils/dateUtils';

const validationFields = {
  kind: yup.string().oneOf(Object.values(INVOICE_KINDS)),
  projectId: yup.number().nullable().required(REQUIRED),
  name: yup.string().required(REQUIRED),
  number: yup.string(),
  purchaseOrder: yup.string(),
  subcontractorEmail: yup
    .string()
    .email(EMAIL)
    .when('kind', {
      is: kind => kind === INVOICE_KINDS.requested,
      then: schema => schema.email(EMAIL).required(REQUIRED),
    }),
  note: yup.string(),
  attachment: yup.mixed().nullable(),
  automaticallyApproved: yup.boolean(),
  projectedPaidAt: yup
    .date()
    .nullable(true)
    .when('automaticallyApproved', {
      is: automaticallyApproved => automaticallyApproved,
      then: schema =>
        schema
          .required(REQUIRED)
          .test({
            name: 'max',
            test: value => startOfDay(value).getTime() < addDays(endOfDay(new Date()), DAYS_AFTER_CREATED).getTime(),
          })
          .test({
            name: 'min',
            test: value => endOfDay(value).getTime() > addDays(startOfDay(new Date()), DAYS_AFTER_TODAY).getTime(),
          }),
    }),
  inboundLineItems: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(REQUIRED),
        amount: yup
          .number()
          .typeError(NUMBER)
          .positive(POSITIVE)
          .max(MAX_AMOUNT_PAYMENT, maxAmountValue(formatUSD(MAX_AMOUNT_PAYMENT, 2)))
          .required(REQUIRED),
      }),
    )
    .min(1),
};

export const initialValues = {
  kind: INVOICE_KINDS.requested,
  projectId: null,
  projectName: '',
  name: '',
  number: '',
  purchaseOrder: '',
  subcontractorEmail: '',
  note: '',
  attachment: null,
  builderEmail: '',
  inboundLineItems: [{ id: '', amount: null, categoryName: '', key: nanoid() }],
  automaticallyApproved: false,
  serverData: {
    invoiceLineItems: null,
  },
  projectedPaidAt: null,
};

export const validationSchema = yup.object().shape(validationFields);

export const formDataToRequest = invoice => {
  const getInvoiceLineItemsAttributes = () => {
    const invoiceLineItemsAttributes = invoice.inboundLineItems.map(item => ({
      lineItemId: Number(item.id),
      amount: item.amount,
    }));

    if (invoice.serverData.invoiceLineItems?.length) {
      const lineItemsFromServer = invoice.serverData.invoiceLineItems.map(item => {
        const indexFormItem = invoiceLineItemsAttributes.findIndex(
          formItem => item.lineItem?.id === formItem.lineItemId,
        );
        const isIndexFormItem = indexFormItem > -1;

        return {
          lineItemId: item.lineItem?.id,
          amount: isIndexFormItem ? invoiceLineItemsAttributes[indexFormItem].amount : item.amount,
          id: item.id,
          _destroy: !isIndexFormItem,
        };
      });

      const newLineItems = differenceWith(
        (item1, item2) => item1.lineItemId === item2.lineItemId,
        invoiceLineItemsAttributes,
        lineItemsFromServer,
      );

      const lineItemsToSend = lineItemsFromServer.concat(newLineItems);

      return lineItemsToSend;
    }

    return invoiceLineItemsAttributes;
  };

  const getDeleteAttachment = () => {
    if (!invoice.serverData.attachmentName) {
      return false;
    }

    return invoice.serverData.attachmentName !== invoice.attachmentName;
  };

  const getProjectedPaidAt = () => {
    if (invoice.projectedPaidAt) {
      return dateTimeToUTC(invoice.projectedPaidAt).toJSON();
    }

    return null;
  };

  return {
    name: invoice.name,
    projectedPaidAt: getProjectedPaidAt(),
    subcontractorEmail: invoice.subcontractorEmail,
    projectInvoiceAttributes: { projectId: invoice.projectId },
    number: invoice.number,
    purchaseOrder: invoice.purchaseOrder,
    note: invoice.note,
    kind: invoice.kind,
    automaticallyApproved: invoice.automaticallyApproved,
    invoiceLineItemsAttributes: getInvoiceLineItemsAttributes(),
    deleteAttachment: getDeleteAttachment(),
    ...(invoice.attachment && { attachment: invoice.attachment }),
  };
};

export const apiErrorsToForm = errors => {
  const separator = ', ';
  const formErrors = {
    projectId: sentencize(joinMoreThanOne(errors.project, separator)),
    name: sentencize(joinMoreThanOne(errors.name, separator)),
    subcontractorEmail: sentencize(joinMoreThanOne(errors.subcontractorEmail, separator)),
    projectedPaidAt: sentencize(joinMoreThanOne(errors.projectedPaidAt, separator)),
  };

  return formErrors;
};

export const apiInvoiceToForm = invoice => {
  const inboundLineItems = InvoicePresenter.invoiceLineItems(invoice);

  return {
    kind: InvoicePresenter.kind(invoice) || INVOICE_KINDS.requested,
    automaticallyApproved: InvoicePresenter.automaticallyApproved(invoice) || false,
    projectId: InvoicePresenter.projects(invoice)?.[0]?.id || null,
    projectName: InvoicePresenter.projects(invoice)?.[0]?.name || '',
    name: InvoicePresenter.name(invoice) || '',
    number: InvoicePresenter.number(invoice) || '',
    purchaseOrder: InvoicePresenter.purchaseOrder(invoice) || '',
    subcontractorEmail: InvoicePresenter.subcontractorEmail(invoice) || '',
    note: InvoicePresenter.note(invoice) || '',
    attachment: null,
    attachmentName: InvoicePresenter.attachmentName(invoice) || null,
    inboundLineItems: inboundLineItems?.length
      ? inboundLineItems.map(item => ({
          id: item.lineItem?.id || '',
          amount: item.amount || null,
          accountCode: item.lineItem?.accountCode || '',
          key: nanoid(),
          categoryName: item.lineItem?.categoryName || '',
        }))
      : initialValues.inboundLineItems,
    serverData: {
      invoiceLineItems: invoice.invoiceLineItems,
    },
    projectedPaidAt: new Date(invoice.projectedPaidAt),
  };
};

export const formPaths = {
  automaticallyApproved: 'automaticallyApproved',
  projectedPaidAt: 'projectedPaidAt',
};
