import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.colors.lilyWhiteGray,
    borderRadius: 4,
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(4),
  },
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    padding: '16px 0 12px 0',
    wordBreak: 'break-all',
  },
  headerRow: {
    borderBottom: [1, 'solid', theme.palette.divider],
  },
  headerCell: {
    fontSize: theme.typography.pxToRem(12),
    padding: '4px 0',
  },
}));
