import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.colors.smokeWhite,
  },
  item: {
    borderCollapse: 'collapse',
    boxSizing: 'border-box',
    gap: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.colors.lightGray}`,
    paddingBottom: theme.spacing(2),

    '&:last-of-type': {
      border: 'none',
      paddingBottom: 0,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.colors.hunterGreen,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 600,
    opacity: 0.5,
    textTransform: 'uppercase',
  },
  value: {
    color: theme.palette.colors.hunterGreen,
    fontSize: theme.typography.pxToRem(16),
    opacity: 0.9,
    lineHeight: theme.typography.pxToRem(20),
  },
}));
