import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.siteApiV1BuilderCompanyInvoicesPath();

    return FetchHelpers.get(path, params);
  },

  approve(id, params) {
    const path = apiRoutes.approveSiteApiV1BuilderCompanyInvoicePath(id);

    return FetchHelpers.put(path, params);
  },

  reject(id, params) {
    const path = apiRoutes.rejectSiteApiV1BuilderCompanyInvoicePath(id);

    return FetchHelpers.put(path, params);
  },

  update(id, params) {
    const path = apiRoutes.siteApiV1BuilderCompanyInvoicePath(id);

    return FetchHelpers.putMultipartFormData(path, params);
  },

  show(id) {
    const path = apiRoutes.siteApiV1BuilderCompanyInvoicePath(id);

    return FetchHelpers.get(path);
  },

  create(params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyInvoicesPath();

    return FetchHelpers.postMultipartFormData(path, params);
  },
};
