import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';

import { isBlank } from 'utils/storeUtils';
import { isPathActive, isAnyPathActive } from 'utils/location';
import Link from 'components/Link';

import useStyles from './useStyles';

const CollapsedListItem = props => {
  const { path, paths, icon, label, submenu, onDrawerClose } = props;
  const classes = useStyles();

  const linkPath = isBlank(submenu) ? path : submenu[0].path;
  const selected = isBlank(submenu) ? isPathActive(path) : isAnyPathActive(paths);

  return (
    <ListItem key={label} classes={{ root: classes.listItem, selected: classes.selectedItem }} selected={selected}>
      <Link to={linkPath} className={classes.collapsedLink} onClick={() => onDrawerClose()}>
        <div className={classes.iconWrapper}>{icon}</div>
      </Link>
    </ListItem>
  );
};

CollapsedListItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  path: PropTypes.string,
  paths: PropTypes.arrayOf(PropTypes.string),
  submenu: PropTypes.arrayOf(PropTypes.shape()),
  onDrawerClose: PropTypes.func.isRequired,
};
CollapsedListItem.defaultProps = {
  path: null,
  paths: null,
  submenu: null,
};

export default CollapsedListItem;
