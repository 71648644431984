import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Typography from '@mui/material/Typography';

import Link from 'components/Link';
import Dropzone from 'components/Dropzone';
import AppRoutes from 'routes/AppRoutes';

import useStyles from './useStyles';

const ATTACHMENT_DESCRIPTION = 'Your PDF file must be less than 10 MB';

const InvoiceFormHeader = props => {
  const { attachment, title, onFileAttach } = props;
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <div className={classes.breadcrumbs}>
          <Link to={AppRoutes.invoicesPath()}>Invoices</Link>
        </div>
        <Typography variant="h1" classes={{ root: classes.title }}>
          {title}
        </Typography>
      </div>
      <div className={classes.attachmentContainer}>
        <Dropzone isDisabled={!isNil(attachment)} description={ATTACHMENT_DESCRIPTION} onDrop={onFileAttach} />
      </div>
    </header>
  );
};

InvoiceFormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onFileAttach: PropTypes.func.isRequired,
  attachment: PropTypes.string,
};
InvoiceFormHeader.defaultProps = {
  attachment: null,
};

export default InvoiceFormHeader;
