import { format as dateFnsFormat, formatDistanceStrict as dateFnsFormatDistanceStrict, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { DATE_FORMATS } from 'constants/date';

export const format = (timestamp, formatString = DATE_FORMATS.default) =>
  dateFnsFormat(new Date(timestamp), formatString);

export const formatDistanceStrict = (startDay, endDay, unit = 'day') =>
  dateFnsFormatDistanceStrict(startDay, endDay, { unit, addSuffix: false });

export const formatDistance = (days = 0, unit = 'day') =>
  formatDistanceStrict(subDays(new Date(), days), new Date(), unit);

export const dateTimeToUTC = timestamp => zonedTimeToUtc(timestamp, 'UTC');
