import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import AppRoutes from 'routes/AppRoutes';
import Link from 'components/Link';
import ProjectPresenter from 'presenters/ProjectPresenter';

import useStyles from './useStyles';

const ProjectHeader = props => {
  const { project, actions } = props;
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <Link to={AppRoutes.projectsPath()}>Projects</Link>
        <Typography variant="h1" className={classes.projectName}>
          {ProjectPresenter.name(project)}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>{actions}</div>
    </header>
  );
};

ProjectHeader.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  actions: PropTypes.node,
};

ProjectHeader.defaultProps = {
  actions: null,
};

export default ProjectHeader;
