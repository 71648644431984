/* eslint-disable sonarjs/no-duplicate-string */
import BlocksBackground from "assets/images/bgBlocks.png";

import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    backgroundColor: "colors.suluGreen",
    backgroundImage: {
      md: `url(${BlocksBackground})`,
    },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 0%",
    minHeight: "100vh",
    padding: {
      xs: "24px 24px 40px",
      md: "72px 24px 24px 40px",
    },
  },
  rootContainer: {
    width: "min(100%, 1080px)",
    margin: "0 auto",
    display: "flex",
    position: "relative",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    gap: {
      lg: "140px",
    },
    minHeight: {
      md: "680px",
    },
  },
  handIconContainer: {
    "& svg": {
      marginBottom: "14px",
    },
  },
  sidebar: {
    marginTop: "3px",
    marginBottom: {
      xs: "32px",
      lg: 0,
    },
    width: {
      md: "260px",
    },
  },
  greet: {
    fontSize: {
      xs: "32px",
      md: "44px",
    },
    fontWeight: 700,
    fontFamily: "Poppins",
    lineHeight: {
      xs: "48px",
      md: "52px",
    },
    letterSpacing: -1,
    marginBottom: {
      xs: "8px",
      md: "16px",
    },
  },
  title: {
    whiteSpace: "nowrap",
  },
  appName: {
    color: "colors.white",
    display: {
      md: "block",
    },
  },
  logoutButton: {
    textTransform: "none",
    color: "colors.white",
    "& svg": {
      fill: "currentColor",
    },
  },
  logoutLabel: {
    marginLeft: "10px",
  },
  stopImpersonatingLabel: {
    marginLeft: "6px",
  },
  actions: {
    position: {
      lg: "absolute",
    },
    left: 0,
    bottom: 0,
  },
  mainContainer: {
    width: {
      xs: "100%",
      md: "680px",
    },
    padding: "0 !important",
    // backgroundColor: "colors.white",
    // boxShadow: "0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)",
    // borderRadius: 8,
    alignSelf: "baseline",
    marginLeft: {
      xs: "0",
      lg: "auto",
    },
    marginBottom: {
      xs: "36px",
      lg: "0",
    },
  },
};

export default styles;
