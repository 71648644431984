import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { validationSchema, initialValues } from 'forms/Onboarding/UserForm';
import { UNIQUE, INVITE } from 'forms/errorsText';
import { COMPANY_ROLES } from 'presenters/UserPresenter';
import useFormik from 'hooks/useFormik';

import Table from './components/Table';
import useStyles from './useStyles';

const TeamSetup = props => {
  const { onUsersCreate, onCurrentUserLoad, onUserAvailabilityCheck } = props;

  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [isAddUserButtonActive, setIsAddUserButtonActive] = useState(false);
  const { touched, errors, setErrors, handleChange, values, setValues, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ email }) => {
      const isEmailExists = users.some(user => user.email === email);
      if (isEmailExists) {
        setErrors({ email: UNIQUE });
        return;
      }

      try {
        await onUserAvailabilityCheck({ email });
        setUsers(value => [...value, { email, role: COMPANY_ROLES.manager }]);
        setValues(initialValues);
      } catch (e) {
        setErrors({ email: INVITE });
      }
    },
  });

  useEffect(() => {
    setIsAddUserButtonActive(!isEmpty(values.email));
  }, [values.email]);

  const handleUsersSubmit = async () => {
    await onUsersCreate({ users });
    onCurrentUserLoad();
  };

  const handleUserRoleChange = email => event => {
    setUsers(userValues =>
      userValues.map(user => {
        if (user.email === email) {
          return { ...user, role: event.target.value };
        }

        return user;
      }),
    );
  };

  const handleUserRemove = email => {
    setUsers(userValues => userValues.filter(user => user.email !== email));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Add users to account</Typography>
      <div className={classes.tip}>You also can add users and manage their permissions in your account.</div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formContainer}>
          <TextField
            FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
            placeholder="Enter email"
            name="email"
            onChange={handleChange}
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <Button
            classes={{ root: classes.textButton }}
            color="primary"
            type="submit"
            variant="contained"
            disabled={!isAddUserButtonActive}
          >
            Add User
          </Button>
        </div>
      </form>
      {!isEmpty(users) && (
        <Table users={users} onUserRoleChange={handleUserRoleChange} onUserRemove={handleUserRemove} />
      )}
      <div className={classes.submitContainer}>
        <Button variant="contained" color="primary" classes={{ root: classes.submitRoot }} onClick={handleUsersSubmit}>
          Continue
        </Button>
      </div>
    </div>
  );
};

TeamSetup.propTypes = {
  onUsersCreate: PropTypes.func.isRequired,
  onCurrentUserLoad: PropTypes.func.isRequired,
  onUserAvailabilityCheck: PropTypes.func.isRequired,
};

export default TeamSetup;
