import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MaterialLink from '@mui/material/Link';
import Box from '@mui/material/Box';

import AppRoutes from 'routes/AppRoutes';
import PageHeader from 'components/PageHeader';

import useStyles from './useStyles';

const InvoicePageHeader = props => {
  const { invoiceName } = props;
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <Box mb={1}>
          <MaterialLink to={AppRoutes.invoicesPath()} component={Link}>
            Invoices
          </MaterialLink>
        </Box>
        <PageHeader text={`Invoice ${invoiceName}`} icon={<DescriptionOutlinedIcon className={classes.titleIcon} />} />
      </div>
    </header>
  );
};

InvoicePageHeader.propTypes = {
  invoiceName: PropTypes.string,
};

InvoicePageHeader.defaultProps = {
  invoiceName: '',
};

export default memo(InvoicePageHeader);
