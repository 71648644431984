import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import qs from "qs";
import { isNil } from "ramda";

import AppRoutes from "routes/AppRoutes";

import { isGuestRoute } from "utils/routes";

const authenticityToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
  return token?.content ?? null;
};

export const axiosInstance = axios.create({
  withCredentials: true,
  paramsSerializer: (parameters) => qs.stringify(parameters, { encode: false, arrayFormat: "brackets" }),
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    "X-CSRF-Token": authenticityToken(),
    "X-Requested-With": "XMLHttpRequest",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (!isGuestRoute(window.location.pathname)) {
        window.location.href = AppRoutes.signInPath();
      }
      return Promise.reject(new Error("Unauthorized"));
    }

    return Promise.reject(error);
  },
);

export const axiosBaseQuery = (): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    headers?: AxiosRequestConfig["headers"];
  },
  unknown,
  unknown
> => {
  return async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data: !isNil(data) && decamelizeKeys(data),
        params: !isNil(params) && decamelizeKeys(params),
        headers,
      });
      return { data: camelizeKeys(result.data) };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          status: error.response?.status,
          data: camelizeKeys(error.response?.data) || error.message,
        },
      };
    }
  };
};
