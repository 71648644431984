import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const CompanyTopMenu = props => {
  const { company } = props;
  const classes = useStyles();

  return (
    <div className={classes.topMenu}>
      <div className={classes.logoContainer}>
        <Icon name="logo" width={28.42} height={28.42} />
      </div>
      <div className={classes.companyNameContainer}>
        <span className={classes.companyName}>{CompanyPresenter.name(company)}</span>
      </div>
    </div>
  );
};

CompanyTopMenu.propTypes = {
  company: UserPresenter.shape().isRequired,
};

export default CompanyTopMenu;
