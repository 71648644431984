import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  plaidLinkToken(params = {}) {
    const path = apiRoutes.plaidLinkTokenSiteApiV1CustomerPaymentTokensPath();

    return FetchHelpers.post(path, params);
  },
  bankAccountToken(params = {}) {
    const path = apiRoutes.bankAccountTokenSiteApiV1CustomerPaymentTokensPath();

    return FetchHelpers.post(path, params);
  },
};
