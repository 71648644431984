import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import AddButton from 'components/AddButton';
import LineItemsTable from 'components/LineItemsTable';
import InfiniteScroll from 'components/InfiniteScroll';
import TemplateLineItems from 'components/TemplateLineItems';
import { useLineItemsSettingsActions } from 'store/LineItemsSettingsSlice';

import useLoading from 'hooks/useLoading';

import useStyles from './useStyles';

const LineItems = () => {
  const [isNewItemFormShown, setIsNewItemFormShown] = useState(false);
  const [isTemplateModalShown, setIsTemplateModalShown] = useState(false);
  const classes = useStyles();
  const {
    loadTemplateLineItems,
    loadLineItems,
    createLineItem,
    updateLineItem,
    destroyLineItem,
    massCreateLineItems,
    resetTemplateLineItems,
    resetLineItemsSettings,
  } = useLineItemsSettingsActions();
  const {
    lineItems: { data: lineItems, meta: lineItemsMeta },
    templateLineItems: { data: templateLineItems, meta: templateLineItemsMeta },
  } = useSelector(state => state.LineItemsSettingsSlice);
  const { func: sendLoadTemplateLineItems, isNotFinished: isTemplateLineItemsLoading } =
    useLoading(loadTemplateLineItems);
  const { func: sendLoadLineItems, isNotFinished: isLineItemsLoading } = useLoading(loadLineItems);

  useEffect(() => {
    sendLoadLineItems();

    return resetLineItemsSettings;
  }, []);

  const handleTemplateModalShow = () => {
    setIsTemplateModalShown(true);
  };

  const handleTemplateModalHide = () => {
    setIsTemplateModalShown(false);
  };

  const handleNewItemFormShow = () => {
    setIsNewItemFormShown(true);
  };

  const handleNewItemFormHide = () => {
    setIsNewItemFormShown(false);
  };

  const handleNewItemCreate = async params => {
    await createLineItem(params);
    handleNewItemFormHide();
    resetLineItemsSettings();
    sendLoadLineItems();
  };

  const handleItemDestroy = ({ id }) => destroyLineItem(id);

  const handleItemsMassCreate = async params => {
    const lineItemsParams = params.map(({ categoryName }) => ({ categoryName }));

    await massCreateLineItems({ lineItems: lineItemsParams });
    handleTemplateModalHide();
    handleNewItemFormHide();
    resetLineItemsSettings();
    sendLoadLineItems();
  };

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.actions}>
          <AddButton variant="outlined" onClick={handleNewItemFormShow}>
            Add Item
          </AddButton>
          <Button classes={{ root: classes.textButton }} color="primary" onClick={handleTemplateModalShow}>
            Select From Template
          </Button>
        </div>
        <div className={classes.totalItems}>Total Items: {lineItemsMeta.totalCount}</div>
      </div>
      <Dialog open={isTemplateModalShown} onClose={handleTemplateModalHide}>
        <TemplateLineItems
          lineItems={templateLineItems}
          isLoading={isTemplateLineItemsLoading}
          onHide={handleTemplateModalHide}
          onLineItemsCreate={handleItemsMassCreate}
          onLineItemsLoad={sendLoadTemplateLineItems}
          onUnmount={resetTemplateLineItems}
          meta={templateLineItemsMeta}
        />
      </Dialog>
      <div className={classes.lineItemsContainer}>
        <InfiniteScroll nextPage={lineItemsMeta.nextPage} isLoading={isLineItemsLoading} onLoad={sendLoadLineItems}>
          <LineItemsTable
            lineItems={lineItems}
            isLoading={isLineItemsLoading}
            onRemove={handleItemDestroy}
            onEdit={updateLineItem}
            onCreate={handleNewItemCreate}
            isNewItemFormActive={isNewItemFormShown}
            onNewItemFormShow={handleNewItemFormShow}
            onNewItemFormHide={handleNewItemFormHide}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default LineItems;
