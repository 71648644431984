import { useEffect } from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import MoneyTextField from 'components/MoneyTextField';
import useFormik from 'hooks/useFormik';
import { initialValues, validationSchema } from 'forms/Builder/ProjectBudgetForm';
import ProjectPresenter from 'presenters/ProjectPresenter';
import { formatUSD } from 'utils/numberUtils';
import { getProjectProfit } from 'utils/projectUtils';

import useStyles from './useStyles';

const ProjectBudget = props => {
  const { onFormStepIncrement, project, onProjectChange, onFormStepDecrement } = props;
  const classes = useStyles();
  const { touched, errors, handleChange, values, setValues, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: newProjectValues => {
      onProjectChange({ ...project, ...newProjectValues });
      onFormStepIncrement();
    },
  });

  useEffect(() => {
    setValues({ ...values, ...project, ...(!ProjectPresenter.isCostPlus(project) && { percentMarkup: '' }) });
  }, [project]);

  const isProjectCostPlus = ProjectPresenter.isCostPlus(project);

  const renderContractValue = () => {
    if (isProjectCostPlus) {
      return null;
    }

    const contractValue = formatUSD(project.contractValue, 2);

    return (
      <div className={classes.contractValueContainer}>
        <div className={classes.title}>Contract Value</div>
        <div className={classes.value}>{contractValue}</div>
      </div>
    );
  };

  const renderPercentMarkup = () => {
    if (isProjectCostPlus) {
      return (
        <div className={classes.formControlContainer}>
          <div className={classes.labelContainer}>
            <InputLabel classes={{ root: classes.label }} htmlFor="percentMarkup">
              Percent Markup
            </InputLabel>
            <div className={classes.tip}>What is the markup on expences?</div>
          </div>
          <div className={classes.percentMarkupContainer}>
            <TextField
              classes={{ root: classes.percentMarkupInput }}
              FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
              name="percentMarkup"
              id="percentMarkup"
              value={values.percentMarkup}
              onChange={handleChange}
              error={touched.percentMarkup && Boolean(errors.percentMarkup)}
              helperText={touched.percentMarkup && errors.percentMarkup}
            />
            <div className={classes.percentSign}>%</div>
          </div>
        </div>
      );
    }

    return null;
  };

  const thirdPartyExpensesTip = project.sold
    ? 'What are your total third party expenses for this project?'
    : 'What do you anticipate paying for third party expenses?';
  const overheadExpensesTip = project.sold
    ? 'What is your total estimated overhead for this project?'
    : 'What expenses do you anticipate incurring for your overhead?';

  return (
    <form onSubmit={handleSubmit}>
      {renderContractValue()}
      <div className={classes.formControlContainer}>
        <div className={classes.labelContainer}>
          <InputLabel classes={{ root: classes.label }} htmlFor="thirdPartyExpenses">
            Third Party Expenses
          </InputLabel>
          <div className={classes.tip}>{thirdPartyExpensesTip}</div>
        </div>
        <MoneyTextField
          classes={{ root: classes.inputRoot }}
          FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
          name="thirdPartyExpenses"
          id="thirdPartyExpenses"
          value={values.thirdPartyExpenses}
          onChange={handleChange}
          error={touched.thirdPartyExpenses && Boolean(errors.thirdPartyExpenses)}
          helperText={touched.thirdPartyExpenses && errors.thirdPartyExpenses}
        />
      </div>
      <div className={classes.formControlContainer}>
        <div className={classes.labelContainer}>
          <InputLabel classes={{ root: classes.label }} htmlFor="overheadExpenses">
            Overhead Expense
          </InputLabel>
          <div className={classes.tip}>{overheadExpensesTip}</div>
        </div>
        <MoneyTextField
          classes={{ root: classes.inputRoot }}
          FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
          name="overheadExpenses"
          id="overheadExpenses"
          value={values.overheadExpenses}
          onChange={handleChange}
          error={touched.overheadExpenses && Boolean(errors.overheadExpenses)}
          helperText={touched.overheadExpenses && errors.overheadExpenses}
        />
      </div>
      {renderPercentMarkup()}
      <div className={classes.projectProfitContainer}>
        <div className={classes.title}>Projected Profit</div>
        <div className={classes.value}>{formatUSD(getProjectProfit({ ...project, ...values }), 2)}</div>
      </div>
      <div className={classes.actions}>
        <Button variant="contained" onClick={onFormStepDecrement}>
          Back
        </Button>
        <Button variant="contained" color="primary" classes={{ root: classes.nextButton }} type="submit">
          Next
        </Button>
      </div>
    </form>
  );
};

ProjectBudget.propTypes = {
  onFormStepIncrement: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  onProjectChange: PropTypes.func.isRequired,
  onFormStepDecrement: PropTypes.func.isRequired,
};

export default ProjectBudget;
