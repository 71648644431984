import * as yup from 'yup';

import { REQUIRED, NUMBER, POSITIVE } from 'forms/errorsText';
import { PROJECT_PRICINGS } from 'presenters/ProjectPresenter';

const validationFields = {
  pricing: yup.string().oneOf(Object.values(PROJECT_PRICINGS)),
  contractValue: yup.number().when('pricing', {
    is: PROJECT_PRICINGS.costPlus,
    then: schema => schema.nullable(),
    otherwise: schema => schema.typeError(NUMBER).positive(POSITIVE).required(REQUIRED),
  }),
  percentMarkup: yup.number().when('pricing', {
    is: PROJECT_PRICINGS.costPlus,
    then: schema => schema.typeError(NUMBER).positive(POSITIVE).required(REQUIRED),
    otherwise: schema => schema.nullable(),
  }),
  thirdPartyExpenses: yup.number().typeError(NUMBER).positive(POSITIVE).required(REQUIRED),
  overheadExpenses: yup.number().typeError(NUMBER).positive(POSITIVE).required(REQUIRED),
};

export const initialValues = {
  pricing: PROJECT_PRICINGS.fixedPrice,
  contractValue: '',
  thirdPartyExpenses: '',
  overheadExpenses: '',
  percentMarkup: '',
};

export const validationSchema = yup.object().shape(validationFields);
