import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(projectId, params = {}, customHeaders, cancelToken) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectCustomerInvoicesPath(projectId);

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  create(projectId, params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectCustomerInvoicesPath(projectId);

    return FetchHelpers.post(path, params);
  },
};
