import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';

export const STATES = {
  draft: 'draft',
  active: 'active',
};

export default new Presenter(
  {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categoryName: PropTypes.string,
    accountCode: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  {
    isDraft(lineItem) {
      return this.state(lineItem) === STATES.draft;
    },
    formattedUSDAmount(item, maximumFractionDigits = 2) {
      return formatUSD(item.amount, maximumFractionDigits);
    },
  },
);
