import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@mui/material/Button';

import UserPresenter from 'presenters/UserPresenter';
import Icon from 'components/Icon';
import Navigation from 'components/Navigation';

import UserTopMenu from './components/UserTopMenu';
import CompanyTopMenu from './components/CompanyTopMenu';

import useStyles from './useStyles';

const ExpandedMenu = props => {
  const { currentUser, onMenuOpen, getNavItems, onDrawerClose } = props;
  const classes = useStyles();

  const company = UserPresenter.company(currentUser);

  const getIconClassName = name => {
    if (name === 'gear') {
      return clsx(classes.icon, classes.gearIcon);
    }

    return classes.icon;
  };
  const navItems = getNavItems(getIconClassName);
  const isCustomer = UserPresenter.isCustomer(currentUser);

  return (
    <div className={classes.root}>
      <div>
        {isCustomer ? (
          <UserTopMenu currentUser={currentUser} onMenuOpen={onMenuOpen} />
        ) : (
          <CompanyTopMenu company={company} />
        )}

        <div className={classes.navigation}>
          <Navigation navItems={navItems} variant="expanded" onDrawerClose={onDrawerClose} />
        </div>
      </div>
      {!isCustomer && (
        <div className={classes.userPanel}>
          <Button classes={{ root: classes.menuButton }} onClick={onMenuOpen}>
            <span className={classes.userNameContainer}>
              <Icon name="avatar" width={25} height={25} className={classes.avatar} />
              <span className={classes.userName}>{UserPresenter.fullName(currentUser)}</span>
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

ExpandedMenu.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  onMenuOpen: PropTypes.func.isRequired,
  getNavItems: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default ExpandedMenu;
