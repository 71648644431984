import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

const subcontractorCompanyIncomingConnectionApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getHasPendingConnections: builder.query<boolean, void>({
      query: () => ({
        url: apiRoutes.hasPendingConnectionsSiteApiV1SubcontractorCompanyIncomingConnectionsPath(),
        method: "GET",
      }),
      transformResponse: (response: { hasPendingConnections: boolean }) => response.hasPendingConnections,
      providesTags: [CacheTag.subcontractorCompanyIncomingConnectionHasPendingConnections],
    }),
  }),
  overrideExisting: false,
});
export const { useGetHasPendingConnectionsQuery } = subcontractorCompanyIncomingConnectionApi;
