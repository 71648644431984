import * as yup from 'yup';

import { REQUIRED } from 'forms/errorsText';

const validationFields = {
  name: yup.string().required(REQUIRED),
  address1: yup.string().required(REQUIRED),
  address2: yup.string(),
  city: yup.string().required(REQUIRED),
  state: yup.string().required(REQUIRED),
  zipCode: yup.string().required(REQUIRED),
};

export const initialValues = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
};

export const validationSchema = yup.object().shape(validationFields);
