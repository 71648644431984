import { useState } from 'react';
import PropTypes from 'prop-types';
import { equals, curry } from 'ramda';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import clsx from 'clsx';

import SortableHeadCell from 'components/SortableHeadCell';
import { PROJECT_STATES } from 'presenters/ProjectPresenter';
import { SORTING_RULE } from 'constants/projects';

import { getNewSortDirection, getSortRuleForApi } from 'utils/sortUtils';

import useStyles from './useStyles';

const ProjectsTableHead = props => {
  const { variant, projectsCount, onSortChange } = props;
  const classes = useStyles();
  const [sortingRule, setSortingRule] = useState({});

  const handleCellClick = selectedSortingRule => {
    const newDirection = getNewSortDirection(sortingRule, selectedSortingRule);
    const newSortingRule = { rule: selectedSortingRule, direction: newDirection };
    setSortingRule(newSortingRule);
    onSortChange(getSortRuleForApi(newSortingRule));
  };

  const isSortActive = curry((currentRule, rule) => equals(currentRule.rule, rule))(sortingRule);

  const renderTitle = () => {
    switch (variant) {
      case PROJECT_STATES.active:
        return (
          <span className={clsx(classes.label, classes.activeLabel)}>
            Active <span className={clsx(classes.count, classes.activeCount)}>{projectsCount}</span>
          </span>
        );
      case PROJECT_STATES.draft:
        return (
          <span className={clsx(classes.label, classes.prospectLabel)}>
            Prospects <span className={clsx(classes.count, classes.prospectCount)}>{projectsCount}</span>
          </span>
        );
      case PROJECT_STATES.completed:
        return (
          <span className={clsx(classes.label, classes.completedLabel)}>
            Completed <span className={clsx(classes.count, classes.completedCount)}>{projectsCount}</span>
          </span>
        );
      default:
        return null;
    }
  };

  const renderCells = () => {
    if (variant === PROJECT_STATES.draft) {
      return (
        <>
          <SortableHeadCell
            isActive={isSortActive(SORTING_RULE.probability)}
            sortDirection={sortingRule.direction}
            onClick={() => handleCellClick(SORTING_RULE.probability)}
          >
            Probability
          </SortableHeadCell>
          <SortableHeadCell
            isActive={isSortActive(SORTING_RULE.startedAt)}
            sortDirection={sortingRule.direction}
            onClick={() => handleCellClick(SORTING_RULE.startedAt)}
          >
            Start Date
          </SortableHeadCell>
          <SortableHeadCell
            isActive={isSortActive(SORTING_RULE.duration)}
            sortDirection={sortingRule.direction}
            onClick={() => handleCellClick(SORTING_RULE.duration)}
          >
            Duration
          </SortableHeadCell>
          <SortableHeadCell
            isActive={isSortActive(SORTING_RULE.contractValue)}
            sortDirection={sortingRule.direction}
            onClick={() => handleCellClick(SORTING_RULE.contractValue)}
            align="right"
          >
            Sales Price
          </SortableHeadCell>
        </>
      );
    }

    return (
      <>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.startedAt)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.startedAt)}
          width="8.5%"
        >
          Start Date
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.duration)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.duration)}
          width="11.5%"
        >
          Duration
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.endedAt)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.endedAt)}
          width="11%"
        >
          End Date
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.contractValue)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.contractValue)}
          align="right"
          width="9.36%"
        >
          Sales Price
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.thirdPartyExpenses)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.thirdPartyExpenses)}
          align="right"
          width="8%"
        >
          COGS
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.overheadExpenses)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.overheadExpenses)}
          align="right"
          width="9.25%"
        >
          Builder Fee
        </SortableHeadCell>
        <SortableHeadCell
          isActive={isSortActive(SORTING_RULE.profit)}
          sortDirection={sortingRule.direction}
          onClick={() => handleCellClick(SORTING_RULE.profit)}
          align="right"
          width="17%"
        >
          Profit
        </SortableHeadCell>
      </>
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerCell} width="24.41%">
          {renderTitle()}
        </TableCell>
        {renderCells()}
      </TableRow>
    </TableHead>
  );
};

ProjectsTableHead.propTypes = {
  variant: PropTypes.oneOf(Object.values(PROJECT_STATES)).isRequired,
  projectsCount: PropTypes.number.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default ProjectsTableHead;
