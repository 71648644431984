import { useSelector } from 'react-redux';

import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import { useCustomerPaymentsActions, selectors } from 'store/CustomerPaymentsSlice';
import { useUserLayoutActions } from 'store/UserLayoutSlice';
import Icon from 'components/Icon';
import { PAYMENT_METHODS } from 'constants/paymentMethods';

import useStyles from './useStyles';

const OpenInvoicesPayButton = props => {
  const { customerInvoice } = props;
  const classes = useStyles();
  const paymentMethod = useSelector(selectors.paymentMethod);
  const { approve } = useCustomerPaymentsActions();
  const { setIsLoading } = useUserLayoutActions();
  const { enqueueSnackbar } = useSnackbar();

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      await approve(CustomerInvoicePresenter.id(customerInvoice));
    } catch (error) {
      const resolvedMessage = error.response ? error.response.data.error.message : error.message;
      enqueueSnackbar(resolvedMessage, {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isAch = paymentMethod === PAYMENT_METHODS.ach;
  const isPayButton = !CustomerInvoicePresenter.isAwaitingPayment(customerInvoice) && isAch;

  if (isPayButton) {
    return (
      <Button className={classes.payButton} color="primary" variant="contained" onClick={handleButtonClick}>
        Pay
      </Button>
    );
  }

  return (
    <div className={classes.awaitingContainer}>
      <Icon name="sandglass" width={12} height={16} className={classes.awaitingIcon} />
      <Typography>Awaiting payment</Typography>
    </div>
  );
};

OpenInvoicesPayButton.propTypes = {
  customerInvoice: CustomerInvoicePresenter.shape().isRequired,
};

export default OpenInvoicesPayButton;
