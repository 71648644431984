import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  label: {
    color: theme.palette.colors.deltaGray,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  rowContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    '&:nth-child(2)': {
      marginTop: 4,
    },
    '&:nth-child(3)': {
      marginTop: theme.spacing(2),
    },
  },
  draftTip: {
    color: theme.palette.colors.deltaGray,
    fontSize: theme.typography.pxToRem(12),
  },
  soldDialogContainer: {
    maxWidth: 600,
    padding: theme.spacing(4, 3),
  },
  soldTitle: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  dialogTitleIcon: {
    marginRight: theme.spacing(1),
    marginTop: 6,
    minWidth: 20,
  },
  soldButtonContainer: {
    textAlign: 'right',
  },
  soldAcceptButton: {
    marginRight: 12,
  },
}));
