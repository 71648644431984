import PropTypes from 'prop-types';
import clsx from 'clsx';

import IconButton from '@mui/material/IconButton';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const DeleteButton = props => {
  const { onClick, isDisabled, ...restProps } = props;
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} disabled={isDisabled} {...restProps} size="large">
      <Icon
        className={clsx(classes.binIcon, isDisabled && classes.disabledBinIcon)}
        name="bin"
        width={16}
        height={16}
      />
    </IconButton>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
DeleteButton.defaultProps = {
  isDisabled: false,
};

export default DeleteButton;
