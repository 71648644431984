import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  buttonContainer: {
    minHeight: 40,
    boxShadow: [0, 4, 4, 'rgba(0, 0, 0, 0.05)'],
  },
  button: {
    height: '100%',
    width: '100%',
    textTransform: 'uppercase',
    paddingTop: 12,
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
}));
