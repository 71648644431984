import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    fontFamily: 'Poppins',
    fontWeight: 500,
    letterSpacing: -0.2,
    color: theme.palette.colors.blackOlive,
    opacity: 0.5,
    textTransform: 'uppercase',

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
}));
