import { useEffect } from 'react';
import { addDays, isValid, startOfDay, endOfDay } from 'date-fns';

import { formatDistanceStrict } from 'utils/dateUtils';
import { DAYS_AFTER_TODAY, DAYS_AFTER_CREATED } from 'constants/invoicePaymentDate';

const useInvoicePaymentDate = ({ createdDate, selectedDay, onDateChange = Function.prototype }) => {
  const minDate = addDays(startOfDay(new Date()), DAYS_AFTER_TODAY);
  const maxDate = addDays(endOfDay(createdDate), DAYS_AFTER_CREATED);

  const differenceWithToday = isValid(selectedDay)
    ? formatDistanceStrict(endOfDay(new Date()), endOfDay(selectedDay))
    : '0 days';

  const isExpiredDate = maxDate.getTime() < minDate.getTime();

  useEffect(() => {
    const date = isExpiredDate ? minDate : maxDate;
    onDateChange(date);
    return () => onDateChange(null);
  }, []);

  return {
    minDate,
    maxDate,
    differenceWithToday,
    isExpiredDate,
  };
};

export default useInvoicePaymentDate;
