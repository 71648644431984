import * as yup from 'yup';

import { EMAIL } from 'forms/errorsText';

const validationFields = {
  email: yup.string().email(EMAIL).required(EMAIL),
};

export const initialValues = {
  email: '',
};

export const validationSchema = yup.object().shape(validationFields);
