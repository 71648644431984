import PropTypes from 'prop-types';
import { format, addDays } from 'date-fns';

import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';
import { DATE_FORMATS } from 'constants/date';

export default new Presenter(
  {
    id: PropTypes.number,
    earlyPaymentId: PropTypes.number,
    daysDelay: PropTypes.number,
    amount: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  },
  {
    labelText(earlyPayment) {
      const formattedAmount = formatUSD(this.amount(earlyPayment), 2);
      if (this.daysDelay(earlyPayment) === 0) {
        return `${formattedAmount} available within 1-3 business days from builder approval`;
      }

      return `${formattedAmount} available within 7-10 business days from builder approval`;
    },
    estimatedPaymentDate(paymentTerms) {
      const date = addDays(new Date(), paymentTerms);
      return format(date, DATE_FORMATS.paymentDate);
    },
  },
);
