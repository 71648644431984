import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params) {
    const path = apiRoutes.siteApiV1SubcontractorCompanyInvoicesPath();

    return FetchHelpers.get(path, params);
  },
  show(id) {
    const path = apiRoutes.siteApiV1SubcontractorCompanyInvoicePath(id);

    return FetchHelpers.get(path);
  },
  create(params = {}) {
    const path = apiRoutes.siteApiV1SubcontractorCompanyInvoicesPath();

    return FetchHelpers.postMultipartFormData(path, params);
  },
  update(id, { params }) {
    const path = apiRoutes.siteApiV1SubcontractorCompanyInvoicePath(id);

    return FetchHelpers.putMultipartFormData(path, params);
  },
};
