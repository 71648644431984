import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  impersonate(id) {
    const path = apiRoutes.impersonateSiteApiV1AdminUserPath(id);
    return FetchHelpers.put(path);
  },
  stopImpersonating() {
    const path = apiRoutes.stopImpersonatingSiteApiV1AdminUsersPath();
    return FetchHelpers.delete(path);
  },
};
