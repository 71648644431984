import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useCustomerOnboardingActions, selectors } from 'store/CustomerOnboardingSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';
import useLoading from 'hooks/useLoading';
import LinearProgress from 'components/LinearProgress';
import ProjectPresenter from 'presenters/ProjectPresenter';

import useStyles from './useStyles';

const ProjectConfirm = () => {
  const classes = useStyles();
  const { confirmProject, loadProjects } = useCustomerOnboardingActions();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();
  const { data: projects } = useSelector(selectors.projects);
  const { func: handleProjectsLoad, isPending: isProjectsLoadPending } = useLoading(loadProjects);

  useEffect(() => {
    handleProjectsLoad();
  }, []);

  const sendConfirmProject = async () => {
    await confirmProject();
    await loadCurrentUser();
  };

  const { func: handleContinueClick, isConfirmPending } = useLoading(sendConfirmProject);

  return (
    <div className={classes.projectConfirmContainer}>
      <LinearProgress isVisible={isConfirmPending} />
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          Confirm the Project
        </Typography>
        <Typography className={classes.subtitle}>Please confirm that you have been invited to the project</Typography>
      </div>
      <div className={classes.projectsContainer}>
        {isProjectsLoadPending ? (
          <CircularProgress className={classes.circularProgress} />
        ) : (
          projects.map(project => (
            <div key={ProjectPresenter.id(project)} className={classes.projectName}>
              <Typography className={classes.projectNameText}>{ProjectPresenter.name(project)}</Typography>
            </div>
          ))
        )}
      </div>
      <div className={classes.nextButtonContainer}>
        <Button variant="contained" color="primary" disabled={isConfirmPending} onClick={handleContinueClick}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ProjectConfirm;
