import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';
import TableWithStatus from 'components/TableWithStatus';
import { MAX_COLS_COUNT, headers } from 'constants/adminPayments';
import { TABLE_CELL_VARIANT } from 'components/TableStatus';
import { useAdminPaymentsActions, selectors } from 'store/AdminPaymentsSlice';
import useLoading from 'hooks/useLoading';
import TransactionPresenter from 'presenters/TransactionPresenter';

import AdminPaymentRow from './components/AdminPaymentRow';
import CreateTransactionButton from './components/CreateTransactionButton';

import useStyles from './useStyles';

const AdminPayments = () => {
  const classes = useStyles();
  const { loadActivePayments, resetPayments } = useAdminPaymentsActions();
  const { func: sendLoadActivePayments, isPending } = useLoading(loadActivePayments);
  const { data, nextPage, totalCount } = useSelector(selectors.active);

  useEffect(() => {
    sendLoadActivePayments();
    return resetPayments;
  }, []);

  const handleCreateTransaction = () => {
    resetPayments();
    sendLoadActivePayments();
  };

  return (
    <main>
      <header className={classes.header}>
        <PageHeader
          text="Admin Payments"
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="incomingPayments" />}
        />
        <CreateTransactionButton onCreate={handleCreateTransaction} />
      </header>
      <TableWithStatus
        isLoading={isPending}
        maxColsCount={MAX_COLS_COUNT}
        title="Active"
        isData={!isEmpty(data)}
        count={totalCount}
        nextPage={nextPage}
        color={TABLE_CELL_VARIANT.green}
        loadMore={loadActivePayments}
        headers={headers}
        rows={data.map(transaction => (
          <AdminPaymentRow key={TransactionPresenter.id(transaction)} transaction={transaction} />
        ))}
      />
    </main>
  );
};

export default AdminPayments;
