import PropTypes from 'prop-types';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import useBreakpoints from 'hooks/useBreakpoints';

import InboundLineItemPresenter from 'presenters/InboundLineItemPresenter';

import TableCard from 'components/TableCard';

import useStyles from './useStyles';

const InvoiceInboundTable = props => {
  const { lineItems } = props;
  const classes = useStyles();
  const { screenWiderThan } = useBreakpoints();

  const dataLabels = [
    { label: 'ID', code: 'accountCode' },
    { label: 'Description', code: 'categoryName' },
    { label: 'Amount', code: 'amount' },
  ];

  if (!screenWiderThan.md) {
    return <TableCard data={lineItems} labels={dataLabels} />;
  }

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.headerCell} width="12.7%" align="left">
              ID
            </TableCell>
            <TableCell className={classes.headerCell} width="67.46%" align="left">
              Description
            </TableCell>
            <TableCell className={classes.headerCell} width="19.84%" align="right">
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map(item => (
            <TableRow key={InboundLineItemPresenter.id(item)} className={classes.row}>
              <TableCell className={classes.cell} width="12.7%" align="left">
                {InboundLineItemPresenter.accountCode(item)}
              </TableCell>
              <TableCell className={classes.cell} width="67.46%" align="left">
                {InboundLineItemPresenter.categoryName(item)}
              </TableCell>
              <TableCell className={classes.cell} width="19.84%" align="right">
                {InboundLineItemPresenter.formattedUSDAmount(item)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InvoiceInboundTable.propTypes = {
  lineItems: PropTypes.arrayOf(InboundLineItemPresenter.shape()).isRequired,
};

export default InvoiceInboundTable;
