import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiTableType {
  defaultProps: ComponentsProps["MuiTable"];
  styleOverrides: ComponentsOverrides["MuiTable"];
}

const MuiTable: IMuiTableType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      tableLayout: "fixed",
    },
  },
};

export default MuiTable;
