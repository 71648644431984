import { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { validationSchema, initialValues } from 'forms/Builder/LineItemForm';
import { validationSchema as projectLineItemValidationSchema } from 'forms/Builder/Project/LineItemForm';
import { UNIQUE } from 'forms/errorsText';
import Icon from 'components/Icon';
import LineItemPresenter, { PROJECT_LINE_ITEMS_STATES } from 'presenters/LineItemPresenter';
import useFormik from 'hooks/useFormik';
import { mapObject, isBlank } from 'utils/storeUtils';
import { TABLE_VARIANTS } from 'constants/lineItems';

import useStyles from './useStyles';

const LineItemForm = props => {
  const { isActive, onFormHide, lineItems, onSubmit, lineItem, variant } = props;
  const classes = useStyles();
  const { touched, errors, setFieldError, setErrors, handleChange, values, setValues, handleSubmit, resetForm } =
    useFormik({
      initialValues: lineItem,
      validationSchema: variant === TABLE_VARIANTS.project ? projectLineItemValidationSchema : validationSchema,
      validateOnChange: false,
      clearErrorsOnInput: true,
      onSubmit: async currentLineItem => {
        const isAccountCodeExists = lineItems.some(item => item.accountCode === currentLineItem.accountCode);

        if (isAccountCodeExists) {
          setFieldError('accountCode', UNIQUE);
          return;
        }

        try {
          await onSubmit(currentLineItem, lineItem);
        } catch (e) {
          const processedErrors = mapObject(e, (key, value) => [key.replace('lineItem.', ''), value]);
          setErrors(processedErrors);
        }
      },
    });

  useEffect(() => {
    setValues(lineItem);
    return resetForm;
  }, [isActive]);

  const isStatusAvailable =
    !isBlank(LineItemPresenter.projectLineItemState(lineItem)) && variant === TABLE_VARIANTS.project;

  const getCategoryCellColSpan = () => {
    if (variant === TABLE_VARIANTS.default) {
      return 1;
    }

    return isStatusAvailable ? 1 : 2;
  };

  return (
    <TableRow className={clsx(classes.row, { [classes.visibleRow]: isActive })}>
      <TableCell className={classes.cell}>
        <form onSubmit={handleSubmit}>
          <TextField
            InputProps={{ classes: { input: classes.input } }}
            FormHelperTextProps={{ className: classes.inputHelperText }}
            placeholder="Acc. Code"
            name="accountCode"
            onChange={handleChange}
            value={values.accountCode}
            error={touched.accountCode && Boolean(errors.accountCode)}
            helperText={touched.accountCode && errors.accountCode}
          />
        </form>
      </TableCell>
      <TableCell className={classes.cell} colSpan={getCategoryCellColSpan()}>
        <div className={classes.categoryInputContainer}>
          <form onSubmit={handleSubmit}>
            <TextField
              InputProps={{ classes: { input: classes.input } }}
              FormHelperTextProps={{ className: classes.inputHelperText }}
              placeholder="Category"
              name="categoryName"
              onChange={handleChange}
              value={values.categoryName}
              error={touched.categoryName && Boolean(errors.categoryName)}
              helperText={touched.categoryName && errors.categoryName}
            />
          </form>
        </div>
      </TableCell>
      {isStatusAvailable && (
        <TableCell className={classes.cell}>
          <Select
            variant="filled"
            name="projectLineItemState"
            classes={{ select: classes.input }}
            value={values.projectLineItemState}
            onChange={handleChange}
          >
            <MenuItem value={PROJECT_LINE_ITEMS_STATES.opened}>Open</MenuItem>
            <MenuItem value={PROJECT_LINE_ITEMS_STATES.closed}>Closed</MenuItem>
          </Select>
        </TableCell>
      )}
      <TableCell className={classes.cell}>
        <div className={classes.actions}>
          <Button className={classes.checkIconButton} onClick={handleSubmit} variant="contained" color="primary">
            <Icon className={classes.checkIcon} name="checkMark" width={16} height={16} />
          </Button>
          <Button className={classes.crossIconButton} onClick={onFormHide} variant="contained">
            <Icon className={classes.crossIcon} name="cross" width={16} height={16} />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

LineItemForm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onFormHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  lineItem: LineItemPresenter.shape(),
  variant: PropTypes.oneOf(Object.values(TABLE_VARIANTS)),
};

LineItemForm.defaultProps = {
  variant: TABLE_VARIANTS.default,
  lineItem: initialValues,
};

export default LineItemForm;
