import { useEffect } from 'react';

import Paper from '@mui/material/Paper';

import SectionTitle from 'components/SectionTitle';
import { useIncomingPayments } from 'store/IncomingPaymentsSlice';

import IncomingPaymentsHeader from './components/IncomingPaymentsHeader';
import UnassignedIncomingPaymentsTable from './components/UnassignedIncomingPaymentsTable';
import HistoricalIncomingPaymentsTable from './components/HistoricalIncomingPaymentsTable';

import useStyles from './useStyles';

const IncomingPayments = () => {
  const classes = useStyles();
  const { readAll } = useIncomingPayments();

  useEffect(() => {
    readAll();
  }, []);

  return (
    <main className={classes.root}>
      <IncomingPaymentsHeader />
      <div className={classes.paymentsContainer}>
        <SectionTitle className={classes.sectionTitle}>New</SectionTitle>
        <Paper className={classes.paper}>
          <div className={classes.tableContainer}>
            <UnassignedIncomingPaymentsTable />
          </div>
        </Paper>
        <SectionTitle className={classes.sectionTitle}>Historical</SectionTitle>
        <Paper>
          <div className={classes.tableContainer}>
            <HistoricalIncomingPaymentsTable />
          </div>
        </Paper>
      </div>
    </main>
  );
};

export default IncomingPayments;
