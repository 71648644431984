import { memo } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import useStyles from './useStyles';

const TableEmptyRow = props => {
  const { colSpan, text } = props;

  const classes = useStyles();

  return (
    <TableRow classes={{ root: classes.row }}>
      <TableCell classes={{ root: classes.cell }} colSpan={colSpan} align="center">
        <span className={classes.label}>{text}</span>
      </TableCell>
    </TableRow>
  );
};

TableEmptyRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default memo(TableEmptyRow);
