import AppRoutes from 'routes/AppRoutes';
import Icon from 'components/Icon';
import BuilderPendingInvoicesIcon from 'layouts/UserLayout/components/BuilderPendingInvoicesIcon';
import IncomingPaymentsUnreadIcon from 'containers/Builder/components/IncomingPaymentsUnreadIcon';
import SubcontractorContactsIcon from 'layouts/UserLayout/components/SubcontractorContactsIcon';
import SubcontractorPendingInvoicesCountIcon from 'layouts/UserLayout/components/SubcontractorPendingInvoicesCountIcon';
import UserPresenter from 'presenters/UserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

export const BUILDER_NAV_ITEMS = [
  // {
  //   path: AppRoutes.dashboardPath(),
  //   iconName: 'dashboard', // <Icon width={24} height={16} name="dashboard" className={getIconClassName('dashboard')} />,
  //   label: 'Dashboard',
  // },
  {
    path: AppRoutes.projectsPath(),
    iconName: 'folder',
    label: 'Projects',
  },
  {
    path: AppRoutes.invoicesPath(),
    iconName: 'builderInvoices',
    label: 'Invoices',
  },
  {
    path: AppRoutes.subcontractorsPath(),
    iconName: 'couple',
    label: 'Subcontractors',
  },
  {
    path: AppRoutes.incomingPayments(),
    iconName: 'builderIncomingPayments',
    label: 'Incoming Payments',
  },
  {
    paths: [AppRoutes.settingsCompanyPath(), AppRoutes.settingsTeamPath()],
    iconName: 'gear',
    label: 'Settings',
    submenu: [
      {
        path: AppRoutes.settingsCompanyPath(),
        label: 'Company Settings',
      },
      {
        path: AppRoutes.settingsTeamPath(),
        label: 'User Management',
      },
    ],
  },
];

const BUILDER_MANAGER_NAV_ITEMS = [
  {
    path: AppRoutes.projectsPath(),
    iconName: 'folder',
    label: 'Projects',
  },
  {
    path: AppRoutes.invoicesPath(),
    iconName: 'builderInvoices',
    label: 'Invoices',
  },
];

export const SUBCONTRACTOR_NAV_ITEMS = [
  {
    path: AppRoutes.invoicesPath(),
    iconName: 'subcontractorInvoices',
    label: 'Invoices',
  },
  {
    path: AppRoutes.contactsPath(),
    iconName: 'subcontractorContacts',
    label: 'Contacts',
  },
  {
    path: AppRoutes.settingsPath(),
    iconName: 'gear',
    label: 'Settings',
  },
];

const SUBCONTRACTOR_MANAGER_NAV_ITEMS = [
  {
    path: AppRoutes.invoicesPath(),
    iconName: 'subcontractorInvoices',
    label: 'Invoices',
  },
];

export const ADMIN_NAV_ITEMS = [
  {
    path: AppRoutes.adminLoansPath(),
    iconName: 'bankCheck',
    label: 'Loans',
  },
  {
    path: AppRoutes.adminPaymentsPath(),
    iconName: 'incomingPayments',
    label: 'Payments',
  },
  {
    path: AppRoutes.adminProjectsPath(),
    iconName: 'folder',
    label: 'Projects',
  },
  {
    path: AppRoutes.adminInvoicesPath(),
    iconName: 'file',
    label: 'Invoices',
  },
  {
    path: AppRoutes.adminBuildersPath(),
    iconName: 'couple',
    label: 'GC teams',
  },
  {
    path: AppRoutes.adminSubcontractorsPath(),
    iconName: 'tools',
    label: 'Subcontractors',
  },
];

export const CUSTOMER_NAV_ITEMS = [
  {
    path: AppRoutes.customerPaymentsPath(),
    iconName: 'file',
    label: 'Payments',
  },
];

const getNavItemIcon = (name, getIconClassName) => {
  const mapping = {
    dashboard: <Icon width={24} height={16} name="dashboard" className={getIconClassName('dashboard')} />,
    folder: <Icon width={24} height={16} name="folder" className={getIconClassName('folder')} />,
    builderInvoices: <BuilderPendingInvoicesIcon />,
    couple: <Icon width={24} height={16} name="couple" className={getIconClassName('couple')} />,
    subcontractorContacts: <SubcontractorContactsIcon />,
    gear: <Icon width={20} height={20} name="gear" className={getIconClassName('gear')} />,
    builderIncomingPayments: <IncomingPaymentsUnreadIcon />,
    subcontractorInvoices: <SubcontractorPendingInvoicesCountIcon />,
    incomingPayments: (
      <Icon width={23} height={20} name="incomingPayments" className={getIconClassName('incomingPayments')} />
    ),
    tools: <Icon width={20} height={20} name="tools" />,
    bankCheck: <Icon width={20} height={20} name="bankCheck" />,
    file: <Icon width={24} height={18} name="file" className={getIconClassName('file')} />,
  };

  return mapping[name];
};

export const getPlainNavItems = user => {
  if (UserPresenter.isSubcontractorManager(user)) {
    return SUBCONTRACTOR_MANAGER_NAV_ITEMS;
  }
  if (UserPresenter.isSubcontractor(user)) {
    return SUBCONTRACTOR_NAV_ITEMS;
  }
  if (UserPresenter.isBuilderManager(user)) {
    return BUILDER_MANAGER_NAV_ITEMS;
  }
  if (UserPresenter.isBuilder(user)) {
    return BUILDER_NAV_ITEMS;
  }

  if (UserPresenter.isAdmin(user)) {
    return ADMIN_NAV_ITEMS;
  }

  if (UserPresenter.isCustomer(user)) {
    return CUSTOMER_NAV_ITEMS;
  }

  return [];
};

export const generateNavItems = items => getIconClassName => {
  return items.map(({ iconName, ...item }) => {
    return { ...item, icon: getNavItemIcon(iconName, getIconClassName) };
  });
};

const DEFAULT_PROJECT_SUBMENU_NAV_ITEMS = projectId => [
  {
    path: AppRoutes.projectDetailsPath(projectId),
    label: 'Project Details',
  },
  {
    path: AppRoutes.projectClientPaymentsPath(projectId),
    label: 'Client Payments',
  },
  {
    path: AppRoutes.projectInvoicesPath(projectId),
    label: 'Invoices',
  },
  {
    path: AppRoutes.projectTeamPath(projectId),
    label: 'Team',
  },
];

const MANAGER_PROJECT_SUBMENU_NAV_ITEMS = projectId => [
  {
    path: AppRoutes.projectDetailsPath(projectId),
    label: 'Project Details',
  },
  {
    path: AppRoutes.projectClientPaymentsPath(projectId),
    label: 'Client Payments',
  },
  {
    path: AppRoutes.projectInvoicesPath(projectId),
    label: 'Invoices',
  },
];

const DRAFT_PROJECT_SUBMENU_NAV_ITEMS = projectId => [
  {
    path: AppRoutes.projectDetailsPath(projectId),
    label: 'Project Details',
  },
];

export const getPlainProjectSubmenuNavItems = (project, projectId, isManager) => {
  if (ProjectPresenter.isDraft(project)) {
    return DRAFT_PROJECT_SUBMENU_NAV_ITEMS(projectId);
  }
  if (isManager) {
    return MANAGER_PROJECT_SUBMENU_NAV_ITEMS(projectId);
  }
  return DEFAULT_PROJECT_SUBMENU_NAV_ITEMS(projectId);
};
