import { BuilderOnboardingState, CustomerOnboardingState, SubcontractorOnboardingState } from "domain/user/enums";

import { CurrentUser } from "store/api/user/types";

import { OnboardingLayoutVariant } from "./types";

export const getFormOrderState = (variant: OnboardingLayoutVariant, currentUser: CurrentUser): number | null => {
  if (variant === OnboardingLayoutVariant.builder) {
    switch (currentUser.builderOnboardingState) {
      case BuilderOnboardingState.notStarted: {
        return 1;
      }
      case BuilderOnboardingState.companyCreated: {
        return 2;
      }
      case BuilderOnboardingState.lineItemsCreated: {
        return 3;
      }
      case BuilderOnboardingState.completed: {
        return 4;
      }
      default: {
        return null;
      }
    }
  }

  if (variant === OnboardingLayoutVariant.subcontractor) {
    switch (currentUser.subcontractorOnboardingState) {
      case SubcontractorOnboardingState.notStarted: {
        return 1;
      }
      case SubcontractorOnboardingState.companyCreated: {
        return 2;
      }
      default: {
        return null;
      }
    }
  }

  if (variant === OnboardingLayoutVariant.customer) {
    switch (currentUser.customerOnboardingState) {
      case CustomerOnboardingState.notStarted: {
        return 1;
      }
      case CustomerOnboardingState.projectConfirmed: {
        return 2;
      }
      default: {
        return null;
      }
    }
  }

  return null;
};
