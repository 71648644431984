import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  breadcrumbs: {
    fontSize: theme.typography.pxToRem(14),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  title: {
    marginTop: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      letterSpacing: -0.2,
    },
  },
}));
