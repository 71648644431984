import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { reject, concat } from 'ramda';

import { isBlank } from 'utils/storeUtils';
import ProjectsRepository from 'repositories/builder/company/ProjectsRepository';
import ManagersRepository from 'repositories/builder/company/ManagersRepository';
import RegularPaymentsRepository from 'repositories/builder/project/RegularPaymentsRepository';

const initialState = {
  project: {},
  regularPayments: {
    data: [],
  },
  companyManagers: {
    data: [],
    meta: {},
  },
};

const newProjectSlice = createSlice({
  name: 'newProject',
  initialState,
  reducers: {
    loadRegularPaymentsSuccess(state, { payload }) {
      state.regularPayments.data = payload;
    },
    loadCompanyManagersSuccess(state, { payload }) {
      state.companyManagers.data = concat(state.companyManagers.data, payload.users);
      state.companyManagers.meta = payload.meta;
    },
    changeProject(state, { payload }) {
      state.project = payload;
    },
    resetNewProject() {
      return initialState;
    },
  },
});

export const { actions } = newProjectSlice;
export default newProjectSlice.reducer;

export const useNewProjectActions = () => {
  const dispatch = useDispatch();

  const loadRegularPayments = async params => {
    const { data } = await RegularPaymentsRepository.options(params);
    dispatch(actions.loadRegularPaymentsSuccess(data));

    return Promise.resolve(data);
  };

  const loadCompanyManagers = async (page = 1) => {
    const { data } = await ManagersRepository.index({ page });
    dispatch(actions.loadCompanyManagersSuccess(data));

    return Promise.resolve(data);
  };

  const createProject = params => {
    const filteredParams = reject(isBlank, params);

    return ProjectsRepository.create({ project: filteredParams });
  };

  const changeProject = params => {
    dispatch(actions.changeProject(params));
  };

  const resetNewProject = () => {
    dispatch(actions.resetNewProject());
  };

  return {
    loadRegularPayments,
    loadCompanyManagers,
    createProject,
    changeProject,
    resetNewProject,
  };
};
