import BlocksImage from "assets/images/blocks.png";
import RoofImage from "assets/images/roof.png";

import { SxStyles } from "theme";

const styles: SxStyles = {
  container: {
    maxWidth: {
      md: "800px",
    },
    margin: "0 auto",
    padding: 0,
  },
  root: {
    display: "flex",
    borderRadius: {
      sm: "8px",
    },
    backgroundColor: "colors.white",
    boxShadow: {
      sm: "0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)",
    },
    marginTop: {
      md: "122px",
    },
  },
  sideContainer: {
    minHeight: "inherit",
    borderRadius: "inherit",
    boxShadow: "inherit",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: "300px",
    backgroundSize: "cover",
    display: {
      xs: "none",
      md: "block",
    },
  },
  roof: {
    backgroundImage: `url(${RoofImage})`,
    minHeight: "460px",
  },
  blocks: {
    backgroundImage: `url(${BlocksImage})`,
    minHeight: "580px",
  },
  logo: {
    display: "flex",
    marginTop: "30px",
    marginLeft: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "32px",
    textTransform: "uppercase",
    color: "colors.white",
    letterSpacing: -1,
  },
  main: {
    padding: {
      xs: "64px 24px",
      md: "56px 90px",
    },
    height: {
      xs: "100vh",
      md: "auto",
    },
    margin: "0 auto",
  },
};

export default styles;
