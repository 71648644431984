import Tooltip from '@mui/material/Tooltip';

import UserPresenter from 'presenters/UserPresenter';
import { isBlank } from 'utils/storeUtils';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const MemberName = props => {
  const { member } = props;
  const classes = useStyles();

  if (isBlank(UserPresenter.fullName(member))) {
    return (
      <div title={UserPresenter.email(member)} className={classes.name}>
        {UserPresenter.email(member)}
        <Tooltip title="Once user has confirmed their account, the user's name will appear here.">
          <div>
            <Icon name="containedInfo" height={15} width={14} className={classes.infoIcon} />
          </div>
        </Tooltip>
      </div>
    );
  }

  return <div className={classes.name}>{UserPresenter.fullName(member)}</div>;
};

MemberName.propTypes = {
  member: UserPresenter.shape().isRequired,
};

export default MemberName;
