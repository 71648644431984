import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import { selectors } from 'store/ProjectClientInvoicesSlice';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';

import ProjectClientInvoicesTableRow from './components/ProjectClientInvoicesTableRow';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 4;

const ProjectClientInvoicesTable = props => {
  const { isLoading, loadMore } = props;
  const classes = useStyles();
  const { data, nextPage } = useSelector(selectors.customerInvoices);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.headerCell} width="29.43%">
              Invoice
            </TableCell>
            <TableCell align="left" className={classes.headerCell} width="17.43%">
              Date
            </TableCell>
            <TableCell align="right" className={clsx(classes.headerCell, classes.amountHeaderCell)} width="24.37%">
              Amount
            </TableCell>
            <TableCell align="center" className={classes.headerCell} width="28.77%">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowsWithLoading
            nextPage={nextPage}
            isLoading={isLoading}
            rows={data.map(customerInvoice => (
              <ProjectClientInvoicesTableRow
                key={CustomerInvoicePresenter.id(customerInvoice)}
                customerInvoice={customerInvoice}
              />
            ))}
            loadMore={loadMore}
            isData={!isEmpty(data)}
            maxColsCount={MAX_COLS_COUNT}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProjectClientInvoicesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export default ProjectClientInvoicesTable;
