import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { isBlank } from 'utils/storeUtils';

import InboundLineItemPresenter from 'presenters/InboundLineItemPresenter';

import useStyles from './useStyles';

const TableCard = props => {
  const { data, labels } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {data.map((item, idx) => {
        return (
          <Grid item container direction="column" key={idx} className={classes.item}>
            {labels.map(label => {
              const itemData = item[label.code];

              return !isBlank(itemData) ? (
                <div key={label.label} className={classes.row}>
                  <span className={classes.label}>{label.label}</span>
                  <span className={classes.value}>{InboundLineItemPresenter[label.code](item)}</span>
                </div>
              ) : null;
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

TableCard.propTypes = {
  data: PropTypes.arrayOf(InboundLineItemPresenter.shape()).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
      ]),
    }),
  ).isRequired,
};

export default TableCard;
