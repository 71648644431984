import { InvoiceKind, InvoiceState } from "domain/invoice/enums";

import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { IMeta, IPaginationParameters } from "types/api";

import { AdminInvoiceListItem } from "./types";

import type { PartialDeep } from "type-fest";

interface IAdminInvoicesResponse {
  invoices: Array<AdminInvoiceListItem>;
  meta: IMeta;
}

type GetAdminInvoicesParameters = IPaginationParameters & {
  q: {
    stateIn: Array<InvoiceState>;
    kindIn: Array<InvoiceKind>;
    s: string;
  };
};

const adminInvoiceApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getAdminInvoices: builder.query<IAdminInvoicesResponse, PartialDeep<GetAdminInvoicesParameters>>({
      query: (parameters) => ({
        url: apiRoutes.siteApiV1AdminInvoicesPath(),
        method: "GET",
        params: {
          ...parameters,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.invoices.map(({ id }) => ({ type: CacheTag.adminInvoice as const, id })),
              { type: CacheTag.adminInvoice, id: "LIST" },
            ]
          : [{ type: CacheTag.adminInvoice, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});
export const { useGetAdminInvoicesQuery } = adminInvoiceApi;
