export const API_ERROR_TEXTS = {
  unexpectedError: 'Looks like something went wrong.',
};

export const EMAIL = 'Not a valid email address.';
export const PASSWORD = 'Password must have at least one non space character.';
export const REQUIRED = "Can't be blank.";
export const UNIQUE = 'Should be unique.';
export const INVITE = 'User with that email is unavailable for the invite.';
export const NUMBER = 'Must be a number.';
export const INTEGER = 'Must be an integer.';
export const POSITIVE = 'Number must be positive.';
export const DATE = 'Must be a date.';
export const PROBABILITY = 'Must be a correct probability.';
export const PHONE = 'Invalid Phone number';

export const maxAmountValue = val => `Must be less than or equal to ${val}`;
export const minLength = length => `Must have at least ${length} characters.`;
