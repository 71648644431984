import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  projectConfirmContainer: {
    paddingRight: theme.spacing(8),
  },
  header: {
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.colors.gunsmokeGray,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(6),
  },
  projectName: {
    backgroundColor: theme.palette.colors.desertStorm,
    marginBottom: theme.spacing(3),
  },
  projectNameText: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(25),
    padding: '12px 24px',
  },
  nextButtonContainer: {
    textAlign: 'right',
  },
  headerCell: {
    fontSize: theme.typography.pxToRem(12),
    padding: '4px 0px',
  },
  invoiceHeaderCell: {
    paddingRight: theme.spacing(8),
  },
}));
