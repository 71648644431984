import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  autocompleteRoot: {
    '&:not(:last-child)': {
      marginTop: 0,
    },
  },
  option: {
    width: '100%',
    margin: '16px 16px 0 16px',
    paddingBottom: theme.typography.pxToRem(12),
    borderBottom: [1, 'solid', theme.palette.colors.lightGray],
  },
  optionName: {
    fontSize: theme.typography.pxToRem(16),
  },
  lineItemOptions: {
    '& li:last-child $option': {
      border: 'none',
    },
  },
}));
