export const enum UserRole {
  systemAdmin = "system_admin",
  companyOwner = "company_owner",
  user = "user",
  customer = "customer",
}

export const enum UserCompanyRole {
  companyAdmin = "company_admin",
  companyManager = "company_manager",
}

export enum BuilderOnboardingState {
  notStarted = "builder_onboarding_not_started",
  companyCreated = "builder_onboarding_company_created",
  lineItemsCreated = "builder_onboarding_line_items_created",
  completed = "builder_onboarding_completed",
}

export enum CustomerOnboardingState {
  notStarted = "customer_onboarding_not_started",
  projectConfirmed = "customer_onboarding_project_confirmed",
  completed = "customer_onboarding_completed",
}

export enum SubcontractorOnboardingState {
  notStarted = "subcontractor_onboarding_not_started",
  companyCreated = "subcontractor_onboarding_company_created",
  completed = "subcontractor_onboarding_completed",
}
