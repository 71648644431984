import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  topMenu: {
    display: 'flex',
  },
  logoContainer: {
    backgroundColor: theme.palette.colors.smokeWhite,
    borderRadius: 3,
    width: 40,
    height: 40,
    padding: 5.79,
  },
  companyNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    maxHeight: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
  },
  companyName: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
}));
