import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { equals, isNil } from 'ramda';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { selectors as earlyPaymentsSelectors } from 'store/InvoiceEarlyPaymentsSlice';
import { getInvoiceTotal } from 'utils/invoiceUtils';
import { formatUSD } from 'utils/numberUtils';
import { INVOICE_FORM_VARIANTS, MAX_AMOUNT_PAYMENT } from 'constants/invoices';
import useFormik from 'hooks/useFormik';
import {
  validationSchema as subcontractorValidationSchema,
  initialValues as subcontractorInitialValues,
} from 'forms/Subcontractor/InvoiceForm';
import {
  validationSchema as builderValidationSchema,
  initialValues as builderInitialValues,
} from 'forms/Builder/InvoiceForm';
import { maxAmountValue } from 'forms/errorsText';
import { INVOICE_KINDS } from 'presenters/InvoicePresenter';

import InvoiceFormHeader from './components/InvoiceFormHeader';
import InvoiceInfo from './components/InvoiceInfo';
import InvoiceLineItems from './components/InvoiceLineItems';
import InvoiceConfirmation from './components/InvoiceConfirmation';
import PaymentTerms from './components/PaymentTerms';

import useStyles from './useStyles';

const getFormikInitParams = variant => {
  switch (variant) {
    case INVOICE_FORM_VARIANTS.builder:
      return { initialValues: builderInitialValues, validationSchema: builderValidationSchema };
    case INVOICE_FORM_VARIANTS.subcontractor:
      return { initialValues: subcontractorInitialValues, validationSchema: subcontractorValidationSchema };
    default:
      return null;
  }
};

const InvoiceForm = props => {
  const {
    variant,
    confirmButtonText,
    title,
    initialValues,
    onConfirm,
    onCompaniesLoad,
    onLineItemItemCreate,
    onLineItemLoad,
  } = props;
  const classes = useStyles();
  const earlyPayments = useSelector(earlyPaymentsSelectors.earlyPayments, equals);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [invoiceToConfirm, setInvoiceToConfirm] = useState(null);
  const [isAddressDisabled, setIsAddressDisabled] = useState(false);
  const formData = useFormik({
    ...getFormikInitParams(variant),
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: params => {
      setIsConfirmationDialogOpen(true);
      setInvoiceToConfirm(params);
    },
  });
  const { values, handleSubmit, setFieldValue, setErrors, setValues } = formData;
  const isSelfPaid = values.kind === INVOICE_KINDS.selfPaid;

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    setIsAddressDisabled(isSelfPaid);

    if (isSelfPaid) {
      setFieldValue('subcontractorEmail', '');
    }
  }, [values]);

  const handleFileAttach = ([file]) => {
    formData.setFieldValue('attachment', file);
    formData.setFieldValue('attachmentName', file.name);
  };

  const handleConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
    setInvoiceToConfirm(null);
  };

  const earlyPaymentAmount = isNil(formData.values.earlyPaymentId)
    ? null
    : earlyPayments.find(({ earlyPaymentId }) => earlyPaymentId === Number(formData.values.earlyPaymentId)).amount;

  const invoiceTotal = earlyPaymentAmount || getInvoiceTotal(values.inboundLineItems);
  const invoiceTotalInUSD = formatUSD(invoiceTotal, 2);
  const isInvoiceTotalOverMaxAmount = invoiceTotal > MAX_AMOUNT_PAYMENT;

  const isEarlyPaymentReady = () => {
    if (variant !== INVOICE_FORM_VARIANTS.subcontractor) {
      return true;
    }

    if (!values.earlyPaymentId) {
      return true;
    }

    return values.termsOfService;
  };

  return (
    <main className={classes.root}>
      <InvoiceFormHeader title={title} attachment={values.attachment} onFileAttach={handleFileAttach} />
      <form onSubmit={handleSubmit}>
        <div className={classes.form}>
          <div className={classes.formContentContainer}>
            <InvoiceInfo
              formData={formData}
              onCompaniesLoad={onCompaniesLoad}
              variant={variant}
              isAddressDisabled={isAddressDisabled}
            />
          </div>
          <div className={classes.formContentContainer}>
            <InvoiceLineItems
              formData={formData}
              variant={variant}
              onLineItemItemCreate={onLineItemItemCreate}
              onLineItemLoad={onLineItemLoad}
            />
          </div>
        </div>
        {variant === INVOICE_FORM_VARIANTS.subcontractor && (
          <div className={classes.paymentTermsContainer}>
            <PaymentTerms formData={formData} />
          </div>
        )}
        <div className={classes.confirmationContainer}>
          <div className={classes.invoiceTotalContainer}>
            <div className={classes.invoiceTotalLabel}>
              {variant === INVOICE_FORM_VARIANTS.subcontractor ? 'Payment total' : 'Total'}
            </div>
            <div className={classes.invoiceTotal}>{invoiceTotalInUSD}</div>
            {isInvoiceTotalOverMaxAmount && (
              <div className={classes.invoiceTotalError}>{maxAmountValue(formatUSD(MAX_AMOUNT_PAYMENT, 2))}</div>
            )}
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isInvoiceTotalOverMaxAmount || !isEarlyPaymentReady()}
          >
            {confirmButtonText}
          </Button>
        </div>
      </form>
      <Dialog
        open={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        className={classes.confirmationDialog}
      >
        {invoiceToConfirm && (
          <InvoiceConfirmation
            variant={variant}
            invoice={invoiceToConfirm}
            confirmButtonText="Submit"
            setErrors={setErrors}
            onConfirm={onConfirm}
            onCancel={handleConfirmationDialogClose}
          />
        )}
      </Dialog>
    </main>
  );
};

InvoiceForm.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  confirmButtonText: PropTypes.string,
  title: PropTypes.string,
  initialValues: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
  onCompaniesLoad: PropTypes.func.isRequired,
  onLineItemItemCreate: PropTypes.func,
  onLineItemLoad: PropTypes.func,
};

InvoiceForm.defaultProps = {
  confirmButtonText: 'Preview',
  title: 'Create New Invoice',
  initialValues: null,
  onLineItemItemCreate: Function.prototype,
  onLineItemLoad: Function.prototype,
};

export default InvoiceForm;
