import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  listItem: {
    padding: 0,
  },
  listItemLabel: {},
  listItemLabelIndented: {
    marginLeft: 11,
  },
  iconWrapper: {
    color: theme.palette.colors.heavyMetalGray,
  },
  selectedItem: {
    '& > $collapsedLink > $iconWrapper, & > $collapsedButton > $iconWrapper, & > $collapsedButton > $listItemLabel': {
      color: theme.palette.colors.green,
    },
  },
  collapsedLink: {
    padding: '14px 11px',
    color: theme.palette.colors.heavyMetalGray,
    '&:hover > $iconWrapper': {
      color: theme.palette.colors.green,
    },
  },
  collapsedButton: {
    padding: '14px 11px',
    color: theme.palette.colors.heavyMetalGray,
    '&:hover > $iconWrapper': {
      color: theme.palette.colors.green,
    },
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none',
    textAlign: 'left',
    wordBreak: 'break-all',
  },
}));
