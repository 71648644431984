import { FC, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography } from "@mui/material";
import muiSx from "mui-sx";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";

import * as EarlyPaymentService from "domain/earlyPayment/service";
import * as InboundLineItemService from "domain/inboundLineItem/service";
import { InvoicePaymentKind } from "domain/invoice/enums";
import * as InvoiceService from "domain/invoice/service";
import * as InvoiceLineItemService from "domain/invoiceLineItem/service";

import { formatUSD } from "libs/currency";
import { formatDate } from "libs/datetime";

import { useUpdateSubcontractorCompanyInvoiceMutation } from "store/api/subcontractor/company/invoice/apiSlice";
import {
  defaultValues,
  InvoicePaymentTermsFormData,
  serializeFormData,
  validationSchema,
} from "store/api/subcontractor/company/invoice/schemas/paymentTerms";
import { useGetSubcontractorInvoiceEarlyPaymentOptionsQuery } from "store/api/subcontractor/invoice/earlyPayment/option/apiSlice";

import EarlyPaymentOptions from "./EarlyPaymentOptions";
import styles from "./styles";
import { IPaymentTermsProps } from "./types";

const PaymentTerms: FC<IPaymentTermsProps> = (props) => {
  const { invoice, onInvoiceSubmit } = props;

  const { t } = useTranslation("subcontractorInvoice", { keyPrefix: "paymentTerms" });

  const [updateInvoice] = useUpdateSubcontractorCompanyInvoiceMutation();

  const invoiceLineItemAmounts = InvoiceLineItemService.getInvoiceLineItemsAmounts(invoice.invoiceLineItems);
  const inboundLineItemAmounts = InboundLineItemService.getInboundLineItemsAmounts(invoice.inboundLineItems);

  const parameters = {
    earlyPayment: {
      lineItemAmounts: [...invoiceLineItemAmounts, ...inboundLineItemAmounts],
    },
  };

  const { data: earlyPaymentOptions, isFetching } = useGetSubcontractorInvoiceEarlyPaymentOptionsQuery(parameters);

  const methods = useForm<InvoicePaymentTermsFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const {
    field: { value: earlyPaymentId, onChange: onEarlyPaymentIdChange },
  } = useController({ name: "earlyPaymentId", control });

  const {
    field: { value: paymentKind, onChange: onPaymentKindChange },
  } = useController({ name: "paymentKind", control });

  const {
    field: { value: termsOfService, onChange: onTermsOfServiceChange },
  } = useController({ name: "termsOfService", control });

  const isEarlyPayment = InvoiceService.isEarlyPaymentKind({ paymentKind });
  const isDefaultPayment = InvoiceService.isDefaultPaymentKind({ paymentKind });

  useEffect(() => {
    if (isDefaultPayment) {
      onEarlyPaymentIdChange(null);
    }
  }, [paymentKind]);

  const onSubmit = async (data: InvoicePaymentTermsFormData) => {
    const serializedData = serializeFormData(data);
    await updateInvoice({ invoiceId: invoice.id, invoice: serializedData });
    onInvoiceSubmit();
  };

  const estimatedPaymentDate = InvoiceService.getInvoiceEstimatedPaymentDate(earlyPaymentId, invoice);
  const earlyPaymentAmount = EarlyPaymentService.amount(earlyPaymentOptions, Number(earlyPaymentId));

  return (
    <Div sx={styles.root}>
      <Typography variant="h4" sx={styles.title}>
        {t("paymentTerms")}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper sx={styles.content}>
            <Div sx={styles.description}>
              <Typography sx={styles.contentHeader}>{t("acceptEarlyPayment")}</Typography>
              <Typography sx={styles.text}>
                {t("estimatedPaymentDate", { date: formatDate(estimatedPaymentDate) })}
              </Typography>
              {isDefaultPayment && (
                <Typography sx={styles.text}>
                  {t("hasDayPaymentTerms", { companyName: invoice.builder.name })}
                </Typography>
              )}
            </Div>
            <Div sx={styles.actions}>
              <Div sx={styles.buttons}>
                <Button
                  variant="outlined"
                  sx={muiSx(styles.button, {
                    sx: styles.buttonActive,
                    condition: isEarlyPayment,
                  })}
                  onClick={() => onPaymentKindChange(InvoicePaymentKind.earlyPayment)}
                >
                  {t("yesAcceptEarlyPayment")}
                </Button>
                <Button
                  variant="outlined"
                  sx={muiSx(styles.button, {
                    sx: styles.buttonActive,
                    condition: isDefaultPayment,
                  })}
                  onClick={() => onPaymentKindChange(InvoicePaymentKind.default)}
                >
                  {t("noUseStandardPaymentTerms")}
                </Button>
              </Div>
              {isEarlyPayment && <EarlyPaymentOptions earlyPaymentOptions={earlyPaymentOptions} loading={isFetching} />}
            </Div>
          </Paper>

          {isEarlyPayment && (
            <Paper sx={styles.agreement}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsOfService}
                      onChange={(event) => onTermsOfServiceChange(event.target.checked)}
                    />
                  }
                  label={t("termsOfService")}
                />
              </FormGroup>
            </Paper>
          )}

          <Div sx={styles.confirmationContainer}>
            <Div sx={styles.invoiceTotalContainer}>
              <Div sx={styles.invoiceTotalLabel}>{t("paymentTotal")}</Div>
              <Div sx={styles.invoiceTotal}>{formatUSD(earlyPaymentAmount ?? invoice.totalAmount)}</Div>
            </Div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting || (isEarlyPayment && !termsOfService)}
            >
              {t("acceptPayment")}
            </Button>
          </Div>
        </form>
      </FormProvider>
    </Div>
  );
};

export default PaymentTerms;
