import UsersRepository from 'repositories/UsersRepository';
import EmailConfirmationsRepository from 'repositories/user/EmailConfirmationsRepository';
import PasswordResetsRepository from 'repositories/user/PasswordResetsRepository';
import ActivationsRepository from 'repositories/user/ActivationsRepository';
import CustomersRepository from 'repositories/builder/company/CustomersRepository';
import { joinMoreThanOne } from 'utils/arrayUtils';

export const useAuthActions = () => {
  const signUp = params => {
    return UsersRepository.create(params);
  };

  const sendConfirmationEmail = params => {
    return EmailConfirmationsRepository.create(params);
  };

  const confirmEmail = params => {
    return EmailConfirmationsRepository.update(params);
  };

  const sendPasswordResetEmail = params => {
    return PasswordResetsRepository.create(params);
  };

  const resetPassword = params => {
    return PasswordResetsRepository.update(params);
  };

  const handleTeamMemberActivationError = errors => {
    const separator = ', ';
    return {
      password: joinMoreThanOne(errors.password, separator),
      message: errors.response && errors.response.data.error,
    };
  };

  const teamMemberActivation = async ({ activationToken = '', fullName = '', password = '' }) => {
    const requestParams = {
      activationToken,
      userActivation: {
        fullName,
        password,
      },
    };
    try {
      const data = await ActivationsRepository.create(requestParams);
      return data;
    } catch (error) {
      throw handleTeamMemberActivationError(error);
    }
  };

  const customerActivation = async () => {
    const data = await CustomersRepository.create();
    return data;
  };

  return {
    signUp,
    sendConfirmationEmail,
    confirmEmail,
    sendPasswordResetEmail,
    resetPassword,
    teamMemberActivation,
    customerActivation,
  };
};
