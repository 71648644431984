import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  checkIcon: {
    fill: theme.palette.colors.white,
  },
  crossIcon: {
    fill: theme.palette.colors.lemonGrass,
  },
  categoryInputContainer: {
    width: 315,
  },
  input: {
    padding: '6px 10px',
  },
  binContainer: {
    position: 'relative',
    visibility: 'hidden',
  },
  binIconButton: {
    position: 'absolute',
    top: -20,
    right: -10,
  },
  row: {
    '&:hover $cell $binContainer': {
      visibility: 'visible',
    },
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    padding: '15px 10px 15px 5px',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
  },
  stateCell: {
    paddingRight: 25,
    width: 88,
  },
  categoryCell: {
    width: 210,
  },
  binCell: {
    width: 40,
  },
  noticeIcon: {
    fill: theme.palette.colors.orange,
    marginRight: 12,
    marginTop: 5,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  infoContainer: {
    display: 'flex',
    visibility: 'hidden',
  },
  info: {
    color: theme.palette.colors.orange,
    lineHeight: '16px',
    wordBreak: 'break-word',
  },
  visible: {
    visibility: 'visible',
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none',
    textAlign: 'left',
    wordBreak: 'break-all',
  },
  status: {
    fontWeight: 600,
    color: theme.palette.colors.orange,
    textTransform: 'capitalize',
  },
}));
