import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: '28px 24px',
    width: 528,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
  },
  invitationIcon: {
    fill: theme.palette.colors.green,
    margin: '4px 10px 0px 4px',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  errors: {
    color: theme.palette.colors.red,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: theme.spacing(1),
  },
}));
