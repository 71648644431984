import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuthActions } from 'store/AuthSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';
import { getUrlParam } from 'utils/location';
import AppRoutes from 'routes/AppRoutes';

const EmailConfirmation = () => {
  const { confirmEmail } = useAuthActions();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();
  const history = useHistory();

  const token = getUrlParam('token');

  const handleConfirmEmail = async () => {
    await confirmEmail({ token });
    await loadCurrentUser();
    history.push(AppRoutes.rootPath());
  };

  useEffect(() => {
    handleConfirmEmail();
  }, []);

  return null;
};

export default EmailConfirmation;
