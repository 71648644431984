import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useStyles from './useStyles';

const AdminInvoiceApproveForm = props => {
  const { onOpenRejectForm, approveInvoice } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={10} />
        <Grid item xs={3} md={2} className={classes.buttonsContainer}>
          <div className={classes.stickyContainer}>
            <div className={classes.fixedButtonsContainer}>
              <Box mb={2}>
                <Button variant="contained" color="primary" onClick={approveInvoice} fullWidth>
                  Approve and pay
                </Button>
              </Box>
              <Box className={classes.actionsContainer}>
                <Button color="secondary" className={classes.rejectButton} onClick={onOpenRejectForm}>
                  Reject
                </Button>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

AdminInvoiceApproveForm.propTypes = {
  onOpenRejectForm: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
};

export default AdminInvoiceApproveForm;
