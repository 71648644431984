import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  invoiceContainer: {
    marginTop: theme.spacing(5),
  },
  invoiceResponseFormContainer: {
    marginTop: theme.spacing(5),
  },
}));
