import { FC } from "react";

import { Typography } from "@mui/material";

import Div from "components/common/Div";
import InvoiceDocumentIcon from "components/common/InvoiceDocumentIcon";

import * as InvoicePresenter from "domain/invoice/presenter";

import useBreakpoints from "hooks/useBreakpoints";

import styles from "./styles";
import { IInvoiceSubcontractorNameCardProps } from "./types";

const InvoiceSubcontractorNameCard: FC<IInvoiceSubcontractorNameCardProps> = (props) => {
  const { invoice } = props;
  const { screenWiderThan } = useBreakpoints();

  return (
    <Div sx={styles.root}>
      <Div sx={styles.icon}>
        <InvoiceDocumentIcon invoice={invoice} />
      </Div>
      <Div sx={styles.title}>
        <Typography sx={styles.titleText} noWrap={screenWiderThan.md}>
          {InvoicePresenter.subcontractorDocumentTitle(invoice)}
        </Typography>
      </Div>
    </Div>
  );
};

export default InvoiceSubcontractorNameCard;
