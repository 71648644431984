import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Icon from 'components/Icon';
import InvoicePresenter from 'presenters/InvoicePresenter';

import useStyles from './useStyles';

const PayableInvoicesOpenTableRows = props => {
  const { invoice, onRowClick } = props;
  const classes = useStyles();

  const handleRowClick = () => {
    onRowClick(invoice);
  };

  return (
    <TableRow
      key={InvoicePresenter.id(invoice)}
      hover
      className={clsx(classes.row, classes.hoverInvoice)}
      onClick={handleRowClick}
    >
      <TableCell align="left" className={classes.cell}>
        <div className={classes.invoiceNameContainer}>
          <Icon name={InvoicePresenter.invoiceIconName(invoice)} width={38} height={38} />
          <span className={classes.invoiceName}>{InvoicePresenter.builderInvoiceTitle(invoice)}</span>
        </div>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {InvoicePresenter.assignedProjects(invoice).map(project => (
          <div key={project.id}>{project.name}</div>
        ))}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {InvoicePresenter.name(invoice)}
      </TableCell>
      <TableCell align="left" className={clsx(classes.cell, classes[InvoicePresenter.state(invoice)])}>
        {InvoicePresenter.stateTableText(invoice)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {InvoicePresenter.formattedUSDTotalAmount(invoice)}
      </TableCell>
    </TableRow>
  );
};

PayableInvoicesOpenTableRows.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default PayableInvoicesOpenTableRows;
