import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import ProjectPresenter from 'presenters/ProjectPresenter';
import { useProjectActions } from 'store/ProjectSlice';
import Icon from 'components/Icon';
import LinearProgress from 'components/LinearProgress';
import useLoading from 'hooks/useLoading';
import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues } from 'forms/Builder/Project/ClientInviteForm';

import useStyles from './useStyles';

const SUCCESS_MESSAGE = 'The client was invited';

const ClientInviteForm = props => {
  const { project, onClose } = props;
  const classes = useStyles();

  const { createProjectCustomer, loadProject } = useProjectActions();
  const { func: sendUpdateProject, isPending } = useLoading(createProjectCustomer, {
    isShowSuccessNotification: true,
    successMessage: SUCCESS_MESSAGE,
    isAbortable: true,
  });
  const { errors, setErrors, handleChange, values, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ email }) => {
      try {
        await sendUpdateProject(project.id, { customer: { email } });
        onClose(() => loadProject(project.id));
      } catch (e) {
        setErrors(e);
      }
    },
  });

  return (
    <>
      <LinearProgress isVisible={isPending} />
      <div className={classes.soldDialogContainer}>
        <div className={classes.soldTitle}>
          <Icon name="invitation" width={25} height={25} className={classes.dialogTitleIcon} />
          <Typography variant="h3">Invite Customer</Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            name="email"
            label="Email address"
            value={values.email}
            error={Boolean(errors.email)}
            helperText={errors.email}
            onChange={handleChange}
            className={classes.emailField}
          />
          <div className={classes.soldButtonContainer}>
            <Button className={classes.cancelButton} variant="contained" onClick={() => onClose()} disabled={isPending}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.inviteButton}
              disabled={isPending}
            >
              Invite
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

ClientInviteForm.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClientInviteForm;
