import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: '28px 24px 32px',
    maxWidth: 529,
  },
  header: {
    display: 'flex',
    marginBottom: 4,
  },
  invitationIcon: {
    fill: theme.palette.colors.green,
    marginRight: 8,
  },
  heading: {
    color: theme.palette.colors.hunterGreen,
  },
  message: {
    color: `${theme.palette.colors.hunterGreen}99`,
    marginLeft: 36,
    marginBottom: 32,
  },
  buttonGroup: {
    textAlign: 'end',
  },
  deleteMemberButton: {
    marginRight: 12,
    color: `${theme.palette.colors.hunterGreen}B3`,
  },
}));
