import { isEmpty, pickBy } from "ramda";

import { InvoiceState } from "domain/invoice/enums";

import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { IMeta, IPaginationParameters } from "types/api";

import { BuilderCompanyInvoice } from "./types";

import type { PartialDeep } from "type-fest";

interface IBuilderCompanyInvoicesResponse {
  invoices: Array<BuilderCompanyInvoice>;
  meta: IMeta;
}

type GetBuilderCompanyInvoicesParameters = IPaginationParameters & {
  q: {
    stateIn: Array<InvoiceState>;
    s: string;
  };
};

const omitParameters = (
  parameters: PartialDeep<GetBuilderCompanyInvoicesParameters>,
): PartialDeep<GetBuilderCompanyInvoicesParameters> => {
  return { ...parameters, q: pickBy((value) => !isEmpty(value), parameters.q) };
};

const builderCompanyInvoiceApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getBuilderCompanyInvoices: builder.query<
      IBuilderCompanyInvoicesResponse,
      PartialDeep<GetBuilderCompanyInvoicesParameters>
    >({
      query: (parameters) => ({
        url: apiRoutes.siteApiV1BuilderCompanyInvoicesPath(),
        method: "GET",
        params: {
          ...omitParameters(parameters),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.invoices.map(({ id }) => ({ type: CacheTag.builderCompanyInvoice as const, id })),
              { type: CacheTag.builderCompanyInvoice, id: "LIST" },
            ]
          : [{ type: CacheTag.builderCompanyInvoice, id: "LIST" }],
    }),
    getAwaitingApprovalTotalCount: builder.query<number, void>({
      query: () => ({
        url: apiRoutes.awaitingApprovalTotalCountSiteApiV1BuilderCompanyInvoicesPath(),
        method: "GET",
      }),
      transformResponse: (response: { awaitingApprovalInvoicesCount: number }) =>
        response.awaitingApprovalInvoicesCount,
      providesTags: [CacheTag.builderCompanyInvoiceAwaitingApprovalTotalCount],
    }),
    markInvoiceAsPaid: builder.mutation<void, number>({
      query: (invoiceId) => ({
        url: apiRoutes.markAsPaidSiteApiV1BuilderCompanyInvoicePath(invoiceId),
        method: "PUT",
      }),
      invalidatesTags: [{ type: CacheTag.builderCompanyInvoice, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetBuilderCompanyInvoicesQuery,
  useLazyGetBuilderCompanyInvoicesQuery,
  useGetAwaitingApprovalTotalCountQuery,
  useMarkInvoiceAsPaidMutation,
} = builderCompanyInvoiceApi;
