import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Icon from 'components/Icon';
import LinearProgress from 'components/LinearProgress';
import useLoading from 'hooks/useLoading';
import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues, formPaths } from 'forms/Admin/CreateTransactionForm';
import AutocompletePick from 'components/AutocompletePick';
import MoneyTextField from 'components/MoneyTextField';
import { useAdminPaymentsActions } from 'store/AdminPaymentsSlice';
import CompanyPresenter from 'presenters/CompanyPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import UserPresenter from 'presenters/UserPresenter';

import useStyles from './useStyles';

const CreateTransactionForm = props => {
  const { onSubmit, onClose } = props;
  const classes = useStyles();

  const { loadCompanies, loadProjects, loadCustomers, loadCustomerInvoices } = useAdminPaymentsActions();
  const { func: sendLoadCompanies, isPending: isBuildersPending } = useLoading(loadCompanies, {
    isAbortable: true,
  });
  const { func: sendLoadProjects, isPending: isProjectsPending } = useLoading(loadProjects, {
    isAbortable: true,
  });
  const { func: sendLoadCustomerInvoices, isPending: isCustomerInvoicesPending } = useLoading(loadCustomerInvoices, {
    isAbortable: true,
  });
  const { func: sendLoadCustomers, isPending: isCustomersPending } = useLoading(loadCustomers, {
    isAbortable: true,
  });

  const { errors, setErrors, handleChange, values, handleSubmit, isSubmitting, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ amount, projectId, customerInvoiceId, companyId, customerId }) => {
      try {
        await onSubmit({ amount, projectId, customerInvoiceId, companyId, customerId });
        onClose();
      } catch (e) {
        setErrors(e);
      }
    },
  });

  const [companyOptions, setCompanyOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [customerInvoiceOptions, setCustomerInvoiceOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);

  const handleCompaniesLoad = async (name = '') => {
    const { companies } = await sendLoadCompanies(name);
    setCompanyOptions(companies);
  };

  const handleProjectsLoad = async (name = '') => {
    if (values.customerId) {
      const { projects } = await sendLoadProjects({ name, companyId: values.companyId, customerId: values.customerId });
      setProjectOptions(projects);
    }
  };

  const handleCustomerInvoicesLoad = async (name = '') => {
    const { customerInvoices } = await sendLoadCustomerInvoices({ name, projectId: values.projectId });
    setCustomerInvoiceOptions(customerInvoices);
  };

  const handleCustomersLoad = async (name = '') => {
    const { customers } = await sendLoadCustomers({ name, companyId: values.companyId });
    setCustomerOptions(customers);
  };

  useEffect(() => {
    handleCompaniesLoad();
    handleCustomerInvoicesLoad();
    handleCustomersLoad();
  }, []);

  useEffect(() => {
    setFieldValue(formPaths.projectName, '');
    setFieldValue(formPaths.projectId, '');

    handleProjectsLoad();
  }, [values.companyId, values.customerId]);

  const handleCompanyFieldValueSet = (_, option) => {
    setFieldValue(formPaths.companyName, option.name);
    setFieldValue(formPaths.companyId, option.id);
  };

  const handleProjectFieldValueSet = (_, option) => {
    setFieldValue(formPaths.projectName, option.name);
    setFieldValue(formPaths.projectId, option.id);
    handleCustomerInvoicesLoad();
  };

  const handleCustomerInvoiceFieldValueSet = (_, option) => {
    setFieldValue(formPaths.customerInvoiceName, option.name);
    setFieldValue(formPaths.customerInvoiceId, option.id);
  };

  const handleCustomerFieldValueSet = (_, option) => {
    setFieldValue(formPaths.customerName, option.fullName);
    setFieldValue(formPaths.customerId, option.id);
  };

  const isProjectDisabled = isSubmitting || !values.companyId || !values.customerId;
  const isCustomerInvoiceDisabled = isSubmitting || !values.projectId;
  const isCustomerDisable = isSubmitting || !values.companyId;

  return (
    <>
      <LinearProgress isVisible={isSubmitting} />
      <div className={classes.dialogContainer}>
        <div className={classes.title}>
          <Icon name="outlinedPlus" width={25} height={25} className={classes.dialogTitleIcon} />
          <Typography variant="h3">Create Transaction</Typography>
        </div>
        <form>
          <div className={classes.fieldsContainer}>
            <MoneyTextField
              name="amount"
              label="Amount"
              value={values.amount}
              error={Boolean(errors.amount)}
              helperText={errors.amount}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <div className={classes.fieldContainer}>
              <AutocompletePick
                label="Company"
                options={companyOptions}
                displayedValueSelectedOption={values[formPaths.companyName]}
                fieldName={formPaths.companyName}
                isLoading={isBuildersPending}
                error={Boolean(errors.companyId)}
                helperText={errors.companyId}
                disabled={isSubmitting}
                isOnFieldValueSetWrap={false}
                getOptionLabel={option => option.name}
                onLoad={handleCompaniesLoad}
                onFieldValueSet={handleCompanyFieldValueSet}
                classes={{ listbox: classes.optionsListBox }}
                renderOptions={(optionProps, option) => (
                  <li className={classes.option} {...optionProps}>
                    <Typography className={classes.companyName}>{CompanyPresenter.name(option)}</Typography>
                  </li>
                )}
              />
            </div>
            <div className={classes.fieldContainer}>
              <AutocompletePick
                label="Customer"
                options={customerOptions}
                displayedValueSelectedOption={values[formPaths.customerName]}
                fieldName={formPaths.customerName}
                isLoading={isCustomersPending}
                error={Boolean(errors.customerId)}
                helperText={errors.customerId}
                disabled={isCustomerDisable}
                isOnFieldValueSetWrap={false}
                getOptionLabel={option => option.fullName}
                onLoad={handleCustomersLoad}
                onFieldValueSet={handleCustomerFieldValueSet}
                classes={{ listbox: classes.optionsListBox }}
                acceptedSortingKeys={['fullName']}
                renderOptions={(optionProps, option) => (
                  <li className={classes.option} {...optionProps}>
                    <Typography className={classes.companyName}>{UserPresenter.fullName(option)}</Typography>
                  </li>
                )}
              />
            </div>
            <div className={classes.fieldContainer}>
              <AutocompletePick
                label="Project"
                options={projectOptions}
                displayedValueSelectedOption={values[formPaths.projectName]}
                fieldName={formPaths.projectName}
                isLoading={isProjectsPending}
                error={Boolean(errors.projectId)}
                helperText={errors.projectId}
                disabled={isProjectDisabled}
                isOnFieldValueSetWrap={false}
                getOptionLabel={option => option.name}
                onLoad={handleProjectsLoad}
                onFieldValueSet={handleProjectFieldValueSet}
                classes={{ listbox: classes.optionsListBox }}
                placeholder="Optional"
                renderOptions={(optionProps, option) => (
                  <li className={classes.option} {...optionProps}>
                    <Typography className={classes.companyName}>{ProjectPresenter.name(option)}</Typography>
                  </li>
                )}
              />
            </div>
            <div className={classes.fieldContainer}>
              <AutocompletePick
                label="Customer Invoice"
                options={customerInvoiceOptions}
                displayedValueSelectedOption={values[formPaths.customerInvoiceName]}
                fieldName={formPaths.customerInvoiceName}
                isLoading={isCustomerInvoicesPending}
                error={Boolean(errors.customerInvoiceId)}
                helperText={errors.customerInvoiceId}
                disabled={isCustomerInvoiceDisabled}
                isOnFieldValueSetWrap={false}
                getOptionLabel={option => option.name}
                onLoad={handleCustomerInvoicesLoad}
                onFieldValueSet={handleCustomerInvoiceFieldValueSet}
                classes={{ listbox: classes.optionsListBox }}
                placeholder="Optional"
                renderOptions={(optionProps, option) => (
                  <li className={classes.option} {...optionProps}>
                    <Typography className={classes.companyName}>{CustomerInvoicePresenter.name(option)}</Typography>
                  </li>
                )}
              />
            </div>
          </div>
          <div className={classes.soldButtonContainer}>
            <Button
              color="secondary"
              className={classes.cancelButton}
              variant="contained"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.inviteButton}
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

CreateTransactionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateTransactionForm;
