import { createRoot } from 'react-dom/client';
import railsUjs from '@rails/ujs';
import App from 'App';

railsUjs.start();

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('[data-react-class="App"]');
  const root = createRoot(container);
  root.render(<App />);
});
