import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useLoading from 'hooks/useLoading';
import InvoiceForm from 'components/InvoiceForm';
import AppRoutes from 'routes/AppRoutes';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';
import { useInvoiceActions, selectors } from 'store/PayableInvoiceSlice';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import { apiInvoiceToForm } from 'forms/Builder/InvoiceForm';

const PayableInvoiceUpdate = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { loadSubcontractors, updateInvoice, createLineItem, loadOpenedProjectLineItems } =
    useBuilderInvoiceCreateActions();

  const { loadInvoice, resetInvoice } = useInvoiceActions();
  const history = useHistory();
  const routeParams = useParams();
  const invoiceId = Number(routeParams.id);
  const { data: invoice } = useSelector(selectors.invoice);
  const { func: sendLoadInvoice } = useLoading(loadInvoice, { isShowLoading: true });

  useEffect(() => {
    sendLoadInvoice(invoiceId);
    return resetInvoice;
  }, [invoiceId]);

  const loadSubcontractorsByBuilderId = nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont => {
    return loadSubcontractors(currentUser.company.id, nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont);
  };

  const handleInvoiceConfirm = async invoiceToUpdate => {
    await updateInvoice({ id: invoiceId, params: invoiceToUpdate });
    history.push(AppRoutes.invoicesPath());
  };

  const initialValues = apiInvoiceToForm(invoice);

  return (
    <InvoiceForm
      variant="builder"
      title="Update invoice"
      initialValues={initialValues}
      onCompaniesLoad={loadSubcontractorsByBuilderId}
      onConfirm={handleInvoiceConfirm}
      onLineItemItemCreate={createLineItem}
      onLineItemLoad={loadOpenedProjectLineItems}
    />
  );
};

export default PayableInvoiceUpdate;
