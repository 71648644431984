import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiLinkType {
  defaultProps: ComponentsProps["MuiLink"];
  styleOverrides: ComponentsOverrides["MuiLink"];
}

const MuiLink: IMuiLinkType = {
  defaultProps: {
    underline: "none",
  },
  styleOverrides: {
    root: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "16px",
      "&:hover": {
        color: baseTheme.palette.colors.christyGreen,
      },
    },
  },
};

export default MuiLink;
