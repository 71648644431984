import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    marginTop: "32px",
  },
  title: {
    marginBottom: "12px",
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
    color: "colors.blackOlive",
    opacity: 0.5,
    fontWeight: 500,
  },
  content: {
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    marginBottom: "24px",
    flexDirection: {
      xs: "column",
      md: "row",
    },
  },
  contentHeader: {
    marginBottom: "8px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "-0.2px",
    color: "colors.blackOlive",
    opacity: 0.9,
  },
  text: {
    lineHeight: "20px",
    color: "rgba(32, 36, 20, 0.6)",
  },
  buttons: {
    display: "flex",
    flexDirection: {
      xs: "column",
      lg: "row",
    },
    gap: "16px",
    marginBottom: "8px",
  },
  button: {
    textTransform: "none",
    height: "48px",
    padding: "8px 24px",
    fontSize: 14,
    flexDirection: (theme) => ({
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    }),
  },
  buttonActive: {
    color: "primary.main",
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    outline: (theme) => `1px solid ${theme.palette.primary.main}`,
  },
  agreement: {
    padding: "24px",
    marginBottom: "24px",
  },
  confirmationContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  invoiceTotalContainer: {
    position: "relative",
    marginRight: "24px",
  },
  invoiceTotalLabel: {
    textTransform: "uppercase",
    fontFamily: "Poppins",
    color: "colors.deltaGray",
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  invoiceTotal: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "right",
  },
};

export default styles;
