import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';

import useStyles from './useStyles';

const ContentBox = props => {
  const { title, children, className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.blockContainer, className)}>
      <Typography className={classes.title} variant="overline" display="block">
        {title}
      </Typography>
      {children}
    </div>
  );
};

ContentBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentBox.defaultProps = {
  title: '',
  children: null,
  className: null,
};

export default memo(ContentBox);
