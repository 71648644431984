import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}) {
    const path = apiRoutes.siteApiV1CustomerProjectsPath();

    return FetchHelpers.get(path, params);
  },
};
