import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import { Router } from 'react-router-dom';
import { isNil } from 'ramda';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { SnackbarProvider } from 'notistack';

import store from 'store';
import theme from 'theme';

import { I18nextProvider } from 'react-i18next';
import locales from 'locales/i18next';

import * as GAService from 'libs/googleAnalytics';
import * as LogRocketService from 'libs/logRocket';

import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import history from 'utils/history';
import GuestUserSwitch from 'routeSwitches/GuestUserSwitch';
import PlainUserSwitch from 'routeSwitches/PlainUserSwitch';

import { setInitialRedirectUrl, hasInitialRedirectUrl } from 'libs/initialRedirect';

import { ANCHOR_ORIGIN } from 'constants/layout';

if (process.env.NODE_ENV === 'production') {
  GAService.initialize();
  LogRocketService.initialize();
}

const renderSwitch = currentUser => {
  if (isNil(currentUser)) {
    return <GuestUserSwitch />;
  }

  return <PlainUserSwitch currentUser={currentUser} />;
};

const App = () => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  useEffect(() => {
    if (isNil(currentUser) && !hasInitialRedirectUrl()) {
      setInitialRedirectUrl(window.location.pathname);
    }
  }, [currentUser]);

  return (
    <I18nextProvider i18n={locales}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>{!isLoading && renderSwitch(currentUser)}</Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nextProvider>
  );
};

const addStore = ProxyComponent =>
  function (props) {
    return (
      <Provider store={store}>
        <SnackbarProvider maxSnack={3} anchorOrigin={ANCHOR_ORIGIN}>
          <ProxyComponent {...props} />
        </SnackbarProvider>
      </Provider>
    );
  };

export default compose(addStore)(App);
