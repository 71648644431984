import PropTypes from 'prop-types';

import ProjectPresenter from 'presenters/ProjectPresenter';
import TotalInformationPresenter from 'presenters/TotalInformationPresenter';

import AdminProjectRow from '../AdminProjectRow';
import AdminProjectTotalRow from '../AdminProjectTotalRow';

const AdminProjectRows = props => {
  const { projects, totalInformation, approveProject, isCompleted } = props;
  return (
    <>
      {projects.map(project => (
        <AdminProjectRow
          key={ProjectPresenter.id(project)}
          project={project}
          iconName="activeProject"
          approveProject={approveProject}
          isCompleted={isCompleted}
        />
      ))}
      {totalInformation && <AdminProjectTotalRow totalInformation={totalInformation} />}
    </>
  );
};

AdminProjectRows.propTypes = {
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  totalInformation: TotalInformationPresenter.shape(),
  approveProject: PropTypes.func,
  isCompleted: PropTypes.bool,
};

AdminProjectRows.defaultProps = {
  approveProject: () => {},
  totalInformation: null,
  isCompleted: false,
};

export default AdminProjectRows;
