import { Route, Switch, Redirect } from 'react-router-dom';

import Invoices from 'components/pages/builder/Invoices';

import AppRoutes from 'routes/AppRoutes';
import UserLayout from 'layouts/UserLayout';
import UserPresenter from 'presenters/UserPresenter';
import Project from 'containers/Builder/Project';
import Projects from 'containers/Builder/Projects';
import Subcontractors from 'containers/Builder/Subcontractors';
import CompanySettings from 'containers/Builder/CompanySettings';
import TeamSettings from 'containers/Builder/TeamSettings';
import PayableInvoiceCreate from 'containers/Builder/PayableInvoiceCreate';
import PayableInvoice from 'containers/Builder/PayableInvoice';
import IncomingPayments from 'containers/Builder/IncomingPayments';
import PayableInvoiceUpdate from 'containers/Builder/PayableInvoiceUpdate';
import useBuilderUnreadCounts from 'hooks/useBuilderUnreadCounts';

const BuilderSwitch = props => {
  const { currentUser } = props;
  useBuilderUnreadCounts();

  return (
    <UserLayout currentUser={currentUser}>
      <Switch>
        <Route path={AppRoutes.projectsPath()} exact component={Projects} />
        <Route path={AppRoutes.projectPath(':id')} component={Project} />
        <Route path={AppRoutes.incomingPayments()} exact component={IncomingPayments} />
        <Route path={AppRoutes.subcontractorsPath()} exact component={Subcontractors} />
        <Route path={AppRoutes.settingsCompanyPath()} exact component={CompanySettings} />
        <Route path={AppRoutes.settingsTeamPath()} exact component={TeamSettings} />
        <Route path={AppRoutes.invoiceEditPath(':id')} exact component={PayableInvoiceUpdate} />
        <Route path={AppRoutes.invoicesPath()} exact component={Invoices} />
        <Route path={AppRoutes.invoicesCreatePath()} exact component={PayableInvoiceCreate} />
        <Route path={AppRoutes.invoicePath(':id')} exact component={PayableInvoice} />

        <Redirect to={AppRoutes.projectsPath()} />
      </Switch>
    </UserLayout>
  );
};

BuilderSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default BuilderSwitch;
