import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  contractValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 48,
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: -0.2,
  },
  value: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '30px',
  },
  formControlContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:first-child': {
      marginTop: 5,
    },
    '&:not(:first-child)': {
      marginTop: 48,
    },
  },
  percentMarkupContainer: {
    display: 'flex',
    width: 240,
    marginBottom: 25,
  },
  percentMarkupInput: {
    width: 64,
  },
  percentSign: {
    marginLeft: 12,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.colors.deltaGray,
    lineHeight: '40px',
  },
  label: {
    color: theme.palette.colors.black,
    textTransform: 'none',
    letterSpacing: -0.2,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '15px',
  },
  labelContainer: {
    maxWidth: 240,
  },
  inputRoot: {
    width: 240,
    marginTop: -2,
  },
  inputHelperText: {
    top: 40,
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tip: {
    marginTop: 10,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  projectProfitContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    paddingTop: 8,
    boxShadow: 'inset 0px 1px 0px rgba(0, 48, 32, 0.1)',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  nextButton: {
    width: 140,
    marginLeft: 12,
  },
}));
