import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  tip: {
    marginTop: 6,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  formContainer: {
    marginTop: 32,
  },
  buttonsContainer: {
    display: 'flex',
  },
  buttonChoice: {
    fontFamily: 'Lato',
    fontWeight: 400,
    textTransform: 'none',
    fontSize: 14,
    lineHeight: '16px',
    padding: '14px 22px',
    borderWidth: 2,
    '&:not(:first-child)': {
      marginLeft: 16,
    },
  },
  buttonChoiceSelected: {
    padding: '14px 22px',
    border: `2px solid ${theme.palette.colors.green} !important`,
    color: `${theme.palette.colors.green} !important`,
  },
  radioContainer: {
    marginTop: 14,
    marginLeft: 5,
  },
  hidden: {
    visibility: 'hidden',
  },
  label: {
    fontSize: 14,
  },
  radio: {
    '&:not(:first-child)': {
      marginTop: -2,
    },
  },
  totalPayment: {
    marginTop: 12,
    marginLeft: 4,
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 72,
  },
  nextButton: {
    marginLeft: 12,
    width: 140,
  },
}));
