import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import MoneyTextField from 'components/MoneyTextField';
import Icon from 'components/Icon';
import { initialValues, validationSchema } from 'forms/Builder/NewProjectForm';
import ProjectPresenter, { PROJECT_PRICINGS } from 'presenters/ProjectPresenter';
import useFormik from 'hooks/useFormik';

import useStyles from './useStyles';

const NewProjectForm = props => {
  const { onFormSubmit, onFormHide, project } = props;
  const classes = useStyles();
  const { touched, errors, handleChange, values, setValues, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: newProject => {
      onFormSubmit(newProject);
    },
  });

  useEffect(() => {
    if (!isEmpty(project)) {
      setValues(project);
    }
  }, []);

  const handleSoldValueChange = event => {
    if (!event.target.checked) {
      setValues({
        ...values,
        sold: event.target.checked,
        deposit: false,
        pricing: PROJECT_PRICINGS.fixedPrice,
      });
      return;
    }

    setValues({ ...values, sold: event.target.checked, pricing: initialValues.pricing, deposit: true });
  };

  const handleDepositValueChange = event => {
    if (!event.target.checked) {
      setValues({
        ...values,
        deposit: event.target.checked,
        sold: false,
        pricing: PROJECT_PRICINGS.fixedPrice,
      });
      return;
    }

    setValues({ ...values, deposit: event.target.checked, sold: true, pricing: initialValues.pricing });
  };

  const handlePricingValueChange = event => {
    if (event.target.value === PROJECT_PRICINGS.costPlus) {
      setValues({
        ...values,
        deposit: false,
        pricing: event.target.value,
        contractValue: initialValues.contractValue,
      });
      return;
    }

    setValues({
      ...values,
      deposit: true,
      pricing: event.target.value,
    });
  };

  const contractValueLabel = values.sold ? 'Total Contract Value' : 'Projected Contract Value';
  const createButtonLabel = values.sold ? 'Create Project' : 'Create Draft Project';

  const renderPricing = () => {
    if (values.sold) {
      return (
        <div className={classes.selectContainer}>
          <InputLabel shrink htmlFor="pricing">
            Pricing
          </InputLabel>
          <Select name="pricing" id="pricing" value={values.pricing} onChange={handlePricingValueChange}>
            <MenuItem value={PROJECT_PRICINGS.fixedPrice}>Fixed Price</MenuItem>
            <MenuItem value={PROJECT_PRICINGS.costPlus}>Cost Plus</MenuItem>
          </Select>
        </div>
      );
    }

    return null;
  };
  const renderDeposit = () => {
    if (values.sold) {
      return (
        <div className={classes.checkboxContainer}>
          <FormControlLabel
            classes={{ label: classes.checkboxLabel }}
            control={
              <Checkbox name="deposit" color="primary" onChange={handleDepositValueChange} checked={values.deposit} />
            }
            label="I will collect a deposit."
          />
          <Tooltip
            title="Funl requires deposits for all Active projects. If you are not planning to collect a deposit the project will be moved to Draft status until a deposit is collected."
            classes={{ popper: classes.popover }}
          >
            <div>
              <Icon name="help" width={16} height={16} className={classes.helpIcon} />
            </div>
          </Tooltip>
        </div>
      );
    }

    return null;
  };
  const renderContractValue = () => {
    if (ProjectPresenter.isCostPlus(values)) {
      return null;
    }

    return (
      <>
        <MoneyTextField
          classes={{ root: classes.inputRoot }}
          name="contractValue"
          id="contractValue"
          label={contractValueLabel}
          value={values.contractValue}
          onChange={handleChange}
          error={touched.contractValue && Boolean(errors.contractValue)}
          helperText={touched.contractValue && errors.contractValue}
        />
        {renderDeposit()}
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        classes={{ root: classes.inputRoot }}
        name="name"
        id="name"
        label="Project Name"
        value={values.name}
        onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />
      <div className={classes.checkboxContainer}>
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={<Checkbox name="sold" color="primary" onChange={handleSoldValueChange} checked={values.sold} />}
          label="The job has been sold."
        />
        <Tooltip
          title="If the job has not been sold please feel free to use Funl for pipeline planning purposes. Funl will only move a project to Active, and subsequently provide funds for the project, once the project has been sold."
          classes={{ popper: classes.popover }}
        >
          <div>
            <Icon name="help" width={16} height={16} className={classes.helpIcon} />
          </div>
        </Tooltip>
      </div>
      {renderPricing()}
      {renderContractValue()}
      <div className={classes.actions}>
        <Button variant="contained" onClick={onFormHide}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" classes={{ root: classes.createButton }} type="submit">
          {createButtonLabel}
        </Button>
      </div>
    </form>
  );
};

NewProjectForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onFormHide: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
};

export default NewProjectForm;
