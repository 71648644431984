import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  tableContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
    height: 'inherit',
    paddingRight: theme.spacing(2),
    overflow: 'auto',
  },
  table: {
    overflowY: 'scroll',
    marginRight: theme.spacing(3),
  },
  tableEmpty: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.colors.gunsmokeGray,
    border: 'none',
  },
  codeHead: {
    width: 94,
  },
  statusHead: {
    width: 150,
  },
  stateHead: {
    width: 88,
  },
  binHead: {
    width: 40,
  },
  categoryHead: {
    width: 210,
  },
}));
