import PropTypes from 'prop-types';

import dictionary from './dictionary';

const Icon = props => {
  const { name, width, height, ...rest } = props;
  const { viewBox, width: dictionaryWidth, height: dictionaryHeight, id } = dictionary[name];
  const widthValue = width || dictionaryWidth;
  const heightValue = height || dictionaryHeight;

  return (
    <svg viewBox={viewBox} width={widthValue} height={heightValue} {...rest}>
      <use xlinkHref={id} />
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
Icon.defaultProps = {
  width: 28,
  height: 28,
  fill: 'none',
};

export default Icon;
