import * as yup from 'yup';

import { PASSWORD, minLength } from './errorsText';

const PASSWORD_MIN_LENGTH = 8;

const validationFields = {
  password: yup.string().min(PASSWORD_MIN_LENGTH, minLength(PASSWORD_MIN_LENGTH)).required(PASSWORD),
};

export const initialValues = {
  password: '',
};

export const validationSchema = yup.object().shape(validationFields);
