import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: theme.typography.pxToRem(14),
    padding: '11px 0',
  },
  rejected: {
    color: theme.palette.colors.red,
  },
  invoiceNameContainer: {
    display: 'flex',
  },
  invoiceName: {
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(38),
    marginLeft: theme.spacing(1),
  },
  invoiceCell: {
    paddingRight: theme.spacing(8),
    color: theme.palette.colors.green,
    fontWeight: 700,
  },
  paidIcon: {
    fill: theme.palette.colors.green,
    marginRight: theme.spacing(1),
  },
  state: {
    textTransform: 'capitalize',
  },
}));
