import { useEffect, useState } from "react";

import { InvoiceTabsStatus, InvoiceTabsStatusCount } from "components/common/InvoiceTabs/types";

import { InvoiceState } from "domain/invoice/enums";

import { useGetBuilderCompanyInvoicesQuery } from "store/api/builder/company/invoice/apiSlice";

const useBuilderInvoicesCountByStatus = () => {
  const [statusCount, setStatusCount] = useState<InvoiceTabsStatusCount>({
    open: null,
    approved: null,
    closed: null,
  });

  const { data: openData } = useGetBuilderCompanyInvoicesQuery({
    q: { stateIn: [InvoiceState.pending, InvoiceState.awaitingApproval] },
  });
  const { data: approvedData } = useGetBuilderCompanyInvoicesQuery({ q: { stateIn: [InvoiceState.approved] } });
  const { data: closedData } = useGetBuilderCompanyInvoicesQuery({
    q: { stateIn: [InvoiceState.canceled, InvoiceState.paid, InvoiceState.rejected] },
  });

  const changeStatusCount = (type: InvoiceTabsStatus, count: number | null) => {
    setStatusCount((previousCount) => {
      return { ...previousCount, [type]: count };
    });
  };

  useEffect(() => {
    const countValue = openData?.meta?.totalCount ?? null;
    changeStatusCount("open", countValue);
  }, [openData?.meta?.totalCount]);

  useEffect(() => {
    const countValue = approvedData?.meta?.totalCount ?? null;
    changeStatusCount("approved", countValue);
  }, [approvedData?.meta?.totalCount]);

  useEffect(() => {
    const countValue = closedData?.meta?.totalCount ?? null;
    changeStatusCount("closed", countValue);
  }, [closedData?.meta?.totalCount]);

  return {
    statusCount,
    changeStatusCount,
  };
};

export default useBuilderInvoicesCountByStatus;
