import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { COMPANY_ROLES } from 'presenters/UserPresenter';

const MemberRoleSelect = props => {
  const renderMenuItem = role => {
    switch (role) {
      case COMPANY_ROLES.admin:
        return (
          <MenuItem key={role} value={role}>
            Admin
          </MenuItem>
        );
      case COMPANY_ROLES.manager:
        return (
          <MenuItem key={role} value={role}>
            Project Manager
          </MenuItem>
        );
      default:
        return null;
    }
  };

  return (
    <Select variant="filled" {...props}>
      {Object.values(COMPANY_ROLES).map(renderMenuItem)}
    </Select>
  );
};
export default MemberRoleSelect;
