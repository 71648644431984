import { FC } from "react";

import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";

import useBreakpoints from "hooks/useBreakpoints";

import Counter from "./Counter";
import styles from "./styles";
import { IInvoiceLineItemsNamesProps, InvoiceTabsStatus } from "./types";

const InvoiceTabs: FC<IInvoiceLineItemsNamesProps> = (props) => {
  const { onChange, statusCount } = props;
  const { t } = useTranslation("components", { keyPrefix: "invoiceTabs" });
  const { screenWiderThan } = useBreakpoints();

  const handleChange = (event: React.SyntheticEvent, newValue: InvoiceTabsStatus) => {
    onChange(newValue);
  };

  return (
    <Div sx={styles.tabList}>
      <TabList
        onChange={handleChange}
        aria-label="invoices-tabs"
        variant={screenWiderThan.md ? "standard" : "fullWidth"}
      >
        <Tab label={<Counter label={t("open")} count={statusCount.open} type="open" />} value="open" sx={styles.tab} />
        <Tab
          label={<Counter label={t("approved")} count={statusCount.approved} type="approved" />}
          value="approved"
          sx={styles.tab}
        />
        <Tab
          label={<Counter label={t("closed")} count={statusCount.closed} type="closed" />}
          value="closed"
          sx={styles.tab}
        />
      </TabList>
    </Div>
  );
};

export default InvoiceTabs;
