import { useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import muiSx from "mui-sx";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import Icon from "components/Icon";

import useOnboardingRoleRedirection from "hooks/useOnboardingRoleRedirection";

import OnboardingLayout from "layouts/OnboardingLayout";
import { OnboardingLayoutVariant } from "layouts/OnboardingLayout/types";

import { useDeleteSessionMutation } from "store/api/session/apiSlice";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import styles from "./styles";
import { OnboardingRole } from "./types";

const Landing = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [signOut] = useDeleteSessionMutation();
  const { t } = useTranslation("onboardingLanding");

  const history = useHistory();
  useOnboardingRoleRedirection();

  const [selectedOption, setSelectedOption] = useState<OnboardingRole | null>(null);

  const handleBuilderSelect = () => {
    setSelectedOption(OnboardingRole.builder);
  };

  const handleSubcontractorSelect = () => {
    setSelectedOption(OnboardingRole.subcontractor);
  };

  const handleRoleChoice = () => {
    switch (selectedOption) {
      case OnboardingRole.builder: {
        history.push(AppRoutes.onboardingBuilderPath());
        break;
      }
      case OnboardingRole.subcontractor: {
        history.push(AppRoutes.onboardingSubcontractorPath());
        break;
      }
      default: {
        break;
      }
    }
  };

  const isBuilder = selectedOption === OnboardingRole.builder;
  const isSubcontractor = selectedOption === OnboardingRole.subcontractor;

  if (isNil(currentUser)) {
    return null;
  }

  return (
    <OnboardingLayout onSignOut={signOut} currentUser={currentUser} variant={OnboardingLayoutVariant.roleChoice}>
      <Div sx={styles.root}>
        <Div sx={styles.main}>
          <Typography variant="h4">{t("chooseYourRole")}</Typography>
          <Div sx={styles.tip}>{t("howDoYouWantToUseFunl")}</Div>
          <Div sx={styles.chooseContainer}>
            <Button
              variant="outlined"
              disabled={isBuilder}
              onClick={handleBuilderSelect}
              sx={muiSx(styles.buttonChoice, { sx: styles.buttonChoiceSelected, condition: isBuilder })}
            >
              <Div sx={styles.buttonContent}>
                <Icon name="briefcase" width={28} height={28} />
                {t("iAmAGeneralContractor")}
              </Div>
            </Button>
            <Button
              variant="outlined"
              disabled={isSubcontractor}
              onClick={handleSubcontractorSelect}
              sx={muiSx(styles.buttonChoice, { sx: styles.buttonChoiceSelected, condition: isSubcontractor })}
            >
              <Div sx={styles.buttonContent}>
                <Icon name="tools" width={28} height={28} />
                {t("iAmASubcontractor")}
              </Div>
            </Button>
          </Div>
        </Div>
        <Div sx={styles.actions}>
          <Button
            sx={styles.actionButton}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isNil(selectedOption)}
            onClick={handleRoleChoice}
          >
            {t("continue")}
          </Button>
        </Div>
      </Div>
    </OnboardingLayout>
  );
};

export default Landing;
