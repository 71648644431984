import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';
import { format } from 'utils/dateUtils';
import ProjectPresenter from 'presenters/ProjectPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

export const CUSTOMER_INVOICE_STATES = {
  pending: 'pending',
  paid: 'paid',
  approved: 'approved',
};

const CUSTOMER_INVOICE_STATUS = {
  [CUSTOMER_INVOICE_STATES.pending]: 'Sent',
  [CUSTOMER_INVOICE_STATES.paid]: 'Paid',
  [CUSTOMER_INVOICE_STATES.approved]: 'Awaiting payment',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    amount: PropTypes.string,
    state: PropTypes.string,
    company: CompanyPresenter.shape(),
    project: ProjectPresenter.shape(),
  },
  {
    formattedUSDAmount(customerInvoice, maximumFractionDigits = 2) {
      return formatUSD(this.amount(customerInvoice), maximumFractionDigits);
    },
    formattedCreatedAt(customerInvoice) {
      return format(this.createdAt(customerInvoice));
    },
    status(customerInvoice) {
      return CUSTOMER_INVOICE_STATUS[this.state(customerInvoice)];
    },
    projectName(customerInvoice) {
      return ProjectPresenter.name(this.project(customerInvoice));
    },
    isAwaitingPayment(customerInvoice) {
      return this.state(customerInvoice) === CUSTOMER_INVOICE_STATES.approved;
    },
    companyName(customerInvoice) {
      return CompanyPresenter.name(this.company(customerInvoice));
    },
    isPaid(customerInvoice) {
      return this.state(customerInvoice) === CUSTOMER_INVOICE_STATES.paid;
    },
  },
);
