import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";
import InvoiceDocumentIcon from "components/common/InvoiceDocumentIcon";

import * as InvoicePresenter from "domain/invoice/presenter";

import styles from "./styles";
import { IInvoiceBuilderNameCardProps } from "./types";

const InvoiceBuilderNameCard: FC<IInvoiceBuilderNameCardProps> = (props) => {
  const { invoice } = props;
  const { t } = useTranslation("common");

  return (
    <Div sx={styles.root}>
      <Div sx={styles.icon}>
        <InvoiceDocumentIcon invoice={invoice} />
      </Div>
      <Div sx={styles.title}>
        <Typography sx={styles.titleText} noWrap>
          {InvoicePresenter.builderDocumentTitle(invoice, t)}
        </Typography>
      </Div>
    </Div>
  );
};

export default InvoiceBuilderNameCard;
