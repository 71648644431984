import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const STATES = {
  pending: 'pending',
  accepted: 'accepted',
};

export default new Presenter(
  {
    id: PropTypes.number,
    referralEmail: PropTypes.string,
    state: PropTypes.oneOf(Object.values(STATES)),
  },
  {},
);
