import { Route, Switch, Redirect } from 'react-router-dom';

import Invoices from 'components/pages/builder/Invoices';

import AppRoutes from 'routes/AppRoutes';
import UserLayout from 'layouts/UserLayout';
import UserPresenter from 'presenters/UserPresenter';
import Project from 'containers/Builder/Project';
import Projects from 'containers/Builder/Projects';
import PayableInvoiceCreate from 'containers/Builder/PayableInvoiceCreate';
import PayableInvoice from 'containers/Builder/PayableInvoice';
import PayableInvoiceUpdate from 'containers/Builder/PayableInvoiceUpdate';

const BuilderManagerSwitch = props => {
  const { currentUser } = props;

  return (
    <UserLayout currentUser={currentUser}>
      <Switch>
        <Route path={AppRoutes.projectsPath()} exact component={Projects} />
        <Route path={AppRoutes.projectPath(':id')} component={Project} />
        <Route path={AppRoutes.invoiceEditPath(':id')} exact component={PayableInvoiceUpdate} />
        <Route path={AppRoutes.invoicesPath()} exact component={Invoices} />
        <Route path={AppRoutes.invoicesCreatePath()} exact component={PayableInvoiceCreate} />
        <Route path={AppRoutes.invoicePath(':id')} exact component={PayableInvoice} />

        <Redirect to={AppRoutes.projectsPath()} />
      </Switch>
    </UserLayout>
  );
};

BuilderManagerSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default BuilderManagerSwitch;
