import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import InfiniteScroll from 'components/InfiniteScroll';
import Icon from 'components/Icon';
import SelectAllButton from 'components/SelectAllButton';
import TableRowsWithLoading from 'components/TableRowsWithLoading';
import LineItemPresenter from 'presenters/LineItemPresenter';

import TemplateLineItemsRows from './components/TemplateLineItemsRows';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 2;

const TemplateLineItems = props => {
  const { lineItems, isLoading, onLineItemsCreate, onHide, onLineItemsLoad, onUnmount, meta } = props;
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    onLineItemsLoad();

    return onUnmount;
  }, []);

  const handleLineItemSelectToggle = lineItem => () => {
    setSelectedLineItems(items => {
      const isItemChecked = items.find(item => item.id === lineItem.id);
      if (isItemChecked) {
        return items.filter(item => item.id !== lineItem.id);
      }
      return [...items, lineItem];
    });
  };

  const handleSelectAll = () => {
    setSelectedLineItems(lineItems);
  };

  const handleDeselectAll = () => {
    setSelectedLineItems([]);
  };

  const handleLineItemsCreate = () => {
    onLineItemsCreate(selectedLineItems);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHeader}>
        <div className={classes.titleContainer}>
          <Icon name="gear" width={24} height={24} className={classes.gearIcon} />
          <Typography variant="h3">Add Line Items</Typography>
        </div>
        <IconButton className={classes.closeButton} onClick={onHide} size="large">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.selectAllContainer}>
        {!isEmpty(lineItems) && (
          <SelectAllButton
            isEveryItemSelected={lineItems.length === selectedLineItems.length}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleDeselectAll}
          />
        )}
      </div>
      <InfiniteScroll
        className={classes.tableContainer}
        nextPage={meta.nextPage}
        isLoading={isLoading}
        onLoad={onLineItemsLoad}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRowsWithLoading
                isLoading={isLoading}
                maxColsCount={MAX_COLS_COUNT}
                isData={!isEmpty(lineItems)}
                emptyText="No available template line items."
                rows={
                  <TemplateLineItemsRows
                    items={lineItems}
                    selectedLineItems={selectedLineItems}
                    onLineItemSelectToggle={handleLineItemSelectToggle}
                  />
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
      <div className={classes.toolbar}>
        <div className={classes.selectedCount}>{selectedLineItems.length} items selected</div>
        <div className={classes.actions}>
          <Button variant="contained" color="secondary" className={classes.cancelButton} onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmpty(selectedLineItems)}
            className={classes.addButton}
            onClick={handleLineItemsCreate}
          >
            Add Selected Items
          </Button>
        </div>
      </div>
    </Paper>
  );
};

TemplateLineItems.propTypes = {
  lineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLineItemsCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onLineItemsLoad: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  meta: PropTypes.shape().isRequired,
};

export default TemplateLineItems;
