import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: theme.palette.colors.white,
    borderRadius: 3,
    border: `2px dashed ${theme.palette.colors.nurseGray}`,
    padding: '10px',

    [theme.breakpoints.up('sm')]: {
      padding: '10px 60px',
    },
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  icon: {
    fill: theme.palette.colors.green,
  },
  label: {
    color: theme.palette.colors.green,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  descriptionText: {
    color: theme.palette.colors.gunsmokeGray,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: 0.2,
    marginTop: theme.spacing(1),
    textAlign: 'right',
  },
  dialogContainer: {
    padding: theme.spacing(4),
    minWidth: 400,
  },
  dialogTitleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.colors.red,
  },
  dialogContent: {
    padding: '0 0 24px 0',
  },
}));
