import { SxStyles } from "theme";

const styles: SxStyles = {
  paper: {
    padding: {
      xs: 0,
      md: "20px 24px 0",
    },
  },
  tabList: {
    boxShadow: (theme) => `inset 0px -1px 0px ${theme.palette.colors.blackHaze}`,
  },
  tabPanel: {
    padding: {
      xs: "16px",
    },
  },
};

export default styles;
