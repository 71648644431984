import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { useIncomingPayments, selectors } from 'store/IncomingPaymentsSlice';
import useLoading from 'hooks/useLoading';
import IncomingPaymentPresenter from 'presenters/IncomingPaymentPresenter';

import IncomingPaymentsTable from '../IncomingPaymentsTable';

import HistoricalIncomingPaymentsTableRows from './components/HistoricalIncomingPaymentsTableRows';

const UnassignedIncomingPaymentsTable = () => {
  const { loadHistoricalIncomingPayments, resetHistoricalIncomingPayments } = useIncomingPayments();
  const { data, meta } = useSelector(selectors.historical);
  const { func: sendLoadHistoricalIncomingPayments, isPending } = useLoading(loadHistoricalIncomingPayments);

  useEffect(() => {
    sendLoadHistoricalIncomingPayments();
    return resetHistoricalIncomingPayments;
  }, []);

  return (
    <IncomingPaymentsTable
      isLoading={isPending}
      rows={data.map(payment => (
        <HistoricalIncomingPaymentsTableRows key={IncomingPaymentPresenter.id(payment)} payment={payment} />
      ))}
      isData={!isEmpty(data)}
      maxColsCount={5}
      nextPage={meta.nextPage}
      loadMore={loadHistoricalIncomingPayments}
    />
  );
};

export default memo(UnassignedIncomingPaymentsTable);
