import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(projectId, params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectLineItemsPath(projectId);

    return FetchHelpers.get(path, params);
  },
};
