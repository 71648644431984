import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  listItem: {
    padding: 0,
  },
  listItemLabel: {},
  listItemLabelIndented: {
    marginLeft: 11,
  },
  iconWrapper: {
    color: theme.palette.colors.heavyMetalGray,
  },
  selectedItem: {
    '& > $expandedLink > $iconWrapper, & > $expandedButton > $iconWrapper, & > $expandedLink > $listItemLabel, & > $expandedButton > $listItemLabel':
      {
        color: theme.palette.colors.green,
      },
  },
  expandedLink: {
    padding: '14px 16px',
    display: 'flex',
    fontWeight: 600,
    color: theme.palette.colors.heavyMetalGray,
    width: '100%',
    height: '100%',
    '&:hover > $iconWrapper, &:hover > $listItemLabel': {
      color: theme.palette.colors.green,
    },
  },
  expandedButton: {
    padding: '14px 16px',
    display: 'flex',
    fontWeight: 600,
    color: theme.palette.colors.heavyMetalGray,
    width: '100%',
    height: '100%',
    '&:hover > $iconWrapper, &:hover > $listItemLabel': {
      color: theme.palette.colors.green,
    },
    fontFamily: 'Lato',
    fontSize: 14,
    lineHeight: '16px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    outline: 'none',
    textAlign: 'left',
    wordBreak: 'break-all',
  },
}));
