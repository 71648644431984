import { useState } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import UserPresenter from 'presenters/UserPresenter';
import MemberRoleSelect from 'components/MemberRoleSelect';
import DeleteButton from 'components/DeleteButton';
import useLoading from 'hooks/useLoading';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import MemberName from './components/MemberName';
import MemberStatus from './components/MemberStatus';
import DeleteMemberDialog from './components/DeleteMemberDialog';
import useStyles from './useStyles';

const Member = props => {
  const { member, onMemberUpdate, onMemberDestroy, isRoleManagementEnabled, shouldRenderStatus } = props;
  const classes = useStyles();
  const { func: sendOnMemberUpdate, isPending: isMemberUpdating } = useLoading(onMemberUpdate);
  const { func: sendOnMemberDestroy, isPending: isMemberDestroying } = useLoading(onMemberDestroy);
  const isLoading = isMemberUpdating || isMemberDestroying;
  const [isDeleteMemberDialogVisible, setIsDeleteMemberDialogVisible] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();
  const memberId = UserPresenter.id(member);
  const memberIsCurrentUser = equals(UserPresenter.id(currentUser), memberId);

  const handleRoleUpdate = event => sendOnMemberUpdate(memberId, event.target.value);

  const handleDeleteMember = () => sendOnMemberDestroy(member);

  const handleDeleteMemberDialogOpen = () => setIsDeleteMemberDialogVisible(true);

  const handleDeleteMemberDialogClose = () => setIsDeleteMemberDialogVisible(false);

  return (
    <>
      <DeleteMemberDialog
        isVisible={isDeleteMemberDialogVisible}
        onClose={handleDeleteMemberDialogClose}
        onMemberDelete={handleDeleteMember}
      />
      <TableRow className={classes.row} key={memberId}>
        <TableCell className={classes.cell}>
          <MemberName member={member} />
        </TableCell>
        <TableCell className={classes.cell}>
          <Link
            title={UserPresenter.email(member)}
            href={`mailto:${UserPresenter.email(member)}`}
            underline="hover"
            color="primary"
          >
            {UserPresenter.email(member)}
          </Link>
        </TableCell>
        <TableCell className={classes.cell}>
          {isRoleManagementEnabled ? (
            <div className={classes.selectContainer}>
              <MemberRoleSelect
                value={UserPresenter.companyRole(member)}
                onChange={handleRoleUpdate}
                disabled={isMemberUpdating || memberIsCurrentUser}
              />
            </div>
          ) : (
            <div className={classes.role}>{UserPresenter.companyRoleLabel(member)}</div>
          )}
        </TableCell>
        {shouldRenderStatus && (
          <TableCell className={classes.cell}>
            <MemberStatus member={member} />
          </TableCell>
        )}
        <TableCell className={classes.cell}>
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            !memberIsCurrentUser && (
              <div className={classes.binContainer}>
                <DeleteButton className={classes.binIconButton} onClick={handleDeleteMemberDialogOpen} />
              </div>
            )
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

Member.propTypes = {
  member: UserPresenter.shape().isRequired,
  onMemberUpdate: PropTypes.func.isRequired,
  onMemberDestroy: PropTypes.func.isRequired,
  isRoleManagementEnabled: PropTypes.bool,
  shouldRenderStatus: PropTypes.bool,
};

Member.defaultProps = {
  isRoleManagementEnabled: true,
  shouldRenderStatus: true,
};

export default Member;
