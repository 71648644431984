import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: theme.typography.pxToRem(14),
    padding: '11px 0',
    wordBreak: 'break-all',
  },
  title: {
    fontWeight: 700,
  },
}));
