import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import AdminProjectsRepository from 'repositories/admin/ProjectsRepository';
import { PROJECT_STATES } from 'presenters/ProjectPresenter';

const MAX_PER_PAGE = 5;

const initialState = {
  active: {
    data: [],
    nextPage: null,
    totalCount: 0,
    totalInformation: null,
  },
  completed: {
    data: [],
    nextPage: null,
    totalCount: 0,
    totalInformation: null,
  },
};

const projectsSlice = createSlice({
  name: 'adminProjects',
  initialState,
  reducers: {
    loadActiveProjectsSuccess(state, { payload }) {
      state.active.data = concat(state.active.data, payload.projects);
      state.active.nextPage = payload.meta.nextPage;
      state.active.totalCount = payload.meta.totalCount;
      state.active.totalInformation = payload.meta.totalInformation;
    },
    loadCompletedProjectsSuccess(state, { payload }) {
      state.completed.data = concat(state.completed.data, payload.projects);
      state.completed.nextPage = payload.meta.nextPage;
      state.completed.totalCount = payload.meta.totalCount;
      state.completed.totalInformation = payload.meta.totalInformation;
    },
    approveProject(state, { payload }) {
      const approvedProjectIndex = state.active.data.findIndex(project => project.id === payload.project.id);
      if (approvedProjectIndex > -1) {
        state.active.data[approvedProjectIndex] = payload.project;
      }
    },
    resetProjects() {
      return initialState;
    },
  },
});

export const { actions } = projectsSlice;
export default projectsSlice.reducer;

export const useAdminProjectsActions = () => {
  const dispatch = useDispatch();

  const loadActiveProjects = async (page = 1) => {
    const { data } = await AdminProjectsRepository.index({
      q: { stateEq: PROJECT_STATES.active, s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadActiveProjectsSuccess(data));

    return data;
  };

  const loadCompletedProjects = async (page = 1) => {
    const { data } = await AdminProjectsRepository.index({
      q: { stateEq: PROJECT_STATES.completed, s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadCompletedProjectsSuccess(data));

    return data;
  };

  const approveProject = async id => {
    const { data } = await AdminProjectsRepository.approve(id);
    dispatch(actions.approveProject(data));
  };

  const resetProjects = () => {
    dispatch(actions.resetProjects());
  };

  return {
    loadActiveProjects,
    loadCompletedProjects,
    approveProject,
    resetProjects,
  };
};

export const selectors = {
  active: state => state.AdminProjectsSlice.active,
  completed: state => state.AdminProjectsSlice.completed,
};
