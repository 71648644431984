import { PROJECT_PRICINGS } from 'presenters/ProjectPresenter';

export const getProjectProfit = ({ pricing, contractValue, thirdPartyExpenses, overheadExpenses, percentMarkup }) => {
  const expenses = Number(thirdPartyExpenses) + Number(overheadExpenses);

  if (pricing === PROJECT_PRICINGS.costPlus) {
    return expenses * (1 + Number(percentMarkup) / 100) - expenses;
  }

  return contractValue - expenses;
};
