import { SxStyles } from "theme";

const styles: SxStyles = {
  rowHighlighted: {
    backgroundColor: "colors.bridalHealth",
  },
  status: {
    whiteSpace: {
      xs: "break-spaces",
      md: "nowrap",
    },
    display: {
      xs: "inline",
      md: "block",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionRequired: {
    fontWeight: 600,
    color: "colors.red",
  },
};

export default styles;
