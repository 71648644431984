import { useFormik as useNativeFormik } from 'formik';

const useFormik = ({ clearErrorsOnInput, onApiErrorsSet, ...params }) => {
  const formik = useNativeFormik(params);

  const handleChange = event => {
    if (onApiErrorsSet) {
      onApiErrorsSet({});
    }
    formik.setErrors({});
    formik.handleChange(event);
  };

  const setFieldValue = (path = '', value, shouldValidate) => {
    if (onApiErrorsSet) {
      onApiErrorsSet({});
    }
    formik.setErrors({});
    formik.setFieldValue(path, value, shouldValidate);
  };

  return { ...formik, ...(clearErrorsOnInput && { handleChange, setFieldValue }) };
};

export default useFormik;
