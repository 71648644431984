import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import InvoicesRepository from 'repositories/admin/InvoicesRepository';

const initialState = {
  invoice: {
    data: {},
  },
};

const slice = createSlice({
  name: 'adminInvoice',
  initialState,
  reducers: {
    loadInvoiceSuccess(state, { payload }) {
      state.invoice.data = payload.invoice;
    },
    resetInvoice() {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminInvoiceActions = () => {
  const dispatch = useDispatch();

  const loadInvoice = async id => {
    const { data } = await InvoicesRepository.show(id);

    dispatch(actions.loadInvoiceSuccess(data));

    return data;
  };

  const approveInvoice = async ({ id, projectedPaidAt }) => {
    const requestParams = {
      invoice: {
        projectedPaidAt,
      },
    };

    return InvoicesRepository.approve(id, requestParams);
  };

  const rejectInvoice = async ({ id, rejectionReason }) => {
    const requestParams = {
      invoice: {
        rejectionReason,
      },
    };

    return InvoicesRepository.reject(id, requestParams);
  };

  const resetInvoice = () => {
    dispatch(actions.resetInvoice());
  };

  return {
    loadInvoice,
    approveInvoice,
    rejectInvoice,
    resetInvoice,
  };
};

export const selectors = {
  invoice: state => state.AdminInvoiceSlice.invoice,
};
