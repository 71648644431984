import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tip: {
    marginTop: 8,
    color: theme.palette.colors.deltaGray,
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
  },
  formContainer: {
    marginTop: 36,
    maxWidth: 320,
  },
  submitRoot: {
    marginTop: 46,
    padding: '16px 48px',
  },
  signIn: {
    marginTop: 22,
  },
}));
