import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import styles from "./styles";

const Sidebar = () => {
  const { t } = useTranslation("accountActivation");
  return (
    <>
      <Typography sx={styles.topName}>{t("title")}</Typography>
      <Typography>{t("subtitle")}</Typography>
    </>
  );
};

export default Sidebar;
