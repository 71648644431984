import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params) {
    const path = apiRoutes.siteApiV1UserEmailConfirmationPath();

    return FetchHelpers.post(path, params);
  },
  update(params) {
    const path = apiRoutes.siteApiV1UserEmailConfirmationPath();

    return FetchHelpers.put(path, params);
  },
};
