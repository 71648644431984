import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  totalInformation(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.totalInformationSiteApiV1AdminTransactionsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  buildersLoans(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.buildersLoansSiteApiV1AdminTransactionsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  subcontractorsLoans(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.subcontractorsLoansSiteApiV1AdminTransactionsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  projectsLoans(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.projectsLoansSiteApiV1AdminTransactionsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1AdminTransactionsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  create(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1AdminTransactionsPath();

    return FetchHelpers.post(path, params, customHeaders, cancelToken);
  },
};
