import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
    height: 56,
  },
  cell: {
    fontSize: theme.typography.pxToRem(14),
    padding: '11px 16px 11px 0',
  },
  amountCell: {
    paddingRight: 60,
  },
  number: {
    color: theme.palette.colors.green,
    fontWeight: 700,
  },
  paidIcon: {
    fill: theme.palette.colors.green,
    marginRight: theme.spacing(1),
  },
}));
