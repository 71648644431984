import { SxStyles } from "theme";

const styles: SxStyles = {
  topName: {
    margin: "24px 0",
  },
  companyName: {
    fontWeight: 700,
  },
};

export default styles;
