import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  companyName: {
    fontWeight: 600,
  },
  rejectButton: {
    textTransform: 'none',
    color: theme.palette.colors.red,
    marginLeft: theme.spacing(1),
  },
  cell: {},
  nameCell: {
    cursor: 'pointer',
  },
  actionsCell: {
    maxWidth: 60,
  },
  row: {
    '&:last-child $cell': {
      borderBottom: 'none',
    },
  },
}));
