import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import UserPresenter from 'presenters/UserPresenter';
import TableEmptyRow from 'components/TableEmptyRow';
import TableLoader from 'components/TableLoader';

import Member from './components/Member';
import useStyles from './useStyles';

const MAX_COL_SPAN = 5;

const MembersTable = props => {
  const { members, isLoading, onMemberUpdate, onMemberDestroy, isRoleManagementEnabled, shouldRenderStatus } = props;
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            {shouldRenderStatus && <TableCell>Status</TableCell>}
            <TableCell className={classes.actionCellHead} />
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(members) && !isLoading ? (
            <TableEmptyRow text="There are no users in your team." colSpan={MAX_COL_SPAN} />
          ) : (
            members.map(member => (
              <Member
                key={UserPresenter.id(member)}
                member={member}
                onMemberUpdate={onMemberUpdate}
                onMemberDestroy={onMemberDestroy}
                isRoleManagementEnabled={isRoleManagementEnabled}
                shouldRenderStatus={shouldRenderStatus}
              />
            ))
          )}
          {isLoading && <TableLoader colSpan={MAX_COL_SPAN} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MembersTable.propTypes = {
  members: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onMemberDestroy: PropTypes.func.isRequired,
  onMemberUpdate: PropTypes.func,
  isRoleManagementEnabled: PropTypes.bool,
  shouldRenderStatus: PropTypes.bool,
};

MembersTable.defaultProps = {
  onMemberUpdate: Function.prototype,
  isRoleManagementEnabled: true,
  shouldRenderStatus: true,
};

export default MembersTable;
