import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Icon from 'components/Icon';
import CompanyConnectionPresenter from 'presenters/CompanyConnectionPresenter';

import useStyles from './useStyles';

const IncomingContact = props => {
  const { connection, onCancel, onConfirm, onReject } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <header>
        <div className={classes.titleContainer}>
          <Icon name="outlinedPlus" width={24} height={24} className={classes.titleIcon} />
          <Typography className={classes.title} variant="h3">
            New Incoming Invitation
          </Typography>
        </div>
      </header>
      <main className={classes.main}>
        <div className={classes.infoContainer}>
          <div className={classes.label}>Company Name</div>
          <div className={classes.value}>{CompanyConnectionPresenter.companyName(connection)}</div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.label}>Contact Person</div>
          <div className={classes.value}>{CompanyConnectionPresenter.companyOwnerName(connection)}</div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.label}>Email</div>
          <div className={classes.value}>{CompanyConnectionPresenter.companyOwnerEmail(connection)}</div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.label}>Address</div>
          <div className={classes.value}>{CompanyConnectionPresenter.companyFullAddress(connection)}</div>
        </div>
      </main>
      <footer>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <div className={classes.responseActions}>
            <Button variant="outlined" color="primary" className={classes.confirmButton} onClick={onConfirm}>
              Confirm
            </Button>
            <Button className={classes.rejectButton} onClick={onReject}>
              Reject
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};

IncomingContact.propTypes = {
  connection: CompanyConnectionPresenter.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default IncomingContact;
