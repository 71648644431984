import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    display: "flex",
  },
  icon: {
    width: "40px",
    flex: "0 0 auto",
    flexBasis: "40px",
  },
  title: {
    marginLeft: "8px",
    minWidth: 0,
    transform: "translateY(4px)",
  },
  titleText: {
    lineHeight: "38px",
    fontWeight: 600,
  },
};

export default styles;
