import { FC } from "react";

import Span from "components/common/Span";

import styles from "./styles";
import { IBadgeProps } from "./types";

const Badge: FC<IBadgeProps> = (props) => {
  const { children } = props;

  return <Span sx={styles.count}>{children}</Span>;
};

export default Badge;
