import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  binIcon: {
    fill: theme.palette.colors.sunsetOrange,
  },
  disabledBinIcon: {
    fill: theme.palette.colors.nurseGray,
  },
}));
