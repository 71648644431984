import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import useLoading from 'hooks/useLoading';
import Icon from 'components/Icon';
import { useAdminSubcontractorsActions, selectors } from 'store/AdminSubcontractorsSlice';
import PageHeader from 'components/PageHeader';
import UserPresenter from 'presenters/UserPresenter';

import SubcontractorsTableRow from './components/SubcontractorsTableRow';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 2;

const Subcontractors = () => {
  const classes = useStyles();
  const { loadSubcontractors, resetSubcontractors } = useAdminSubcontractorsActions();
  const { users, nextPage } = useSelector(selectors.subcontractors);
  const { func: sendLoadSubcontractors, isPending } = useLoading(loadSubcontractors);

  useEffect(() => {
    sendLoadSubcontractors();
    return resetSubcontractors;
  }, []);

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="Subcontractors"
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="tools" />}
        />
      </header>
      <Paper className={classes.buildersTableContainer}>
        <div className={classes.tableContainer}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.headerCell}>
                    Name
                  </TableCell>
                  <TableCell align="left" className={classes.headerCell}>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRowsWithLoading
                  nextPage={nextPage}
                  isLoading={isPending}
                  rows={users.map(subcontractor => (
                    <SubcontractorsTableRow key={UserPresenter.id(subcontractor)} subcontractor={subcontractor} />
                  ))}
                  loadMore={loadSubcontractors}
                  isData={!isEmpty(users)}
                  maxColsCount={MAX_COLS_COUNT}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </main>
  );
};

export default Subcontractors;
