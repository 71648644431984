import { FC } from "react";

import { Grid, Typography } from "@mui/material";

import Div from "components/common/Div";
import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceDocumentIcon from "components/common/InvoiceDocumentIcon";

import styles from "./styles";
import { IInvoiceResponseHeaderProps } from "./types";

const InvoiceResponseHeader: FC<IInvoiceResponseHeaderProps> = (props) => {
  const { invoice, name } = props;

  return (
    <Grid container sx={styles.headerContainer}>
      <Grid item xs={3}>
        <Div sx={styles.iconWrapper}>
          <Div sx={styles.icon}>
            <InvoiceDocumentIcon invoice={invoice} />
          </Div>
          <Typography sx={styles.name}>{name}</Typography>
        </Div>
      </Grid>
      <Grid item xs={3}>
        <InvoiceAssignedProjects invoice={invoice} />
      </Grid>
      <Grid item xs={3}>
        <Typography>{invoice.name}</Typography>
      </Grid>
    </Grid>
  );
};

export default InvoiceResponseHeader;
