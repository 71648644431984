import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InvoicePresenter from 'presenters/InvoicePresenter';
import InvoiceResponseHeader from 'components/common/InvoiceResponseHeader';
import ContentBox from 'components/ContentBox';

import useStyles from './useStyles';

const InvoiceRejectReason = props => {
  const { invoice, onEditInvoiceClick, invoiceName } = props;
  const classes = useStyles();

  const isInvoiceEdit = InvoicePresenter.isAuthored(invoice);

  return (
    <div className={classes.invoiceRejectReasonContainer}>
      <InvoiceResponseHeader invoice={invoice} name={invoiceName} />
      <Grid container className={classes.invoiceRejectReasonContent} spacing={4}>
        <Grid xs={10} item>
          <ContentBox className={classes.contentBox} title="Reason of rejection">
            <Typography>{InvoicePresenter.rejectionReasonText(invoice)}</Typography>
          </ContentBox>
        </Grid>
        <Grid xs={2} item className={classes.buttonContainer}>
          {isInvoiceEdit && (
            <Button variant="contained" color="primary" onClick={onEditInvoiceClick}>
              Edit Invoice
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

InvoiceRejectReason.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  onEditInvoiceClick: PropTypes.func.isRequired,
  invoiceName: PropTypes.string.isRequired,
};

export default InvoiceRejectReason;
