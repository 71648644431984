import { useState, useEffect } from 'react';
import { uniqBy } from 'ramda';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router-dom';

import { validationSchema, initialValues, formPaths } from 'forms/Builder/InvoiceResponseForm';
import { getInvoiceTotal } from 'utils/invoiceUtils';
import useFormik from 'hooks/useFormik';
import useLoading from 'hooks/useLoading';
import AppRoutes from 'routes/AppRoutes';
import { pathWithDots } from 'utils/formUtils';
import { dateTimeToUTC } from 'utils/dateUtils';

export const useInvoiceResponseForm = ({
  invoiceId,
  isAmountEdit,
  totalAmount,
  approveInvoice,
  rejectInvoice,
  onLineItemItemCreate,
}) => {
  const [apiErrors, setApiErrors] = useState({});
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const { isPending: isApprovePending, func: sendApprove } = useLoading(approveInvoice);
  const { isPending: isRejectPending, func: sendReject } = useLoading(rejectInvoice);
  const [isInvoiceLineItemFormOpen, setIsInvoiceLineItemFormOpen] = useState(false);
  const [createItemInputIndex, setCreateItemInputIndex] = useState(null);

  const formData = useFormik({
    initialValues,
    validationSchema: validationSchema(isAmountEdit),
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async ({ splittedInvoices, projectedPaidAt }) => {
      const resolvedDate = dateTimeToUTC(projectedPaidAt);

      try {
        if (!isAmountEdit) {
          await sendApprove({
            id: invoiceId,
            projectedPaidAt: resolvedDate.toJSON(),
          });
          return;
        }

        const invoiceLineItemsAttributes = splittedInvoices.map(splittedInvoice => ({
          lineItemId: splittedInvoice.lineItem.id,
          amount: splittedInvoice.amount,
        }));
        const projectInvoicesAttributes = uniqBy(
          ({ projectId }) => projectId,
          splittedInvoices.map(splittedInvoice => ({
            projectId: splittedInvoice.project.id,
          })),
        );

        await sendApprove({
          id: invoiceId,
          projectInvoicesAttributes,
          invoiceLineItemsAttributes,
          projectedPaidAt: resolvedDate.toJSON(),
        });
        formData.resetForm();
      } catch (error) {
        setApiErrors(error);
      }
    },
  });
  const { setFieldValue, values, setValues, resetForm } = formData;
  const history = useHistory();

  const isFormDisabled = isApprovePending || isRejectPending;

  useEffect(() => {
    if (totalAmount) {
      setFieldValue(formPaths.firstAmount, totalAmount);
    }
  }, []);

  useEffect(() => {
    const isAmount = Boolean(values.splittedInvoices?.filter(splittedInvoice => splittedInvoice.amount).length);
    if (isAmount) {
      const formTotalAmount = getInvoiceTotal(values.splittedInvoices);
      const neededAmount = totalAmount - formTotalAmount;
      setFieldValue(formPaths.neededAmount, neededAmount);
    } else {
      setFieldValue(formPaths.neededAmount, null);
    }
  }, [values.splittedInvoices]);

  const handleOpenRejectForm = () => {
    setIsRejectFormOpen(true);
  };

  const handleReject = async rejectionReason => {
    await sendReject({ id: invoiceId, rejectionReason });
    setIsRejectFormOpen(false);
    resetForm();
  };

  const handleCancelRejectForm = () => setIsRejectFormOpen(false);

  const handleSplitInvoice = () => {
    const emptyField = { ...initialValues.splittedInvoices[0], key: nanoid() };
    setValues(prevValues => ({
      ...prevValues,
      splittedInvoices: [...prevValues.splittedInvoices, emptyField],
    }));
  };

  const handleRemoveSplits = () => {
    setValues(prevValues => ({
      ...prevValues,
      splittedInvoices: [Object.assign(prevValues.splittedInvoices[0])],
    }));
  };

  const handleFieldValueSet = path => (_, option) => {
    setFieldValue(path, option);
  };

  const handleRemoveButtonClick = splittedIndex => () => {
    setValues(prevValues => ({
      ...prevValues,
      splittedInvoices: prevValues.splittedInvoices.filter((_, index) => index !== splittedIndex),
    }));
  };

  const handleViewInvoiceClick = () => {
    history.push(AppRoutes.invoicePath(invoiceId));
  };

  const handleInvoiceLineItemFormOpen = index => {
    setIsInvoiceLineItemFormOpen(true);
    setCreateItemInputIndex(index);
  };

  const handleInvoiceLineItemFormClose = () => {
    setIsInvoiceLineItemFormOpen(false);
    setCreateItemInputIndex(null);
  };

  const handleLineItemItemCreate = async item => {
    const {
      data: {
        projectLineItem: { lineItem },
      },
    } = await onLineItemItemCreate(item);

    formData.setFieldValue(formPaths.getLineItemPath(createItemInputIndex), { ...lineItem });
    handleInvoiceLineItemFormClose();
  };

  const creationLineItemProjectId = pathWithDots(formPaths.getProjectIdPath(createItemInputIndex), formData.values);

  return {
    formData,
    apiErrors,
    isFormDisabled,
    isRejectFormOpen,
    creationLineItemProjectId,
    isInvoiceLineItemFormOpen,
    handleSplitInvoice,
    handleRemoveSplits,
    handleOpenRejectForm,
    handleFieldValueSet,
    handleReject,
    handleCancelRejectForm,
    handleRemoveButtonClick,
    handleViewInvoiceClick,
    handleInvoiceLineItemFormOpen,
    handleInvoiceLineItemFormClose,
    handleLineItemItemCreate,
  };
};
