import { FC, useEffect } from "react";

import { FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";

import * as EarlyPaymentOptionPresenter from "store/api/subcontractor/invoice/earlyPayment/option/presenter";

import styles from "./styles";
import { IEarlyPaymentOptionsProps } from "./types";

const EarlyPaymentOptions: FC<IEarlyPaymentOptionsProps> = (props) => {
  const { earlyPaymentOptions, loading } = props;
  const { t } = useTranslation("subcontractorInvoice");

  const { control } = useFormContext();
  const {
    field: { value: earlyPaymentId, onChange: onEarlyPaymentIdChange },
  } = useController({ name: "earlyPaymentId", control });

  useEffect(() => {
    if (earlyPaymentOptions) {
      onEarlyPaymentIdChange(earlyPaymentOptions?.at(0)?.earlyPaymentId);
    }
  }, [earlyPaymentOptions]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Div sx={styles.root}>
      <Div sx={styles.options}>
        <FormControl>
          <RadioGroup
            defaultValue={earlyPaymentOptions?.at(0)?.earlyPaymentId}
            name="early-payment-options-radio-buttons-group"
            value={earlyPaymentId}
            onChange={(event) => onEarlyPaymentIdChange(event.target.value)}
          >
            {earlyPaymentOptions?.map((earlyPaymentOption) => {
              return (
                <FormControlLabel
                  key={earlyPaymentOption.earlyPaymentId}
                  value={earlyPaymentOption.earlyPaymentId}
                  control={<Radio />}
                  label={EarlyPaymentOptionPresenter.label(earlyPaymentOption, t)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Div>
    </Div>
  );
};

export default EarlyPaymentOptions;
