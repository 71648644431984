import { Route, Switch, Redirect } from 'react-router-dom';

import Invoices from 'components/pages/subcontractor/Invoices';

import AppRoutes from 'routes/AppRoutes';
import UserLayout from 'layouts/UserLayout';
import UserPresenter from 'presenters/UserPresenter';
import Invoice from 'containers/Subcontractor/Invoice';
import InvoiceCreate from 'containers/Subcontractor/InvoiceCreate';
import TeamSettings from 'containers/Subcontractor/TeamSettings';
import Builders from 'containers/Subcontractor/Builders';
import InvoiceUpdate from 'containers/Subcontractor/InvoiceUpdate';

const SubcontractorSwitch = props => {
  const { currentUser } = props;

  return (
    <UserLayout currentUser={currentUser}>
      <Switch>
        <Route path={AppRoutes.invoicesPath()} exact component={Invoices} />
        <Route path={AppRoutes.invoicesCreatePath()} exact component={InvoiceCreate} />
        <Route path={AppRoutes.settingsPath()} exact component={TeamSettings} />
        <Route path={AppRoutes.invoiceEditPath(':id')} exact component={InvoiceUpdate} />
        <Route path={AppRoutes.invoicePath(':id')} exact component={Invoice} />
        <Route path={AppRoutes.contactsPath()} exact component={Builders} />

        <Redirect to={AppRoutes.invoicesPath()} />
      </Switch>
    </UserLayout>
  );
};

SubcontractorSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default SubcontractorSwitch;
