import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';

import Icon from 'components/Icon';
import { PAYMENT_METHODS, CHECK_ADDRESS } from 'constants/paymentMethods';

import useStyles from './useStyles';

const PaymentMethods = props => {
  const { onPaymentMethodSelected } = props;
  const [paymentMethod, setPaymentMethod] = useState(null);
  const classes = useStyles();

  const handlePaymentMethodSelect = method => () => {
    setPaymentMethod(method);
  };

  const handleContinue = () => {
    onPaymentMethodSelected(paymentMethod);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          Please select a Payment Method
        </Typography>
        <Typography className={classes.subtitle}>
          This will be the payment method you prefer to use when submitting payments for your project.
        </Typography>
      </div>
      <div className={classes.paymentMethodsContainer}>
        <ul className={classes.paymentMethodsList}>
          <li className={classes.paymentMethod}>
            <div
              className={clsx(classes.paymentMethodDescription, {
                [classes.paymentMethodActive]: paymentMethod === PAYMENT_METHODS.ach,
              })}
              role="button"
              tabIndex={0}
              onClick={handlePaymentMethodSelect(PAYMENT_METHODS.ach)}
              onKeyDown={handlePaymentMethodSelect(PAYMENT_METHODS.ach)}
            >
              <Icon
                name="creditCard"
                width={34}
                height={34}
                className={clsx(classes.icon, { [classes.activeIcon]: paymentMethod === PAYMENT_METHODS.ach })}
              />
              <Typography className={classes.paymentMethodTitle}>ACH</Typography>
              <Typography className={classes.paymentMethodText}>I will pay from my account using ACH</Typography>
            </div>
          </li>
          <li className={classes.paymentMethod}>
            <div
              className={clsx(classes.paymentMethodDescription, {
                [classes.paymentMethodActive]: paymentMethod === PAYMENT_METHODS.check,
              })}
              role="button"
              tabIndex={0}
              onClick={handlePaymentMethodSelect(PAYMENT_METHODS.check)}
              onKeyDown={handlePaymentMethodSelect(PAYMENT_METHODS.check)}
            >
              <Icon
                name="bankCheck"
                width={34}
                height={34}
                className={clsx(classes.icon, { [classes.activeIcon]: paymentMethod === PAYMENT_METHODS.check })}
              />
              <Typography className={classes.paymentMethodTitle}>Check</Typography>
              <Typography className={classes.paymentMethodText}>I will pay from my account using a check</Typography>
            </div>
          </li>
          <li className={classes.paymentMethod}>
            <div
              className={clsx(classes.paymentMethodDescription, {
                [classes.paymentMethodActive]: paymentMethod === PAYMENT_METHODS.bankAccount,
              })}
              role="button"
              tabIndex={0}
              onClick={handlePaymentMethodSelect(PAYMENT_METHODS.bankAccount)}
              onKeyDown={handlePaymentMethodSelect(PAYMENT_METHODS.bankAccount)}
            >
              <Icon
                name="bank"
                width={34}
                height={34}
                className={clsx(classes.icon, { [classes.activeIcon]: paymentMethod === PAYMENT_METHODS.bankAccount })}
              />
              <Typography className={classes.paymentMethodTitle}>Bank Account</Typography>
              <Typography className={classes.paymentMethodText}>
                My bank will pay for the project from their account
              </Typography>
            </div>
          </li>
        </ul>
        {paymentMethod === PAYMENT_METHODS.check && (
          <div>
            <Fade in={paymentMethod === PAYMENT_METHODS.check}>
              <div>
                <Typography className={classes.subtitle}>Send your checks to the address below. </Typography>
                <Typography className={classes.subtitle}>
                  You will see this address when the payment day comes and you log in.
                </Typography>
                <div className={classes.checkAddressContainer}>
                  <div className={classes.checkAddressDescription}>
                    <Typography className={classes.address}>
                      Address: <span className={classes.bold}>{CHECK_ADDRESS}</span>
                    </Typography>
                    <Typography>
                      Please make checks payable to <span className={classes.bold}>Funl LLC</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        )}
      </div>
      <div className={classes.nextButtonContainer}>
        <Button variant="contained" color="primary" disabled={!paymentMethod} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </>
  );
};

PaymentMethods.propTypes = {
  onPaymentMethodSelected: PropTypes.func.isRequired,
};

export default PaymentMethods;
