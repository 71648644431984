import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';
import { matchSorter } from 'match-sorter';
import clsx from 'clsx';

import TextField from '@mui/material/TextField';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MuiAutocomplete from '@mui/material/Autocomplete';

import useStyles from './useStyles';

const DEBOUNCE_TIMEOUT = 500;

const Autocomplete = props => {
  const {
    inputValue,
    fieldName,
    options,
    classes: autocompleteClasses,
    disabled,
    acceptedSortingKeys,
    isOnFieldValueSetWrap,
    clearOnBlur,
    renderOptions,
    getOptionLabel,
    disableClearable,
    isLoading,
    PaperComponent,
    onInputChange,
    onLoad,
    onFieldValueSet,
    ...inputProps
  } = props;
  const classes = useStyles();

  const debouncedHandleUsersLoad = useDebouncedCallback(async value => {
    onLoad(value);
  }, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    debouncedHandleUsersLoad.callback(inputValue);
  }, [inputValue]);

  const filterOptions = (possibleOptions, { inputValue: value }) =>
    matchSorter(possibleOptions, value, {
      keys: ['name', 'companyOwner.fullName', 'companyOwner.email', ...acceptedSortingKeys],
    });

  const handleAutocompleteKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const defaultGetOptionLabel = option => (is(String, option) ? option : option?.companyOwner?.email);
  const autoCompleteGetOptionLabel = getOptionLabel || defaultGetOptionLabel;
  const handleFieldValueSet = (_, option) => {
    if (isOnFieldValueSetWrap) {
      const fieldOption = option || '';
      return onFieldValueSet(fieldName, autoCompleteGetOptionLabel(fieldOption));
    }
    return onFieldValueSet(_, option);
  };

  const defaultRenderOptions = (optionProps, option) => {
    return (
      <li className={classes.optionContainer} {...optionProps}>
        <div className={classes.option}>
          <div className={classes.optionName}>{option.name}</div>
          <div className={classes.optionAddress}>
            {option?.city}, {option?.state}, {option?.zipCode}
          </div>
          <div className={classes.optionEmail}>{option.companyOwner?.email}</div>
        </div>
      </li>
    );
  };

  const autoCompleteRenderOptions = renderOptions || defaultRenderOptions;

  const renderInput = params => (
    <TextField
      {...params}
      {...inputProps}
      id={fieldName}
      name={fieldName}
      InputLabelProps={{ shrink: true }}
      onKeyDown={handleAutocompleteKeyDown}
      InputProps={{
        ...params.InputProps,
        className: clsx(params.InputProps.className, classes.textField),
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="primary" className={classes.loadingIcon} size={20} /> : null}
            <ExpandMoreIcon color="disabled" className={classes.expandIcon} />
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <MuiAutocomplete
      disableClearable={disableClearable}
      freeSolo
      disabled={disabled}
      autoHighlight
      selectOnFocus
      clearOnBlur={clearOnBlur}
      options={options}
      classes={{ option: classes.autocompleteOption, clearIndicator: classes.clearIndicator, ...autocompleteClasses }}
      getOptionLabel={autoCompleteGetOptionLabel}
      filterOptions={filterOptions}
      renderGroup={() => null}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={handleFieldValueSet}
      renderOption={autoCompleteRenderOptions}
      renderInput={renderInput}
      PaperComponent={PaperComponent}
    />
  );
};

Autocomplete.propTypes = {
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  inputValue: PropTypes.string.isRequired,
  onFieldValueSet: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  acceptedSortingKeys: PropTypes.arrayOf(PropTypes.string),
  isOnFieldValueSetWrap: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  isLoading: PropTypes.bool,
  PaperComponent: PropTypes.elementType,
  renderOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onLoad: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
};

Autocomplete.defaultProps = {
  renderOptions: null,
  getOptionLabel: null,
  disabled: false,
  acceptedSortingKeys: [],
  isOnFieldValueSetWrap: true,
  disableClearable: true,
  clearOnBlur: false,
  isLoading: false,
  onFieldValueSet: Function.prototype,
  onInputChange: Function.prototype,
  PaperComponent: Paper,
};

export default Autocomplete;
