import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '320px',
    },
  },
  signUpContainer: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    color: theme.palette.colors.deltaGray,
  },
  formContainer: {
    marginTop: 36,
    position: 'relative',
  },
  resetPassword: {
    position: 'absolute',
    right: 0,
    marginTop: 23,
    zIndex: 1,
  },
  inputRoot: {
    '&:not(:first-child)': {
      marginTop: 28,
    },
  },
  submitRoot: {
    marginTop: 48,
    width: 180,
    height: 48,
  },
}));
