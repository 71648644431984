import AppRoutes from "routes/AppRoutes";

const guestRoutes = new Set([
  AppRoutes.signInPath(),
  AppRoutes.signUpPath(),
  AppRoutes.emailConfirmationPath(),
  AppRoutes.forgotPasswordPath(),
  AppRoutes.passwordResetPath(),
  AppRoutes.accountActivationPath(),
  AppRoutes.customerActivationPath(),
]);

export const isGuestRoute = (pathname: string) => guestRoutes.has(pathname);
