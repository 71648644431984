/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /admin/sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "admin" + "/" + "sidekiq" + $buildOptions(options, []);
}

/** /letter_opener */
export function letterOpenerWebPath(options?: object): string {
  return "/" + "letter_opener" + $buildOptions(options, []);
}

/** /letter_opener/ */
export function letterOpenerWebLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + $buildOptions(options, []);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /letter_opener/:id(/:style)(.:format) */
export function letterOpenerWebLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "style") ? "/" + (options as any).style : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","style","format"]);
}

/** /letter_opener/:id/delete(.:format) */
export function letterOpenerWebDeleteLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + "/" + "delete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/current_user(.:format) */
export function siteApiV1CurrentUserPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/user(.:format) */
export function siteApiV1UserPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/session(.:format) */
export function siteApiV1SessionPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "session" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/states(.:format) */
export function siteApiV1StatesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "states" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/user/activation(.:format) */
export function siteApiV1UserActivationPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "user" + "/" + "activation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/user/email_confirmation(.:format) */
export function siteApiV1UserEmailConfirmationPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "user" + "/" + "email_confirmation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/user/password_reset(.:format) */
export function siteApiV1UserPasswordResetPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "user" + "/" + "password_reset" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/onboarding/companies(.:format) */
export function siteApiV1BuilderOnboardingCompaniesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "onboarding" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/project/regular_payments/options(.:format) */
export function optionsSiteApiV1BuilderProjectRegularPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "project" + "/" + "regular_payments" + "/" + "options" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/onboarding/line_items/proceed(.:format) */
export function proceedSiteApiV1BuilderCompanyOnboardingLineItemsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "onboarding" + "/" + "line_items" + "/" + "proceed" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/onboarding/members/check_availability(.:format) */
export function checkAvailabilitySiteApiV1BuilderCompanyOnboardingMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "onboarding" + "/" + "members" + "/" + "check_availability" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/onboarding/members/mass_create(.:format) */
export function massCreateSiteApiV1BuilderCompanyOnboardingMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "onboarding" + "/" + "members" + "/" + "mass_create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/line_items/mass_create(.:format) */
export function massCreateSiteApiV1BuilderCompanyLineItemsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "line_items" + "/" + "mass_create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/line_items(.:format) */
export function siteApiV1BuilderCompanyLineItemsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/line_items/:id(.:format) */
export function siteApiV1BuilderCompanyLineItemPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "line_items" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/subcontractors/mass_invite(.:format) */
export function massInviteSiteApiV1BuilderCompanySubcontractorsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "subcontractors" + "/" + "mass_invite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/subcontractors/reinvite(.:format) */
export function reinviteSiteApiV1BuilderCompanySubcontractorsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "subcontractors" + "/" + "reinvite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/invoices/awaiting_approval_total_count(.:format) */
export function awaitingApprovalTotalCountSiteApiV1BuilderCompanyInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + "awaiting_approval_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/invoices/:id/reject(.:format) */
export function rejectSiteApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/invoices/:id/approve(.:format) */
export function approveSiteApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/invoices/:id/mark_as_paid(.:format) */
export function markAsPaidSiteApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + "/" + "mark_as_paid" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/invoices(.:format) */
export function siteApiV1BuilderCompanyInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/invoices/:id(.:format) */
export function siteApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/projects/:id/complete(.:format) */
export function completeSiteApiV1BuilderCompanyProjectPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + id + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/project_line_items/mass_create(.:format) */
export function massCreateSiteApiV1BuilderCompanyProjectProjectLineItemsPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "project_line_items" + "/" + "mass_create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/project_line_items(.:format) */
export function siteApiV1BuilderCompanyProjectProjectLineItemsPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "project_line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/project_line_items/:id(.:format) */
export function siteApiV1BuilderCompanyProjectProjectLineItemPath(project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "project_line_items" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/managers/mass_create(.:format) */
export function massCreateSiteApiV1BuilderCompanyProjectManagersPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "managers" + "/" + "mass_create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/managers(.:format) */
export function siteApiV1BuilderCompanyProjectManagersPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "managers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/managers/:id(.:format) */
export function siteApiV1BuilderCompanyProjectManagerPath(project_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "managers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/customers(.:format) */
export function siteApiV1BuilderCompanyProjectCustomersPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "customers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/line_items(.:format) */
export function siteApiV1BuilderCompanyProjectLineItemsPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/invoices(.:format) */
export function siteApiV1BuilderCompanyProjectInvoicesPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects/:project_id/customer_invoices(.:format) */
export function siteApiV1BuilderCompanyProjectCustomerInvoicesPath(project_id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "customer_invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /site/api/v1/builder/company/projects(.:format) */
export function siteApiV1BuilderCompanyProjectsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/projects/:id(.:format) */
export function siteApiV1BuilderCompanyProjectPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/incoming_connections/:id/accept(.:format) */
export function acceptSiteApiV1BuilderCompanyIncomingConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_connections" + "/" + id + "/" + "accept" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/incoming_connections/:id/reject(.:format) */
export function rejectSiteApiV1BuilderCompanyIncomingConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_connections" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/incoming_connections(.:format) */
export function siteApiV1BuilderCompanyIncomingConnectionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/payment_tokens/stripe_connect_account(.:format) */
export function stripeConnectAccountSiteApiV1BuilderCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "payment_tokens" + "/" + "stripe_connect_account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/payment_tokens/stripe_account_link(.:format) */
export function stripeAccountLinkSiteApiV1BuilderCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "payment_tokens" + "/" + "stripe_account_link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/payment_tokens/activated_stripe_account(.:format) */
export function activatedStripeAccountSiteApiV1BuilderCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "payment_tokens" + "/" + "activated_stripe_account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/incoming_payments/read_all(.:format) */
export function readAllSiteApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + "read_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/incoming_payments/unread_total_count(.:format) */
export function unreadTotalCountSiteApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + "unread_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/incoming_payments(.:format) */
export function siteApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/incoming_payments/:id(.:format) */
export function siteApiV1BuilderCompanyIncomingPaymentPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/connections(.:format) */
export function siteApiV1BuilderCompanyConnectionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/user_invitations(.:format) */
export function siteApiV1BuilderCompanyUserInvitationsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "user_invitations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/template_line_items(.:format) */
export function siteApiV1BuilderCompanyTemplateLineItemsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "template_line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/managers(.:format) */
export function siteApiV1BuilderCompanyManagersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "managers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/regular_payments(.:format) */
export function siteApiV1BuilderCompanyRegularPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "regular_payments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/members(.:format) */
export function siteApiV1BuilderCompanyMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "members" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/members/:id(.:format) */
export function siteApiV1BuilderCompanyMemberPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "members" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/builder/company/company_billing_settings(.:format) */
export function siteApiV1BuilderCompanyCompanyBillingSettingsIndexPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "company_billing_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/company/customers(.:format) */
export function siteApiV1BuilderCompanyCustomersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "customers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/builder/subcontractors(.:format) */
export function siteApiV1BuilderSubcontractorsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "subcontractors" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/onboarding/companies(.:format) */
export function siteApiV1SubcontractorOnboardingCompaniesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "onboarding" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/invoice/early_payments/options(.:format) */
export function optionsSiteApiV1SubcontractorInvoiceEarlyPaymentsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "invoice" + "/" + "early_payments" + "/" + "options" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/onboarding/members/check_availability(.:format) */
export function checkAvailabilitySiteApiV1SubcontractorCompanyOnboardingMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "onboarding" + "/" + "members" + "/" + "check_availability" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/onboarding/members/mass_create(.:format) */
export function massCreateSiteApiV1SubcontractorCompanyOnboardingMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "onboarding" + "/" + "members" + "/" + "mass_create" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/builders/mass_invite(.:format) */
export function massInviteSiteApiV1SubcontractorCompanyBuildersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "builders" + "/" + "mass_invite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/incoming_connections/has_pending_connections(.:format) */
export function hasPendingConnectionsSiteApiV1SubcontractorCompanyIncomingConnectionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "incoming_connections" + "/" + "has_pending_connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/incoming_connections/:id/accept(.:format) */
export function acceptSiteApiV1SubcontractorCompanyIncomingConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "incoming_connections" + "/" + id + "/" + "accept" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/subcontractor/company/incoming_connections/:id/reject(.:format) */
export function rejectSiteApiV1SubcontractorCompanyIncomingConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "incoming_connections" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/subcontractor/company/incoming_connections(.:format) */
export function siteApiV1SubcontractorCompanyIncomingConnectionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "incoming_connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/payment_tokens/stripe_connect_account(.:format) */
export function stripeConnectAccountSiteApiV1SubcontractorCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "payment_tokens" + "/" + "stripe_connect_account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/payment_tokens/stripe_account_link(.:format) */
export function stripeAccountLinkSiteApiV1SubcontractorCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "payment_tokens" + "/" + "stripe_account_link" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/payment_tokens/activated_stripe_account(.:format) */
export function activatedStripeAccountSiteApiV1SubcontractorCompanyPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "payment_tokens" + "/" + "activated_stripe_account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/connections(.:format) */
export function siteApiV1SubcontractorCompanyConnectionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/invoices/pending_total_count(.:format) */
export function pendingTotalCountSiteApiV1SubcontractorCompanyInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "invoices" + "/" + "pending_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/invoices(.:format) */
export function siteApiV1SubcontractorCompanyInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/invoices/:id(.:format) */
export function siteApiV1SubcontractorCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "invoices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/subcontractor/company/members(.:format) */
export function siteApiV1SubcontractorCompanyMembersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "members" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/members/:id(.:format) */
export function siteApiV1SubcontractorCompanyMemberPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "members" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/subcontractor/company/user_invitations(.:format) */
export function siteApiV1SubcontractorCompanyUserInvitationsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "user_invitations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/company/company_billing_settings(.:format) */
export function siteApiV1SubcontractorCompanyCompanyBillingSettingsIndexPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "company_billing_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/subcontractor/builders(.:format) */
export function siteApiV1SubcontractorBuildersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "builders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/onboarding/projects/confirm(.:format) */
export function confirmSiteApiV1CustomerOnboardingProjectsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "onboarding" + "/" + "projects" + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/onboardings/complete(.:format) */
export function completeSiteApiV1CustomerOnboardingsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "onboardings" + "/" + "complete" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/customer_invoices/:id/approve(.:format) */
export function approveSiteApiV1CustomerCustomerInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "customer_invoices" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/customer/customer_invoices(.:format) */
export function siteApiV1CustomerCustomerInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "customer_invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/payment_tokens/plaid_link_token(.:format) */
export function plaidLinkTokenSiteApiV1CustomerPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "payment_tokens" + "/" + "plaid_link_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/payment_tokens/bank_account_token(.:format) */
export function bankAccountTokenSiteApiV1CustomerPaymentTokensPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "payment_tokens" + "/" + "bank_account_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/customer_billing_settings/payment_method(.:format) */
export function paymentMethodSiteApiV1CustomerCustomerBillingSettingsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "customer_billing_settings" + "/" + "payment_method" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/customer_billing_settings(.:format) */
export function siteApiV1CustomerCustomerBillingSettingsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "customer_billing_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/bank_accounts(.:format) */
export function siteApiV1CustomerBankAccountsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "bank_accounts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/projects(.:format) */
export function siteApiV1CustomerProjectsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/customer/transactions(.:format) */
export function siteApiV1CustomerTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "customer" + "/" + "transactions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/projects/:id/approve(.:format) */
export function approveSiteApiV1AdminProjectPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "projects" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/projects(.:format) */
export function siteApiV1AdminProjectsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/projects/:id(.:format) */
export function siteApiV1AdminProjectPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "projects" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/transactions/total_information(.:format) */
export function totalInformationSiteApiV1AdminTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "transactions" + "/" + "total_information" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/transactions/builders_loans(.:format) */
export function buildersLoansSiteApiV1AdminTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "transactions" + "/" + "builders_loans" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/transactions/subcontractors_loans(.:format) */
export function subcontractorsLoansSiteApiV1AdminTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "transactions" + "/" + "subcontractors_loans" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/transactions/projects_loans(.:format) */
export function projectsLoansSiteApiV1AdminTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "transactions" + "/" + "projects_loans" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/transactions(.:format) */
export function siteApiV1AdminTransactionsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "transactions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/invoices/:id/reject(.:format) */
export function rejectSiteApiV1AdminInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "invoices" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/invoices/:id/approve(.:format) */
export function approveSiteApiV1AdminInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "invoices" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/invoices(.:format) */
export function siteApiV1AdminInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/invoices/:id(.:format) */
export function siteApiV1AdminInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "invoices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/builders(.:format) */
export function siteApiV1AdminBuildersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "builders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/subcontractors(.:format) */
export function siteApiV1AdminSubcontractorsPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "subcontractors" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/companies(.:format) */
export function siteApiV1AdminCompaniesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/customer_invoices(.:format) */
export function siteApiV1AdminCustomerInvoicesPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "customer_invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/customers(.:format) */
export function siteApiV1AdminCustomersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "customers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /site/api/v1/admin/users/:id/impersonate(.:format) */
export function impersonateSiteApiV1AdminUserPath(id: ScalarType, options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "users" + "/" + id + "/" + "impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /site/api/v1/admin/users/stop_impersonating(.:format) */
export function stopImpersonatingSiteApiV1AdminUsersPath(options?: object): string {
  return "/" + "site" + "/" + "api" + "/" + "v1" + "/" + "admin" + "/" + "users" + "/" + "stop_impersonating" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/current_user(.:format) */
export function mobileApiV1CurrentUserPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/auth_token/refresh(.:format) */
export function refreshMobileApiV1AuthTokenPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "auth_token" + "/" + "refresh" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/auth_token(.:format) */
export function mobileApiV1AuthTokenPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "auth_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/states(.:format) */
export function mobileApiV1StatesPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "states" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/user/password_reset(.:format) */
export function mobileApiV1UserPasswordResetPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "user" + "/" + "password_reset" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/line_items(.:format) */
export function mobileApiV1BuilderCompanyLineItemsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/projects/:project_id/project_line_items(.:format) */
export function mobileApiV1BuilderCompanyProjectProjectLineItemsPath(project_id: ScalarType, options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + "/" + project_id + "/" + "project_line_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["project_id","format"]);
}

/** /mobile/api/v1/builder/company/projects(.:format) */
export function mobileApiV1BuilderCompanyProjectsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "projects" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/invoices/awaiting_approval_total_count(.:format) */
export function awaitingApprovalTotalCountMobileApiV1BuilderCompanyInvoicesPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + "awaiting_approval_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/invoices/:id/approve(.:format) */
export function approveMobileApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mobile/api/v1/builder/company/invoices/:id/reject(.:format) */
export function rejectMobileApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mobile/api/v1/builder/company/invoices(.:format) */
export function mobileApiV1BuilderCompanyInvoicesPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/invoices/:id(.:format) */
export function mobileApiV1BuilderCompanyInvoicePath(id: ScalarType, options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "invoices" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mobile/api/v1/builder/company/incoming_payments/read_all(.:format) */
export function readAllMobileApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + "read_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/incoming_payments/unread_total_count(.:format) */
export function unreadTotalCountMobileApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + "unread_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/incoming_payments(.:format) */
export function mobileApiV1BuilderCompanyIncomingPaymentsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/builder/company/incoming_payments/:id(.:format) */
export function mobileApiV1BuilderCompanyIncomingPaymentPath(id: ScalarType, options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "company" + "/" + "incoming_payments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /mobile/api/v1/builder/subcontractors(.:format) */
export function mobileApiV1BuilderSubcontractorsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "builder" + "/" + "subcontractors" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/subcontractor/company/invoices/pending_total_count(.:format) */
export function pendingTotalCountMobileApiV1SubcontractorCompanyInvoicesPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "invoices" + "/" + "pending_total_count" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/subcontractor/company/invoices(.:format) */
export function mobileApiV1SubcontractorCompanyInvoicesPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "company" + "/" + "invoices" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/subcontractor/invoice/early_payments/options(.:format) */
export function optionsMobileApiV1SubcontractorInvoiceEarlyPaymentsPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "invoice" + "/" + "early_payments" + "/" + "options" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /mobile/api/v1/subcontractor/builders(.:format) */
export function mobileApiV1SubcontractorBuildersPath(options?: object): string {
  return "/" + "mobile" + "/" + "api" + "/" + "v1" + "/" + "subcontractor" + "/" + "builders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /webhooks/v1/stripe/payments(.:format) */
export function webhooksV1StripePaymentsPath(options?: object): string {
  return "/" + "webhooks" + "/" + "v1" + "/" + "stripe" + "/" + "payments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format) */
export function railsConductorInboundEmailIncineratePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "incinerate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
