import * as yup from 'yup';

import { DATE, REQUIRED, maxAmountValue } from 'forms/errorsText';

const MAX_AMOUNT_PAYMENT = 999999.99;

const validationFields = {
  projectedPaidAt: yup.date().typeError(DATE).required(REQUIRED),
  amount: yup.number().max(MAX_AMOUNT_PAYMENT, maxAmountValue(MAX_AMOUNT_PAYMENT.toLocaleString())).required(REQUIRED),
  name: yup.string().required(REQUIRED),
};

export const initialValues = {
  projectedPaidAt: new Date(),
  amount: '',
  name: '',
};

export const validationSchema = yup.object().shape(validationFields);

export const formPaths = {
  projectedPaidAt: 'projectedPaidAt',
  amount: 'amount',
  name: 'name',
};
