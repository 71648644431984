import * as yup from "yup";

export interface IUserActivationFormSubmitData {
  activationToken: string;
  userActivation: {
    fullName: string;
    password: string;
  };
}

export const validationSchema = yup.object({
  fullName: yup.string().required("validation:required").default(""),
  password: yup.string().required("validation:required").default(""),
});

export const defaultValues = validationSchema.getDefault();

export type UserActivationFormData = yup.InferType<typeof validationSchema>;

export const serializeFormData = (
  data: UserActivationFormData,
  activationToken: string,
): IUserActivationFormSubmitData => ({
  activationToken,
  userActivation: {
    fullName: data.fullName,
    password: data.password,
  },
});
