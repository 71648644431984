import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';

import AddButton from 'components/AddButton';
import SectionTitle from 'components/SectionTitle';
import InvoiceLineItemCreateForm from 'components/InvoiceLineItemCreateForm';
import { INVOICE_FORM_VARIANTS } from 'constants/invoices';
import { formatUSD } from 'libs/currency';
import { getInvoiceTotal } from 'utils/invoiceUtils';
import useBreakpoints from 'hooks/useBreakpoints';

import PayableInvoiceLineItem from './components/PayableInvoiceLineItem';
import SubcontractorLineItems from './components/SubcontractorLineItems';

import useStyles from './useStyles';

const InvoiceLineItems = props => {
  const { formData, variant, onLineItemItemCreate, onLineItemLoad } = props;
  const [isInvoiceLineItemFormOpen, setIsInvoiceLineItemFormOpen] = useState(false);
  const [createItemInputIndex, setCreateItemInputIndex] = useState(null);
  const { values, validateField, setFieldValue } = formData;
  const { inboundLineItems, projectId } = values;
  const classes = useStyles();
  const { screenWiderThan } = useBreakpoints();

  const handleLineItemAdd = () => {
    const newLineItem = { key: nanoid(), accountCode: '', categoryName: '', amount: '', id: '' };
    setFieldValue('inboundLineItems', [...inboundLineItems, newLineItem]);
  };

  const handleLineItemRemove = lineItem => {
    const newLineItems = inboundLineItems.filter(({ key }) => key !== lineItem.key);
    setFieldValue('inboundLineItems', newLineItems);
  };

  const handleInvoiceLineItemFormOpen = index => {
    setIsInvoiceLineItemFormOpen(true);
    setCreateItemInputIndex(index);
  };

  const handleInvoiceLineItemFormClose = () => {
    setIsInvoiceLineItemFormOpen(false);
    setCreateItemInputIndex(null);
  };

  const isCreateItemDialogOpen = isInvoiceLineItemFormOpen && Boolean(values.projectId);

  useEffect(() => {
    if (isInvoiceLineItemFormOpen) {
      validateField('projectId');
      if (!values.projectId) {
        handleInvoiceLineItemFormClose();
      }
    }
  }, [isInvoiceLineItemFormOpen]);

  const handleLineItemItemCreate = async item => {
    const {
      data: {
        projectLineItem: { lineItem },
      },
    } = await onLineItemItemCreate(item);

    setFieldValue(`inboundLineItems[${createItemInputIndex}]`, { ...lineItem, amount: '', key: lineItem.id });
    handleInvoiceLineItemFormClose();
  };

  const isDeletionDisabled = inboundLineItems.length <= 1;

  const sumOfLineItems = getInvoiceTotal(inboundLineItems);

  return (
    <>
      <div className={classes.header}>
        <SectionTitle>Line Items</SectionTitle>
      </div>
      <Paper className={classes.paper}>
        <div>
          {inboundLineItems.map((lineItem, index) => (
            <div className={classes.lineItemContainer} key={lineItem.key}>
              {variant === INVOICE_FORM_VARIANTS.builder && (
                <PayableInvoiceLineItem
                  index={index}
                  lineItem={lineItem}
                  formData={formData}
                  isDeletionDisabled={isDeletionDisabled}
                  onLineItemRemove={handleLineItemRemove}
                  onLineItemLoad={onLineItemLoad}
                  onInvoiceLineItemFormOpen={handleInvoiceLineItemFormOpen}
                />
              )}
              {variant === INVOICE_FORM_VARIANTS.subcontractor && (
                <SubcontractorLineItems
                  index={index}
                  lineItem={lineItem}
                  formData={formData}
                  isDeletionDisabled={isDeletionDisabled}
                  onLineItemRemove={handleLineItemRemove}
                />
              )}
            </div>
          ))}
          <AddButton className={classes.buttonRoot} color="secondary" onClick={handleLineItemAdd}>
            Add Item
          </AddButton>
        </div>
      </Paper>
      {variant === INVOICE_FORM_VARIANTS.subcontractor && screenWiderThan.sm && (
        <div className={classes.lineItemsTotalContainer}>
          <div className={classes.lineItemsTotalLabel}>Invoice Total</div>
          <Typography className={classes.lineItemsTotal} component="div">
            <Box fontWeight="fontWeightBold">{formatUSD(sumOfLineItems)}</Box>
          </Typography>
        </div>
      )}
      <Dialog open={isCreateItemDialogOpen} maxWidth="lg" onClose={handleInvoiceLineItemFormClose}>
        <InvoiceLineItemCreateForm
          projectId={projectId}
          onLineItemItemCreate={handleLineItemItemCreate}
          onClose={handleInvoiceLineItemFormClose}
        />
      </Dialog>
    </>
  );
};

InvoiceLineItems.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  formData: PropTypes.shape().isRequired,
  onLineItemItemCreate: PropTypes.func,
  onLineItemLoad: PropTypes.func,
};

InvoiceLineItems.defaultProps = {
  onLineItemItemCreate: Function.prototype,
  onLineItemLoad: Function.prototype,
};

export default InvoiceLineItems;
