import clsx from 'clsx';

import Paper from '@mui/material/Paper';

import ProjectPresenter from 'presenters/ProjectPresenter';

import SoldButton from './components/SoldButton';
import InviteClientDetails from './components/InviteClientDetails';

import useStyles from './useStyles';

const ClientDetails = props => {
  const { project } = props;
  const classes = useStyles();

  const isDraft = ProjectPresenter.isDraft(project);

  return (
    <Paper className={clsx({ [classes.root]: !isDraft, [classes.draftRoot]: isDraft })}>
      {isDraft ? <SoldButton project={project} /> : <InviteClientDetails project={project} />}
    </Paper>
  );
};

ClientDetails.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default ClientDetails;
