import { Route, Switch, Redirect } from 'react-router-dom';

import AppRoutes from 'routes/AppRoutes';
import SignIn from 'containers/SignIn';
import SignUp from 'containers/SignUp';
import EmailConfirmation from 'containers/EmailConfirmation';
import ForgotPassword from 'containers/ForgotPassword';
import PasswordReset from 'containers/PasswordReset';
import AccountActivation from 'components/pages/guest/AccountActivation';
import CustomerActivation from 'components/pages/guest/CustomerActivation';

const GuestUserSwitch = () => {
  return (
    <Switch>
      <Route path={AppRoutes.signInPath()} exact component={SignIn} />
      <Route path={AppRoutes.signUpPath()} exact component={SignUp} />
      <Route path={AppRoutes.emailConfirmationPath()} exact component={EmailConfirmation} />
      <Route path={AppRoutes.forgotPasswordPath()} exact component={ForgotPassword} />
      <Route path={AppRoutes.passwordResetPath()} exact component={PasswordReset} />
      <Route path={AppRoutes.accountActivationPath()} component={AccountActivation} />
      <Route path={AppRoutes.customerActivationPath()} component={CustomerActivation} />

      <Redirect to={AppRoutes.signInPath()} />
    </Switch>
  );
};

export default GuestUserSwitch;
