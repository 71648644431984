import { Route, Switch, Redirect } from 'react-router-dom';

import OnboardingLanding from 'components/pages/onboarding/Landing';

import AppRoutes from 'routes/AppRoutes';
import UserPresenter from 'presenters/UserPresenter';
import BuilderOnboarding from 'containers/Builder/Onboarding';
import SubcontractorOnboarding from 'containers/Subcontractor/Onboarding';
import CustomerOnboarding from 'containers/Customer/Onboarding';

import BuilderSwitch from './BuilderSwitch';
import SubcontractorSwitch from './SubcontractorSwitch';
import AdminSwitch from './AdminSwitch';
import CustomerSwitch from './CustomerSwitch';
import BuilderAdminSwitch from './BuilderAdminSwitch';
import BuilderManagerSwitch from './BuilderManagerSwitch';
import SubcontractorAdminSwitch from './SubcontractorAdminSwitch';
import SubcontractorManagerSwitch from './SubcontractorManagerSwitch';

const PlainUserSwitch = props => {
  const { currentUser } = props;
  if (UserPresenter.isAdmin(currentUser)) {
    return <AdminSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isBuilderAdmin(currentUser) && !UserPresenter.isCompanyOwner(currentUser)) {
    return <BuilderAdminSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isBuilderManager(currentUser)) {
    return <BuilderManagerSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isSubcontractorAdmin(currentUser) && !UserPresenter.isCompanyOwner(currentUser)) {
    return <SubcontractorAdminSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isSubcontractorManager(currentUser)) {
    return <SubcontractorManagerSwitch currentUser={currentUser} />;
  }

  if (!UserPresenter.isCompletedOnboarding(currentUser)) {
    return (
      <Switch>
        <Route path={AppRoutes.onboardingPath()} exact component={OnboardingLanding} />
        <Route path={AppRoutes.onboardingBuilderPath()} exact component={BuilderOnboarding} />
        <Route path={AppRoutes.onboardingSubcontractorPath()} exact component={SubcontractorOnboarding} />
        <Route path={AppRoutes.onboardingCustomerPath()} exact component={CustomerOnboarding} />

        <Redirect to={AppRoutes.onboardingPath()} />
      </Switch>
    );
  }

  if (UserPresenter.isCustomer(currentUser)) {
    return <CustomerSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isBuilder(currentUser)) {
    return <BuilderSwitch currentUser={currentUser} />;
  }

  if (UserPresenter.isSubcontractor(currentUser)) {
    return <SubcontractorSwitch currentUser={currentUser} />;
  }

  return null;
};

PlainUserSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default PlainUserSwitch;
