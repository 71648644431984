import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MaterialLink from '@mui/material/Link';

import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';
import { useAdminProjectActions, selectors } from 'store/AdminProjectSlice';
import ProjectPresenter from 'presenters/ProjectPresenter';
import AppRoutes from 'routes/AppRoutes';

import useStyles from './useStyles';

const AdminProject = () => {
  const classes = useStyles();
  const { loadProject, resetProject } = useAdminProjectActions();
  const routeParams = useParams();
  const projectId = Number(routeParams.id);
  const project = useSelector(selectors.project);

  useEffect(() => {
    loadProject(projectId);
    return resetProject;
  }, []);

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerLink}>
          <MaterialLink to={AppRoutes.adminProjectsPath()} component={Link}>
            Projects
          </MaterialLink>
        </div>
        <PageHeader
          text={ProjectPresenter.name(project)}
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="folder" />}
        />
      </header>
      <div className={classes.contentContainer}>
        <Paper>
          <div className={classes.statsContainer}>
            <Typography>Revenue: {ProjectPresenter.formattedUSDTotalRevenue(project)}</Typography>
            <Typography>Third party expenses: {ProjectPresenter.formattedUSDThirdPartyExpenses(project)}</Typography>
            <Typography>Overhead: {ProjectPresenter.formattedUSDOverheadExpenses(project)}</Typography>
          </div>
        </Paper>
      </div>
    </main>
  );
};

export default AdminProject;
