import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiAutocompleteType {
  defaultProps: ComponentsProps["MuiAutocomplete"];
  styleOverrides: ComponentsOverrides["MuiAutocomplete"];
}

const MuiAutocomplete: IMuiAutocompleteType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      "&:not(:last-child)": {
        marginTop: 20,
      },
    },
    inputRoot: {
      padding: 0,
    },
    input: {
      padding: "10px !important",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 600,
    },
    paper: {
      marginTop: 8,
      fontSize: 16,
    },
  },
};

export default MuiAutocomplete;
