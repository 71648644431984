import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiOutlinedInputType {
  defaultProps: ComponentsProps["MuiOutlinedInput"];
  styleOverrides: ComponentsOverrides["MuiOutlinedInput"];
}

const MuiOutlinedInput: IMuiOutlinedInputType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: "100%",
      border: `2px solid ${baseTheme.palette.colors.lilyWhiteGray}`,
      borderRadius: 3,
      backgroundColor: baseTheme.palette.colors.alabaster,
      "&.Mui-focused": {
        border: `2px solid ${baseTheme.palette.colors.green}`,
        backgroundColor: baseTheme.palette.colors.white,
      },
      "&.Mui-error": {
        border: `2px solid ${baseTheme.palette.colors.red}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
    input: {
      padding: 10,
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 600,
    },
  },
};

export default MuiOutlinedInput;
