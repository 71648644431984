import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  sectionTitle: {
    marginTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(1),
    maxWidth: 720,
    paddingTop: theme.spacing(3),
    padding: theme.spacing(3),
    minHeight: 190,
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.colors.gunsmokeGray,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
}));
