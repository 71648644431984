import { Component } from 'react';
import { withRouter } from 'react-router';

import PropsTypes from 'prop-types';

import Error from 'components/Error';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.handleRejection = this.handleRejection.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.handleRejection);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.error !== this.state.error) {
      return true;
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.resetErrorState();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleRejection);
  }

  handleRejection(error) {
    this.setState(() => ({
      error: error.reason,
    }));
  }

  resetErrorState() {
    this.setState({ error: null });
  }

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropsTypes.node.isRequired,
  location: PropsTypes.shape({ pathname: PropsTypes.string.isRequired }).isRequired,
};

export default withRouter(ErrorBoundary);
