import { FC } from "react";

import { isEmpty } from "ramda";

import Div from "components/common/Div";

import styles from "./styles";
import { IInvoiceAssignedProjectsProps } from "./types";

const InvoiceAssignedProjects: FC<IInvoiceAssignedProjectsProps> = (props) => {
  const {
    invoice: { projects = [], projectName },
  } = props;

  const assignedProjects = isEmpty(projects) ? [projectName] : projects.map((project) => project.name);

  return (
    <>
      {assignedProjects.map((assignedProject) => (
        <Div sx={styles.root} key={assignedProject}>
          {assignedProject}
        </Div>
      ))}
    </>
  );
};

export default InvoiceAssignedProjects;
