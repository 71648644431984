import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import MembersRepository from 'repositories/subcontractor/company/MembersRepository';

import { editUser } from './utils';

const initialState = {
  members: {
    data: [],
    meta: {},
  },
};

const builderTeamSettingsSlice = createSlice({
  name: 'subcontractorTeamSettingsSlice',
  initialState,
  reducers: {
    loadMembersSuccess(state, { payload }) {
      state.members.data = concat(state.members.data, payload.users);
      state.members.meta = payload.meta;
      state.members.isLoading = false;
    },
    editMember(state, { payload }) {
      const { user } = payload;
      state.members.data = editUser({ user, members: state.members.data });
    },
    resetSubcontractorTeamSettings() {
      return initialState;
    },
  },
});

export const { actions } = builderTeamSettingsSlice;
export default builderTeamSettingsSlice.reducer;

export const useSubcontractorTeamSettingsActions = () => {
  const dispatch = useDispatch();

  const loadMembers = async (page = 1) => {
    const params = { q: { s: 'id desc' }, page };
    const { data } = await MembersRepository.index(params);
    dispatch(actions.loadMembersSuccess(data));

    return data;
  };

  const createMember = params => {
    return MembersRepository.create({ user: params });
  };

  const updateMember = async (id, params) => {
    const { data } = await MembersRepository.update(id, { user: params });
    dispatch(actions.editMember(data));
  };

  const destroyMember = id => {
    return MembersRepository.destroy(id);
  };

  const resetSubcontractorTeamSettings = () => {
    dispatch(actions.resetSubcontractorTeamSettings());
  };

  return {
    loadMembers,
    createMember,
    updateMember,
    destroyMember,
    resetSubcontractorTeamSettings,
  };
};
