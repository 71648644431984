import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  helperText: {
    position: 'relative',
    top: 'unset',
    alignSelf: 'flex-end',
  },
  rejectContainer: {
    padding: '20px 24px',
  },
  contentBox: {
    marginBottom: 0,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rejectButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.colors.deltaGray,
    textTransform: 'none',
  },
}));
