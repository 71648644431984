import Badge from "components/common/Badge";
import Span from "components/common/Span";
import Icon from "components/Icon";

import { useGetPendingTotalCountQuery } from "store/api/subcontractor/company/invoice/apiSlice";

import styles from "./styles";

const SubcontractorPendingInvoicesCountIcon = () => {
  const { data: pendingInvoicesCount } = useGetPendingTotalCountQuery(undefined, { pollingInterval: 5000 });

  return (
    <Span sx={styles.iconContainer}>
      <Icon width={24} height={18} name="file" />
      {Boolean(pendingInvoicesCount) && (
        <Span sx={styles.badgeContainer}>
          <Badge>{pendingInvoicesCount}</Badge>
        </Span>
      )}
    </Span>
  );
};

export default SubcontractorPendingInvoicesCountIcon;
