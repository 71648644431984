import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 120,
    marginBottom: 40,
    maxWidth: 309,
    fontSize: 14,
    lineHeight: '16px',
  },
  emailContainer: {
    marginTop: 16,
    marginLeft: 3,
    display: 'flex',
  },
  checkIconContainer: {
    marginTop: 3,
    marginRight: 9,
  },
  email: {
    fontSize: 15,
    lineHeight: '20px',
  },
  resendButtonRoot: {
    fontSize: 14,
    lineHeight: '16px',
  },
  resendContainer: {
    marginTop: 46,
    color: theme.palette.colors.deltaGray,
  },
  emailRepeatStatus: {
    marginBottom: 16,
  },
  checkIcon: {
    fill: theme.palette.colors.green,
  },
}));
