import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { equals, curry, isNil, isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import TableStatus, { TABLE_CELL_VARIANT } from 'components/TableStatus';
import SortableHeadCell from 'components/SortableHeadCell';
import { getNewSortDirection, getSortRuleForApi } from 'utils/sortUtils';

import useStyles from './useStyles';

const TableWithStatus = props => {
  const { isLoading, count, nextPage, title, color, loadMore, headers, rows, isData, maxColsCount, isSortable } = props;

  const classes = useStyles();
  const [sortRule, setSortRule] = useState({});

  useEffect(() => {
    if (isEmpty(sortRule)) {
      return;
    }

    loadMore({ page: 1, sortRule: getSortRuleForApi(sortRule) });
  }, [sortRule]);

  const handleSortChange = selectedSortingRule => {
    const newDirection = getNewSortDirection(sortRule, selectedSortingRule);

    setSortRule({ rule: selectedSortingRule, direction: newDirection });
  };

  const handleLoadMore = isSortable ? page => loadMore({ page, sortRule: getSortRuleForApi(sortRule) }) : loadMore;

  const isSortActive = curry((currentRule, rule) => !isNil(rule) && equals(currentRule.rule, rule))(sortRule);

  const renderHeaderCells = sortable => {
    if (sortable) {
      return headers.map((header, index) => (
        <SortableHeadCell
          align={header.align || 'left'}
          key={index}
          className={classes.headerCell}
          width={header.width}
          sortDirection={sortRule.direction}
          isActive={isSortActive(header.sortingRule)}
          onClick={() => handleSortChange(header.sortingRule)}
          disabled={isNil(header.sortingRule)}
        >
          {header.text}
        </SortableHeadCell>
      ));
    }

    return headers.map((header, index) => (
      <TableCell align={header.align || 'left'} key={index} className={classes.headerCell} width={header.width}>
        {header.text}
      </TableCell>
    ));
  };

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>
              <TableStatus color={color} title={title} count={count} />
            </TableCell>
            {renderHeaderCells(isSortable)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowsWithLoading
            nextPage={nextPage}
            isLoading={isLoading}
            rows={rows}
            loadMore={handleLoadMore}
            isData={isData}
            maxColsCount={maxColsCount}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableWithStatus.propTypes = {
  count: PropTypes.number.isRequired,
  color: PropTypes.oneOf(Object.values(TABLE_CELL_VARIANT)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  rows: PropTypes.node.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      align: PropTypes.string,
      width: PropTypes.string,
    }),
  ).isRequired,
  isData: PropTypes.bool.isRequired,
  maxColsCount: PropTypes.number.isRequired,
  nextPage: PropTypes.number,
  loadMore: PropTypes.func,
  isSortable: PropTypes.bool,
};

TableWithStatus.defaultProps = {
  nextPage: null,
  loadMore: Function.prototype,
  isSortable: false,
};

export default TableWithStatus;
