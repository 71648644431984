import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import Badge from 'components/common/Badge';
import { selectors } from 'store/IncomingPaymentsSlice';

import useStyles from './useStyles';

const IncomingPaymentsUnreadIcon = () => {
  const classes = useStyles();
  const unreadIncomingPayments = useSelector(selectors.unreadIncomingPaymentsCount);

  return (
    <span className={classes.iconContainer}>
      <Icon width={23} height={20} name="incomingPayments" className={classes.icon} />
      {Boolean(unreadIncomingPayments) && (
        <span className={classes.badgeContainer}>
          <Badge>{unreadIncomingPayments}</Badge>
        </span>
      )}
    </span>
  );
};

export default IncomingPaymentsUnreadIcon;
