import { SxStyles } from "theme";

const styles: SxStyles = {
  count: {
    display: "inline-flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "8px",
    borderRadius: 12,
    padding: "0 4px",
    height: "12px",
    minWidth: "12px",
    color: "colors.white",
    backgroundColor: "colors.sunsetOrange",
  },
};

export default styles;
