import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: '28px 24px',
    width: 528,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
  },
  invitationIcon: {
    fill: theme.palette.colors.green,
    margin: '4px 10px 0px 4px',
  },
  formContainer: {
    marginTop: 36,
  },
  selectContainer: {
    marginTop: 36,
  },
  actions: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginLeft: 12,
  },
}));
