import { path, isNil } from 'ramda';

export const pathWithDots = (objPath, obj) => {
  if (Array.isArray(objPath)) {
    return path(objPath, obj);
  }
  return path(objPath.split('.'), obj);
};

export const getTouchedFieldFormikError = ({ touched, touchedPath, errors, errorPath }) => {
  const touchedFieldPath = touchedPath || errorPath;
  return pathWithDots(touchedFieldPath, touched) && pathWithDots(errorPath, errors);
};

export const getTouchedFieldApiError = ({ touched, touchedPath, errors, apiErrors, errorPath }) => {
  if (isNil(path(errorPath, apiErrors))) {
    return getTouchedFieldFormikError({ touchedPath, touched, errorPath, errors });
  }
  return pathWithDots([errorPath, 0], apiErrors);
};
