import { addDays as libraryAddDays, format, addBusinessDays as libraryAddBusinessDays } from "date-fns";

// December 15th, 2020
export const formatDate = (date: Date): string => {
  return format(date, "MMMM do, yyyy");
};

// 3/21/2023
export const formatSimpleDate = (date: Date | string): string => {
  return format(new Date(date), "M/d/y");
};

export const now = (): Date => new Date();

export const addDays = (date: Date | string, days: number): Date => {
  return libraryAddDays(new Date(date), days);
};

export const addBusinessDays = (date: Date | string, days: number): Date => {
  return libraryAddBusinessDays(new Date(date), days);
};
