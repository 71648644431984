import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import UserPresenter from 'presenters/UserPresenter';
import useImpersonate from 'hooks/useImpersonate';

import useStyles from './useStyles';

const SubcontractorsTableRow = props => {
  const { subcontractor } = props;
  const classes = useStyles();
  const { impersonate } = useImpersonate(subcontractor.id);

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {UserPresenter.fullName(subcontractor)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {UserPresenter.email(subcontractor)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <Button variant="outlined" size="small" onClick={impersonate}>
          Impersonate
        </Button>
      </TableCell>
    </TableRow>
  );
};

SubcontractorsTableRow.propTypes = {
  subcontractor: UserPresenter.shape().isRequired,
};

export default SubcontractorsTableRow;
