import { equals, isEmpty, isNil } from 'ramda';

import { DIRECTION } from 'constants/sorting';

export const getNewSortDirection = (prevSortingRule, selectedSort) => {
  const { rule, direction } = prevSortingRule;

  return !equals(rule, selectedSort) || equals(direction, DIRECTION.asc) ? DIRECTION.desc : DIRECTION.asc;
};

export const getSortRuleForApi = sortRule => {
  if (isEmpty(sortRule) || isNil(sortRule)) {
    return undefined;
  }

  const { rule, direction } = sortRule;
  return `${rule} ${direction}`;
};
