import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactInfiniteScroll from 'react-infinite-scroller';
import { isNil } from 'ramda';

import useStyles from './useStyles.js';

const InfiniteScroll = props => {
  const { children, isLoading, onLoad, nextPage, className, ...rest } = props;
  const classes = useStyles();

  const hasMorePages = () => {
    if (isLoading) {
      return false;
    }

    return !isNil(nextPage);
  };

  const loadMoreData = () => {
    if (!isLoading) {
      onLoad(nextPage);
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ReactInfiniteScroll {...rest} pageStart={1} loadMore={loadMoreData} hasMore={hasMorePages()} useWindow={false}>
        {children}
      </ReactInfiniteScroll>
    </div>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  onLoad: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
  className: PropTypes.string,
};

InfiniteScroll.defaultProps = {
  nextPage: null,
  className: null,
};

export default InfiniteScroll;
