import axios from 'axios';
import qs from 'qs';
import FileSaver from 'file-saver';

import { isGuestRoute } from 'utils/routes';
import AppRoutes from 'routes/AppRoutes';
import { toFormData } from 'utils/formDataHelpers';

import { camelize, decamelize } from './keysConverter';
import { extractFileName } from './fileUtils';

const authenticityToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
};

const headers = () => {
  return {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'X-CSRF-Token': authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
};

axios.defaults.headers.common = headers();
axios.interceptors.response.use(null, error => {
  if (error.response?.status === 401) {
    if (!isGuestRoute(window.location.pathname)) {
      window.location.href = AppRoutes.signInPath();
    }
    return Promise.reject(new Error('Unauthorized'));
  }

  if (error.response?.status === 422) {
    const {
      response: { data: errors },
    } = error;
    return Promise.reject(camelize(errors.errors));
  }

  if (error.response?.status === 500) {
    return Promise.reject(new Error('Something went wrong, please retry again'));
  }

  if (error.response?.status === 404) {
    return Promise.reject(new Error('404 The Resource You’re Looking For Was Not Found'));
  }

  return Promise.reject(error);
});

const headersMultipartFormData = () => {
  return {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data',
    'X-CSRF-Token': authenticityToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export default {
  get(url, params = {}, customHeaders = {}, cancelToken) {
    return axios
      .get(url, {
        headers: customHeaders,
        cancelToken,
        params: decamelize(params),
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
      })
      .then(camelize);
  },

  post(url, json, customHeaders = {}, cancelToken) {
    const body = decamelize(json);

    return axios.post(url, body, { headers: customHeaders, cancelToken }).then(camelize);
  },

  put(url, json, customHeaders = {}, cancelToken) {
    const body = decamelize(json);

    return axios.put(url, body, { headers: customHeaders, cancelToken }).then(camelize);
  },

  delete(url, json, customHeaders = {}, cancelToken) {
    const body = decamelize(json);

    return axios.delete(url, { data: body, cancelToken, headers: customHeaders }).then(camelize);
  },

  download(url, params = {}, cancelToken) {
    return axios
      .get(url, {
        params: decamelize(params),
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false }),
        responseType: 'blob',
        cancelToken,
      })
      .then(response => {
        const fileName = extractFileName(response);
        FileSaver.saveAs(new Blob([response.data]), fileName);
      });
  },

  postMultipartFormData(url, params, cancelToken) {
    const formData = toFormData(params);

    return axios
      .post(url, formData, {
        headers: headersMultipartFormData(),
        cancelToken,
      })
      .then(response => camelize(response.data));
  },

  putMultipartFormData(url, params, cancelToken) {
    const formData = toFormData(params);

    return axios
      .put(url, formData, {
        headers: headersMultipartFormData(),
        cancelToken,
      })
      .then(response => camelize(response.data));
  },
};
