import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiTextFieldType {
  defaultProps: ComponentsProps["MuiTextField"];
  styleOverrides: ComponentsOverrides["MuiTextField"];
}

const MuiTextField: IMuiTextFieldType = {
  defaultProps: {
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {},
};

export default MuiTextField;
