import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    width: '100%',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  actionsTitle: {
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '20px',
  },
}));
