import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import Icon from 'components/Icon';
import MemberRoleSelect from 'components/MemberRoleSelect';

import useStyles from './useStyles';

const Table = props => {
  const { users, onUserRoleChange, onUserRemove } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {users.map(user => (
        <div className={classes.card} key={user.email}>
          <div className={classes.info}>
            <div className={classes.label}>User</div>
            <div className={classes.email}>{user.email}</div>
            <div className={classes.emailTip}>User will be invited to create an account</div>
          </div>
          <div className={classes.role}>
            <div className={classes.label}>Role</div>
            <div className={classes.roleContent}>
              <div className={classes.select}>
                <MemberRoleSelect value={user.role} onChange={onUserRoleChange(user.email)} />
              </div>

              <div className={classes.deleteButton}>
                <IconButton onClick={() => onUserRemove(user.email)} size="large">
                  <Icon className={classes.crossIcon} name="cross" width={12} height={12} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Table.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onUserRoleChange: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
};

export default Table;
