import { isNil } from 'ramda';

import Paper from '@mui/material/Paper';

import ProjectPresenter from 'presenters/ProjectPresenter';
import { format } from 'utils/dateUtils';

import useStyles from './useStyles';

const formatPercentageValue = value => (isNil(value) ? 'N/A' : `${value}%`);

const ProjectInfo = props => {
  const { project } = props;
  const classes = useStyles();

  const feeToDate = ProjectPresenter.formattedUSDFeeToDate(project);
  const currentMargin = formatPercentageValue(ProjectPresenter.currentMargin(project));
  const feeBalance = ProjectPresenter.formattedUSDFeeBalance(project);
  const targetMargin = ProjectPresenter.targetMargin(project);
  const endedAt = format(ProjectPresenter.endedAt(project));
  const variance = formatPercentageValue(ProjectPresenter.variance(project));

  return (
    <Paper classes={{ root: classes.root }}>
      <div className={classes.leftContainer}>
        <div className={classes.label}>Overhead/Fee to Date</div>
        <div className={classes.value}>{feeToDate}</div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.label}>Current Gross Margin</div>
        <div className={classes.value}>{currentMargin}</div>
      </div>
      <div className={classes.leftContainer}>
        <div className={classes.label}>Overhead/Fee Balance</div>
        <div className={classes.value}>{feeBalance}</div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.label}>Target Gross Margin</div>
        <div className={classes.value}>{targetMargin}%</div>
      </div>
      <div className={classes.leftContainer}>
        <div className={classes.label}>Target completion</div>
        <div className={classes.value}>{endedAt}</div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.label}>Variance</div>
        <div className={classes.value}>{variance}</div>
      </div>
    </Paper>
  );
};

ProjectInfo.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default ProjectInfo;
