import PropTypes from 'prop-types';
import clsx from 'clsx';
import { equals } from 'ramda';

import TableCell from '@mui/material/TableCell';

import Icon from 'components/Icon';
import { DIRECTION } from 'constants/sorting';

import useStyles from './useStyles';

const SortableHeadCell = props => {
  const { children, isActive, sortDirection, onClick, disabled, ...restProps } = props;
  const classes = useStyles();

  const isAscDirection = equals(sortDirection, DIRECTION.asc);

  const handleClick = disabled ? () => undefined : onClick;

  return (
    <TableCell
      {...restProps}
      onClick={handleClick}
      className={clsx({
        [classes.headerCell]: !disabled,
        [classes.activeSort]: isActive,
      })}
    >
      <span>{children}</span>
      {isActive && (
        <Icon
          name="arrow"
          width={20}
          height={20}
          className={clsx({
            [classes.sortArrow]: true,
            [classes.ascSortArrow]: isAscDirection,
          })}
        />
      )}
    </TableCell>
  );
};

SortableHeadCell.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  disabled: PropTypes.bool,
};

SortableHeadCell.defaultProps = {
  sortDirection: null,
  disabled: false,
};

export default SortableHeadCell;
