import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(5),
  },
  topContainer: {
    display: 'grid',
    gridTemplateColumns: '49.5% 49.5%',
    columnGap: '1%',
  },
  lineItemsContainer: {
    marginTop: theme.spacing(2),
  },
}));
