import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import UserPresenter from 'presenters/UserPresenter';
import RoundCheckbox from 'components/RoundCheckbox';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const MemberChoiceRows = props => {
  const { members, selectedMembers, onMemberToggle } = props;
  const classes = useStyles();

  return members.map(member => {
    const isMemberSelected = selectedMembers.some(selectedMember => selectedMember.id === member.id);
    const nameOrEmail = UserPresenter.fullName(member) || UserPresenter.email(member);

    return (
      <TableRow className={classes.row} key={member.id}>
        <TableCell className={classes.cell}>
          <Icon name="avatar" width={34} height={34} className={classes.avatarIcon} />
        </TableCell>
        <TableCell className={classes.cell}>
          <div className={classes.name}>{nameOrEmail}</div>
        </TableCell>
        <TableCell className={classes.cell}>
          <RoundCheckbox color="primary" checked={isMemberSelected} onChange={event => onMemberToggle(member, event)} />
        </TableCell>
      </TableRow>
    );
  });
};

MemberChoiceRows.propTypes = {
  members: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  selectedMembers: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  onMemberToggle: PropTypes.func.isRequired,
};

export default MemberChoiceRows;
