import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  title: {
    color: theme.palette.colors.hunterGreen,
    fontSize: theme.typography.pxToRem(12),
    opacity: 0.5,
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
  },
  blockContainer: {
    marginBottom: theme.spacing(4),
  },
}));
