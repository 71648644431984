import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  iconContainer: {
    position: 'relative',
  },
  icon: {
    fill: 'currentColor',
  },
  badgeContainer: {
    position: 'absolute',
    bottom: -2,
    right: -2,
  },
}));
