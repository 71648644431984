import clsx from 'clsx';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import Icon from 'components/Icon';
import Navigation from 'components/Navigation';

import useStyles from './useStyles';

const CollapsedMenu = props => {
  const { onMenuOpen, getNavItems, onDrawerClose } = props;
  const classes = useStyles();

  const getIconClassName = name => {
    if (name === 'gear') {
      return clsx(classes.icon, classes.gearIcon);
    }

    return classes.icon;
  };
  const navItems = getNavItems(getIconClassName);

  return (
    <div className={classes.root}>
      <div className={classes.topMenu}>
        <div className={classes.logoContainer}>
          <Icon name="logo" width={28.42} height={28.42} />
        </div>
        <div className={classes.navigation}>
          <Navigation navItems={navItems} variant="collapsed" onDrawerClose={onDrawerClose} />
        </div>
      </div>
      <div className={classes.userPanel}>
        <Button classes={{ root: classes.menuButton }} onClick={onMenuOpen}>
          <Icon name="avatar" width={25} height={25} className={classes.avatar} />
        </Button>
      </div>
    </div>
  );
};

CollapsedMenu.propTypes = {
  onMenuOpen: PropTypes.func.isRequired,
  getNavItems: PropTypes.func.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default CollapsedMenu;
