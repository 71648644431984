import { useState } from 'react';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import IncomingPaymentPresenter from 'presenters/IncomingPaymentPresenter';
import { useIncomingPayments } from 'store/IncomingPaymentsSlice';
import { useUserLayoutActions } from 'store/UserLayoutSlice';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';
import AssignPayment from 'components/AssignPayment';

import useStyles from './useStyles';

const HistoricalIncomingPaymentsTableRows = props => {
  const { payment } = props;
  const classes = useStyles();
  const { updateIncomingPayment, renameHistoricalPayment, loadCustomerInvoices } = useIncomingPayments();
  const [isEdit, setIsEdit] = useState(false);
  const { setIsLoading } = useUserLayoutActions();
  const { loadProjects } = useBuilderInvoiceCreateActions();

  const handleEditClick = () => setIsEdit(true);
  const handleEditClose = () => setIsEdit(false);

  const handleAssignPayment = async ({ projectId, projectName, customerInvoiceName, customerInvoiceId }) => {
    setIsLoading(true);
    try {
      const paymentId = IncomingPaymentPresenter.id(payment);
      await updateIncomingPayment(paymentId, { projectId, customerInvoiceId });
      renameHistoricalPayment(paymentId, { projectName, customerInvoiceName });
      handleEditClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {IncomingPaymentPresenter.payerName(payment)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {IncomingPaymentPresenter.date(payment)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.cell, classes.amountCell)}>
        {IncomingPaymentPresenter.formattedUSDTotalAmount(payment)}
      </TableCell>
      {isEdit ? (
        <TableCell align="left" className={classes.cell} colSpan={2}>
          <AssignPayment
            loadProjects={loadProjects}
            loadCustomerInvoices={loadCustomerInvoices}
            onAssigned={handleAssignPayment}
            onClose={handleEditClose}
          />
        </TableCell>
      ) : (
        <>
          <TableCell align="left" className={classes.cell}>
            {IncomingPaymentPresenter.projectName(payment)}
          </TableCell>
          <TableCell align="left" className={classes.cell}>
            <div className={classes.projectCell}>
              {IncomingPaymentPresenter.customerInvoiceName(payment)}
              <Button className={classes.editButton} variant="text" color="primary" onClick={handleEditClick}>
                Edit
              </Button>
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

HistoricalIncomingPaymentsTableRows.propTypes = {
  payment: IncomingPaymentPresenter.shape().isRequired,
};

export default HistoricalIncomingPaymentsTableRows;
