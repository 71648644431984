import { CurrentUser } from "store/api/user/types";

export enum OnboardingLayoutVariant {
  builder = "builder",
  subcontractor = "subcontractor",
  customer = "customer",
  roleChoice = "role choice",
}

export interface IOnboardingLayoutProps {
  children: React.ReactNode;
  currentUser: CurrentUser;
  onSignOut: () => void;
  variant: OnboardingLayoutVariant;
}
