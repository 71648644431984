import { SxStyles } from "theme";

const styles: SxStyles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "36px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  titleIcon: {
    fill: (theme) => theme.palette.colors.green,
  },
};

export default styles;
