import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  invoicePaper: {
    padding: theme.spacing(4, 3),
  },
  informationContainer: {
    borderRight: [1, 'solid', theme.palette.divider],
  },
  invoiceSummaryItem: {
    marginBottom: theme.spacing(1),
  },
  invoiceSummaryAmountContainer: {
    textAlign: 'right',
  },
  builderLink: {
    fontSize: theme.typography.pxToRem(16),
    overflowWrap: 'break-word',
  },
  infoIcon: {
    fill: theme.palette.colors.nurseGray,
  },
  paymentTermsText: {
    display: 'flex',
  },
  paymentTermsIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  downloadButton: {
    marginRight: theme.spacing(4),
  },
}));
