import Typography from '@mui/material/Typography';

import AppRoutes from 'routes/AppRoutes';
import Link from 'components/Link';

import useStyles from './useStyles';

const Confirmation = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">Your password has been reset successfully.</Typography>
      <div className={classes.text}>You can now sign in with your new password.</div>
      <div className={classes.signInContainer}>
        <Link classes={{ root: classes.linkRoot }} to={AppRoutes.signInPath()}>
          Sign into Funl
        </Link>
      </div>
    </div>
  );
};

export default Confirmation;
