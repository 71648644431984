import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    cursor: 'pointer',
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: theme.typography.pxToRem(14),
    padding: '11px 0',
  },
  rejected: {
    color: theme.palette.colors.red,
  },
  invoiceNameContainer: {
    display: 'flex',
  },
  invoiceName: {
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(38),
    marginLeft: theme.spacing(1),
  },
}));
