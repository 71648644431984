import { SxStyles } from "theme";

const styles: SxStyles = {
  clickableRow: {
    cursor: "pointer",
  },
  header: {
    "& button": {
      background: "none",
      padding: "0 12px 0 0",
      border: "none",
      fontSize: "12px",
      color: "colors.bud",
      position: "relative",
    },
  },

  headerSortable: {
    "& span": {
      textDecoration: "dotted underline",
      textUnderlineOffset: "3px",
    },
    "& button": {
      cursor: "pointer",
      background: "none",
      padding: "0 12px 0 0",
      border: "none",
      fontSize: "12px",
      color: "colors.bud",
      position: "relative",
      "&: hover": {
        color: "primary.main",
      },
    },
  },
  sortIcon: {
    fontSize: "12px",
    transition: "transform 0.6s cubic-bezier(0.25, 1, 0.5, 1)",
    position: "absolute",
    right: 0,
    top: 0,
  },
  sortIconAsc: {
    transform: "rotate(180deg) translateY(0)",
  },
  sortIconDesc: {
    transform: "rotate(0deg) translateY(2px)",
  },
  tableBody: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },

  root: {
    flexDirection: "column",
    gap: "8px",
  },
  item: {
    boxSizing: "border-box",
    gap: "32px",
    padding: "8px 12px 16px",
    borderBottom: "1px solid",
    borderColor: "colors.lightGray",
  },
  row: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "colors.hunterGreen",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 600,
    opacity: 0.5,
    textTransform: "uppercase",
  },
  value: {
    color: "colors.hunterGreen",
    fontSize: "16px",
    opacity: 0.9,
    lineHeight: "20px",
  },
};

export default styles;
