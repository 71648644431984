import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Icon from 'components/Icon';
import LinearProgress from 'components/LinearProgress';
import useFormik from 'hooks/useFormik';
import useLoading from 'hooks/useLoading';
import { validationSchema, initialValues } from 'forms/InvoiceLineItemForm';

import useStyles from './useStyles';

const InvoiceLineItemCreateForm = props => {
  const { projectId, onLineItemItemCreate, onClose } = props;
  const [apiErrors, setApiErrors] = useState({});
  const classes = useStyles();
  const { func: sendLineItems, isPending } = useLoading(onLineItemItemCreate, {
    isShowSuccessNotification: true,
    successMessage: 'New Item created',
  });
  const { errors, handleChange, values, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ accountCode, categoryName }) => {
      try {
        await sendLineItems({ projectId, accountCode, categoryName });
      } catch (e) {
        setApiErrors(e);
      }
    },
  });

  return (
    <>
      <LinearProgress isVisible={isPending} />
      <div className={classes.container}>
        <Box display="flex" mb={4}>
          <Icon name="gear" width={32} height={32} className={classes.headerIcon} />
          <Typography variant="h3">Create New Item</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            value={values.accountCode}
            className={classes.input}
            label="Account code"
            name="accountCode"
            error={Boolean(errors.accountCode) || Boolean(apiErrors['lineItem.accountCode'])}
            helperText={errors.accountCode || apiErrors['lineItem.accountCode']}
            disabled={isPending}
            onChange={handleChange}
          />
          <TextField
            value={values.categoryName}
            className={classes.input}
            error={Boolean(errors.categoryName)}
            helperText={errors.categoryName}
            label="Category"
            name="categoryName"
            disabled={isPending}
            onChange={handleChange}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button className={classes.cancelButton} variant="contained" onClick={onClose} disabled={isPending}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isPending}>
              Create and add
            </Button>
          </Box>
        </form>
      </div>
    </>
  );
};

InvoiceLineItemCreateForm.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onLineItemItemCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

InvoiceLineItemCreateForm.defaultProps = {
  projectId: null,
};

export default InvoiceLineItemCreateForm;
