import { SortingState } from "@tanstack/react-table";
import { isEmpty } from "ramda";

import { getDirection, getSortingParameter, isSortedByField } from "utils/tableSorting";

export const getBuilderInvoicesSorting = (sorting: SortingState): string => {
  if (isSortedByField(sorting, "state") || isEmpty(sorting)) {
    return ["status_for_builder", getDirection(sorting)].join(" ");
  }

  return getSortingParameter(sorting);
};
