import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(projectId, params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectProjectLineItemsPath(projectId);

    return FetchHelpers.get(path, params);
  },
  create(projectId, params) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectProjectLineItemsPath(projectId);

    return FetchHelpers.post(path, params);
  },
  update(projectId, id, params) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectProjectLineItemPath(projectId, id);

    return FetchHelpers.put(path, params);
  },
  destroy(projectId, id) {
    const path = apiRoutes.siteApiV1BuilderCompanyProjectProjectLineItemPath(projectId, id);

    return FetchHelpers.delete(path);
  },
  massCreate(projectId, params) {
    const path = apiRoutes.massCreateSiteApiV1BuilderCompanyProjectProjectLineItemsPath(projectId);

    return FetchHelpers.post(path, params);
  },
};
