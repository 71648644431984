import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import { FormControlLabel, Radio, CircularProgress, FormControl, RadioGroup } from '@mui/material';

import EarlyPaymentPresenter from 'presenters/EarlyPaymentPresenter';
import useLoading from 'hooks/useLoading';
import { useEarlyPaymentsActions, selectors } from 'store/InvoiceEarlyPaymentsSlice';

import useStyles from './useStyles';

const DEBOUNCE_TIMEOUT = 500;

const EarlyPayments = props => {
  const { formData, onEarlyPaymentIdChange } = props;
  const classes = useStyles();
  const earlyPayments = useSelector(selectors.earlyPayments, equals);
  const { loadEarlyPayments } = useEarlyPaymentsActions();
  const { func: sendLoadEarlyPayments, isPending } = useLoading(loadEarlyPayments);

  const {
    values: { inboundLineItems, earlyPaymentId },
  } = formData;

  const debounceEarlyPaymentsLoad = useDebouncedCallback(async value => {
    sendLoadEarlyPayments(value);
  }, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    if (inboundLineItems.length) {
      debounceEarlyPaymentsLoad.callback({ inboundLineItems });
    }
  }, [inboundLineItems]);

  useEffect(() => {
    if (earlyPayments && !earlyPaymentId) {
      onEarlyPaymentIdChange(EarlyPaymentPresenter.earlyPaymentId(earlyPayments[0]));
    }
  }, [earlyPayments]);

  if (isPending) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (earlyPayments) {
    return (
      <div className={classes.root}>
        <FormControl>
          <RadioGroup
            defaultValue={earlyPayments?.at(0)?.earlyPaymentId}
            name="early-payment-options-radio-buttons-group"
            value={earlyPaymentId}
            onChange={event => onEarlyPaymentIdChange(event.target.value)}
          >
            {earlyPayments?.map(earlyPayment => {
              return (
                <FormControlLabel
                  key={earlyPayment.earlyPaymentId}
                  value={EarlyPaymentPresenter.earlyPaymentId(earlyPayment)}
                  control={<Radio />}
                  label={EarlyPaymentPresenter.labelText(earlyPayment)}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  return null;
};

EarlyPayments.propTypes = {
  formData: PropTypes.shape().isRequired,
  onEarlyPaymentIdChange: PropTypes.func.isRequired,
};

export default EarlyPayments;
