import { SxStyles } from "theme";

const styles: SxStyles = {
  header: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    justifyContent: "space-between",
    gap: {
      xs: "24px",
      md: 0,
    },
    width: "100%",
    marginBottom: "36px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  titleIcon: {
    fill: (theme) => theme.palette.colors.green,
  },
  button: {
    display: {
      xs: "block",
      md: "inline-block",
    },
    width: "100%",
  },
};

export default styles;
