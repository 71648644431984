import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  selectAllContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableContainer: {
    marginTop: theme.spacing(1),
    height: 240,
    overflow: 'auto',
  },
}));
