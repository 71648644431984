import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    email: PropTypes.string,
    fullName: PropTypes.string,
  },
  {},
);
