import { TFunction } from "i18next";
import { FieldError } from "react-hook-form";

export const buildErrorMessage = (t: TFunction, field?: FieldError | FieldError[] | Record<string, FieldError>[]) => {
  if (Array.isArray(field)) {
    if (Object.prototype.toString.call(field[0]) === "[object Object]") {
      return "";
    }
    return field.map((item) => t(`${item.message}`)).join(", ");
  }

  return field && t(`${field.message}`);
};
