import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

export const STATES = {
  active: 'active',
  inactive: 'inactive',
};

export const BUILDER_ONBOARDING_STATES = {
  notStarted: 'builder_onboarding_not_started',
  companyCreated: 'builder_onboarding_company_created',
  lineItemsCreated: 'builder_onboarding_line_items_created',
  completed: 'builder_onboarding_completed',
};

export const SUBCONTRACTOR_ONBOARDING_STATES = {
  notStarted: 'subcontractor_onboarding_not_started',
  companyCreated: 'subcontractor_onboarding_company_created',
  completed: 'subcontractor_onboarding_completed',
};

export const CUSTOMER_ONBOARDING_STATES = {
  notStarted: 'customer_onboarding_not_started',
  projectConfirmed: 'customer_onboarding_project_confirmed',
  completed: 'customer_onboarding_completed',
};

export const COMPANY_ROLES = {
  admin: 'company_admin',
  manager: 'company_manager',
};

export const USER_ROLES = {
  customer: 'customer',
  system_admin: 'system_admin',
  company_owner: 'company_owner',
};

const COMPANY_ROLES_LABELS = {
  [USER_ROLES.company_owner]: 'Owner',
  [COMPANY_ROLES.admin]: 'Admin',
  [COMPANY_ROLES.manager]: 'Manager',
};

export default new Presenter(
  {
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    isCompletedOnboarding: PropTypes.bool,
    builderOnboardingState: PropTypes.oneOf(Object.values(BUILDER_ONBOARDING_STATES)),
    subcontractorOnboardingState: PropTypes.oneOf(Object.values(SUBCONTRACTOR_ONBOARDING_STATES)),
    customerOnboardingState: PropTypes.oneOf(Object.values(CUSTOMER_ONBOARDING_STATES)),
    plainUserOnboardingState: PropTypes.string,
    company: CompanyPresenter.shape(),
    companyRole: PropTypes.string,
    state: PropTypes.string,
    role: PropTypes.string,
  },
  {
    isActive(user) {
      return this.state(user) === STATES.active;
    },
    isCustomer(user) {
      return this.role(user) === USER_ROLES.customer;
    },
    isBuilder(user) {
      const company = this.company(user);

      return CompanyPresenter.isBuilder(company);
    },
    isSubcontractor(user) {
      const company = this.company(user);

      return CompanyPresenter.isSubcontractor(company);
    },
    companyRoleLabel(user) {
      return COMPANY_ROLES_LABELS[this.role(user)] || COMPANY_ROLES_LABELS[this.companyRole(user)];
    },
    isStartedOnboarding(user) {
      return (
        this.builderOnboardingState(user) === BUILDER_ONBOARDING_STATES.notStarted ||
        this.subcontractorOnboardingState(user) === SUBCONTRACTOR_ONBOARDING_STATES.notStarted
      );
    },
    isAdmin(user) {
      return this.role(user) === USER_ROLES.system_admin;
    },
    companyName(user) {
      return CompanyPresenter.name(this.company(user));
    },
    isCustomerOnboardingCompleted(user) {
      return this.customerOnboardingState(user) === CUSTOMER_ONBOARDING_STATES.completed;
    },
    isManager(user) {
      return this.companyRole(user) === COMPANY_ROLES.manager;
    },
    isBuilderManager(user) {
      return this.isBuilder(user) && this.isManager(user);
    },
    isSubcontractorManager(user) {
      return this.isSubcontractor(user) && this.isManager(user);
    },
    isCompanyAdmin(user) {
      return this.companyRole(user) === COMPANY_ROLES.admin;
    },
    isBuilderAdmin(user) {
      return this.isBuilder(user) && this.isCompanyAdmin(user);
    },
    isSubcontractorAdmin(user) {
      return this.isSubcontractor(user) && this.isCompanyAdmin(user);
    },
    isCompanyOwner(user) {
      return this.role(user) === USER_ROLES.company_owner;
    },
  },
);
