import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

import { INVOICES_TABLE_STATUS } from 'constants/invoices';
import ProjectPresenter from 'presenters/ProjectPresenter';
import InvoicePresenter from 'presenters/InvoicePresenter';
import PayableInvoicesTable from 'components/PayableInvoicesTable';
import InvoiceRejectReason from 'components/InvoiceRejectReason';
import AppRoutes from 'routes/AppRoutes';
import { selectors } from 'store/ProjectSlice';
import { usePayableInvoicesActions } from 'store/PayableInvoicesSlice';

import InvoiceResponseDialog from './components/InvoiceResponseDialog';

import useStyles from './useStyles';

const ProjectInvoices = props => {
  const {
    project,
    openInvoices,
    openInvoicesMeta,
    approvedInvoices,
    approvedInvoicesMeta,
    isApprovedInvoicesLoading,
    closedInvoices,
    closedInvoicesMeta,
    isClosedInvoicesLoading,
    isOpenInvoicesLoading,
    loadOpenInvoices,
    loadApprovedInvoices,
    loadClosedInvoices,
    resetInvoices,
    resetResponseDialog,
    resetModalInvoice,
  } = props;
  const classes = useStyles();
  const { open: isModalOpen, invoice: openedInvoice } = useSelector(selectors.modalInvoice);
  const history = useHistory();
  const { loadProjects, loadLineItems, approveInvoice, rejectInvoice } = usePayableInvoicesActions();

  useEffect(() => {
    loadApprovedInvoices(project.id);
    loadClosedInvoices(project.id);
    loadOpenInvoices(project.id);

    return resetInvoices;
  }, []);

  const handleOpenInvoicesLoad = queryParams => {
    loadOpenInvoices(project.id, queryParams);
  };

  const handleApprovedInvoiceLoad = queryParams => {
    loadApprovedInvoices(project.id, queryParams);
  };

  const handleClosedInvoiceLoad = queryParams => {
    loadClosedInvoices(project.id, queryParams);
  };

  const handleInvoiceResponseDialogClose = () => {
    resetResponseDialog();
  };

  const handleSuccessAction = () => {
    resetInvoices();

    loadApprovedInvoices(project.id);
    loadClosedInvoices(project.id);
    loadOpenInvoices(project.id);

    handleInvoiceResponseDialogClose();
  };

  const handleApproveInvoice = async (...params) => {
    await approveInvoice(...params);
    handleSuccessAction();
  };

  const handleRejectInvoice = async (...params) => {
    await rejectInvoice(...params);
    handleSuccessAction();
  };

  const handleEditInvoiceClick = () => {
    const link = AppRoutes.invoiceEditPath(InvoicePresenter.id(openedInvoice));
    history.push(link);
  };

  const handleCloseInvoiceRejectReason = () => {
    resetModalInvoice();
  };

  const handleRowClick = invoice => {
    history.push(AppRoutes.invoicePath(InvoicePresenter.id(invoice)));
  };

  return (
    <div className={classes.root}>
      <div className={classes.invoicesContainer}>
        <PayableInvoicesTable
          loadMore={handleOpenInvoicesLoad}
          variant={INVOICES_TABLE_STATUS.open}
          invoices={openInvoices}
          meta={openInvoicesMeta}
          isLoading={isOpenInvoicesLoading}
          onRowClick={handleRowClick}
          isSortable
        />
      </div>
      <div className={classes.invoicesContainer}>
        <PayableInvoicesTable
          loadMore={handleApprovedInvoiceLoad}
          variant={INVOICES_TABLE_STATUS.approved}
          invoices={approvedInvoices}
          meta={approvedInvoicesMeta}
          isLoading={isApprovedInvoicesLoading}
          onRowClick={handleRowClick}
          isSortable
        />
      </div>
      <div className={classes.invoicesContainer}>
        <PayableInvoicesTable
          loadMore={handleClosedInvoiceLoad}
          variant={INVOICES_TABLE_STATUS.closed}
          invoices={closedInvoices}
          meta={closedInvoicesMeta}
          isLoading={isClosedInvoicesLoading}
          onRowClick={handleRowClick}
          isSortable
        />
      </div>
      <InvoiceResponseDialog
        loadProjects={loadProjects}
        loadLineItems={loadLineItems}
        approveInvoice={handleApproveInvoice}
        rejectInvoice={handleRejectInvoice}
        onClose={handleInvoiceResponseDialogClose}
      />
      <Dialog open={isModalOpen} fullWidth maxWidth="lg" onClose={handleCloseInvoiceRejectReason}>
        {openedInvoice && (
          <InvoiceRejectReason
            invoice={openedInvoice}
            onEditInvoiceClick={handleEditInvoiceClick}
            invoiceName={InvoicePresenter.builderInvoiceTitle(openedInvoice)}
          />
        )}
      </Dialog>
    </div>
  );
};

ProjectInvoices.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  openInvoices: PropTypes.arrayOf(InvoicePresenter.shape()).isRequired,
  openInvoicesMeta: PropTypes.shape().isRequired,
  approvedInvoices: PropTypes.arrayOf(InvoicePresenter.shape()).isRequired,
  approvedInvoicesMeta: PropTypes.shape().isRequired,
  isApprovedInvoicesLoading: PropTypes.bool.isRequired,
  isOpenInvoicesLoading: PropTypes.bool.isRequired,
  loadOpenInvoices: PropTypes.func.isRequired,
  closedInvoices: PropTypes.arrayOf(InvoicePresenter.shape()).isRequired,
  closedInvoicesMeta: PropTypes.shape().isRequired,
  isClosedInvoicesLoading: PropTypes.bool.isRequired,
  loadApprovedInvoices: PropTypes.func.isRequired,
  loadClosedInvoices: PropTypes.func.isRequired,
  resetInvoices: PropTypes.func.isRequired,
  resetResponseDialog: PropTypes.func.isRequired,
  resetModalInvoice: PropTypes.func.isRequired,
};

export default ProjectInvoices;
