import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import IncomingPaymentsRepository from 'repositories/builder/company/IncomingPaymentsRepository';
import CustomerInvoicesRepository from 'repositories/builder/company/project/CustomerInvoicesRepository';
import { CUSTOMER_INVOICE_STATES } from 'presenters/CustomerInvoicePresenter';

const MAX_PER_PAGE = 5;

const IncomingPaymentsState = {
  paid: 'paid',
};

const initialState = {
  unassigned: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  unreadIncomingPaymentsCount: 0,
  historical: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
};

const invoicesSlice = createSlice({
  name: 'incomingPayments',
  initialState,
  reducers: {
    loadUnassignedIncomingPaymentsSuccess(state, { payload }) {
      state.unassigned.data = concat(state.unassigned.data, payload.incomingPaymentTransactions);
      state.unassigned.meta = payload.meta;
    },
    loadHistoricalIncomingPaymentsSuccess(state, { payload }) {
      state.historical.data = concat(state.historical.data, payload.incomingPaymentTransactions);
      state.historical.meta = payload.meta;
    },
    resetUnassignedIncomingPayments(state) {
      state.unassigned = initialState.unassigned;
    },
    resetHistoricalIncomingPayments(state) {
      state.historical = initialState.historical;
    },
    resetIncomingPayments() {
      return initialState;
    },
    moveToHistorical(state, { payload }) {
      const paymentIndex = state.unassigned.data.findIndex(unassignedPayment => unassignedPayment.id === payload.id);
      const payment = state.unassigned.data[paymentIndex];
      const historicalPayment = {
        ...payment,
        project: { name: payload.projectName },
        customerInvoice: { name: payload.customerInvoiceName },
      };
      state.historical.data = [historicalPayment, ...state.historical.data];
      state.unassigned.data.splice(paymentIndex, 1);
    },
    renameHistoricalPayment(state, { payload }) {
      const paymentIndex = state.historical.data.findIndex(historicalPayment => historicalPayment.id === payload.id);
      const renamedPayment = {
        ...state.historical.data[paymentIndex],
        project: { name: payload.projectName },
        customerInvoice: { name: payload.customerInvoiceName },
      };
      state.historical.data[paymentIndex] = renamedPayment;
    },
    resetUnreadIncomingPaymentsCount(state) {
      state.unreadIncomingPaymentsCount = initialState.totalUnreadIncomingPayments;
    },
    setUnreadIncomingPaymentsCount(state, { payload }) {
      state.unreadIncomingPaymentsCount = payload;
    },
  },
});

export const { actions } = invoicesSlice;
export default invoicesSlice.reducer;

export const useIncomingPayments = () => {
  const dispatch = useDispatch();

  const loadUnassignedIncomingPayments = async (page = 1) => {
    const params = {
      q: { projectIdNull: true, s: 'created_at desc', stateEq: IncomingPaymentsState.paid },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await IncomingPaymentsRepository.index(params);
    dispatch(actions.loadUnassignedIncomingPaymentsSuccess(data));
    return data;
  };

  const resetUnassignedIncomingPayments = () => dispatch(actions.resetUnassignedIncomingPayments());

  const resetHistoricalIncomingPayments = () => dispatch(actions.resetHistoricalIncomingPayments());

  const updateIncomingPayment = async (id, { projectId, customerInvoiceId }) => {
    await IncomingPaymentsRepository.update(id, { projectId, customerInvoiceId });
  };

  const moveToHistorical = (id, { projectName, customerInvoiceName }) => {
    dispatch(actions.moveToHistorical({ id, projectName, customerInvoiceName }));
  };

  const renameHistoricalPayment = async (id, { projectName, customerInvoiceName }) => {
    dispatch(actions.renameHistoricalPayment({ id, projectName, customerInvoiceName }));
  };

  const loadHistoricalIncomingPayments = async (page = 1) => {
    const params = {
      q: { projectIdNotNull: true, s: 'created_at desc', stateEq: IncomingPaymentsState.paid },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await IncomingPaymentsRepository.index(params);
    dispatch(actions.loadHistoricalIncomingPaymentsSuccess(data));
    return data;
  };

  const loadUnreadIncomingPaymentsCount = async () => {
    const {
      data: { incomingPaymentsCount },
    } = await IncomingPaymentsRepository.unreadTotalCount();
    dispatch(actions.setUnreadIncomingPaymentsCount(incomingPaymentsCount));
  };

  const resetUnreadIncomingPayments = () => {
    dispatch(actions.resetUnreadIncomingPaymentsCount());
  };

  const readAll = () => {
    resetUnreadIncomingPayments();
    IncomingPaymentsRepository.readAll();
  };

  const loadCustomerInvoices = async ({ name, projectId }, customHeaders, cancelToken) => {
    const params = {
      q: { nameCont: name, stateIn: [CUSTOMER_INVOICE_STATES.pending, CUSTOMER_INVOICE_STATES.approved] },
    };
    const { data } = await CustomerInvoicesRepository.index(projectId, params, customHeaders, cancelToken);
    return data;
  };

  return {
    loadUnassignedIncomingPayments,
    resetUnassignedIncomingPayments,
    updateIncomingPayment,
    moveToHistorical,
    loadHistoricalIncomingPayments,
    resetHistoricalIncomingPayments,
    renameHistoricalPayment,
    loadUnreadIncomingPaymentsCount,
    readAll,
    loadCustomerInvoices,
  };
};

export const selectors = {
  unassigned: state => state.IncomingPaymentsSlice.unassigned,
  historical: state => state.IncomingPaymentsSlice.historical,
  unreadIncomingPaymentsCount: state => state.IncomingPaymentsSlice.unreadIncomingPaymentsCount,
};
