import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: 24,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
  title: {
    marginLeft: 16,
  },
  name: {
    marginTop: 40,

    [theme.breakpoints.down('md')]: {
      color: theme.palette.colors.hunterGreen,
    },
  },
  label: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.gunsmokeGray,
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  labelAlignRight: {
    textAlign: 'right',
  },
  generalInformation: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    gap: theme.spacing(1),
  },
  notesContainer: {
    marginTop: 24,
  },
  tableContainer: {
    marginTop: 24,
  },
  summaryContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  summaryValue: {
    marginLeft: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    gap: theme.spacing(1),
  },
  confirmButton: {
    marginLeft: theme.spacing(1),
  },
  noteText: {
    color: theme.palette.colors.gunsmokeGray,
    marginTop: theme.spacing(2),
  },
}));
