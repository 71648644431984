import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, path } from 'ramda';
import { useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { initialValues, validationSchema, formPaths } from 'forms/SignUpForm';
import AppRoutes from 'routes/AppRoutes';
import PasswordTextField from 'components/PasswordTextField';
import Link from 'components/Link';
import useFormik from 'hooks/useFormik';

import useStyles from './useStyles';

const Form = props => {
  const { onFormSubmit } = props;
  const classes = useStyles();
  const { search } = useLocation();
  const [apiErrors, setApiErrors] = useState({});
  const { touched, errors, handleChange, values, isSubmitting, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async valuesToSubmit => {
      try {
        await onFormSubmit({ user: valuesToSubmit });
      } catch (e) {
        setApiErrors(e);
      }
    },
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get('email');
    if (email) {
      setFieldValue(formPaths.email, email);
    }
  }, []);

  const getErrors = field => {
    if (isNil(apiErrors[field])) {
      return touched[field] && errors[field];
    }

    return path([field, 0], apiErrors);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2">Sign Up to Funl</Typography>
      <div className={classes.signInContainer}>
        Already have an account?
        <div className={classes.signIn}>
          <Link to={AppRoutes.signInPath()}>Sign In</Link>
        </div>
      </div>
      <div className={classes.formContainer}>
        <form onSubmit={handleSubmit}>
          <TextField
            classes={{ root: classes.inputRoot }}
            name="fullName"
            label="Full Name"
            onChange={handleChange}
            value={values.fullName}
            error={Boolean(getErrors('fullName'))}
            helperText={getErrors('fullName')}
          />
          <TextField
            className={classes.inputRoot}
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            error={Boolean(getErrors('email'))}
            helperText={getErrors('email')}
          />
          <PasswordTextField
            name="password"
            label="Password"
            className={classes.inputRoot}
            onChange={handleChange}
            value={values.password}
            error={Boolean(getErrors('password'))}
            helperText={getErrors('password')}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submitRoot}
            disabled={isSubmitting}
          >
            Create Account
          </Button>
          <div className={classes.terms}>
            By clicking Create Account you are agreeing
            <br />
            to the{' '}
            <Link to="/" className={classes.termsLink}>
              Terms and Conditions
            </Link>{' '}
            and{' '}
            <Link to="/" className={classes.termsLink}>
              Privacy Policy
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

Form.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

export default Form;
