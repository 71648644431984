import { makeStyles } from '@mui/styles';

export default makeStyles(theme => {
  return {
    root: {
      width: 584,
    },
    paperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '28px 24px 0 24px',
    },
    titleContainer: {
      display: 'flex',
    },
    gearIcon: {
      fill: theme.palette.colors.green,
      margin: '4px 10px 0px 4px',
    },
    closeButton: {
      marginTop: -20,
      marginRight: theme.spacing(1),
    },
    closeIcon: {
      fill: theme.palette.colors.gunsmokeGray,
    },
    tableContainer: {
      marginTop: 20,
      maxHeight: 344,
      minHeight: 100,
      overflow: 'auto',
      padding: theme.spacing(0, 3),
    },
    selectedCount: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(37),
    },
    selectAllContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3),
    },
    cancelButton: {
      padding: '12px 32px',
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.colors.smokeWhite,
      color: theme.palette.colors.gunsmokeGray,
      boxShadow: 'none',
      fontFamily: 'Poppins',
      fontWeight: 600,
      '&:hover, &:active, &:focus': {
        backgroundColor: theme.palette.colors.lightGray,
        boxShadow: 'none',
      },
    },
    addButton: {
      padding: '12px 32px',
    },
  };
});
