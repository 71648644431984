import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}) {
    const path = apiRoutes.siteApiV1CustomerCustomerInvoicesPath();

    return FetchHelpers.get(path, params);
  },
  approve(id, params) {
    const path = apiRoutes.approveSiteApiV1CustomerCustomerInvoicePath({ id });

    return FetchHelpers.put(path, params);
  },
};
