import { useDispatch } from 'react-redux';

import { apiContainer } from 'store';

import BuildersRepository from 'repositories/subcontractor/BuildersRepository';
import InvoicesRepository from 'repositories/subcontractor/company/InvoicesRepository';
import { formDataToApi, apiErrorsToForm } from 'forms/Subcontractor/InvoiceForm';

export const useSubcontractorInvoiceCreateActions = () => {
  const dispatch = useDispatch();

  const loadBuilders = nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont => {
    const params = { q: { nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont } };
    return BuildersRepository.index(params);
  };

  const createInvoice = async params => {
    const requestParams = formDataToApi(params);
    try {
      const data = await InvoicesRepository.create(requestParams);
      dispatch(apiContainer.util.invalidateTags([{ type: 'SubcontractorCompanyInvoice', id: 'LIST' }]));
      return data;
    } catch (error) {
      throw apiErrorsToForm(error);
    }
  };

  const updateInvoice = ({ id, params }, cancelToken) => {
    const requestParams = formDataToApi(params);
    return InvoicesRepository.update(id, { params: requestParams }, cancelToken);
  };

  return {
    loadBuilders,
    createInvoice,
    updateInvoice,
  };
};
