import { isNil } from "ramda";
import * as yup from "yup";

import { InvoicePaymentKind } from "domain/invoice/enums";

export interface IInvoicePaymentTermsFormSubmitData {
  invoice: {
    paymentKind: InvoicePaymentKind;
    termsOfService?: string;
    invoiceEarlyPaymentAttributes?: {
      earlyPaymentId: number;
    };
  };
}

export const validationSchema = yup.object({
  paymentKind: yup
    .mixed<InvoicePaymentKind>()
    .oneOf(Object.values(InvoicePaymentKind))
    .default(InvoicePaymentKind.default),
  termsOfService: yup
    .boolean()
    .when("paymentKind", {
      is: InvoicePaymentKind.earlyPayment,
      // eslint-disable-next-line unicorn/no-thenable
      then: (schema) => schema.required(),
    })
    .default(false),
  earlyPaymentId: yup
    .number()
    .nullable()
    .when("paymentKind", {
      is: InvoicePaymentKind.earlyPayment,
      // eslint-disable-next-line unicorn/no-thenable
      then: (schema) => schema.required().nonNullable(),
    })
    .default(null),
});

export const defaultValues = validationSchema.getDefault();

export type InvoicePaymentTermsFormData = yup.InferType<typeof validationSchema>;

export const serializeFormData = (data: InvoicePaymentTermsFormData): IInvoicePaymentTermsFormSubmitData => {
  if (isNil(data.earlyPaymentId)) {
    return {
      invoice: {
        paymentKind: data.paymentKind,
      },
    };
  }

  return {
    invoice: {
      termsOfService: data.termsOfService.toString(),
      paymentKind: data.paymentKind,
      invoiceEarlyPaymentAttributes: {
        earlyPaymentId: data.earlyPaymentId,
      },
    },
  };
};
