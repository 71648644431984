import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiListItemType {
  defaultProps: ComponentsProps["MuiListItem"];
  styleOverrides: ComponentsOverrides["MuiListItem"];
}

const MuiListItem: IMuiListItemType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: baseTheme.palette.colors.chileanHelthGreen,
        color: baseTheme.palette.colors.green,
      },
    },
  },
};

export default MuiListItem;
