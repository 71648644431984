import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  status: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  openStatus: {
    fontWeight: 600,
    textTransform: 'capitalize',
    color: theme.palette.colors.orange,
  },
  closeStatus: {
    fontWeight: 600,
    textTransform: 'capitalize',
    color: theme.palette.colors.green,
  },
}));
