import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DeleteButton from 'components/DeleteButton';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const InvoiceAttachments = props => {
  const {
    formData: {
      values: { attachmentName },
      setFieldValue,
    },
  } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => setIsDialogOpen(true);

  const handleDialogClose = () => setIsDialogOpen(false);
  const handleRemove = () => {
    setFieldValue('attachment', null);
    setFieldValue('attachmentName', null);
    handleDialogClose();
  };

  return (
    <>
      {attachmentName && (
        <Fade in={Boolean(attachmentName)}>
          <div className={classes.attachmentsContainer}>
            <div>
              <Typography className={classes.tittle}>Attachments</Typography>
            </div>
            <div className={classes.attachRow}>
              <Button variant="text" color="primary">
                {attachmentName}
              </Button>
              <DeleteButton onClick={handleDialogOpen} />
            </div>
          </div>
        </Fade>
      )}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="lg">
        <div className={classes.dialogContainer}>
          <div className={classes.dialogTitleContainer}>
            <Icon name="gear" className={classes.icon} />
            <Typography variant="h3">Remove File?</Typography>
          </div>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={handleRemove} color="primary" autoFocus variant="contained">
              Remove
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

InvoiceAttachments.propTypes = {
  formData: PropTypes.shape({
    values: PropTypes.shape({
      attachmentName: PropTypes.string,
    }),
    setFieldValue: PropTypes.func,
  }).isRequired,
};

export default InvoiceAttachments;
