import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiCssBaselineType {
  defaultProps: ComponentsProps["MuiCssBaseline"];
  styleOverrides: ComponentsOverrides["MuiCssBaseline"];
}

const MuiCssBaseline: IMuiCssBaselineType = {
  defaultProps: {},
  styleOverrides: {
    body: {
      backgroundColor: baseTheme.palette.colors.lightGray,
    },
  },
};

export default MuiCssBaseline;
