import { takeLast } from 'ramda';

import logo from './images/logo.svg';
import checkMark from './images/checkMark.svg';
import hand from './images/hand.svg';
import containedCheckMark from './images/containedCheckMark.svg';
import cross from './images/cross.svg';
import bin from './images/bin.svg';
import arrow from './images/arrow.svg';
import dashboard from './images/dashboard.svg';
import couple from './images/couple.svg';
import file from './images/file.svg';
import folder from './images/folder.svg';
import gear from './images/gear.svg';
import avatar from './images/avatar.svg';
import containedPlus from './images/containedPlus.svg';
import outlinedPlus from './images/outlinedPlus.svg';
import checkbox from './images/checkbox.svg';
import uncheckedCheckbox from './images/uncheckedCheckbox.svg';
import help from './images/help.svg';
import calendar from './images/calendar.svg';
import radio from './images/radio.svg';
import uncheckedRadio from './images/uncheckedRadio.svg';
import roundCheckbox from './images/roundCheckbox.svg';
import circle from './images/circle.svg';
import info from './images/info.svg';
import activeProject from './images/activeProject.svg';
import prospectProject from './images/prospectProject.svg';
import completedProject from './images/completedProject.svg';
import notice from './images/notice.svg';
import sandglass from './images/sandglass.svg';
import invitation from './images/invitation.svg';
import outlinedCheckMark from './images/outlinedCheckMark.svg';
import logout from './images/logout.svg';
import tools from './images/tools.svg';
import briefcase from './images/briefcase.svg';
import draft from './images/draft.svg';
import containedInfo from './images/containedInfo.svg';
import documentOpen from './images/documentOpen.svg';
import documentClosed from './images/documentClosed.svg';
import documentRejected from './images/documentRejected.svg';
import documentApproved from './images/documentApproved.svg';
import documentCanceled from './images/documentRejected.svg';
import picture from './images/picture.svg';
import nameLogo from './images/nameLogo.svg';
import containedCross from './images/containedCross.svg';
import documentPendingApprovalBySystem from './images/documentPendingApprovalBySystem.svg';
import documentAwaitingApproval from './images/documentAwaitingApproval.svg';
import add from './images/add.svg';
import textLogo from './images/textLogo.svg';
import arrowDown from './images/arrowDown.svg';
import descriptionIcon from './images/descriptionIcon.svg';
import creditCard from './images/creditCard.svg';
import bank from './images/bank.svg';
import bankCheck from './images/bankCheck.svg';
import incomingPayments from './images/incomingPayments.svg';
import customerAvatar from './images/customerAvatar.svg';
import addDisabled from './images/addDisabled.svg';

const getIconDimensions = viewbox => {
  const [width, height] = takeLast(2, viewbox.split(' '));

  return {
    width,
    height,
  };
};

const getIconAttributes = name => {
  const { width, height } = getIconDimensions(name.viewBox);

  return {
    src: name,
    viewBox: name.viewBox,
    id: `#${name.id}`,
    width,
    height,
  };
};

export default {
  logo: getIconAttributes(logo),
  checkMark: getIconAttributes(checkMark),
  hand: getIconAttributes(hand),
  containedCheckMark: getIconAttributes(containedCheckMark),
  cross: getIconAttributes(cross),
  bin: getIconAttributes(bin),
  arrow: getIconAttributes(arrow),
  dashboard: getIconAttributes(dashboard),
  couple: getIconAttributes(couple),
  file: getIconAttributes(file),
  folder: getIconAttributes(folder),
  gear: getIconAttributes(gear),
  avatar: getIconAttributes(avatar),
  containedPlus: getIconAttributes(containedPlus),
  outlinedPlus: getIconAttributes(outlinedPlus),
  checkbox: getIconAttributes(checkbox),
  uncheckedCheckbox: getIconAttributes(uncheckedCheckbox),
  help: getIconAttributes(help),
  calendar: getIconAttributes(calendar),
  radio: getIconAttributes(radio),
  uncheckedRadio: getIconAttributes(uncheckedRadio),
  roundCheckbox: getIconAttributes(roundCheckbox),
  circle: getIconAttributes(circle),
  info: getIconAttributes(info),
  activeProject: getIconAttributes(activeProject),
  prospectProject: getIconAttributes(prospectProject),
  completedProject: getIconAttributes(completedProject),
  notice: getIconAttributes(notice),
  sandglass: getIconAttributes(sandglass),
  invitation: getIconAttributes(invitation),
  outlinedCheckMark: getIconAttributes(outlinedCheckMark),
  logout: getIconAttributes(logout),
  tools: getIconAttributes(tools),
  briefcase: getIconAttributes(briefcase),
  draft: getIconAttributes(draft),
  containedInfo: getIconAttributes(containedInfo),
  documentOpen: getIconAttributes(documentOpen),
  documentClosed: getIconAttributes(documentClosed),
  documentRejected: getIconAttributes(documentRejected),
  documentApproved: getIconAttributes(documentApproved),
  documentCanceled: getIconAttributes(documentCanceled),
  picture: getIconAttributes(picture),
  nameLogo: getIconAttributes(nameLogo),
  containedCross: getIconAttributes(containedCross),
  documentPendingApprovalBySystem: getIconAttributes(documentPendingApprovalBySystem),
  documentAwaitingApproval: getIconAttributes(documentAwaitingApproval),
  add: getIconAttributes(add),
  textLogo: getIconAttributes(textLogo),
  arrowDown: getIconAttributes(arrowDown),
  descriptionIcon: getIconAttributes(descriptionIcon),
  creditCard: getIconAttributes(creditCard),
  bank: getIconAttributes(bank),
  bankCheck: getIconAttributes(bankCheck),
  incomingPayments: getIconAttributes(incomingPayments),
  customerAvatar: getIconAttributes(customerAvatar),
  addDisabled: getIconAttributes(addDisabled),
};
