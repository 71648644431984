import CompaniesRepository from 'repositories/subcontractor/onboarding/CompaniesRepository';
import MembersRepository from 'repositories/subcontractor/company/onboarding/MembersRepository';

export const useSubcontractorOnboardingActions = () => {
  const createCompany = params => {
    return CompaniesRepository.create(params);
  };

  const createUsers = params => {
    return MembersRepository.massCreate(params);
  };

  const checkUserAvailability = params => {
    return MembersRepository.checkAvailability(params);
  };

  return {
    createCompany,
    createUsers,
    checkUserAvailability,
  };
};
