import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: 528,
    padding: '28px 24px',
  },
  titleContainer: {
    display: 'flex',
  },
  invitationIcon: {
    marginTop: 3,
    fill: theme.palette.colors.green,
  },
  title: {
    marginLeft: 12,
  },
  formContainer: {
    marginTop: 36,
  },
  addButton: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(13),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
  submitButton: {
    marginLeft: 12,
  },
  removeButton: {
    width: '100%',
    height: '100%',
    textTransform: 'none',
    marginTop: theme.spacing(1),
  },
  fields: {
    marginBottom: theme.spacing(2),
  },
  removeButtonIcon: {
    fill: theme.palette.colors.red,
  },
  deleteButtonContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));
