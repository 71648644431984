import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    margin: '32px 24px',
  },
  headerIcon: {
    marginRight: theme.spacing(1),
    fill: theme.palette.colors.green,
  },
  input: {
    marginBottom: theme.spacing(4),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}));
