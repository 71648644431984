import StraightIcon from "@mui/icons-material/Straight";
import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Grid } from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import muiSx from "mui-sx";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";
import Span from "components/common/Span";

import useBreakpoints from "hooks/useBreakpoints";

import styles from "./styles";
import { ITableLoadMoreProps } from "./types";

// eslint-disable-next-line react/function-component-definition
export default function TableLoadMore<T>(props: ITableLoadMoreProps<T>) {
  const { table, onRowClick, onLoadMore, loading, meta } = props;
  const { t } = useTranslation("components", { keyPrefix: "tableLoadMore" });
  const { screenWiderThan } = useBreakpoints();

  const handleRowClick = (entity: T) => {
    if (!isNil(onRowClick)) {
      onRowClick(entity);
    }
  };

  if (!screenWiderThan.md) {
    return (
      <Grid container sx={styles.root}>
        {table.getRowModel().rows.map((row) => {
          return (
            <>
              {table.getHeaderGroups().map((headerGroup) => (
                <Grid
                  item
                  container
                  direction="column"
                  key={row.id}
                  sx={muiSx(table.options.meta?.getRowSx(row) ?? {}, styles.item)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Div key={cell.id} sx={styles.row}>
                      {headerGroup.headers.map((header) => {
                        const cellEqualsToHeader = cell.column.columnDef.header === header.column.columnDef.header;
                        return (
                          cellEqualsToHeader && (
                            <Div key={headerGroup.id}>
                              <Span key={header.id} sx={styles.label}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </Span>
                              <Div sx={styles.value}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Div>
                            </Div>
                          )
                        );
                      })}
                    </Div>
                  ))}
                  <Button variant="outlined" color="secondary" onClick={() => handleRowClick(row.original)} fullWidth>
                    See details
                  </Button>
                </Grid>
              ))}
            </>
          );
        })}
        {loading && <CircularProgress />}
        {!loading && table.getRowModel().rows.length === 0 && <Span>{t("noItems")}</Span>}
        {meta?.nextPage && !loading && (
          <Button fullWidth onClick={() => onLoadMore()}>
            {t("loadMore")}
          </Button>
        )}
      </Grid>
    );
  }

  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableCell key={header.id} align={header.column.columnDef?.meta?.align ?? "left"}>
                    <Div
                      sx={muiSx(styles.header, { condition: header.column.getCanSort(), sx: styles.headerSortable })}
                    >
                      <button
                        type="button"
                        {...{ onClick: header.column.getToggleSortingHandler() }}
                        disabled={loading}
                      >
                        <Span>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Span>
                        {header.column.getIsSorted() && (
                          <Div
                            sx={muiSx(
                              styles.sortIcon,
                              { condition: header.column.getIsSorted() === "asc", sx: styles.sortIconAsc },
                              { condition: header.column.getIsSorted() === "desc", sx: styles.sortIconDesc },
                            )}
                          >
                            <StraightIcon fontSize="inherit" />
                          </Div>
                        )}
                      </button>
                    </Div>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableHead>
      <TableBody sx={styles.tableBody}>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            hover
            key={row.id}
            onClick={() => handleRowClick(row.original)}
            sx={muiSx(table.options.meta?.getRowSx(row) ?? {}, {
              condition: !isNil(onRowClick),
              sx: styles.clickableRow,
            })}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                align={cell.column.columnDef?.meta?.align ?? "left"}
                width={cell.column.columnDef?.meta?.width ?? ""}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
        {loading && (
          <TableRow>
            <TableCell colSpan={table.getAllColumns().length} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {!loading && table.getRowModel().rows.length === 0 && (
          <TableRow>
            <TableCell colSpan={table.getAllColumns().length} align="center">
              {t("noItems")}
            </TableCell>
          </TableRow>
        )}
        {meta?.nextPage && !loading && (
          <TableRow>
            <TableCell colSpan={table.getAllColumns().length} align="center">
              <Button fullWidth onClick={() => onLoadMore()}>
                {t("loadMore")}
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
