import { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LineItemPresenter, { STATES } from 'presenters/LineItemPresenter';
import useLoading from 'hooks/useLoading';
import { isBlank } from 'utils/storeUtils';
import { API_ERROR_TEXTS } from 'constants/errorText';
import AutocompletePick from 'components/AutocompletePick';
import DeleteButton from 'components/DeleteButton';
import MoneyTextField from 'components/MoneyTextField';
import AutocompletePaperButton from 'components/AutocompletePaperButton';

import useStyles from './useStyles';

const PayableInvoiceLineItem = props => {
  const { lineItem, index, formData, isDeletionDisabled, onLineItemLoad, onLineItemRemove, onInvoiceLineItemFormOpen } =
    props;
  const { errors, handleChange, values, setFieldValue } = formData;
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const { func: loadLineItems, isPending } = useLoading(onLineItemLoad, {
    isShowFailedNotification: true,
    failedMessage: API_ERROR_TEXTS.unexpectedError,
  });

  const getFieldName = name => `inboundLineItems[${index}][${name}]`;
  const getFieldError = name => Boolean(errors.inboundLineItems?.[index]?.[name]);
  const getFieldHelperText = name => errors.inboundLineItems?.[index]?.[name];

  const handleLineItemsLoad = async nameCont => {
    const { projectId, inboundLineItems } = values;
    if (projectId) {
      const excludedIds = inboundLineItems.map(({ id }) => id);
      const state = STATES.active;
      const { lineItems } = await loadLineItems({ nameCont, excludedIds, state });
      setOptions(lineItems);
    }
  };

  const handleFieldValueSet = (_, option) => {
    setFieldValue(`inboundLineItems[${index}]`, { ...option, amount: lineItem.amount, key: option.id });
  };

  const handleCreateLineItemClick = () => {
    onInvoiceLineItemFormOpen(index);
  };

  const handleAutocompleClear = () => {
    const newLineItem = { key: nanoid(), accountCode: '', categoryName: '', amount: '', id: '' };
    setFieldValue(`inboundLineItems[${index}]`, { ...newLineItem });
  };

  const isAutocompleteDisabled = isBlank(values.projectId);
  const displayedValueSelectedOption = isBlank(lineItem.id) ? '' : `${lineItem.accountCode} ${lineItem.categoryName}`;

  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <AutocompletePick
          disabled={isAutocompleteDisabled}
          displayedValueSelectedOption={displayedValueSelectedOption}
          fieldName={getFieldName('categoryName')}
          label="Select line item"
          isLoading={isPending}
          options={options}
          isOnFieldValueSetWrap={false}
          error={getFieldError('id')}
          helperText={getFieldHelperText('id')}
          acceptedSortingKeys={['accountCode', 'categoryName']}
          classes={{ listbox: classes.lineItemOptions }}
          getOptionLabel={option => option.categoryName}
          onClear={handleAutocompleClear}
          renderOptions={(optionProps, option) => (
            <li className={classes.option} {...optionProps}>
              <Typography className={classes.categoryName}>
                {`${LineItemPresenter.accountCode(option)} ${LineItemPresenter.categoryName(option)}`}
              </Typography>
            </li>
          )}
          PaperComponent={paperProps => (
            <AutocompletePaperButton text="Create new item" onClick={handleCreateLineItemClick} {...paperProps} />
          )}
          onFieldValueSet={handleFieldValueSet}
          onLoad={handleLineItemsLoad}
        />
      </Grid>
      <Grid item xs={4} lg={4}>
        <MoneyTextField
          className={classes.moneyTextField}
          name={getFieldName('amount')}
          id={getFieldName('amount')}
          label="$ Amount"
          onChange={handleChange}
          value={lineItem.amount}
          error={getFieldError('amount')}
          helperText={getFieldHelperText('amount')}
        />
      </Grid>
      <Grid item xs={1} lg={1}>
        <div className={classes.deleteButtonContainer}>
          <DeleteButton
            isDisabled={isDeletionDisabled}
            className={classes.deleteButton}
            onClick={() => onLineItemRemove(lineItem)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

PayableInvoiceLineItem.propTypes = {
  lineItem: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  formData: PropTypes.shape().isRequired,
  isDeletionDisabled: PropTypes.bool,
  onLineItemRemove: PropTypes.func.isRequired,
  onLineItemLoad: PropTypes.func.isRequired,
  onInvoiceLineItemFormOpen: PropTypes.func.isRequired,
};

PayableInvoiceLineItem.defaultProps = {
  isDeletionDisabled: false,
};

export default PayableInvoiceLineItem;
