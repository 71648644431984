import { useState } from 'react';

import GuestLayout from 'layouts/GuestLayout';
import { useAuthActions } from 'store/AuthSlice';

import Form from './components/Form';
import Confirmation from './components/Confirmation';

const ForgotPassword = () => {
  const { sendPasswordResetEmail } = useAuthActions();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);

  const handleFormSubmit = async params => {
    await sendPasswordResetEmail(params);
    setIsEmailSent(true);
    setSubmittedEmail(params.passwordReset.email);
  };

  const renderContent = () =>
    isEmailSent ? (
      <Confirmation email={submittedEmail} onEmailResend={sendPasswordResetEmail} />
    ) : (
      <Form onFormSubmit={handleFormSubmit} />
    );

  return <GuestLayout>{renderContent()}</GuestLayout>;
};

export default ForgotPassword;
