import { Route, Switch, Redirect } from 'react-router-dom';

import AppRoutes from 'routes/AppRoutes';
import UserLayout from 'layouts/UserLayout';
import UserPresenter from 'presenters/UserPresenter';
import Payments from 'containers/Customer/Payments';

const CustomerSwitch = props => {
  const { currentUser } = props;

  return (
    <UserLayout currentUser={currentUser}>
      <Switch>
        <Route path={AppRoutes.customerPaymentsPath()} exact component={Payments} />

        <Redirect to={AppRoutes.customerPaymentsPath()} />
      </Switch>
    </UserLayout>
  );
};

CustomerSwitch.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
};

export default CustomerSwitch;
