import { createTheme } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/system";

import baseTheme from "./baseTheme";
import components from "./components";

const theme = createTheme(baseTheme, {
  components,
});

export default theme;

export type SxStyles = Record<string, SxProps<Theme>>;
