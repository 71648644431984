import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: 12,
    padding: 24,
    minHeight: 372,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonRoot: {
    width: '100%',
  },
  lineItemContainer: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  lineItemsTotalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingTop: 24,
  },
  lineItemsTotalLabel: {
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    color: theme.palette.colors.deltaGray,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  lineItemsTotal: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: 20,
    lineHeight: '28px',
  },
}));
