import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TotalInformationPresenter from 'presenters/TotalInformationPresenter';

import useStyles from './useStyles';

const EMPTY_CELL_COUNT = 4;
const EMPTY_ARRAY = Array.from(new Array(EMPTY_CELL_COUNT));

const AdminProjectTotalRow = props => {
  const { totalInformation } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        <span className={classes.title}>Total</span>
      </TableCell>
      {EMPTY_ARRAY.map((_, index) => (
        <TableCell key={index} className={classes.cell} />
      ))}
      <TableCell align="right" className={classes.cell}>
        {TotalInformationPresenter.formattedUSDTotalContractValue(totalInformation)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TotalInformationPresenter.formattedUSDTotalThirdPartyExpenses(totalInformation)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TotalInformationPresenter.formattedUSDTotalOverheadExpenses(totalInformation)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TotalInformationPresenter.formattedUSDTotalProfit(totalInformation)}
      </TableCell>
    </TableRow>
  );
};

AdminProjectTotalRow.propTypes = {
  totalInformation: TotalInformationPresenter.shape().isRequired,
};

export default AdminProjectTotalRow;
