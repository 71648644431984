import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';
import { useDeleteSessionMutation } from 'store/api/session/apiSlice';
import UserPresenter, { CUSTOMER_ONBOARDING_STATES } from 'presenters/UserPresenter';
import OnboardingLayout from 'layouts/OnboardingLayout';

import ProjectConfirm from './components/ProjectConfirm';
import PaymentMethodSelect from './components/PaymentMethodSelect';

const Onboarding = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [signOut] = useDeleteSessionMutation();

  const currentState = UserPresenter.customerOnboardingState(currentUser);

  return (
    <OnboardingLayout onSignOut={signOut} currentUser={currentUser} variant="customer">
      {currentState === CUSTOMER_ONBOARDING_STATES.notStarted && <ProjectConfirm />}
      {currentState === CUSTOMER_ONBOARDING_STATES.projectConfirmed && <PaymentMethodSelect />}
    </OnboardingLayout>
  );
};

export default Onboarding;
