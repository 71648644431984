import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyIncomingPaymentsPath();

    return FetchHelpers.get(path, params);
  },
  update(id, params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyIncomingPaymentPath(id);

    return FetchHelpers.put(path, params);
  },
  unreadTotalCount(params = {}) {
    const path = apiRoutes.unreadTotalCountSiteApiV1BuilderCompanyIncomingPaymentsPath();

    return FetchHelpers.get(path, params);
  },
  readAll(params = {}) {
    const path = apiRoutes.readAllSiteApiV1BuilderCompanyIncomingPaymentsPath();

    return FetchHelpers.put(path, params);
  },
};
