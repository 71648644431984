import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  link: {
    color: 'inherit',
    fontWeight: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
}));
