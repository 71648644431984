import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useState } from 'react';

import { useDeleteSessionMutation } from 'store/api/session/apiSlice';
import { getPlainNavItems, generateNavItems } from 'utils/navigationUtils';
import useUserLayout from 'hooks/useUserLayout';
import UserPresenter from 'presenters/UserPresenter';
import LinerProgress from 'components/LinearProgress';
import MemoChildren from 'components/MemoChildren';
import ErrorBoundary from 'components/ErrorBoundary';

import Sidebar from './components/Sidebar';
import Submenu from './components/Submenu';
import TopBar from './components/TopBar';
import useStyles from './useStyles';

const UserLayout = props => {
  const { currentUser, children } = props;
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [signOut] = useDeleteSessionMutation();
  const navItems = getPlainNavItems(currentUser);
  const { isSidebarCollapsed, isLoading, submenu, tempSubmenu } = useUserLayout(navItems);

  const getNavItems = generateNavItems(navItems);

  const currentSubmenu = tempSubmenu || submenu;

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      {isLoading && <LinerProgress isVisible={isLoading} />}
      <TopBar
        currentUser={currentUser}
        isDrawerOpen={isDrawerOpen}
        onDrawerOpen={handleDrawerOpen}
        onDrawerClose={handleDrawerClose}
      />
      <div className={classes.root}>
        <nav className={classes.nav}>
          <Sidebar
            isDrawerOpen={isDrawerOpen}
            isCollapsed={isSidebarCollapsed}
            currentUser={currentUser}
            onSignOut={signOut}
            getNavItems={getNavItems}
            onDrawerClose={handleDrawerClose}
          />
          {currentSubmenu && (
            <Submenu submenu={currentSubmenu} isDrawerOpen={isDrawerOpen} onDrawerClose={handleDrawerClose} />
          )}
        </nav>
        <div className={clsx(classes.content, !isSidebarCollapsed && classes.indented)}>
          <MemoChildren>
            <ErrorBoundary>{children}</ErrorBoundary>
          </MemoChildren>
        </div>
      </div>
    </>
  );
};

UserLayout.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default UserLayout;
