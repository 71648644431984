import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
}));
