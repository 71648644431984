import { FC } from "react";

import muiSx from "mui-sx";

import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import Icon from "components/Icon";
import Link from "components/Link";

import styles from "./styles";
import { IGuestLayoutProps } from "./types";

const GuestLayout: FC<IGuestLayoutProps> = (props) => {
  const { children, background = "roof" } = props;
  const sideContainerSx = muiSx(styles.sideContainer, styles[background]);

  return (
    <Div sx={styles.container}>
      <Div sx={styles.root}>
        <Div sx={sideContainerSx}>
          <Link to={AppRoutes.rootPath()}>
            <Div sx={styles.logo}>
              <Icon name="textLogo" height={32} width={175} />
            </Div>
          </Link>
        </Div>
        <Div sx={styles.main}>{children}</Div>
      </Div>
    </Div>
  );
};

export default GuestLayout;
