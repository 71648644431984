import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(14),
    alignItems: 'center',
    marginBottom: 4,
    marginTop: theme.spacing(1),
  },
  clientName: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: theme.typography.pxToRem(-0.8),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  clientEmail: {
    color: theme.palette.colors.green,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  stateStatusContainer: {
    backgroundColor: theme.palette.colors.orange,
    borderRadius: 2,
    minWidth: 164,
  },
  stateStatus: {
    color: theme.palette.colors.white,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    textTransform: 'uppercase',
    padding: '2px 6px',
  },
}));
