import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import SubcontractorsRepository from 'repositories/admin/SubcontractorsRepository';
import { USER_ROLES } from 'presenters/UserPresenter';

const MAX_PER_PAGE = 10;

const initialState = {
  subcontractors: {
    users: [],
    nextPage: null,
  },
};

const slice = createSlice({
  name: 'adminSubcontractors',
  initialState,
  reducers: {
    loadSubcontractorsSuccess(state, { payload }) {
      state.subcontractors.users = concat(state.subcontractors.users, payload.users);
      state.subcontractors.nextPage = payload.meta.nextPage;
    },
    resetSubcontractors() {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminSubcontractorsActions = () => {
  const dispatch = useDispatch();

  const loadSubcontractors = async (page = 1) => {
    const { data } = await SubcontractorsRepository.index({
      q: { s: 'id asc', roleEq: USER_ROLES.company_owner },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadSubcontractorsSuccess(data));

    return data;
  };

  const resetSubcontractors = () => {
    dispatch(actions.resetSubcontractors());
  };

  return {
    loadSubcontractors,
    resetSubcontractors,
  };
};

export const selectors = {
  subcontractors: state => state.AdminSubcontractorsSlice.subcontractors,
};
