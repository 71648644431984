import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingTop: 8,
    },
  },
  form: {
    marginTop: 32,
    width: '100%',
    display: 'grid',
    rowGap: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '49% 49%',
      columnGap: '2%',
    },
  },
  confirmationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32,
  },
  invoiceTotalContainer: {
    position: 'relative',
    marginRight: 24,
  },
  invoiceTotalLabel: {
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    color: theme.palette.colors.deltaGray,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  invoiceTotal: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'right',
  },
  invoiceTotalError: {
    position: 'absolute',
    right: 0,
    bottom: theme.spacing(-2),
    color: theme.palette.error.main,
    whiteSpace: 'nowrap',
  },
  paymentTermsContainer: {
    marginTop: theme.spacing(4),
  },
  formContentContainer: {
    width: '100%',
  },
  confirmationDialog: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginTop: 'auto',
    },
  },
}));
