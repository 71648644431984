import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from "react-i18next";

import AppRoutes from "routes/AppRoutes";

import AddButton from "components/AddButton";
import Div from "components/common/Div";
import Link from "components/Link";
import PageHeader from "components/PageHeader";

import useBreakpoints from "hooks/useBreakpoints";

import styles from "./styles";

const Header = () => {
  const { t } = useTranslation("subcontractorInvoices");

  const { screenWiderThan } = useBreakpoints();

  return (
    <Div sx={styles.header}>
      <Div sx={styles.titleContainer}>
        <PageHeader text={t("title")} icon={<DescriptionOutlinedIcon sx={styles.titleIcon} />} />
      </Div>
      <Link to={AppRoutes.invoicesCreatePath()}>
        <AddButton variant="contained" fullWidth={!screenWiderThan.sm}>
          {t("createNewInvoice")}
        </AddButton>
      </Link>
    </Div>
  );
};

export default Header;
