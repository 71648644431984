import { useHistory } from 'react-router-dom';

import InvoiceForm from 'components/InvoiceForm';
import AppRoutes from 'routes/AppRoutes';
import { useSubcontractorInvoiceCreateActions } from 'store/SubcontractorInvoiceCreateSlice';

const InvoiceCreate = () => {
  const { loadBuilders, createInvoice } = useSubcontractorInvoiceCreateActions();
  const history = useHistory();

  const handleInvoiceConfirm = async invoice => {
    await createInvoice(invoice);
    history.push(AppRoutes.invoicesPath());
  };

  return <InvoiceForm variant="subcontractor" onCompaniesLoad={loadBuilders} onConfirm={handleInvoiceConfirm} />;
};

export default InvoiceCreate;
