import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  navigation: {
    marginTop: 40,
  },
  userPanel: {
    padding: '7px 0',
    boxShadow: 'inset 0px 1px 0px rgba(32, 36, 20, 0.1)',
    display: 'flex',
  },
  avatar: {
    fill: theme.palette.colors.heavyMetalGray,
  },
  menuButton: {
    minWidth: 'unset',
    width: 200,
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
    padding: 15,
    paddingTop: 20,
  },
  userNameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userName: {
    display: 'inline-block',
    alignItems: 'center',
    color: theme.palette.colors.heavyMetalGray,
    marginLeft: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 145,
    textAlign: 'left',
    lineHeight: '25px',
  },
  icon: {
    fill: 'currentColor',
  },
  gearIcon: {
    margin: '-2px 4px 0 2px',
  },
}));
