import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import Icon from "components/Icon";

interface IMuiCheckboxType {
  defaultProps: ComponentsProps["MuiCheckbox"];
  styleOverrides: ComponentsOverrides["MuiCheckbox"];
}

const MuiCheckbox: IMuiCheckboxType = {
  defaultProps: {
    icon: <Icon name="uncheckedCheckbox" width={24} height={24} fill="#fff" />,
    checkedIcon: <Icon name="checkbox" width={24} height={24} fill="#fff" />,
  },
  styleOverrides: {
    root: {
      padding: 9,
    },
  },
};

export default MuiCheckbox;
