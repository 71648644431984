import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { format } from 'utils/dateUtils';
import { formatUSD } from 'utils/numberUtils';
import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.string,
    createdAt: PropTypes.string,
    project: ProjectPresenter.shape(),
    customer: UserPresenter.shape(),
    customerInvoice: CustomerInvoicePresenter.shape(),
  },
  {
    date(payment) {
      return format(this.createdAt(payment));
    },
    formattedUSDTotalAmount(payment, maximumFractionDigits = 2) {
      const amount = this.amount(payment);
      return formatUSD(amount, maximumFractionDigits);
    },
    projectName(payment) {
      return ProjectPresenter.name(this.project(payment));
    },
    payerName(payment) {
      return UserPresenter.fullName(this.customer(payment));
    },
    customerInvoiceName(payment) {
      const customerInvoice = this.customerInvoice(payment);
      return CustomerInvoicePresenter.name(customerInvoice);
    },
  },
);
