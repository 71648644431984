import UserPresenter from 'presenters/UserPresenter';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

const useUserPolicy = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  const isCompanyOwner = UserPresenter.isCompanyOwner(currentUser);

  return {
    isCompanyOwner,
  };
};

export default useUserPolicy;
