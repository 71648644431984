import Checkbox from '@mui/material/Checkbox';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const RoundCheckbox = props => {
  const classes = useStyles();

  return (
    <Checkbox
      {...props}
      checkedIcon=<Icon name="roundCheckbox" width={20} height={20} className={classes.checkboxIcon} />
      icon=<Icon name="circle" width={20} height={20} className={classes.circleIcon} />
    />
  );
};

export default RoundCheckbox;
