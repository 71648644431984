import CompaniesRepository from 'repositories/builder/onboarding/CompaniesRepository';
import OnboardingLineItemsRepository from 'repositories/builder/company/onboarding/LineItemsRepository';
import MembersRepository from 'repositories/builder/company/onboarding/MembersRepository';

export const useBuilderOnboardingActions = () => {
  const createCompany = params => {
    return CompaniesRepository.create(params);
  };

  const proceedLineItemsOnboarding = () => {
    return OnboardingLineItemsRepository.proceed();
  };

  const createUsers = params => {
    return MembersRepository.massCreate(params);
  };

  const checkUserAvailability = params => {
    return MembersRepository.checkAvailability(params);
  };

  return {
    createCompany,
    proceedLineItemsOnboarding,
    createUsers,
    checkUserAvailability,
  };
};
