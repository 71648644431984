import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { useIncomingPayments, selectors } from 'store/IncomingPaymentsSlice';
import IncomingPaymentPresenter from 'presenters/IncomingPaymentPresenter';
import useLoading from 'hooks/useLoading';

import IncomingPaymentsTable from '../IncomingPaymentsTable';

import UnassignedIncomingPaymentsTableRows from './components/UnassignedIncomingPaymentsTableRows';

const MAX_COLS_COUNT = 6;

const UnassignedIncomingPaymentsTable = () => {
  const { loadUnassignedIncomingPayments, resetUnassignedIncomingPayments } = useIncomingPayments();
  const { data, meta } = useSelector(selectors.unassigned);
  const { func: sendLoadUnassignedIncomingPayments, isPending } = useLoading(loadUnassignedIncomingPayments);

  useEffect(() => {
    sendLoadUnassignedIncomingPayments();
    return resetUnassignedIncomingPayments;
  }, []);

  return (
    <IncomingPaymentsTable
      isLoading={isPending}
      rows={data.map(payment => (
        <UnassignedIncomingPaymentsTableRows key={IncomingPaymentPresenter.id(payment)} payment={payment} />
      ))}
      isData={!isEmpty(data)}
      maxColsCount={MAX_COLS_COUNT}
      loadMore={loadUnassignedIncomingPayments}
      nextPage={meta.nextPage}
    />
  );
};

export default memo(UnassignedIncomingPaymentsTable);
