import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { apiContainer } from 'store';

import SubcontractorsRepository from 'repositories/builder/SubcontractorsRepository';
import InvoicesRepository from 'repositories/builder/company/InvoicesRepository';
import ProjectLineItemsRepository from 'repositories/builder/company/project/ProjectLineItemsRepository';
import ProjectsRepository from 'repositories/builder/company/ProjectsRepository';
import LineItemsRepository from 'repositories/builder/company/LineItemsRepository';
import { formDataToRequest, apiErrorsToForm } from 'forms/Builder/InvoiceForm';
import { BUILDER_COMPANY_LINE_ITEMS_PER_PAGE } from 'store/api/builder/company/invoice/constants';

const initialState = {
  predefinedProject: null,
};

const builderInvoiceCreateSlice = createSlice({
  name: 'builderInvoiceCreate',
  initialState,
  reducers: {
    updatePredefinedProject(state, { payload }) {
      state.predefinedProject = payload;
    },
    resetBuilderInvoiceCreateState() {
      return initialState;
    },
  },
});

const { actions } = builderInvoiceCreateSlice;

export const selectors = {
  predefinedProject(state) {
    return state.BuilderInvoiceCreateSlice.predefinedProject;
  },
};

export const useBuilderInvoiceCreateActions = () => {
  const dispatch = useDispatch();

  const loadSubcontractors = (builderId, nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont) => {
    const params = {
      q: {
        nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont,
        companyConnectionsStateEq: 'accepted',
        companyConnectionsBuilderIdEq: builderId,
      },
    };
    return SubcontractorsRepository.index(params);
  };

  const createInvoice = async invoice => {
    const requestInvoice = formDataToRequest(invoice);
    try {
      const data = await InvoicesRepository.create({ invoice: requestInvoice });
      dispatch(apiContainer.util.invalidateTags([{ type: 'BuilderCompanyInvoice', id: 'LIST' }]));
      return data;
    } catch (error) {
      throw apiErrorsToForm(error);
    }
  };

  const createLineItem = ({ projectId = 0, categoryName = '', accountCode = '' }) => {
    const requestParams = {
      projectLineItem: {
        lineItemAttributes: {
          categoryName,
          accountCode,
        },
      },
    };
    return ProjectLineItemsRepository.create(projectId, requestParams);
  };

  const loadProjects = async ({ name, state, approvalState }) => {
    const params = {
      q: {
        nameCont: name,
        stateEq: state,
        approvalStateEq: approvalState,
      },
    };
    const { data } = await ProjectsRepository.index(params);
    return data;
  };

  const loadLineItems = async ({ projectId, nameCont, excludedIds, state }) => {
    const params = {
      q: {
        nameCont,
        projectsIdEq: projectId,
        idNotIn: excludedIds,
        stateEq: state,
      },
      perPage: BUILDER_COMPANY_LINE_ITEMS_PER_PAGE,
    };
    const { data } = await LineItemsRepository.index(params);
    return data;
  };

  const loadOpenedProjectLineItems = async ({ projectId, nameCont, excludedIds }) => {
    const params = {
      q: { lineItemCategoryNameOrLineItemAccountCodeCont: nameCont, lineItemIdNotIn: excludedIds, stateEq: 'opened' },
      perPage: 50,
    };
    const { data } = await ProjectLineItemsRepository.index(projectId, params);
    return data;
  };

  const updateInvoice = async ({ id, params }) => {
    const requestParams = { invoice: formDataToRequest(params) };
    try {
      const data = await InvoicesRepository.update(id, requestParams);
      dispatch(apiContainer.util.invalidateTags([{ type: 'BuilderCompanyInvoice', id }]));
      return data;
    } catch (error) {
      throw apiErrorsToForm(error);
    }
  };

  const updatePredefinedProject = project => dispatch(actions.updatePredefinedProject(project));

  const resetBuilderInvoiceCreateState = () => dispatch(actions.resetBuilderInvoiceCreateState());

  return {
    loadSubcontractors,
    loadOpenedProjectLineItems,
    createInvoice,
    createLineItem,
    loadProjects,
    loadLineItems,
    updateInvoice,
    updatePredefinedProject,
    resetBuilderInvoiceCreateState,
  };
};

export default builderInvoiceCreateSlice.reducer;
