export enum CacheTag {
  usaState = "USAState",
  currentUser = "CurrentUser",
  adminInvoice = "AdminInvoice",
  builderCompanyInvoice = "BuilderCompanyInvoice",
  builderCompanyInvoiceAwaitingApprovalTotalCount = "BuilderCompanyInvoiceAwaitingApprovalTotalCount",
  subcontractorCompanyInvoice = "SubcontractorCompanyInvoice",
  subcontractorInvoiceEarlyPaymentOption = "SubcontractorInvoiceEarlyPaymentOption",
  subcontractorCompanyInvoicePendingTotalCount = "SubcontractorCompanyInvoicePendingTotalCount",
  subcontractorCompanyIncomingConnectionHasPendingConnections = "SubcontractorCompanyIncomingConnectionHasPendingConnections",
}
