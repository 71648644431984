import { SxStyles } from "theme";

const styles: SxStyles = {
  label: {
    display: "flex",
    alignItems: "center",
  },
  count: {
    textAlign: "center",
    marginLeft: "8px",
    color: "common.white",
    fontFamily: "Lato",
    fontSize: "11px",
    lineHeight: "16px",
    height: "16px",
    borderRadius: "10px",
    minWidth: "16px",
    padding: "0 4px",
    position: "relative",
  },
  countOpen: {
    backgroundColor: "warning.main",
  },
  countApproved: {
    backgroundColor: "success.main",
  },
  countClosed: {
    backgroundColor: "colors.lemonGrass",
  },
  countEmpty: {
    backgroundColor: "common.white",
  },
  skeleton: {
    position: "absolute",
    left: 0,
    top: 0,
  },
};

export default styles;
