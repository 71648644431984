import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    padding: '15px 10px',
  },
  actionCellHead: {
    width: 40,
  },
  noUsersLabel: {
    color: theme.palette.colors.gunsmokeGray,
  },
}));
