import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  passwordVisibilityButton: {
    marginRight: -1,
  },
  visibilityIcon: {
    width: 18,
    height: 18,
    fill: theme.palette.colors.deltaGray,
  },
  inputRootPassword: {
    letterSpacing: 2,
  },
}));
