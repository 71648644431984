import UserPresenter from 'presenters/UserPresenter';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const MemberStatus = props => {
  const { member } = props;
  const classes = useStyles();

  if (UserPresenter.isActive(member)) {
    return (
      <div className={classes.container}>
        <Icon name="containedCheckMark" width={14} height={14} className={classes.checkIcon} />
        <div className={classes.status}>Confirmed</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Icon name="sandglass" width={10} height={14} className={classes.sandglassIcon} />
      <div className={classes.status}>Waiting Confirmation</div>
    </div>
  );
};

MemberStatus.propTypes = {
  member: UserPresenter.shape().isRequired,
};

export default MemberStatus;
