import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MaterialLink from '@mui/material/Link';
import { Box, Button, Typography } from '@mui/material';

import InvoicePresenter from 'presenters/InvoicePresenter';
import AppRoutes from 'routes/AppRoutes';
import PageHeader from 'components/PageHeader';

import useStyles from './useStyles';

const PayableInvoicePageHeader = props => {
  const { invoiceName, invoice, onMarkAsPaid, loading } = props;
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <Box mb={1}>
          <MaterialLink to={AppRoutes.invoicesPath()} component={Link}>
            Payable Invoices
          </MaterialLink>
        </Box>

        <div className={classes.title}>
          <PageHeader text={invoiceName} icon={<DescriptionOutlinedIcon className={classes.titleIcon} />} />
          <div className={classes.actions}>
            {InvoicePresenter.isApproved(invoice) && (
              <>
                <Typography className={classes.actionsTitle}>Invoice Approved</Typography>

                <Button variant="contained" color="primary" disabled={loading} onClick={() => onMarkAsPaid(invoice.id)}>
                  Mark as Paid
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

PayableInvoicePageHeader.propTypes = {
  invoiceName: PropTypes.string,
  invoice: InvoicePresenter.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
};

PayableInvoicePageHeader.defaultProps = {
  invoiceName: '',
};

export default PayableInvoicePageHeader;
