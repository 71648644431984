import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.colors.white,
    boxShadow: '0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)',
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '48px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '8px',
    },
  },
  tip: {
    marginTop: 6,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  inputRoot: {
    marginTop: 28,
  },
  inlineFormContainer: {
    display: 'flex',
    gap: '10px',
  },
  stateContainer: {
    width: '50%',
    flex: '1 0 50%',
    [theme.breakpoints.up('md')]: {
      width: '422px',
      flex: '1 0 422px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 48,
  },
  submitRoot: {
    padding: '12px 24px',
  },
}));
