import { useEffect } from 'react';

import Icon from 'components/Icon';
import { useAdminProjectsActions } from 'store/AdminProjectsSlice';
import PageHeader from 'components/PageHeader';

import ActiveProjectsTable from './components/ActiveProjectsTable';
import CompletedProjectsTable from './components/CompletedProjectsTable';

import useStyles from './useStyles';

const AdminProjects = () => {
  const classes = useStyles();
  const { resetProjects } = useAdminProjectsActions();

  useEffect(() => {
    return resetProjects;
  }, []);

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="Projects"
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="folder" />}
        />
      </header>
      <div className={classes.tableContainer}>
        <ActiveProjectsTable />
      </div>
      <div className={classes.tableContainer}>
        <CompletedProjectsTable />
      </div>
    </main>
  );
};

export default AdminProjects;
