import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Drawer from '@mui/material/Drawer';
import PopupMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import UserPresenter from 'presenters/UserPresenter';
import useImpersonate from 'hooks/useImpersonate';
import useBreakpoints from 'hooks/useBreakpoints';

import ExpandedMenu from './components/ExpandedMenu';
import CollapsedMenu from './components/CollapsedMenu';
import useStyles from './useStyles';

const Sidebar = props => {
  const { currentUser, onSignOut, isCollapsed, getNavItems, isDrawerOpen, onDrawerClose } = props;
  const isCustomer = UserPresenter.isCustomer(currentUser);
  const { isImpersonated } = currentUser;
  const classes = useStyles({ isCustomer });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { stopImpersonating } = useImpersonate();
  const { screenWiderThan } = useBreakpoints();

  const handleMenuOpen = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuHide = () => {
    setMenuAnchorEl(null);
  };

  const renderMenu = () => {
    if (isCollapsed) {
      return <CollapsedMenu onMenuOpen={handleMenuOpen} getNavItems={getNavItems} onDrawerClose={onDrawerClose} />;
    }

    return (
      <ExpandedMenu
        currentUser={currentUser}
        onMenuOpen={handleMenuOpen}
        getNavItems={getNavItems}
        onDrawerClose={onDrawerClose}
      />
    );
  };

  return (
    <Drawer
      classes={{ paper: clsx({ [classes.collapsedPaper]: isCollapsed, [classes.expandedPaper]: !isCollapsed }) }}
      open={isDrawerOpen || (isDrawerOpen && isCollapsed)}
      onClose={onDrawerClose}
      variant={screenWiderThan.lg ? 'permanent' : 'temporary'}
    >
      {renderMenu()}
      <PopupMenu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuHide}
        classes={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onSignOut}>Log Out</MenuItem>
        {isImpersonated && <MenuItem onClick={stopImpersonating}>Stop Impersonating</MenuItem>}
      </PopupMenu>
    </Drawer>
  );
};

Sidebar.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  onSignOut: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  getNavItems: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default Sidebar;
