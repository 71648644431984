export default {
  rootPath: () => "/",
  signInPath: () => "/signin",
  signUpPath: () => "/signup",
  emailConfirmationPath: () => "/confirm-email",
  forgotPasswordPath: () => "/forgot-password",
  passwordResetPath: () => "/password-reset",
  onboardingPath: () => "/onboarding",
  onboardingBuilderPath: () => "/onboarding/builder",
  onboardingSubcontractorPath: () => "/onboarding/subcontractor",
  onboardingCustomerPath: () => "/onboarding/customer",
  dashboardPath: () => "/dashboard",
  projectsPath: () => "/projects",
  projectPath: (id: number) => `/projects/${id}`,
  projectDetailsPath: (id: number) => `/projects/${id}/details`,
  projectInvoicesPath: (id: number) => `/projects/${id}/invoices`,
  projectTeamPath: (id: number) => `/projects/${id}/team`,
  projectClientPaymentsPath: (id: number) => `/projects/${id}/client_payments`,
  invoicesPath: () => "/invoices",
  invoicePath: (id: number) => `/invoices/${id}`,
  invoiceEditPath: (id: number) => `/invoices/${id}/edit`,
  invoicesCreatePath: () => "/invoices/create",
  subcontractorsPath: () => "/subcontractors",
  contactsPath: () => "/contacts",
  settingsPath: () => "/settings",
  settingsCompanyPath: () => "/settings/company",
  settingsTeamPath: () => "/settings/team",
  accountActivationPath: () => "/activate-account",
  customerActivationPath: () => "/activate-customer",
  incomingPayments: () => "/incoming-payments",
  customerPaymentsPath: () => "/customer/payments",
  adminProjectsPath: () => "/admin/projects",
  adminProjectPath: (id: number) => `/admin/projects/${id}`,
  adminBuildersPath: () => "/admin/builders",
  adminSubcontractorsPath: () => "/admin/subcontractors",
  adminLoansPath: () => "/admin/loans",
  adminInvoicesPath: () => "/admin/invoices",
  adminInvoicePath: (id: number) => `/admin/invoices/${id}`,
  adminPaymentsPath: () => "/admin/payments",
};
