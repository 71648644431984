import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const DeleteMemberDialog = props => {
  const { isVisible, onClose, onMemberDelete } = props;
  const classes = useStyles();

  const handleMemberDelete = () => {
    onMemberDelete();
    onClose();
  };

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Icon className={classes.invitationIcon} name="invitation" width={28} height={32} />
          <Typography className={classes.heading} variant="h3">
            Are you sure?
          </Typography>
        </div>
        <Typography className={classes.message} component="p">
          You are about to deactivate the User. They will lose access to the platform and their projects.
        </Typography>
        <div className={classes.buttonGroup}>
          <Button className={classes.deleteMemberButton} variant="contained" onClick={handleMemberDelete}>
            Yes, proceed
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            No, go back
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

DeleteMemberDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMemberDelete: PropTypes.func.isRequired,
};

export default DeleteMemberDialog;
