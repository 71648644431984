import { memo } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import TableLoader from 'components/TableLoader';

import useStyles from './useStyles';

const TableLoadMoreButton = props => {
  const { colSpan, onClick, isLoading } = props;

  const classes = useStyles();

  if (isLoading) {
    return <TableLoader colSpan={colSpan} />;
  }

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell} colSpan={colSpan} align="center">
        <Button color="primary" onClick={onClick}>
          Load More
        </Button>
      </TableCell>
    </TableRow>
  );
};

TableLoadMoreButton.propTypes = {
  colSpan: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

TableLoadMoreButton.defaultProps = {
  isLoading: false,
};

export default memo(TableLoadMoreButton);
