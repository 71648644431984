import { FC, useEffect, useMemo, useState } from "react";

import { Typography } from "@mui/material";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceSubcontractorNameCard from "components/common/InvoiceSubcontractorNameCard";
import NoWrap from "components/common/NoWrap";
import TableLoadMore from "components/common/TableLoadMore";

import { InvoiceState } from "domain/invoice/enums";
import * as InvoicePresenter from "domain/invoice/presenter";

import useBreakpoints from "hooks/useBreakpoints";

import { formatUSD } from "libs/currency";

import { useGetSubcontractorCompanyInvoicesQuery } from "store/api/subcontractor/company/invoice/apiSlice";
import { SUBCONTRACTOR_COMPANY_INVOICES_PER_PAGE } from "store/api/subcontractor/company/invoice/service";
import { SubcontractorCompanyInvoiceListItem } from "store/api/subcontractor/company/invoice/types";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import { getSortingParameter } from "utils/tableSorting";

import { IClosedInvoicesProps } from "./types";

const columnHelper = createColumnHelper<SubcontractorCompanyInvoiceListItem>();

const ClosedInvoices: FC<IClosedInvoicesProps> = (props) => {
  const { t } = useTranslation(["common", "subcontractorInvoices"]);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { onStatusCountChange } = props;
  const { screenWiderThan } = useBreakpoints();
  const isDesktopLayout = screenWiderThan.md;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [subcontractorCompanyInvoices, setSubcontractorCompanyInvoices] = useState<
    Array<SubcontractorCompanyInvoiceListItem>
  >([]);

  const queryParameters = useMemo(() => {
    return {
      q: {
        stateIn: [InvoiceState.canceled, InvoiceState.paid, InvoiceState.rejected],
        s: getSortingParameter(sorting),
      },
      perPage: SUBCONTRACTOR_COMPANY_INVOICES_PER_PAGE,
      page: currentPage,
    };
  }, [currentPage, sorting]);

  const { currentData: closedData, isFetching } = useGetSubcontractorCompanyInvoicesQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (closedData?.invoices && queryParameters.page === 1) {
      setSubcontractorCompanyInvoices(closedData.invoices);
    }
    if (closedData?.invoices && queryParameters.page > 1) {
      setSubcontractorCompanyInvoices((invoices) => [...invoices, ...closedData.invoices]);
    }
  }, [queryParameters, closedData?.invoices]);

  useEffect(() => {
    if (closedData?.meta.totalCount) {
      onStatusCountChange("closed", closedData?.meta.totalCount);
    }
  }, [closedData?.meta.totalCount]);

  const history = useHistory();

  const columns = useMemo(
    () => [
      columnHelper.accessor("builder", {
        header: t("subcontractorInvoices:table.headers.builder") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceSubcontractorNameCard invoice={info.row.original} />,
      }),
      columnHelper.accessor("projectName", {
        header: t("subcontractorInvoices:table.headers.project") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceAssignedProjects invoice={info.row.original} />,
      }),
      columnHelper.accessor("name", {
        header: t("subcontractorInvoices:table.headers.invoiceDescription") ?? "",
        enableSorting: true,
        cell: (info) => <Typography noWrap={isDesktopLayout}>{info.row.original.name}</Typography>,
      }),
      columnHelper.accessor("state", {
        header: t("subcontractorInvoices:table.headers.status") ?? "",
        enableSorting: true,
        cell: (info) => currentUser && <NoWrap>{InvoicePresenter.status(info.row.original, currentUser, t)}</NoWrap>,
      }),
      columnHelper.accessor("projectedPaidAt", {
        header: t("subcontractorInvoices:table.headers.paymentDate") ?? "",
        enableSorting: false,
        cell: (info) =>
          currentUser && <NoWrap>{InvoicePresenter.paymentDateOrStatus(info.row.original, currentUser, t)}</NoWrap>,
      }),
      columnHelper.accessor("totalAmount", {
        header: t("subcontractorInvoices:table.headers.invoiceAmount") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap>{formatUSD(info.row.original.totalAmount)}</NoWrap>,
        meta: { align: "right", width: "100px" },
      }),
      columnHelper.accessor("invoiceEarlyPayment.amount", {
        header: t("subcontractorInvoices:table.headers.acceptedAmount") ?? "",
        enableSorting: true,
        sortDescFirst: true,
        cell: (info) => {
          const { invoiceEarlyPayment } = info.row.original;
          return isNil(invoiceEarlyPayment) ? "—" : <NoWrap>{formatUSD(invoiceEarlyPayment.amount)}</NoWrap>;
        },
        meta: { align: "right", width: "100px" },
      }),
    ],
    [isDesktopLayout],
  );

  const table = useReactTable({
    data: subcontractorCompanyInvoices,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    enableMultiSort: false,
  });

  const handleLoadMore = () => {
    setCurrentPage((previousPage) => previousPage + 1);
  };

  const handleRowClick = (invoice: SubcontractorCompanyInvoiceListItem) => {
    history.push(AppRoutes.invoicePath(invoice.id));
  };

  return (
    <Div>
      <TableLoadMore<SubcontractorCompanyInvoiceListItem>
        table={table}
        onRowClick={handleRowClick}
        onLoadMore={handleLoadMore}
        loading={isFetching}
        meta={closedData?.meta}
      />
    </Div>
  );
};

export default ClosedInvoices;
