import { SortingState } from "@tanstack/react-table";
import humps from "humps";
import { head, isEmpty } from "ramda";

export const getDirection = (sorting: SortingState): "asc" | "desc" | "" => {
  if (isEmpty(sorting)) {
    return "";
  }

  return head(sorting)?.desc ? "desc" : "asc";
};

export const getSortingParameter = (sorting: SortingState): string => {
  if (isEmpty(sorting)) {
    return "";
  }

  const fieldName = head(sorting)?.id ?? "";
  const fieldSortDirection = getDirection(sorting);

  return `${humps.decamelize(fieldName)} ${fieldSortDirection}`;
};

export const isDescDirection = (sorting: SortingState): boolean => {
  return head(sorting)?.desc ?? false;
};

export const isSortedByField = (sorting: SortingState, fieldName: string): boolean => {
  return head(sorting)?.id === fieldName;
};

export const isSortedByFieldAsc = (sorting: SortingState, fieldName: string): boolean => {
  return isSortedByField(sorting, fieldName) && !isDescDirection(sorting);
};

export const isSortedByFieldDesc = (sorting: SortingState, fieldName: string): boolean => {
  return isSortedByField(sorting, fieldName) && isDescDirection(sorting);
};
