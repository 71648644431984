import { FETCH_STATUSES } from 'constants/fetch';

const useFetchStatus = status => {
  const isIdle = status === FETCH_STATUSES.idle;
  const isPending = status === FETCH_STATUSES.pending;
  const isFulfilled = status === FETCH_STATUSES.fulfilled;
  const isFailed = status === FETCH_STATUSES.failed;
  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
  };
};

export default useFetchStatus;
