import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useLoading from 'hooks/useLoading';
import { usePaymentTokens } from 'store/CustomerPaymentTokensSlice';
import PlaidLink from 'components/PlaidLink';
import LinearProgress from 'components/LinearProgress';
import { useCustomerOnboardingActions } from 'store/CustomerOnboardingSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';

import useStyles from './useStyles';

const AchDetails = props => {
  const { onBackButtonClick } = props;
  const classes = useStyles();
  const { loadPlaidLinkToken, loadBankAccountToken, createStipeId } = usePaymentTokens();
  const [plaidToken, setPlaidToken] = useState('');
  const { completeOnboarding } = useCustomerOnboardingActions();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();

  const loadPlaidToken = async () => {
    const token = await loadPlaidLinkToken();
    setPlaidToken(token);
  };
  const { func: sendLoadToken, isPending } = useLoading(loadPlaidToken);

  useEffect(() => {
    sendLoadToken();
  }, []);

  const handlePlaidSuccess = async () => {
    await completeOnboarding();
    await loadCurrentUser();
  };

  return (
    <>
      <LinearProgress isVisible={isPending} />
      <div className={classes.backButtonContainer}>
        <Button className={classes.backButton} onClick={onBackButtonClick}>
          <ArrowBackIcon />
        </Button>
      </div>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          ACH
        </Typography>
        <Typography className={classes.subtitle}>
          Funl works with Plaid, a third-party service that enables bank connections for leading brands such as Venmo
          and American Express. It is a safe and secure way to connect your bank information in order to pay for your
          Funl invoices. After you click on &#34;Connect a bank account&#34;, you will be taken to Plaid for account
          authentication.
        </Typography>
      </div>
      <div className={classes.nextButtonContainer}>
        {plaidToken && (
          <PlaidLink
            loadBankAccountToken={loadBankAccountToken}
            createStipeId={createStipeId}
            token={plaidToken}
            onSuccess={handlePlaidSuccess}
          />
        )}
      </div>
    </>
  );
};

AchDetails.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
};

export default AchDetails;
