import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AUTOCOMPLETE_INPUT_CHANGE_REASONS } from 'constants/autocomplete';
import Autocomplete from 'components/Autocomplete';
import { isBlank } from 'utils/storeUtils';

const AutocompletePick = props => {
  const { displayedValueSelectedOption, onLoad, onChange, onClear, ...autoCompleteProps } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(displayedValueSelectedOption);
  }, [displayedValueSelectedOption]);

  const handleInputChange = (_, value, reason) => {
    if (
      reason === AUTOCOMPLETE_INPUT_CHANGE_REASONS.reset ||
      (reason === AUTOCOMPLETE_INPUT_CHANGE_REASONS.input && isBlank(value))
    ) {
      setInputValue(displayedValueSelectedOption);
      onClear();
    }
  };

  return (
    <Autocomplete
      inputValue={inputValue}
      clearOnBlur
      isOnFieldValueSetWrap={false}
      onFocus={() => onLoad(inputValue)}
      onChange={event => {
        setInputValue(event.target.value);
        onChange(event);
      }}
      onInputChange={handleInputChange}
      onLoad={onLoad}
      {...autoCompleteProps}
    />
  );
};

AutocompletePick.propTypes = {
  displayedValueSelectedOption: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

AutocompletePick.defaultProps = {
  onChange: Function.prototype,
  onClear: Function.prototype,
};

export default AutocompletePick;
