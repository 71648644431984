import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import CompanyOwnerPresenter from 'presenters/CompanyOwnerPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    companyOwner: CompanyOwnerPresenter.shape(),
  },
  {
    companyOwnerEmail(builder) {
      return CompanyOwnerPresenter.email(this.companyOwner(builder));
    },
  },
);
