import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LinearProgress from 'components/LinearProgress';
import InputPhoneNumber from 'components/InputPhoneNumber';
import useFormik from 'hooks/useFormik';
import { initialValues, validationSchema, formPaths } from 'forms/BankDetailsForm';
import { useCustomerOnboardingActions } from 'store/CustomerOnboardingSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';
import { getNumbersFromString } from 'utils/numberUtils';

import useStyles from './useStyles';

const BankDetails = props => {
  const { onBackButtonClick } = props;
  const classes = useStyles();
  const { createBankAccount, completeOnboarding } = useCustomerOnboardingActions();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();

  const { errors, handleChange, values, isSubmitting, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ bankName, contactPerson, emailAddress, phoneNumber }) => {
      const phone = getNumbersFromString(phoneNumber);
      await createBankAccount({ bankName, contactPerson, email: emailAddress, phone });
      await completeOnboarding();
      await loadCurrentUser();
    },
  });

  return (
    <>
      <div className={classes.backButtonContainer}>
        <Button className={classes.backButton} onClick={onBackButtonClick}>
          <ArrowBackIcon />
        </Button>
      </div>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          Bank Account
        </Typography>
        <Typography className={classes.subtitle}>
          The Bank will submit funds directly from the Bank’s account to Funl.
        </Typography>
      </div>
      <div className={classes.activationFormContainer}>
        <LinearProgress isVisible={isSubmitting} />
        <form className={classes.formContainer} onSubmit={handleSubmit}>
          <div className={classes.formField}>
            <TextField
              value={values.bankName}
              label="Bank name"
              name={formPaths.bankName}
              className={classes.textField}
              error={Boolean(errors.bankName)}
              helperText={errors.bankName}
              onChange={handleChange}
            />
          </div>
          <div className={classes.formField}>
            <TextField
              value={values.contactPerson}
              label="Contact person"
              name={formPaths.contactPerson}
              autoComplete="username"
              className={classes.textField}
              error={Boolean(errors.contactPerson)}
              helperText={errors.contactPerson}
              onChange={handleChange}
            />
          </div>
          <div className={classes.formField}>
            <TextField
              value={values.emailAddress}
              label="email address"
              name={formPaths.emailAddress}
              autoComplete="email"
              className={classes.textField}
              error={Boolean(errors.emailAddress)}
              helperText={errors.emailAddress}
              onChange={handleChange}
            />
          </div>
          <div className={classes.formField}>
            <TextField
              value={values.phoneNumber}
              label="Phone number"
              placeholder="+1 (___) ___-____"
              name={formPaths.phoneNumber}
              autoComplete="tel"
              className={classes.textField}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
              InputProps={{
                inputComponent: InputPhoneNumber,
              }}
              onChange={handleChange}
            />
          </div>
        </form>
      </div>
      <div className={classes.nextButtonContainer}>
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          Continue
        </Button>
      </div>
    </>
  );
};

BankDetails.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
};

export default BankDetails;
