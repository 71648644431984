import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import InvoicesRepository from 'repositories/admin/InvoicesRepository';
import { INVOICE_STATES, INVOICE_KINDS } from 'presenters/InvoicePresenter';

const MAX_PER_PAGE = 5;

const initialState = {
  open: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  approved: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  closed: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
};

const slice = createSlice({
  name: 'adminInvoices',
  initialState,
  reducers: {
    loadOpenInvoicesSuccess(state, { payload }) {
      state.open.data = concat(state.open.data, payload.invoices);
      state.open.meta = payload.meta;
    },
    loadApprovedInvoicesSuccess(state, { payload }) {
      state.approved.data = concat(state.approved.data, payload.invoices);
      state.approved.meta = payload.meta;
    },
    loadClosedInvoicesSuccess(state, { payload }) {
      state.closed.data = concat(state.closed.data, payload.invoices);
      state.closed.meta = payload.meta;
    },
    resetInvoices() {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminInvoicesActions = () => {
  const dispatch = useDispatch();

  const loadOpenInvoices = async (page = 1) => {
    const params = {
      q: { kindEq: INVOICE_KINDS.selfPaid, stateIn: [INVOICE_STATES.pending, INVOICE_STATES.rejected], s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadOpenInvoicesSuccess(data));

    return data;
  };

  const loadApprovedInvoices = async (page = 1) => {
    const params = {
      q: { kindEq: INVOICE_KINDS.selfPaid, stateEq: INVOICE_STATES.approved, s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadApprovedInvoicesSuccess(data));

    return data;
  };

  const loadClosedInvoices = async (page = 1) => {
    const params = {
      q: { kindEq: INVOICE_KINDS.selfPaid, stateIn: [INVOICE_STATES.paid, INVOICE_STATES.canceled], s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(params);

    dispatch(actions.loadClosedInvoicesSuccess(data));

    return data;
  };

  const resetInvoices = () => {
    dispatch(actions.resetInvoices());
  };

  return {
    loadOpenInvoices,
    loadApprovedInvoices,
    loadClosedInvoices,
    resetInvoices,
  };
};

export const selectors = {
  open: state => state.AdminInvoicesSlice.open,
  approved: state => state.AdminInvoicesSlice.approved,
  closed: state => state.AdminInvoicesSlice.closed,
};
