import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { API_ERROR_TEXTS } from 'forms/errorsText';

import useStyles from './useStyles';

const Error = props => {
  const { error } = props;
  const { message = API_ERROR_TEXTS.unexpectedError } = error;

  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <Typography variant="h4" component="span">
        {message}
      </Typography>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }).isRequired,
};

export default Error;
