import { camelize } from "humps";
import { TFunction } from "i18next";
import { isNil } from "ramda";
import { SetOptional } from "type-fest";

import { ICompany } from "domain/company/types";
import { IInvoiceEarlyPayment } from "domain/invoiceEarlyPayment/types";
import * as UserService from "domain/user/service";

import { formatUSD } from "libs/currency";

import { CurrentUser } from "store/api/user/types";

import { format } from "utils/dateUtils";

import {
  isApproved,
  isAwaitingApproval,
  isEarlyPaymentRequested,
  isPaid,
  isPending,
  isRejected,
  isSelfPaid,
} from "./service";
import { IInvoice } from "./types";

export const formattedUSDTotalAmount = (invoice: Pick<IInvoice, "totalAmount">, maximumFractionDigits = 2): string => {
  // if (isBlank(this.totalAmount(invoice))) {
  //   return formatUSD(getInvoiceTotal(this.inboundLineItems(invoice)), maximumFractionDigits);
  // }

  return formatUSD(invoice.totalAmount, maximumFractionDigits);
};

export const actualTotalAmount = (
  invoice: Pick<IInvoice, "totalAmount"> & { invoiceEarlyPayment?: Pick<IInvoiceEarlyPayment, "amount"> },
): string => {
  const total = isNil(invoice.invoiceEarlyPayment) ? invoice.totalAmount : Number(invoice.invoiceEarlyPayment.amount);

  return formatUSD(total);
};

export const status = (
  invoice: Pick<IInvoice, "kind" | "state" | "paymentKind">,
  currentUser: CurrentUser,
  t: TFunction,
): string => {
  if (isSelfPaid(invoice) && isPending(invoice)) {
    return t("invoice.status.selfPaid.pending");
  }

  if (isSelfPaid(invoice) && isAwaitingApproval(invoice)) {
    return t("invoice.status.selfPaid.awaitingApproval");
  }

  if (isSelfPaid(invoice) && isRejected(invoice)) {
    return t("invoice.status.selfPaid.rejected");
  }

  if (UserService.isBuilder(currentUser) && isPending(invoice)) {
    return t("invoice.status.pendingActionFromSC");
  }

  if (UserService.isSubcontractor(currentUser) && isPending(invoice)) {
    return t("invoice.status.yourActionIsRequired");
  }

  if (isEarlyPaymentRequested(invoice)) {
    return t("invoice.status.earlyPaymentRequested");
  }

  return t(`invoice.status.${camelize(invoice.state)}`);
};

export const builderDocumentTitle = (
  invoice: SetOptional<Pick<IInvoice, "kind" | "subcontractorEmail">, "subcontractorEmail"> & {
    subcontractor?: Pick<ICompany, "name"> | null;
  },
  t: TFunction,
): string => {
  if (isSelfPaid(invoice)) {
    return t("common:invoice.selfPaidInvoiceTitle");
  }

  if (!isNil(invoice.subcontractor)) {
    return invoice.subcontractor.name;
  }

  if (!isNil(invoice.subcontractorEmail)) {
    return invoice.subcontractorEmail;
  }

  return "";
};

export const subcontractorDocumentTitle = (
  invoice: Pick<IInvoice, "builderEmail"> & { builder: Pick<ICompany, "name"> },
) => {
  if (!isNil(invoice.builder)) {
    return invoice.builder.name;
  }

  return invoice.builderEmail;
};

export const paymentDateOrStatus = (
  invoice: Pick<IInvoice, "paidAt" | "projectedPaidAt" | "state" | "kind" | "paymentKind">,
  currentUser: CurrentUser,
  t: TFunction,
): string => {
  if (isApproved(invoice)) {
    return format(invoice.projectedPaidAt);
  }
  if (isPaid(invoice)) {
    return format(invoice.paidAt);
  }
  return status(invoice, currentUser, t);
};
