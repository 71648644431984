import { useSnackbar, SnackbarOrigin } from "notistack";
import { useTranslation } from "react-i18next";

const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const showSuccessNotification = (message?: string) => {
    enqueueSnackbar(message ?? t("defaultSuccessNotificationText"), {
      variant: "success",
      anchorOrigin: ANCHOR_ORIGIN,
    });
  };
  const showErrorNotification = (message?: string) => {
    enqueueSnackbar(message ?? t("defaultErrorNotificationText"), {
      variant: "error",
      anchorOrigin: ANCHOR_ORIGIN,
    });
  };

  return {
    showSuccessNotification,
    showErrorNotification,
  };
};
