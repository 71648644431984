import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isNil, isEmpty } from 'ramda';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

import { format } from 'utils/dateUtils';
import { isBlank } from 'utils/storeUtils';
import ProjectPresenter, { PROJECT_STATES } from 'presenters/ProjectPresenter';
import Icon from 'components/Icon';

import LinkedTableCell from './components/LinkedTableCell';
import useStyles from './useStyles';

const MAX_COLS_COUNT = 8;

const ProjectsTableBody = props => {
  const { variant, projects, isLoading, nextPage, onProjectsLoad } = props;
  const classes = useStyles();

  const handleAdditionalProjectsLoad = () => {
    onProjectsLoad(nextPage);
  };

  const renderProjectNameIcon = () => {
    switch (variant) {
      case PROJECT_STATES.active:
        return <Icon name="activeProject" width={38} height={38} />;
      case PROJECT_STATES.draft:
        return <Icon name="prospectProject" width={38} height={38} />;
      case PROJECT_STATES.completed:
        return <Icon name="completedProject" width={38} height={38} />;
      default:
        return null;
    }
  };

  const renderCells = project => {
    if (variant === PROJECT_STATES.draft) {
      const probability = isBlank(ProjectPresenter.probability(project))
        ? ''
        : `${ProjectPresenter.probability(project)} %`;

      return (
        <>
          <LinkedTableCell project={project} classes={{ root: classes.cell }}>
            {probability}
          </LinkedTableCell>
          <LinkedTableCell project={project} classes={{ root: classes.cell }}>
            {format(ProjectPresenter.startedAt(project))}
          </LinkedTableCell>
          <LinkedTableCell project={project} classes={{ root: classes.cell }}>
            {ProjectPresenter.duration(project)} days
          </LinkedTableCell>
          <LinkedTableCell project={project} classes={{ root: classes.cell }} align="right">
            {ProjectPresenter.formattedUSDContractValue(project)}
          </LinkedTableCell>
        </>
      );
    }

    return (
      <>
        <LinkedTableCell project={project} classes={{ root: classes.cell }}>
          {format(ProjectPresenter.startedAt(project))}
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }}>
          {ProjectPresenter.duration(project)} days
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }}>
          {format(ProjectPresenter.endedAt(project))}
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }} align="right">
          {ProjectPresenter.formattedUSDContractValue(project)}
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }} align="right">
          {ProjectPresenter.formattedUSDThirdPartyExpenses(project)}
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }} align="right">
          {ProjectPresenter.formattedUSDOverheadExpenses(project)}
        </LinkedTableCell>
        <LinkedTableCell project={project} classes={{ root: classes.cell }} align="right">
          {ProjectPresenter.formattedUSDProfit(project)} ({ProjectPresenter.percentageOfProfit(project)})
        </LinkedTableCell>
      </>
    );
  };

  const renderProject = (project, idx) => {
    const isLast = idx + 1 === projects.length;

    return (
      <TableRow key={project.id} classes={{ root: clsx(isLast && classes.lastRow) }}>
        <LinkedTableCell project={project} classes={{ root: classes.cell }}>
          <div className={classes.projectNameContainer}>
            {renderProjectNameIcon()}
            <span className={classes.projectName}>{ProjectPresenter.name(project)}</span>
          </div>
        </LinkedTableCell>
        {renderCells(project)}
      </TableRow>
    );
  };

  const renderProjects = () => {
    if (isEmpty(projects) && !isLoading) {
      return (
        <TableRow classes={{ root: classes.lastRow }}>
          <TableCell classes={{ root: classes.cell }} colSpan={MAX_COLS_COUNT} align="center">
            <span className={classes.noProjectsLabel}>No projects added.</span>
          </TableCell>
        </TableRow>
      );
    }

    return projects.map(renderProject);
  };

  const renderLoadMoreButton = () => {
    if (isNil(nextPage)) {
      return null;
    }

    return (
      <TableRow className={classes.loadMore}>
        <TableCell className={classes.cell} colSpan={MAX_COLS_COUNT} align="center">
          <Button color="primary" onClick={handleAdditionalProjectsLoad}>
            Load More
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const renderLoader = () => {
    return (
      <TableRow classes={{ root: classes.lastRow }}>
        <TableCell classes={{ root: classes.cell }} colSpan={MAX_COLS_COUNT} align="center">
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableBody>
      {renderProjects()}
      {isLoading ? renderLoader() : renderLoadMoreButton()}
    </TableBody>
  );
};

ProjectsTableBody.propTypes = {
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  variant: PropTypes.oneOf(Object.values(PROJECT_STATES)).isRequired,
  onProjectsLoad: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
};

ProjectsTableBody.defaultProps = {
  nextPage: null,
};

export default ProjectsTableBody;
