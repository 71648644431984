import { FC, useEffect, useMemo, useState } from "react";

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceBuilderNameCard from "components/common/InvoiceBuilderNameCard";
import NoWrap from "components/common/NoWrap";
import TableLoadMore from "components/common/TableLoadMore";

import { InvoiceState } from "domain/invoice/enums";
import * as InvoicePresenter from "domain/invoice/presenter";

import { formatUSD } from "libs/currency";
import { formatSimpleDate } from "libs/datetime";

import { useGetBuilderCompanyInvoicesQuery } from "store/api/builder/company/invoice/apiSlice";
import { BUILDER_COMPANY_INVOICES_PER_PAGE } from "store/api/builder/company/invoice/constants";
import { BuilderCompanyInvoice } from "store/api/builder/company/invoice/types";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import { getSortingParameter } from "utils/tableSorting";

import { IApprovedInvoicesProps } from "./types";

const columnHelper = createColumnHelper<BuilderCompanyInvoice>();

const ApprovedInvoices: FC<IApprovedInvoicesProps> = (props) => {
  const { t } = useTranslation(["common", "builderInvoices"]);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { onStatusCountChange } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [builderCompanyInvoices, setBuilderCompanyInvoices] = useState<Array<BuilderCompanyInvoice>>([]);

  const queryParameters = useMemo(() => {
    return {
      q: {
        stateIn: [InvoiceState.approved],
        s: getSortingParameter(sorting),
      },
      perPage: BUILDER_COMPANY_INVOICES_PER_PAGE,
      page: currentPage,
    };
  }, [currentPage, sorting]);

  const { currentData: approvedData, isFetching } = useGetBuilderCompanyInvoicesQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (approvedData?.invoices && queryParameters.page === 1) {
      setBuilderCompanyInvoices(approvedData.invoices);
    }
    if (approvedData?.invoices && queryParameters.page > 1) {
      setBuilderCompanyInvoices((invoices) => [...invoices, ...approvedData.invoices]);
    }
  }, [queryParameters, approvedData?.invoices]);

  useEffect(() => {
    if (approvedData?.meta.totalCount) {
      onStatusCountChange("approved", approvedData?.meta.totalCount);
    }
  }, [approvedData?.meta.totalCount]);

  const history = useHistory();

  const columns = useMemo(
    () => [
      columnHelper.accessor("subcontractorEmail", {
        header: t("builderInvoices:table.headers.subcontractor") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceBuilderNameCard invoice={info.row.original} />,
      }),
      columnHelper.accessor("projectName", {
        header: t("builderInvoices:table.headers.projectName") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceAssignedProjects invoice={info.row.original} />,
      }),
      columnHelper.accessor("number", {
        header: t("builderInvoices:table.headers.invoiceNumber") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap title={`${info.row.original.number}`}>{info.row.original.number}</NoWrap>,
      }),
      columnHelper.accessor("state", {
        header: t("builderInvoices:table.headers.status") ?? "",
        enableSorting: true,
        cell: (info) => {
          const status = currentUser && InvoicePresenter.status(info.row.original, currentUser, t);
          return <NoWrap title={status}>{status}</NoWrap>;
        },
      }),
      columnHelper.accessor("projectedPaidAt", {
        header: t("builderInvoices:table.headers.paymentDueDate") ?? "",
        enableSorting: true,
        sortDescFirst: true,
        cell: (info) => {
          const date = info.row.original.projectedPaidAt;
          return isNil(date) ? "—" : <NoWrap>{formatSimpleDate(date)}</NoWrap>;
        },
      }),
      columnHelper.accessor("totalAmount", {
        header: t("builderInvoices:table.headers.invoiceAmount") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap>{formatUSD(info.row.original.totalAmount)}</NoWrap>,
        meta: { align: "right", width: "100px" },
      }),
      columnHelper.accessor("invoiceEarlyPayment.amount", {
        header: t("builderInvoices:table.headers.acceptedAmount") ?? "",
        enableSorting: true,
        sortDescFirst: true,
        cell: (info) => {
          const { invoiceEarlyPayment } = info.row.original;
          return isNil(invoiceEarlyPayment) ? "—" : <NoWrap>{formatUSD(invoiceEarlyPayment.amount)}</NoWrap>;
        },
        meta: { align: "right", width: "100px" },
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: builderCompanyInvoices,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    enableMultiSort: false,
  });

  const handleLoadMore = () => {
    setCurrentPage((previousPage) => previousPage + 1);
  };

  const handleRowClick = (invoice: BuilderCompanyInvoice) => {
    history.push(AppRoutes.invoicePath(invoice.id));
  };

  return (
    <Div>
      <TableLoadMore<BuilderCompanyInvoice>
        table={table}
        onRowClick={handleRowClick}
        onLoadMore={handleLoadMore}
        loading={isFetching}
        meta={approvedData?.meta}
      />
    </Div>
  );
};

export default ApprovedInvoices;
