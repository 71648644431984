import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  fieldsRow: {
    marginBottom: theme.spacing(2),
  },
  inputHelperText: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  input: {
    padding: '6px, 9px',
  },
  amountStartIcon: {
    marginLeft: theme.spacing(1),
  },
  removeButton: {
    height: '100%',
  },
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  deleteButton: {
    marginTop: 12,
  },
}));
