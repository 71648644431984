import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import MembersRepository from 'repositories/builder/company/MembersRepository';

const initialState = {
  members: {
    data: [],
    meta: {},
  },
};

const builderTeamSettingsSlice = createSlice({
  name: 'builderTeamSettingsSlice',
  initialState,
  reducers: {
    loadMembersSuccess(state, { payload }) {
      state.members.data = concat(state.members.data, payload.users);
      state.members.meta = payload.meta;
      state.members.isLoading = false;
    },
    editMember(state, { payload }) {
      state.members.data = state.members.data.map(member => {
        if (member.id === payload.user.id) {
          return payload.user;
        }

        return member;
      });
    },
    resetBuilderTeamSettings() {
      return initialState;
    },
  },
});

export const { actions } = builderTeamSettingsSlice;
export default builderTeamSettingsSlice.reducer;

export const useBuilderTeamSettingsActions = () => {
  const dispatch = useDispatch();

  const loadMembers = async (page = 1) => {
    const { data } = await MembersRepository.index({ q: { s: 'id desc' }, page });
    dispatch(actions.loadMembersSuccess(data));

    return Promise.resolve(data);
  };

  const createMember = params => {
    return MembersRepository.create({ user: params });
  };

  const updateMember = async (id, params) => {
    const { data } = await MembersRepository.update(id, { user: params });
    dispatch(actions.editMember(data));
  };

  const destroyMember = id => {
    return MembersRepository.destroy(id);
  };

  const resetBuilderTeamSettings = () => {
    dispatch(actions.resetBuilderTeamSettings());
  };

  return {
    loadMembers,
    createMember,
    updateMember,
    destroyMember,
    resetBuilderTeamSettings,
  };
};
