import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  paper: {
    width: 180,
    position: 'fixed',
    height: '100vh',
    zIndex: 1301,
    left: 63,
    top: '48px',
    padding: '40px 8px 0',
    boxShadow: 'inset 1px 0px 0px rgba(32, 36, 20, 0.1)',
    [theme.breakpoints.up('sm')]: {
      top: '64px',
      zIndex: 1300,
    },
    [theme.breakpoints.up('lg')]: {
      top: 0,
      boxShadow: 'none',
    },
  },
  title: {
    margin: '0 16px',
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.colors.deltaGray,
  },
  navigation: {
    marginTop: 24,
  },
}));
