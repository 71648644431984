import * as yup from 'yup';

import { REQUIRED } from 'forms/errorsText';

const validationFields = {
  projectId: yup.string().required(REQUIRED),
  customerInvoiceName: yup.string().required(REQUIRED),
};

export const initialValues = {
  projectId: '',
  projectName: '',
  customerInvoiceName: '',
  customerInvoiceId: '',
};

export const validationSchema = yup.object().shape(validationFields);

export const formPaths = {
  projectId: 'projectId',
  projectName: 'projectName',
  customerInvoiceName: 'customerInvoiceName',
  customerInvoiceId: 'customerInvoiceId',
};
