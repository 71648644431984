import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:last-child $cell': {
      border: 'none',
    },
    height: 56,
  },
  cell: {
    fontSize: theme.typography.pxToRem(14),
    padding: '11px 16px 11px 0',
  },
  projectCell: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  amountCell: {
    paddingRight: 60,
  },
  editButton: {
    marginLeft: 'auto',
  },
}));
