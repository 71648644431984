import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 80,
    maxWidth: 329,
    fontSize: 15,
    lineHeight: '20px',
  },
  text: {
    marginTop: 16,
  },
  signInContainer: {
    marginTop: 48,
  },
  linkRoot: {
    fontSize: 15,
    lineHeight: '20px',
  },
}));
