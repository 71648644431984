import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const PERIODS = {
  everyWeek: 'every_week',
  everyTwoWeeks: 'every_two_weeks',
  everyMonth: 'every_month',
};

export default new Presenter(
  {
    id: PropTypes.number,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    period: PropTypes.string,
    totalPaid: PropTypes.string,
  },
  {
    getPeriodLabel(regularPayment) {
      switch (this.period(regularPayment)) {
        case PERIODS.everyWeek:
          return 'per week';
        case PERIODS.everyTwoWeeks:
          return 'every two weeks';
        case PERIODS.everyMonth:
          return 'per month';
        default:
          return null;
      }
    },
  },
);
