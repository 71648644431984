import { FC } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useGetUSAStatesQuery } from "store/api/state/apiSlice";
import { IUSAState } from "store/api/state/types";

import { IUSAStatesAutocompleteProps } from "./types";

const USAStatesAutocomplete: FC<IUSAStatesAutocompleteProps> = (props) => {
  const { label, onChange, name } = props;
  const { data: USAStates, isFetching } = useGetUSAStatesQuery();

  const handleChange = (event: React.SyntheticEvent, USAState: IUSAState | null) => {
    onChange(USAState?.name ?? "");
  };

  return (
    <Autocomplete
      id="usa-states-combobox"
      options={USAStates ?? []}
      loading={isFetching}
      onChange={handleChange}
      autoComplete
      getOptionLabel={(option) => option.name}
      renderInput={(parameters) => <TextField {...parameters} type="text" label={label} name={name} />}
    />
  );
};

export default USAStatesAutocomplete;
