import { useTranslation } from "react-i18next";

import Div from "components/common/Div";
import Icon from "components/Icon";
import PageHeader from "components/PageHeader";

import styles from "./styles";

const Header = () => {
  const { t } = useTranslation("adminInvoices");

  return (
    <Div sx={styles.header}>
      <Div sx={styles.titleContainer}>
        <PageHeader text={t("title")} icon={<Icon width={28} height={28} name="file" fill="#6ED224" />} />
      </Div>
    </Div>
  );
};

export default Header;
