import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Icon from 'components/Icon';
import SectionTitle from 'components/SectionTitle';
import LineItems from 'containers/Builder/LineItems';

import useStyles from './useStyles';

const CompanySettings = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <div className={classes.titleContainer}>
          <Icon className={classes.titleIcon} width={28} height={28} name="gear" />
          <Typography classes={{ root: classes.title }} variant="h1">
            Company Settings
          </Typography>
        </div>
      </header>
      <div className={classes.sectionTitleContainer}>
        <SectionTitle>Line Items</SectionTitle>
      </div>
      <div className={classes.sectionTitle}>Line Items</div>
      <Paper classes={{ root: classes.paper }}>
        <Typography variant="h4">Project Line Items And Accounting Codes</Typography>
        <div className={classes.tip}>
          Enter cost codes and line items used to manage income and expenses at the project level.
        </div>
        <LineItems />
      </Paper>
    </main>
  );
};

export default CompanySettings;
