import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import CompanyOwnerPresenter from 'presenters/CompanyOwnerPresenter';
import { formatUSD } from 'utils/numberUtils';

export const TYPES = {
  builder: 'Builder',
  subcontractor: 'Subcontractor',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    city: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    type: PropTypes.string,
    companyOwner: CompanyOwnerPresenter.shape(),
    companyLoan: PropTypes.string,
  },
  {
    isBuilder(company) {
      return this.type(company) === TYPES.builder;
    },
    isSubcontractor(company) {
      return this.type(company) === TYPES.subcontractor;
    },
    fullAddress(company) {
      return `${this.address1(company)}, ${this.city(company)}, ${this.state(company)} ${this.zipCode(company)}`;
    },
    companyOwnerEmail(builder) {
      return CompanyOwnerPresenter.email(this.companyOwner(builder));
    },
    formattedUSDLoans(company) {
      return formatUSD(this.companyLoan(company), 2);
    },
  },
);
