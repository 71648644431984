import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { ISessionCreateFormSubmitData } from "./schemas/create";

const sessionApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    createSession: builder.mutation<void, ISessionCreateFormSubmitData>({
      query: (formData) => ({
        url: apiRoutes.siteApiV1SessionPath(),
        method: "POST",
        data: formData,
      }),
      invalidatesTags: [CacheTag.currentUser],
    }),
    deleteSession: builder.mutation<void, void>({
      query: () => ({
        url: apiRoutes.siteApiV1SessionPath(),
        method: "DELETE",
      }),
      invalidatesTags: [CacheTag.currentUser],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateSessionMutation, useDeleteSessionMutation } = sessionApi;
