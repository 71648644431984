import * as yup from 'yup';

import { EMAIL } from 'forms/errorsText';
import { COMPANY_ROLES } from 'presenters/UserPresenter';

const validationFields = {
  email: yup.string().email(EMAIL).required(EMAIL),
  role: yup.string().oneOf(Object.values(COMPANY_ROLES)),
};

export const initialValues = {
  email: '',
  role: COMPANY_ROLES.manager,
};

export const validationSchema = yup.object().shape(validationFields);
