import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  projectPaidAtDifferentContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));
