import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    gap: '28px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: 0,
    },
  },
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  deleteButton: {
    marginTop: 6,
  },
}));
