import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { isNil } from 'ramda';

import AppRoutes from 'routes/AppRoutes';
import { useUserLayoutActions } from 'store/UserLayoutSlice';

const COLLAPSIBLE_PATHS = [AppRoutes.projectPath(':id'), AppRoutes.settingsCompanyPath(), AppRoutes.settingsTeamPath()];

const shouldSetSidebarSubmenu = pathname => COLLAPSIBLE_PATHS.some(path => matchPath(pathname, { path }));

const useUserLayout = navItems => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const { setSidebarSubmenu, setSidebarTempSubmenu } = useUserLayoutActions();
  const {
    sidebar: { submenu, tempSubmenu },
    isLoading,
  } = useSelector(state => state.UserLayoutSlice);
  const location = useLocation();

  const submenuNavItems = navItems.filter(item => !isNil(item.submenu));

  useEffect(() => {
    const shouldCollapseSidebar = shouldSetSidebarSubmenu(location.pathname);
    setIsSidebarCollapsed(shouldCollapseSidebar);

    const activeSubmenuNavItem = submenuNavItems.find(item =>
      item.paths.some(path => matchPath(location.pathname, { path })),
    );

    if (activeSubmenuNavItem) {
      setSidebarSubmenu({ title: activeSubmenuNavItem.label, items: activeSubmenuNavItem.submenu });
    } else if (!shouldCollapseSidebar) {
      setSidebarSubmenu(null);
      setSidebarTempSubmenu(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsSidebarCollapsed(!(isNil(submenu) && isNil(tempSubmenu)));
  }, [submenu, tempSubmenu]);

  return {
    isSidebarCollapsed,
    isLoading,
    submenu,
    tempSubmenu,
    setSidebarTempSubmenu,
  };
};

export default useUserLayout;
