import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  tableContainer: {
    backgroundColor: theme.palette.colors.smokeWhite,
    padding: '12px 16px',
  },
  table: {
    padding: 0,
  },
  headerRow: {
    boxShadow: `inset 0px -1px 0px ${theme.palette.colors.catskillWhite}`,
  },
  cell: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bodyCell: {
    paddingLeft: 0,
    border: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
  },
  bodyCellAlignRight: {
    border: 'none',
    paddingLeft: 4,
    paddingRight: 0,
    paddingTop: 4,
    paddingBottom: 4,
  },
}));
