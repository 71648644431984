export const SORTING_RULE = {
  startedAt: 'started_at',
  duration: 'duration',
  endedAt: 'ended_at',
  contractValue: 'contract_value',
  thirdPartyExpenses: 'third_party_expenses',
  overheadExpenses: 'overhead_expenses',
  profit: 'profit',
  probability: 'probability',
};
