import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.colors.gunsmokeGray,
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  contentContainer: {
    marginBottom: theme.spacing(6),
  },
  checkAddressContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.colors.lilyWhiteGray,
    border: `1px solid ${theme.palette.colors.nickelGray}`,
    borderRadius: 3,
  },
  checkAddressDescription: {
    margin: '24px 16px',
  },
  address: {
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 700,
  },
  backButton: {
    minWidth: theme.spacing(1),
    padding: 0,
  },
  backButtonContainer: {
    marginBottom: theme.spacing(4),
  },
}));
