import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import {
  MAX_COLS_COUNT,
  VARIANT_COLORS,
  VARIANT_TITLE,
  INVOICES_TABLE_STATUS,
  GC_INVOICES_SORTING_RULE,
} from 'constants/invoices';
import TableWithStatus from 'components/TableWithStatus';
import InvoicePresenter from 'presenters/InvoicePresenter';

import PayableInvoicesTableRows from './components/PayableInvoicesTableRows';
import PayableInvoicesOpenTableRows from './components/PayableInvoicesOpenTableRows';

const getHeaders = variant => {
  const isStatusOpen = variant === INVOICES_TABLE_STATUS.open;
  return [
    {
      text: isStatusOpen ? 'Project' : 'Assigned Project(s)',
      width: '22.13%',
      sortingRule: GC_INVOICES_SORTING_RULE.projectName,
    },
    {
      text: isStatusOpen ? 'Invoice Name' : 'Line Item(s)',
      width: '22.13%',
      sortingRule: isStatusOpen ? GC_INVOICES_SORTING_RULE.name : null,
    },
    {
      text: isStatusOpen ? 'Status' : '',
      width: '17.24%',
      sortingRule: isStatusOpen ? GC_INVOICES_SORTING_RULE.state : null,
    },
    {
      text: 'Amount',
      align: 'right',
      width: '10.63%',
      sortingRule: GC_INVOICES_SORTING_RULE.totalAmount,
    },
  ];
};

const PayableInvoicesTable = props => {
  const { loadMore, variant, invoices, meta, isLoading, onRowClick, isSortable } = props;

  return (
    <TableWithStatus
      isLoading={isLoading}
      maxColsCount={MAX_COLS_COUNT}
      title={VARIANT_TITLE[variant]}
      isData={!isEmpty(invoices)}
      count={meta.totalCount}
      nextPage={meta.nextPage}
      color={VARIANT_COLORS[variant]}
      loadMore={loadMore}
      headers={getHeaders(variant)}
      rows={invoices.map(invoice =>
        variant === INVOICES_TABLE_STATUS.open ? (
          <PayableInvoicesOpenTableRows key={InvoicePresenter.id(invoice)} invoice={invoice} onRowClick={onRowClick} />
        ) : (
          <PayableInvoicesTableRows key={InvoicePresenter.id(invoice)} invoice={invoice} onRowClick={onRowClick} />
        ),
      )}
      isSortable={isSortable}
    />
  );
};

PayableInvoicesTable.propTypes = {
  loadMore: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(INVOICES_TABLE_STATUS)).isRequired,
  invoices: PropTypes.arrayOf(InvoicePresenter.shape()).isRequired,
  meta: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func,
  isSortable: PropTypes.bool,
};

PayableInvoicesTable.defaultProps = {
  onRowClick: Function.prototype,
  isSortable: false,
};

export default PayableInvoicesTable;
