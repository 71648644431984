import { useState } from 'react';
import PropTypes from 'prop-types';

import { addDays } from 'date-fns';

import InvoiceResponseRejectForm from 'components/InvoiceResponseRejectForm';
import InvoicePresenter from 'presenters/InvoicePresenter';

import AdminInvoiceApproveForm from './components/AdminInvoiceApproveForm';

const AdminInvoiceResponseForm = props => {
  const { invoice, approveInvoice, rejectInvoice } = props;
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);

  const handleRejectFormOpen = () => {
    setIsRejectFormOpen(true);
  };

  const handleRejectFormClose = () => {
    setIsRejectFormOpen(false);
  };

  const handleApproveInvoice = () => {
    const projectedPaidAt = addDays(new Date(), 3).getTime();
    approveInvoice({ id: InvoicePresenter.id(invoice), projectedPaidAt });
  };

  const handleRejectInvoice = rejectionReason => {
    rejectInvoice({ id: InvoicePresenter.id(invoice), rejectionReason });
  };

  return (
    <div>
      {isRejectFormOpen ? (
        <InvoiceResponseRejectForm onReject={handleRejectInvoice} onCancel={handleRejectFormClose} />
      ) : (
        <AdminInvoiceApproveForm
          invoice={invoice}
          approveInvoice={handleApproveInvoice}
          onOpenRejectForm={handleRejectFormOpen}
        />
      )}
    </div>
  );
};

AdminInvoiceResponseForm.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  approveInvoice: PropTypes.func.isRequired,
  rejectInvoice: PropTypes.func.isRequired,
};

export default AdminInvoiceResponseForm;
