import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import SectionTitle from 'components/SectionTitle';
import PdfViewer from 'components/PdfViewer';
import { downloadFileByUrl } from 'utils/DownloadHelpers';

import useStyles from './useStyles';

const InvoiceAttachmentPreview = props => {
  const { fileUrl } = props;
  const classes = useStyles();

  const handleAttachmentDownLoad = () => {
    downloadFileByUrl(fileUrl);
  };

  return (
    <div>
      <SectionTitle className={classes.sectionTitle}>Attachment</SectionTitle>
      <Paper className={classes.invoicePreviewContainer}>
        <div className={classes.downloadButtonContainer}>
          <Button variant="outlined" color="primary" onClick={handleAttachmentDownLoad}>
            Download Attachment
          </Button>
        </div>
        <div className={classes.preview}>
          <PdfViewer fileUrl={fileUrl} />
        </div>
      </Paper>
    </div>
  );
};

InvoiceAttachmentPreview.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default InvoiceAttachmentPreview;
