import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { formatUSD } from 'utils/numberUtils';
import { format } from 'utils/dateUtils';

export const TRANSACTIONS_STATES = {
  requested: 'requested',
  created: 'created',
  paid: 'paid',
  failed: 'failed',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    amount: PropTypes.string,
    createdAt: PropTypes.string,
    company: CompanyPresenter.shape(),
    project: ProjectPresenter.shape(),
    customerInvoice: CustomerInvoicePresenter.shape(),
    customer: UserPresenter.shape(),
    state: PropTypes.string,
    source: PropTypes.string,
  },
  {
    formattedUSDAmount(transaction, maximumFractionDigits = 2) {
      const amount = this.amount(transaction);
      return formatUSD(amount, maximumFractionDigits);
    },
    companyName(transaction) {
      const company = this.company(transaction);
      return CompanyPresenter.name(company);
    },
    projectName(transaction) {
      const project = this.project(transaction);
      return ProjectPresenter.name(project);
    },
    customerInvoiceName(transaction) {
      const customerInvoice = this.customerInvoice(transaction);
      return ProjectPresenter.name(customerInvoice);
    },
    formattedCreatedAt(transaction) {
      return format(this.createdAt(transaction));
    },
    customerName(transaction) {
      const customer = this.customer(transaction);
      return UserPresenter.fullName(customer);
    },
  },
);
