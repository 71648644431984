import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import SetupOptions from './components/SetupOptions';
import LineItemsOnboarding from './components/LineItemsOnboarding';

import useStyles from './useStyles';

const LineItemsSetup = props => {
  const classes = useStyles();
  const { onCurrentUserLoad, onLineItemsOnboardingProceed } = props;
  const [isWantToSetup, setIsWantToSetup] = useState(false);

  const handleManualSetupChoose = () => {
    setIsWantToSetup(true);
  };

  const handleLineItemsOnboardingProceed = async () => {
    await onLineItemsOnboardingProceed();
    onCurrentUserLoad();
  };

  const renderContent = () => {
    if (!isWantToSetup) {
      return (
        <SetupOptions
          onManualSetupChoose={handleManualSetupChoose}
          onLineItemsOnboardingProceed={handleLineItemsOnboardingProceed}
        />
      );
    }

    return <LineItemsOnboarding onLineItemsOnboardingProceed={handleLineItemsOnboardingProceed} />;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Line Items</Typography>
      {renderContent()}
    </div>
  );
};

LineItemsSetup.propTypes = {
  onCurrentUserLoad: PropTypes.func.isRequired,
  onLineItemsOnboardingProceed: PropTypes.func.isRequired,
};

export default LineItemsSetup;
