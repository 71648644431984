import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ContentBox from 'components/ContentBox';
import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues } from 'forms/Builder/InvoiceRejectForm';

import useStyles from './useStyles';

const InvoiceResponseRejectForm = props => {
  const { isDisabled, onReject, onCancel } = props;
  const classes = useStyles();
  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: async ({ rejectionReason }) => {
      await onReject(rejectionReason);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={classes.rejectContainer}>
        <Grid item xs={8} md={9} lg={10}>
          <ContentBox title="Reason of rejection" className={classes.contentBox}>
            <TextField
              multiline
              value={values.rejectionReason}
              rows={3}
              disabled={isDisabled}
              name="rejectionReason"
              aria-label="textarea"
              onChange={handleChange}
              error={touched.rejectionReason && Boolean(errors.rejectionReason)}
              helperText={touched.rejectionReason && errors.rejectionReason}
              FormHelperTextProps={{ className: classes.helperText }}
            />
          </ContentBox>
        </Grid>
        <Grid item xs={4} md={3} lg={2} className={classes.buttonsContainer}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Button
              className={classes.rejectButton}
              type="submit"
              disabled={isDisabled}
              variant="contained"
              color="secondary"
            >
              Reject invoice
            </Button>
            <Button className={classes.cancelButton} onClick={onCancel} disabled={isDisabled}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

InvoiceResponseRejectForm.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onReject: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InvoiceResponseRejectForm;
