import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

import useBreakpoints from 'hooks/useBreakpoints';

import Navigation from 'components/Navigation';

import useStyles from './useStyles';

const Submenu = props => {
  const { submenu, isDrawerOpen, onDrawerClose } = props;
  const classes = useStyles();
  const { screenWiderThan } = useBreakpoints();

  if (!isDrawerOpen && !screenWiderThan.lg) {
    return null;
  }

  return (
    <Paper classes={{ root: classes.paper }} elevation={0} square>
      <div className={classes.title}>{submenu.title}</div>
      <div className={classes.navigation}>
        <Navigation navItems={submenu.items} variant="expanded" onDrawerClose={onDrawerClose} />
      </div>
    </Paper>
  );
};

Submenu.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  submenu: PropTypes.shape({
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default Submenu;
