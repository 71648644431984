import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, path } from 'ramda';
import { nanoid } from 'nanoid';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';

import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues } from 'forms/InvitationForm';
import { INVITATION_VARIANTS } from 'constants/invitationForm';
import { getTouchedFieldFormikError } from 'utils/formUtils';
import useLoading from 'hooks/useLoading';
import Icon from 'components/Icon';
import Autocomplete from 'components/Autocomplete';
import LinearProgress from 'components/LinearProgress';
import DeleteButton from 'components/DeleteButton';
import { sentencize } from 'utils/stringUtils';

import useStyles from './useStyles';

const TITLE_VARIANT = {
  [INVITATION_VARIANTS.subcontractor]: 'Invite Subcontractor',
  [INVITATION_VARIANTS.builder]: 'Invite General Contractors',
};

const API_ERROR_PREFIX_VARIANT = {
  [INVITATION_VARIANTS.subcontractor]: 'subcontractors',
  [INVITATION_VARIANTS.builder]: 'builders',
};

const InvitationForm = props => {
  const { variant, onUsersLoad, onSubmit, onCancel } = props;
  const classes = useStyles();
  const [apiErrors, setApiErrors] = useState({});
  const [options, setOptions] = useState([]);
  const { isPending, func: sendContact } = useLoading(onSubmit, { isAbortable: true });
  const { func: sendUsersLoad, isPending: isUsersPending } = useLoading(onUsersLoad, { isAbortable: true });
  const { touched, errors, setFieldValue, handleChange, values, setValues, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async ({ contacts }) => {
      try {
        await sendContact(contacts);
      } catch (error) {
        setApiErrors(error);
      }
    },
  });

  const loadUsers = async value => {
    const { companies } = await sendUsersLoad(value);
    setOptions(companies);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleUserAdd = () => {
    setValues({ contacts: [...values.contacts, { email: '', key: nanoid() }] });
  };

  const getEmailErrors = ({ index, apiErrorPath }) => {
    if (isNil(apiErrors[apiErrorPath])) {
      return getTouchedFieldFormikError({ touched, errors, errorPath: ['contacts', index, 'email'] });
    }

    return sentencize(path([apiErrorPath, 0], apiErrors));
  };

  const handleRemove = removeIndex => () => {
    setValues(prevValues => ({ contacts: prevValues.contacts.filter((_, index) => removeIndex !== index) }));
  };

  const handleInputChange = fieldName => (_, value) => {
    if (!value) {
      setFieldValue(fieldName, '');
      loadUsers('');
    }
  };

  const isRemoveButtonDisable = values.contacts.length === 1;

  return (
    <div className={classes.root}>
      <LinearProgress isVisible={isPending} />
      <div className={classes.titleContainer}>
        <Icon name="invitation" width={25} height={25} className={classes.invitationIcon} />
        <Typography variant="h3" className={classes.title}>
          {TITLE_VARIANT[variant]}
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <form onSubmit={handleSubmit}>
          {values.contacts.map((user, index) => {
            const fieldName = `contacts[${index}].email`;
            const apiErrorPath = `${API_ERROR_PREFIX_VARIANT[variant]}[${index}].email`;
            const isError =
              Boolean(apiErrors[apiErrorPath]) ||
              Boolean(getTouchedFieldFormikError({ touched, errors, errorPath: ['contacts', index, 'email'] }));
            const errorText = getEmailErrors({ index, apiErrorPath });

            return (
              <Fade key={user.key} in={Boolean(user)}>
                <Grid container spacing={2} className={classes.fields}>
                  <Grid item xs={11}>
                    <Autocomplete
                      label="Email Address"
                      options={options}
                      inputValue={user.email}
                      fieldName={fieldName}
                      disableClearable={false}
                      disabled={isPending}
                      error={isError}
                      isLoading={isUsersPending}
                      helperText={errorText}
                      onChange={handleChange}
                      onInputChange={handleInputChange(fieldName)}
                      onFieldValueSet={setFieldValue}
                      onLoad={loadUsers}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <div className={classes.deleteButtonContainer}>
                      <DeleteButton isDisabled={isRemoveButtonDisable} onClick={handleRemove(index)} />
                    </div>
                  </Grid>
                </Grid>
              </Fade>
            );
          })}
          <Button
            variant="text"
            color="primary"
            disabled={isPending}
            className={classes.addButton}
            onClick={handleUserAdd}
          >
            Add Another
          </Button>
          <div className={classes.actions}>
            <Button variant="contained" disabled={isPending} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={isPending}
              color="primary"
              type="submit"
              className={classes.submitButton}
            >
              Invite
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

InvitationForm.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVITATION_VARIANTS)).isRequired,
  onUsersLoad: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InvitationForm;
