import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';

import { useAdminLoansActions, selectors } from 'store/AdminLoansSlice';
import { formatUSD } from 'utils/numberUtils';

const TotalLoans = () => {
  const { loadTotalLoansInformation, resetTotalLoansInformation } = useAdminLoansActions();
  const { totalAmount, totalCustomersPaid, notRepaid } = useSelector(selectors.totalLoansInformation);

  useEffect(() => {
    loadTotalLoansInformation();
    return resetTotalLoansInformation;
  }, []);

  return (
    <div>
      <Typography variant="h4">Total loans across the entire Funl platform</Typography>
      <Typography>
        Issued: <span>{formatUSD(totalAmount)}</span>
      </Typography>
      <Typography>
        Total customers paid: <span>{formatUSD(totalCustomersPaid)}</span>
      </Typography>
      <Typography>
        Not repaid: <span>{formatUSD(notRepaid)}</span>
      </Typography>
    </div>
  );
};

export default TotalLoans;
