import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { equals } from 'ramda';

import Typography from '@mui/material/Typography';

import useLoading from 'hooks/useLoading';
import { useBuilderInvoiceCreateActions, selectors } from 'store/BuilderInvoiceCreateSlice';
import AutocompletePick from 'components/AutocompletePick';
import ProjectPresenter, { PROJECT_STATES, PROJECT_APPROVAL_STATES } from 'presenters/ProjectPresenter';

import useStyles from './useStyles';

const ProjectNameAutocomplete = props => {
  const {
    formData: { values, errors, setFieldValue },
  } = props;
  const predefinedProject = useSelector(selectors.predefinedProject, equals);
  const classes = useStyles();
  const { loadProjects, resetBuilderInvoiceCreateState } = useBuilderInvoiceCreateActions();
  const { func: sendLoadProjects, isPending } = useLoading(loadProjects);

  const [options, setOptions] = useState([]);

  const setProject = useCallback(project => {
    setFieldValue('projectName', ProjectPresenter.name(project));
    setFieldValue('projectId', ProjectPresenter.id(project));
  }, []);

  const handleLoad = async name => {
    const { projects } = await sendLoadProjects({
      name,
      state: PROJECT_STATES.active,
      approvalState: PROJECT_APPROVAL_STATES.approved,
    });
    setOptions(projects);
  };

  const handleFieldValueSet = (_, option) => {
    setProject(option);
  };

  useEffect(() => {
    if (predefinedProject) {
      setProject(predefinedProject);
    }

    return resetBuilderInvoiceCreateState;
  }, [predefinedProject]);

  return (
    <AutocompletePick
      label="Project Name"
      options={options}
      displayedValueSelectedOption={values.projectName}
      fieldName="projectName"
      isOnFieldValueSetWrap={false}
      isLoading={isPending}
      classes={{ root: classes.autocompleteRoot, listbox: classes.lineItemOptions }}
      error={Boolean(errors.projectId)}
      helperText={errors.projectId}
      renderOptions={(optionProps, option) => (
        <li className={classes.option} {...optionProps}>
          <Typography className={classes.optionName}>{ProjectPresenter.name(option)}</Typography>
        </li>
      )}
      getOptionLabel={option => option.name}
      onLoad={handleLoad}
      onFieldValueSet={handleFieldValueSet}
    />
  );
};

ProjectNameAutocomplete.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default ProjectNameAutocomplete;
