import LineItemPresenter from 'presenters/LineItemPresenter';

import useStyles from './useStyles';

const LineItemStatus = props => {
  const { lineItem } = props;
  const classes = useStyles();

  const className = LineItemPresenter.isOpen(lineItem) ? classes.openStatus : classes.closeStatus;

  return <div className={className}>{LineItemPresenter.projectLineItemStateLabel(lineItem)}</div>;
};

LineItemStatus.propTypes = {
  lineItem: LineItemPresenter.shape().isRequired,
};

export default LineItemStatus;
