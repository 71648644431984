import { useSelector } from 'react-redux';

import TeamSettingsPage from 'containers/TeamSettingsPage';
import { useBuilderTeamSettingsActions } from 'store/BuilderTeamSettingsSlice';
import { TEAM_SETTINGS_VARIANTS } from 'constants/TeamSettings';

import useStyles from './useStyles';

const TeamSettings = () => {
  const classes = useStyles();
  const { loadMembers, updateMember, destroyMember, createMember, resetBuilderTeamSettings } =
    useBuilderTeamSettingsActions();
  const { data: members, meta: membersMeta } = useSelector(state => state.BuilderTeamSettingsSlice.members);

  return (
    <main className={classes.root}>
      <TeamSettingsPage
        members={members}
        membersMeta={membersMeta}
        loadMembers={loadMembers}
        updateMember={updateMember}
        destroyMember={destroyMember}
        createMember={createMember}
        resetTeamSettings={resetBuilderTeamSettings}
        variant={TEAM_SETTINGS_VARIANTS.global}
        description="Create new Funl users and set their permissions here. This will provide your team with access to your
        projects, invoices and Funl account."
      />
    </main>
  );
};

export default TeamSettings;
