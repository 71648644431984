import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import { TYPES } from 'presenters/CompanyPresenter';
import TransactionsRepository from 'repositories/admin/TransactionsRepository';
import CompaniesRepository from 'repositories/admin/CompaniesRepository';
import ProjectsRepository from 'repositories/admin/ProjectsRepository';
import CustomerInvoicesRepository from 'repositories/admin/CustomerInvoicesRepository';
import CustomersRepository from 'repositories/admin/CustomersRepository';

const MAX_PER_PAGE = 5;

const initialState = {
  active: {
    data: [],
    nextPage: null,
    totalCount: 0,
  },
};

const slice = createSlice({
  name: 'adminPayments',
  initialState,
  reducers: {
    loadActivePaymentsSuccess(state, { payload }) {
      state.active.data = concat(state.active.data, payload.transactions);
      state.active.nextPage = payload.meta.nextPage;
      state.active.totalCount = payload.meta.totalCount;
      state.active.totalInformation = payload.meta.totalInformation;
    },
    resetPayments() {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminPaymentsActions = () => {
  const dispatch = useDispatch();

  const loadActivePayments = async (page = 1) => {
    const params = {
      q: { s: 'id desc' },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await TransactionsRepository.index(params);
    dispatch(actions.loadActivePaymentsSuccess(data));

    return data;
  };

  const resetPayments = () => {
    dispatch(actions.resetPayments());
  };

  const loadCompanies = async (name, customHeaders, cancelToken) => {
    const params = {
      q: { nameCont: name, s: 'id desc', typeEq: TYPES.builder },
    };
    const { data } = await CompaniesRepository.index(params, customHeaders, cancelToken);

    return data;
  };

  const loadProjects = async ({ name, companyId, customerId }, customHeaders, cancelToken) => {
    const params = {
      q: { nameCont: name, s: 'id desc', companyIdEq: companyId, customerProjectsCustomerIdEq: customerId },
    };
    const { data } = await ProjectsRepository.index(params, customHeaders, cancelToken);

    return data;
  };

  const loadCustomerInvoices = async ({ name, projectId }, customHeaders, cancelToken) => {
    const params = {
      q: { nameCont: name, s: 'id desc', projectIdEq: projectId },
    };
    const { data } = await CustomerInvoicesRepository.index(params, customHeaders, cancelToken);

    return data;
  };

  const loadCustomers = async ({ name, companyId }, customHeaders, cancelToken) => {
    const params = {
      q: { fullNameCont: name, s: 'id desc', customerCompanyCompanyIdEq: companyId },
    };
    const { data } = await CustomersRepository.index(params, customHeaders, cancelToken);

    return data;
  };

  const createTransaction = async params => {
    return TransactionsRepository.create(params);
  };

  return {
    loadActivePayments,
    resetPayments,
    loadCompanies,
    loadProjects,
    loadCustomerInvoices,
    createTransaction,
    loadCustomers,
  };
};

export const selectors = {
  active: state => state.AdminPaymentsSlice.active,
};
