import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  totalInformationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalText: {
    fontWeight: 700,
  },
  textContainer: {
    width: '56.41%',
  },
  totalSalesPrice: {
    width: '9.36%',
    textAlign: 'right',
  },
  totalGogs: {
    width: '8%',
    textAlign: 'right',
  },
  totalBuilderFee: {
    width: '9.25%',
    textAlign: 'right',
  },
  totalProfit: {
    width: '17%',
    textAlign: 'right',
  },
}));
