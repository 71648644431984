import Typography from '@mui/material/Typography';

import useStyles from './useStyles';

const AchRequest = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h4" className={classes.title}>
        Open Invoices
      </Typography>
      <Typography className={classes.subtitle}>
        One of your partners has sent you an invoice for payment, please check it out.
      </Typography>
    </div>
  );
};

export default AchRequest;
