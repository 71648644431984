import Typography from '@mui/material/Typography';

import { CHECK_ADDRESS, COMPANY_NAME } from 'constants/paymentMethods';

import useStyles from './useStyles';

const CheckRequest = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          A new request for payment of the invoice.
        </Typography>
        <Typography className={classes.subtitle}>Send your checks to the address below.</Typography>
        <Typography className={classes.subtitle}>
          It can take up to 7 days from the time of shipment. Once the payment is accepted, the status of the invoice
          will change.
        </Typography>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.checkAddressContainer}>
          <div className={classes.checkAddressDescription}>
            <Typography className={classes.address}>
              Address: <span className={classes.bold}>{CHECK_ADDRESS}</span>
            </Typography>
            <Typography>
              Please make checks payable to <span className={classes.bold}>{COMPANY_NAME}</span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckRequest;
