import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isNil } from 'ramda';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import RegularPaymentPresenter from 'presenters/RegularPaymentPresenter';
import { formatUSD } from 'utils/numberUtils';

import useStyles from './useStyles';

const RegularPayment = props => {
  const {
    project,
    regularPayments,
    onProjectChange,
    onFormStepIncrement,
    onFormHide,
    onProjectCreate,
    onFormStepDecrement,
  } = props;
  const classes = useStyles();
  const [selectedRegularPaymentPeriod, setSelectedRegularPaymentPeriod] = useState(null);

  useEffect(() => {
    if (!isNil(project.regularPaymentAttributes)) {
      setSelectedRegularPaymentPeriod(project.regularPaymentAttributes?.period);
      return;
    }

    setSelectedRegularPaymentPeriod(RegularPaymentPresenter.period(regularPayments[0]));
  }, [regularPayments]);

  const renderRegularPaymentRadio = regularPayment => {
    const amount = formatUSD(RegularPaymentPresenter.amount(regularPayment), 2);
    const period = RegularPaymentPresenter.period(regularPayment);

    return (
      <FormControlLabel
        key={period}
        checked={period === selectedRegularPaymentPeriod}
        classes={{ root: classes.radio, label: classes.label }}
        value={period}
        onChange={() => setSelectedRegularPaymentPeriod(period)}
        control={<Radio color="primary" size="small" />}
        label={`${amount} ${RegularPaymentPresenter.getPeriodLabel(regularPayment)}`}
      />
    );
  };

  const handleRegularPaymentChoose = async () => {
    const regularPayment = isNil(selectedRegularPaymentPeriod) ? {} : { period: selectedRegularPaymentPeriod };
    const newProject = {
      ...project,
      regularPaymentAttributes: regularPayment,
    };

    if (project.sold) {
      onProjectChange(newProject);
      onFormStepIncrement();
      return;
    }

    await onProjectCreate(newProject);
    onFormHide();
  };

  const handleRegularPaymentWishClick = () => {
    setSelectedRegularPaymentPeriod(RegularPaymentPresenter.period(regularPayments[0]));
  };
  const handleWillSendInvoiceClick = () => {
    setSelectedRegularPaymentPeriod(null);
  };

  return (
    <>
      <Typography variant="h4">Would you like a regular payment for this project?</Typography>
      <div className={classes.tip}>You are eligible to be paid weekly on this project.</div>
      <div className={classes.formContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            disabled={!isNil(selectedRegularPaymentPeriod)}
            variant="outlined"
            classes={{ root: classes.buttonChoice, disabled: classes.buttonChoiceSelected }}
            onClick={handleRegularPaymentWishClick}
          >
            Yes, I want regular payment
          </Button>
          <Button
            disabled={isNil(selectedRegularPaymentPeriod)}
            variant="outlined"
            classes={{ root: classes.buttonChoice, disabled: classes.buttonChoiceSelected }}
            onClick={handleWillSendInvoiceClick}
          >
            No, I do not want a regular payment
          </Button>
        </div>
        <div className={clsx({ [classes.hidden]: isNil(selectedRegularPaymentPeriod) })}>
          <div className={classes.radioContainer}>
            <RadioGroup>{regularPayments.map(renderRegularPaymentRadio)}</RadioGroup>
          </div>
          <div className={classes.totalPayment}>Total payment: {formatUSD(project.overheadExpenses, 2)}</div>
        </div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onFormStepDecrement}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.nextButton }}
            onClick={handleRegularPaymentChoose}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

RegularPayment.propTypes = {
  onFormStepIncrement: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
  onProjectChange: PropTypes.func.isRequired,
  regularPayments: PropTypes.arrayOf(RegularPaymentPresenter.shape()).isRequired,
  onFormHide: PropTypes.func.isRequired,
  onProjectCreate: PropTypes.func.isRequired,
  onFormStepDecrement: PropTypes.func.isRequired,
};

export default RegularPayment;
