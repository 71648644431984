import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat, equals } from 'ramda';

import { INVOICE_STATES } from 'presenters/InvoicePresenter';

import ProjectsRepository from 'repositories/builder/company/ProjectsRepository';
import LineItemsRepository from 'repositories/builder/company/project/LineItemsRepository';
import ProjectLineItemsRepository from 'repositories/builder/company/project/ProjectLineItemsRepository';
import CompanyManagersRepository from 'repositories/builder/company/ManagersRepository';
import ProjectManagersRepository from 'repositories/builder/company/project/ManagersRepository';
import InvoicesRepository from 'repositories/builder/company/project/InvoicesRepository';
import CustomersRepository from 'repositories/builder/company/project/CustomersRepository';

const MAX_PER_PAGE = 5;

const initialState = {
  project: {
    data: {},
    isLoading: true,
  },
  companyLineItems: {
    data: [],
    meta: {},
  },
  projectLineItems: {
    data: [],
    meta: {},
  },
  companyManagers: {
    data: [],
    meta: {},
  },
  projectManagers: {
    data: [],
    meta: {},
  },
  openInvoices: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  approvedInvoices: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  closedInvoices: {
    data: [],
    meta: {
      nextPage: null,
      totalCount: 0,
    },
  },
  invoiceResponseDialog: {
    open: false,
    invoiceId: null,
  },
  modalInvoice: {
    open: false,
    invoice: null,
  },
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    loadProjectSuccess(state, { payload }) {
      state.project.data = payload.project;
    },
    loadCompanyLineItemsSuccess(state, { payload }) {
      state.companyLineItems.data = concat(state.companyLineItems.data, payload.lineItems);
      state.companyLineItems.meta = payload.meta;
    },
    loadProjectLineItemsSuccess(state, { payload }) {
      const newLineItems = payload.projectLineItems.map(projectLineItem => ({
        ...projectLineItem.lineItem,
        projectLineItemId: projectLineItem.id,
        projectLineItemState: projectLineItem.state,
      }));
      state.projectLineItems.data = concat(state.projectLineItems.data, newLineItems);
      state.projectLineItems.meta = payload.meta;
    },
    editProjectLineItem(state, { payload }) {
      state.projectLineItems.data = state.projectLineItems.data.map(item => {
        if (item.projectLineItemId === payload.projectLineItem.id) {
          return {
            ...payload.projectLineItem.lineItem,
            projectLineItemId: payload.projectLineItem.id,
            projectLineItemState: payload.projectLineItem.state,
          };
        }

        return item;
      });
    },
    removeProjectLineItem(state, { payload }) {
      state.projectLineItems.data = state.projectLineItems.data.filter(item => item.projectLineItemId !== payload);
    },
    loadCompanyManagersSuccess(state, { payload }) {
      state.companyManagers.data = concat(state.companyManagers.data, payload.users);
      state.companyManagers.meta = payload.meta;
    },
    loadProjectManagersSuccess(state, { payload }) {
      state.projectManagers.data = concat(state.projectManagers.data, payload.users);
      state.projectManagers.meta = payload.meta;
    },
    loadOpenInvoicesSuccess(state, { payload }) {
      state.openInvoices.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.openInvoices.data, payload.invoices);
      state.openInvoices.meta = payload.meta;
    },
    loadApprovedInvoicesSuccess(state, { payload }) {
      state.approvedInvoices.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.approvedInvoices.data, payload.invoices);
      state.approvedInvoices.meta = payload.meta;
    },
    loadClosedInvoicesSuccess(state, { payload }) {
      state.closedInvoices.data = equals(payload.meta.currentPage, 1)
        ? payload.invoices
        : concat(state.closedInvoices.data, payload.invoices);
      state.closedInvoices.meta = payload.meta;
    },
    resetInvoices(state) {
      state.openInvoices = initialState.openInvoices;
      state.approvedInvoices = initialState.approvedInvoices;
      state.closedInvoices = initialState.closedInvoices;
    },
    resetCompanyLineItems(state) {
      state.companyLineItems = initialState.companyLineItems;
    },
    resetProjectLineItems(state) {
      state.projectLineItems = initialState.projectLineItems;
    },
    resetProjectManagers(state) {
      state.projectManagers = initialState.projectManagers;
    },
    resetCompanyManagers(state) {
      state.companyManagers = initialState.companyManagers;
    },
    resetProject() {
      return initialState;
    },
    openInvoiceResponseDialog(state, { payload }) {
      state.invoiceResponseDialog.open = true;
      state.invoiceResponseDialog.invoiceId = payload.id;
    },
    setModalInvoiceOpen(state, { payload }) {
      state.modalInvoice.open = payload;
    },
    setModalInvoiceData(state, { payload }) {
      state.modalInvoice.invoice = payload;
    },
    resetModalInvoice(state) {
      state.modalInvoice = initialState.modalInvoice;
    },
    resetResponseDialog(state) {
      state.invoiceResponseDialog = initialState.invoiceResponseDialog;
    },
  },
});

export const { actions } = projectSlice;
export default projectSlice.reducer;

export const useProjectActions = () => {
  const dispatch = useDispatch();

  const loadProject = async id => {
    const { data } = await ProjectsRepository.show(id);
    dispatch(actions.loadProjectSuccess(data));

    return data;
  };

  const loadCompanyLineItems = async (projectId, page = 1) => {
    const { data } = await LineItemsRepository.index(projectId, { q: { s: 'id desc' }, page });
    dispatch(actions.loadCompanyLineItemsSuccess(data));

    return data;
  };

  const loadProjectLineItems = async (projectId, page = 1) => {
    const { data } = await ProjectLineItemsRepository.index(projectId, { q: { s: 'id desc' }, page });
    dispatch(actions.loadProjectLineItemsSuccess(data));

    return data;
  };

  const createProjectLineItem = (projectId, params) => {
    return ProjectLineItemsRepository.create(projectId, { projectLineItem: { lineItemAttributes: params } });
  };

  const updateProjectLineItem = async (
    projectId,
    projectLineItemId,
    params = { stateEvent: '', lineItemAttributes: { accountCode: '', categoryName: '' } },
  ) => {
    const { data } = await ProjectLineItemsRepository.update(projectId, projectLineItemId, { projectLineItem: params });

    dispatch(actions.editProjectLineItem(data));
    return data;
  };

  const destroyProjectLineItem = async (projectId, projectLineItemId) => {
    await ProjectLineItemsRepository.destroy(projectId, projectLineItemId);
    dispatch(actions.removeProjectLineItem(projectLineItemId));
  };

  const massCreateProjectLineItem = (projectId, params) => {
    return ProjectLineItemsRepository.massCreate(projectId, { projectLineItems: params });
  };

  const updateProject = (projectId, params) => {
    return ProjectsRepository.update(projectId, { project: params });
  };

  const massCreateManagers = (projectId, params = [{ userId: null }]) => {
    return ProjectManagersRepository.massCreate(projectId, { managers: params });
  };

  const deleteManager = (projectId, userId) => {
    return ProjectManagersRepository.destroy(projectId, userId);
  };

  const loadProjectManagers = async (projectId, page = 1) => {
    const { data } = await ProjectManagersRepository.index(projectId, { page });

    dispatch(actions.loadProjectManagersSuccess(data));
  };

  const loadCompanyManagers = async (projectId, page = 1) => {
    const params = { q: { notHavingProject: [projectId] }, page };
    const { data } = await CompanyManagersRepository.index(params);

    dispatch(actions.loadCompanyManagersSuccess(data));
  };

  const loadOpenInvoices = async (projectId, queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = {
      q: { stateIn: [INVOICE_STATES.pending, INVOICE_STATES.rejected], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(projectId, params);

    dispatch(actions.loadOpenInvoicesSuccess(data));
  };

  const loadApprovedInvoices = async (projectId, queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = { q: { stateEq: INVOICE_STATES.approved, s: sortRule }, perPage: MAX_PER_PAGE, page };
    const { data } = await InvoicesRepository.index(projectId, params);

    dispatch(actions.loadApprovedInvoicesSuccess(data));
  };

  const loadClosedInvoices = async (projectId, queryParams = {}) => {
    const { page = 1, sortRule = 'id desc' } = queryParams;
    const params = {
      q: { stateIn: [INVOICE_STATES.paid, INVOICE_STATES.canceled], s: sortRule },
      perPage: MAX_PER_PAGE,
      page,
    };
    const { data } = await InvoicesRepository.index(projectId, params);

    dispatch(actions.loadClosedInvoicesSuccess(data));
  };

  const completeProject = projectId => {
    return ProjectsRepository.complete(projectId);
  };

  const resetInvoices = () => {
    dispatch(actions.resetInvoices());
  };

  const resetCompanyLineItems = () => {
    dispatch(actions.resetCompanyLineItems());
  };

  const resetProjectLineItems = () => {
    dispatch(actions.resetProjectLineItems());
  };

  const resetProjectManagers = () => {
    dispatch(actions.resetProjectManagers());
  };

  const resetCompanyManagers = () => {
    dispatch(actions.resetCompanyManagers());
  };

  const resetProject = () => {
    dispatch(actions.resetProject());
  };

  const createProjectCustomer = (projectId, params, cancelToken) => {
    return CustomersRepository.create(projectId, params, cancelToken);
  };

  const setModalInvoiceOpen = open => {
    dispatch(actions.setModalInvoiceOpen(open));
  };

  const setModalInvoiceData = invoice => {
    dispatch(actions.setModalInvoiceData(invoice));
  };

  const resetModalInvoice = () => {
    dispatch(actions.resetModalInvoice());
  };

  const openResponseDialog = invoice => {
    dispatch(actions.openInvoiceResponseDialog(invoice));
  };

  const resetResponseDialog = () => {
    dispatch(actions.resetResponseDialog());
  };

  return {
    loadProject,
    loadCompanyLineItems,
    loadProjectLineItems,
    createProjectLineItem,
    updateProjectLineItem,
    destroyProjectLineItem,
    massCreateProjectLineItem,
    updateProject,
    massCreateManagers,
    deleteManager,
    loadProjectManagers,
    loadCompanyManagers,
    loadOpenInvoices,
    loadApprovedInvoices,
    loadClosedInvoices,
    completeProject,
    resetInvoices,
    resetCompanyLineItems,
    resetProjectLineItems,
    resetProjectManagers,
    resetCompanyManagers,
    resetProject,
    createProjectCustomer,
    setModalInvoiceOpen,
    setModalInvoiceData,
    openResponseDialog,
    resetResponseDialog,
    resetModalInvoice,
  };
};

export const selectors = {
  project: state => state.ProjectSlice.project,
  companyLineItems: state => state.ProjectSlice.companyLineItems,
  projectLineItems: state => state.ProjectSlice.projectLineItems,
  companyManagers: state => state.ProjectSlice.companyManagers,
  projectManagers: state => state.ProjectSlice.projectManagers,
  openInvoices: state => state.ProjectSlice.openInvoices,
  approvedInvoices: state => state.ProjectSlice.approvedInvoices,
  closedInvoices: state => state.ProjectSlice.closedInvoices,
  modalInvoice: state => state.ProjectSlice.modalInvoice,
  invoiceResponseDialog: state => state.ProjectSlice.invoiceResponseDialog,
  openInvoice: invoiceId => state =>
    state.ProjectSlice.openInvoices.data.find(openInvoice => openInvoice.id === invoiceId),
};
