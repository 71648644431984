import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';

import OpenInvoicesPayButton from '../OpenInvoicesPayButton';

import useStyles from './useStyles';

const OpenInvoicesTableRow = props => {
  const { customerInvoice } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {CustomerInvoicePresenter.projectName(customerInvoice)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {CustomerInvoicePresenter.companyName(customerInvoice)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.cell, classes.invoiceCell)}>
        {CustomerInvoicePresenter.name(customerInvoice)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {CustomerInvoicePresenter.formattedCreatedAt(customerInvoice)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {CustomerInvoicePresenter.formattedUSDAmount(customerInvoice)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <OpenInvoicesPayButton customerInvoice={customerInvoice} />
      </TableCell>
    </TableRow>
  );
};

OpenInvoicesTableRow.propTypes = {
  customerInvoice: CustomerInvoicePresenter.shape().isRequired,
};

export default OpenInvoicesTableRow;
