import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import Icon from 'components/Icon';
import AddButton from 'components/AddButton';
import NewProject from 'containers/Builder/NewProject';
import { useProjectsActions } from 'store/ProjectsSlice';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';
import useUserPolicy from 'hooks/useUserPolicy';
import useLoading from 'hooks/useLoading';
import UserPresenter from 'presenters/UserPresenter';

import ProjectsTable from './components/ProjectsTable';
import useStyles from './useStyles';

const Projects = () => {
  const classes = useStyles();
  const [isFormShown, setIsFormShown] = useState(false);
  const { loadActiveProjects, resetProjects, loadDraftProjects, loadCompletedProjects } = useProjectsActions();
  const {
    active: {
      data: activeProjects,
      nextPage: activeProjectsNextPage,
      totalCount: activeProjectsTotalCount,
      totalInformation: activeProjectsTotalInformation,
    },
    draft: {
      data: draftProjects,
      nextPage: draftProjectsNextPage,
      totalCount: draftProjectsTotalCount,
      totalInformation: draftProjectsTotalInformation,
    },
    completed: {
      data: completedProjects,
      nextPage: completedProjectsNextPage,
      totalCount: completedProjectsTotalCount,
      totalInformation: completedProjectsTotalInformation,
    },
  } = useSelector(state => state.ProjectsSlice);
  const { isCompanyOwner } = useUserPolicy();
  const { func: sendLoadActiveProjects, isNotFinished: isActiveProjectsLoading } = useLoading(loadActiveProjects);
  const { func: sendLoadDraftProjects, isNotFinished: isDraftProjectsLoading } = useLoading(loadDraftProjects);
  const { func: sendLoadCompletedProjects, isNotFinished: isCompletedProjectsLoading } =
    useLoading(loadCompletedProjects);
  const { data: currentUser } = useGetCurrentUserQuery();
  const isManager = UserPresenter.isManager(currentUser);
  const isShowDraftProjects = !isManager;

  const loadProjects = () => {
    sendLoadActiveProjects();
    sendLoadCompletedProjects();

    if (isShowDraftProjects) {
      sendLoadDraftProjects();
    }
  };

  useEffect(() => {
    loadProjects();

    return resetProjects;
  }, []);

  const handleFormShow = () => {
    setIsFormShown(true);
  };
  const handleFormHide = () => {
    setIsFormShown(false);
  };

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <div className={classes.titleContainer}>
          <Icon className={classes.titleIcon} width={28} height={28} name="folder" />
          <Typography classes={{ root: classes.title }} variant="h1">
            Projects
          </Typography>
        </div>
        {isCompanyOwner && (
          <AddButton variant="contained" onClick={handleFormShow}>
            Create New project
          </AddButton>
        )}
      </header>
      <Dialog open={isFormShown} onClose={handleFormHide}>
        <NewProject onFormHide={handleFormHide} />
      </Dialog>
      <div className={classes.projectsContainer}>
        <ProjectsTable
          projects={activeProjects}
          count={activeProjectsTotalCount}
          variant="active"
          isLoading={isActiveProjectsLoading}
          nextPage={activeProjectsNextPage}
          totalInformation={activeProjectsTotalInformation}
          onProjectsLoad={sendLoadActiveProjects}
        />
      </div>
      {isShowDraftProjects && (
        <div className={classes.projectsContainer}>
          <ProjectsTable
            projects={draftProjects}
            count={draftProjectsTotalCount}
            variant="draft"
            isLoading={isDraftProjectsLoading}
            nextPage={draftProjectsNextPage}
            totalInformation={draftProjectsTotalInformation}
            onProjectsLoad={sendLoadDraftProjects}
          />
        </div>
      )}
      <div className={classes.projectsContainer}>
        <ProjectsTable
          projects={completedProjects}
          count={completedProjectsTotalCount}
          variant="completed"
          isLoading={isCompletedProjectsLoading}
          nextPage={completedProjectsNextPage}
          totalInformation={completedProjectsTotalInformation}
          onProjectsLoad={sendLoadCompletedProjects}
        />
      </div>
    </main>
  );
};

export default Projects;
