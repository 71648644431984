import { useEffect } from 'react';
import { reject, replace } from 'ramda';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import clsx from 'clsx';

import MoneyTextField from 'components/MoneyTextField';
import Icon from 'components/Icon';
import useFormik from 'hooks/useFormik';
import { initialValues, validationSchema } from 'forms/Builder/Project/ChangeOrderForm';
import ProjectPresenter from 'presenters/ProjectPresenter';
import { formatUSD } from 'utils/numberUtils';
import { getProjectProfit } from 'utils/projectUtils';
import { isBlank } from 'utils/storeUtils';

import useStyles from './useStyles';

const ChangeOrderForm = props => {
  const { isOpen, onClose, project, onProjectUpdate } = props;
  const classes = useStyles();
  const { touched, errors, handleChange, values, setValues, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onSubmit: async ({ pricing, ...params }) => {
      const adjustedParams = reject(isBlank, params);
      onProjectUpdate(adjustedParams);
    },
  });

  useEffect(() => {
    const { contractValue, percentMarkup, pricing, thirdPartyExpenses, overheadExpenses } = project;
    const adjustedContractValue = ProjectPresenter.isFixedPrice(project) ? contractValue : '';
    const adjustedPercentMarkup = ProjectPresenter.isCostPlus(project) ? percentMarkup : '';

    setValues({
      contractValue: adjustedContractValue,
      percentMarkup: adjustedPercentMarkup,
      pricing,
      thirdPartyExpenses,
      overheadExpenses,
    });
  }, []);

  const newProfit = getProjectProfit({ ...project, ...values });
  const profitDelta = newProfit - ProjectPresenter.profit(project);

  const currentProfitUSD = formatUSD(ProjectPresenter.profit(project), 2);
  const profitDeltaUSD = formatUSD(profitDelta, 2);
  const newProfitUSD = formatUSD(newProfit, 2);

  const renderProfitDelta = () => {
    const parsedProfitDelta = Number.parseFloat(profitDelta.toFixed(2));

    if (parsedProfitDelta === 0) {
      return <div>{replace('-', '', profitDeltaUSD)}</div>;
    }

    if (parsedProfitDelta > 0) {
      return <div className={clsx(classes.profitValue, classes.profitDeltaPositive)}>+{profitDeltaUSD}</div>;
    }

    return <div className={clsx(classes.profitValue, classes.profitDeltaNegative)}>{profitDeltaUSD}</div>;
  };

  const renderContractValue = () => {
    if (ProjectPresenter.isFixedPrice(project)) {
      return (
        <div className={classes.inputContainer}>
          <div className={classes.labelContainer}>
            <InputLabel classes={{ root: classes.label }} htmlFor="contractValue">
              Contract Value
            </InputLabel>
            <div className={classes.inputTip}>What are your total contract value for this project?</div>
          </div>
          <MoneyTextField
            classes={{ root: classes.textField }}
            FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
            name="contractValue"
            id="contractValue"
            onChange={handleChange}
            value={values.contractValue}
            error={touched.contractValue && Boolean(errors.contractValue)}
            helperText={touched.contractValue && errors.contractValue}
          />
        </div>
      );
    }

    return null;
  };

  const renderPercentMarkup = () => {
    if (ProjectPresenter.isCostPlus(project)) {
      return (
        <div className={classes.inputContainer}>
          <div className={classes.labelContainer}>
            <InputLabel classes={{ root: classes.label }} htmlFor="percentMarkup">
              Percent Markup
            </InputLabel>
            <div className={classes.inputTip}>What is the markup on expences?</div>
          </div>
          <div className={classes.percentMarkupContainer}>
            <TextField
              classes={{ root: classes.percentMarkupInput }}
              FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
              name="percentMarkup"
              id="percentMarkup"
              value={values.percentMarkup}
              onChange={handleChange}
              error={touched.percentMarkup && Boolean(errors.percentMarkup)}
              helperText={touched.percentMarkup && errors.percentMarkup}
            />
            <div className={classes.percentSign}>%</div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Icon className={classes.titleIcon} width={24} height={24} name="gear" />
          <Typography classes={{ root: classes.title }} variant="h3">
            Change Order
          </Typography>
        </div>
        <div className={classes.titleTip}>Enter new project values to adjust for the impact of the change order.</div>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            {renderContractValue()}
            <div className={classes.inputContainer}>
              <div className={classes.labelContainer}>
                <InputLabel classes={{ root: classes.label }} htmlFor="thirdPartyExpenses">
                  Third Party Expenses
                </InputLabel>
                <div className={classes.inputTip}>What are your total third party expenses for this project?</div>
              </div>
              <MoneyTextField
                classes={{ root: classes.textField }}
                FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
                name="thirdPartyExpenses"
                id="thirdPartyExpenses"
                onChange={handleChange}
                value={values.thirdPartyExpenses}
                error={touched.thirdPartyExpenses && Boolean(errors.thirdPartyExpenses)}
                helperText={touched.thirdPartyExpenses && errors.thirdPartyExpenses}
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelContainer}>
                <InputLabel classes={{ root: classes.label }} htmlFor="overheadExpenses">
                  Overhead Expense
                </InputLabel>
                <div className={classes.inputTip}>What is your total estimated overhead for this project?</div>
              </div>
              <MoneyTextField
                classes={{ root: classes.textField }}
                FormHelperTextProps={{ classes: { root: classes.inputHelperText } }}
                name="overheadExpenses"
                id="overheadExpenses"
                onChange={handleChange}
                value={values.overheadExpenses}
                error={touched.overheadExpenses && Boolean(errors.overheadExpenses)}
                helperText={touched.overheadExpenses && errors.overheadExpenses}
              />
            </div>
            {renderPercentMarkup()}
            <div className={classes.profitContainer}>
              <div className={classes.profitTitle}>Projected Profit</div>
              <div className={classes.profitValues}>
                <div className={classes.profitValueContainer}>
                  <div>Current</div>
                  <div className={classes.profitValue}>{currentProfitUSD}</div>
                </div>
                <div className={classes.profitValueContainer}>
                  <div>Delta</div>
                  {renderProfitDelta()}
                </div>
                <div className={classes.profitValueContainer}>
                  <div className={clsx(classes.profitValue, classes.newProfit)}>New Profit</div>
                  <div className={clsx(classes.profitValue, classes.newProfitValue)}>{newProfitUSD}</div>
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

ChangeOrderForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  project: ProjectPresenter.shape().isRequired,
  onProjectUpdate: PropTypes.func.isRequired,
};

export default ChangeOrderForm;
