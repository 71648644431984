import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import useFormik from 'hooks/useFormik';
import useLoading from 'hooks/useLoading';
import { initialValues, validationSchema } from 'forms/Builder/NewMemberForm';
import Icon from 'components/Icon';
import MemberRoleSelect from 'components/MemberRoleSelect';
import { sentencize } from 'utils/stringUtils';

import useStyles from './useStyles';

const NewMember = props => {
  const { onMemberCreate, onClose } = props;
  const classes = useStyles();
  const [apiErrors, setApiErrors] = useState({});
  const { func: sendOnMemberCreate, isPending: isMemberCreating } = useLoading(onMemberCreate);
  const { touched, errors, handleChange, values, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async ({ role, ...attributes }) => {
      try {
        await sendOnMemberCreate({ ...attributes, userCompanyAttributes: { role } });
        onClose();
      } catch (error) {
        setApiErrors({ email: sentencize(error.email[0]) });
      }
    },
  });

  return (
    <>
      {isMemberCreating && <LinearProgress />}
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Icon name="invitation" width={24} height={24} className={classes.invitationIcon} />
          <Typography variant="h3">Invite a Team Member</Typography>
        </div>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              value={values.email}
              name="email"
              onChange={handleChange}
              error={Boolean(apiErrors.email) || (touched.email && Boolean(errors.email))}
              helperText={apiErrors.email || errors.email}
              disabled={isMemberCreating}
            />
            <div className={classes.selectContainer}>
              <InputLabel shrink id="roleLabel">
                Role
              </InputLabel>
              <MemberRoleSelect
                labelId="roleLabel"
                value={values.role}
                name="role"
                disabled={isMemberCreating}
                onChange={handleChange}
              />
            </div>
            <div className={classes.actions}>
              <Button variant="contained" onClick={onClose} disabled={isMemberCreating}>
                Cancel
              </Button>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isMemberCreating}
                onSubmit={handleSubmit}
              >
                Invite
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewMember.propTypes = {
  onMemberCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewMember;
