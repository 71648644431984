import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import InboundLineItemPresenter from 'presenters/InboundLineItemPresenter';

import useBreakpoints from 'hooks/useBreakpoints';

import TableCard from 'components/TableCard';

import useStyles from './useStyles';

const LineItems = props => {
  const { lineItems } = props;
  const classes = useStyles();
  const { screenWiderThan } = useBreakpoints();

  const dataLabels = [
    { label: 'Code', code: 'accountCode' },
    { label: 'Category', code: 'categoryName' },
    { label: 'Amount', code: 'amount' },
  ];

  if (!screenWiderThan.md) {
    return <TableCard data={lineItems} labels={dataLabels} />;
  }

  return (
    <TableContainer classes={{ root: classes.tableContainer }}>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.cell }}>Code</TableCell>
            <TableCell classes={{ root: classes.cell }}>Category</TableCell>
            <TableCell classes={{ root: classes.cell }} align="right">
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map(lineItem => (
            <TableRow key={lineItem.key}>
              <TableCell classes={{ root: classes.bodyCell }}>
                {InboundLineItemPresenter.accountCode(lineItem)}
              </TableCell>
              <TableCell classes={{ root: classes.bodyCell }}>
                {InboundLineItemPresenter.categoryName(lineItem)}
              </TableCell>
              <TableCell classes={{ root: classes.bodyCellAlignRight }} align="right">
                {InboundLineItemPresenter.formattedUSDAmount(lineItem)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

LineItems.propTypes = {
  lineItems: PropTypes.arrayOf(InboundLineItemPresenter.shape()).isRequired,
};

export default LineItems;
