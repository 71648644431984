import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1AdminInvoicesPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  show(id) {
    const path = apiRoutes.siteApiV1AdminInvoicePath(id);

    return FetchHelpers.get(path);
  },
  approve(id, params) {
    const path = apiRoutes.approveSiteApiV1AdminInvoicePath(id);

    return FetchHelpers.put(path, params);
  },
  reject(id, params) {
    const path = apiRoutes.rejectSiteApiV1AdminInvoicePath(id);

    return FetchHelpers.put(path, params);
  },
};
