import { FC, useEffect, useMemo, useState } from "react";

import { Dialog } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import muiSx from "mui-sx";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import InvoiceResponseDialog from "containers/Builder/PayableInvoices/components/InvoiceResponseDialog";
import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceBuilderNameCard from "components/common/InvoiceBuilderNameCard";
import NoWrap from "components/common/NoWrap";
import TableLoadMore from "components/common/TableLoadMore";
import InvoiceRejectReason from "components/InvoiceRejectReason";

import { InvoiceState } from "domain/invoice/enums";
import * as InvoicePresenter from "domain/invoice/presenter";
import * as InvoiceService from "domain/invoice/service";

import { formatUSD } from "libs/currency";
import { formatSimpleDate } from "libs/datetime";

import { useGetBuilderCompanyInvoicesQuery } from "store/api/builder/company/invoice/apiSlice";
import { BUILDER_COMPANY_INVOICES_PER_PAGE } from "store/api/builder/company/invoice/constants";
import { getBuilderInvoicesSorting } from "store/api/builder/company/invoice/service";
import { BuilderCompanyInvoice } from "store/api/builder/company/invoice/types";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";
import { usePayableInvoicesActions, selectors } from "store/PayableInvoicesSlice";

import styles from "./styles";
import { IOpenInvoicesProps } from "./types";

const columnHelper = createColumnHelper<BuilderCompanyInvoice>();

const OpenInvoices: FC<IOpenInvoicesProps> = (props) => {
  const { t } = useTranslation(["common", "builderInvoices"]);
  const { onStatusCountChange } = props;
  const { data: currentUser } = useGetCurrentUserQuery();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([{ id: "state", desc: false }]);
  const [builderCompanyInvoices, setBuilderCompanyInvoices] = useState<Array<BuilderCompanyInvoice>>([]);

  const queryParameters = useMemo(() => {
    return {
      q: {
        stateIn: [InvoiceState.pending, InvoiceState.awaitingApproval],
        s: getBuilderInvoicesSorting(sorting),
      },
      perPage: BUILDER_COMPANY_INVOICES_PER_PAGE,
      page: currentPage,
    };
  }, [currentPage, sorting]);

  const { currentData: openData, isFetching } = useGetBuilderCompanyInvoicesQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (openData?.invoices && queryParameters.page === 1) {
      setBuilderCompanyInvoices(openData.invoices);
    }
    if (openData?.invoices && queryParameters.page > 1) {
      setBuilderCompanyInvoices((invoices) => [...invoices, ...openData.invoices]);
    }
  }, [queryParameters, openData?.invoices]);

  useEffect(() => {
    if (openData?.meta.totalCount) {
      onStatusCountChange("open", openData?.meta.totalCount);
    }
  }, [openData?.meta.totalCount]);

  const history = useHistory();
  const { open: isModalOpen, invoice: openedInvoice } = useSelector(selectors.modalInvoice);

  const {
    loadProjects,
    loadLineItems,
    approveInvoice,
    rejectInvoice,
    resetInvoices,
    resetResponseDialog,
    resetModalInvoice,
    openResponseDialog,
    setModalInvoiceOpen,
    setModalInvoiceData,
  } = usePayableInvoicesActions();

  const columns = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      columnHelper.accessor("subcontractorEmail", {
        header: t("builderInvoices:table.headers.subcontractor") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceBuilderNameCard invoice={info.row.original} />,
      }),
      columnHelper.accessor("projectName", {
        header: t("builderInvoices:table.headers.projectName") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceAssignedProjects invoice={info.row.original} />,
      }),
      columnHelper.accessor("number", {
        header: t("builderInvoices:table.headers.invoiceNumber") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap title={`${info.row.original.number}`}>{info.row.original.number}</NoWrap>,
      }),
      columnHelper.accessor("state", {
        header: t("builderInvoices:table.headers.status") ?? "",
        enableSorting: true,
        cell: (info) => {
          const status = currentUser && InvoicePresenter.status(info.row.original, currentUser, t);
          return <NoWrap title={status}>{status}</NoWrap>;
        },
      }),
      columnHelper.accessor("earlyPaymentTerms", {
        header: t("builderInvoices:table.headers.earlyPaymentTerms") ?? "",
        enableSorting: false,
        cell: (info) => {
          const daysDelay = info.row.original.invoiceEarlyPayment?.daysDelay ?? null;
          return currentUser && <NoWrap>{InvoiceService.earlyPaymentTermsDays(daysDelay, t)}</NoWrap>;
        },
        meta: { align: "right" },
      }),
      columnHelper.accessor("approvalDueDate", {
        header: t("builderInvoices:table.headers.approvalDueDate") ?? "",
        enableSorting: true,
        cell: (info) => {
          const date = info.row.original.approvalDueDate;
          return isNil(date) ? "—" : <NoWrap>{formatSimpleDate(date)}</NoWrap>;
        },
        meta: { align: "center" },
      }),
      columnHelper.accessor("projectedPaidAt", {
        header: t("builderInvoices:table.headers.paymentDueDate") ?? "",
        enableSorting: true,
        sortDescFirst: true,
        cell: (info) => {
          const date = info.row.original.projectedPaidAt;
          return isNil(date) ? "—" : <NoWrap>{formatSimpleDate(date)}</NoWrap>;
        },
        meta: { align: "center" },
      }),
      columnHelper.accessor("totalAmount", {
        header: t("builderInvoices:table.headers.invoiceAmount") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap>{formatUSD(info.row.original.totalAmount)}</NoWrap>,
        meta: { align: "center", width: "100px" },
      }),
      columnHelper.accessor("invoiceEarlyPayment.amount", {
        header: t("builderInvoices:table.headers.acceptedAmount") ?? "",
        enableSorting: true,
        cell: (info) => {
          const { invoiceEarlyPayment } = info.row.original;
          return isNil(invoiceEarlyPayment) ? "—" : <NoWrap>{formatUSD(invoiceEarlyPayment.amount)}</NoWrap>;
        },
        meta: { align: "center", width: "100px" },
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: builderCompanyInvoices,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    enableMultiSort: false,
    meta: {
      getRowSx: (row: Row<BuilderCompanyInvoice>): SxProps<Theme> =>
        muiSx({
          sx: styles.rowHighlighted,
          condition:
            (!InvoiceService.isSelfPaid(row.original) && InvoiceService.isAwaitingApproval(row.original)) ||
            InvoiceService.isEarlyPaymentRequested(row.original),
        }),
    },
  });

  const handleLoadMore = () => {
    setCurrentPage((previousPage) => previousPage + 1);
  };

  const handleInvoiceResponseDialogClose = () => {
    resetResponseDialog();
  };

  const handleSuccessAction = () => {
    resetInvoices();

    handleInvoiceResponseDialogClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleApproveInvoice = async (...parameters: any[]) => {
    await approveInvoice(...parameters);
    handleSuccessAction();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRejectInvoice = async (...parameters: any[]) => {
    await rejectInvoice(...parameters);
    handleSuccessAction();
  };

  const handleEditInvoiceClick = () => {
    const link = AppRoutes.invoiceEditPath(openedInvoice.id);
    history.push(link);
  };

  const handleCloseInvoiceRejectReason = () => {
    resetModalInvoice();
  };

  const handleRowClick = (invoice: BuilderCompanyInvoice) => {
    if (InvoiceService.isApprovable(invoice)) {
      openResponseDialog(invoice);
      return;
    }

    if (InvoiceService.isRejected(invoice)) {
      setModalInvoiceOpen(true);
      setModalInvoiceData(invoice);
      return;
    }

    history.push(AppRoutes.invoicePath(invoice.id));
  };

  return (
    <Div>
      <TableLoadMore<BuilderCompanyInvoice>
        table={table}
        onRowClick={handleRowClick}
        onLoadMore={handleLoadMore}
        loading={isFetching}
        meta={openData?.meta}
      />

      <InvoiceResponseDialog
        loadProjects={loadProjects}
        loadLineItems={loadLineItems}
        approveInvoice={handleApproveInvoice}
        rejectInvoice={handleRejectInvoice}
        onClose={handleInvoiceResponseDialogClose}
      />
      <Dialog open={isModalOpen} fullWidth maxWidth="lg" onClose={handleCloseInvoiceRejectReason}>
        {openedInvoice && (
          <InvoiceRejectReason
            invoice={openedInvoice}
            onEditInvoiceClick={handleEditInvoiceClick}
            invoiceName={InvoicePresenter.builderDocumentTitle(openedInvoice, t)}
          />
        )}
      </Dialog>
    </Div>
  );
};

export default OpenInvoices;
