import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  invoicesContainer: {
    marginTop: theme.spacing(2),
  },
  invoicesIndent: {
    padding: theme.spacing(5, 3),
  },
  invoicesTopArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  info: {
    color: theme.palette.colors.orange,
  },
  total: {
    marginLeft: 'auto',
  },
}));
