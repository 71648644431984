import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiButtonType {
  defaultProps: ComponentsProps["MuiButton"];
  styleOverrides: ComponentsOverrides["MuiButton"];
}

const MuiButton: IMuiButtonType = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    containedPrimary: {
      borderRadius: 3,
      fontSize: 14,
      lineHeight: "16px",
      fontFamily: "Poppins",
      fontWeight: 600,
      color: baseTheme.palette.colors.white,
      transition: "none",
      boxShadow: "none",
      backgroundColor: `${baseTheme.palette.primary.main}`,
      "&:hover, &:active, &:focus": {
        background: baseTheme.palette.button.primaryContained.hover,
        color: `${baseTheme.palette.colors.white} !important`,
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: baseTheme.palette.colors.smokeWhite,
        color: baseTheme.palette.colors.nickelGray,
      },
    },
    containedSecondary: {
      color: "rgba(0, 0, 0, 0.87)",
    },
    textPrimary: {
      padding: 0,
      borderRadius: 0,
      textTransform: "none",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "16px",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
        color: baseTheme.palette.colors.christyGreen,
      },
    },
    textSecondary: {
      color: baseTheme.palette.colors.red,
    },
    outlined: {
      borderRadius: 3,
      borderColor: baseTheme.palette.colors.nurseGray,
      fontSize: 12,
      lineHeight: "16px",
      padding: "7px 17px",
      fontFamily: "Poppins",
      fontWeight: 600,
      color: baseTheme.palette.colors.heavyMetalGray,
    },
    outlinedSecondary: {
      color: baseTheme.palette.colors.green,
      borderColor: baseTheme.palette.colors.green,
    },
    sizeMedium: {
      padding: "12px 24px",
    },
    sizeSmall: {
      padding: "4px 10px",
      fontSize: "0.8125rem",
    },
  },
};

export default MuiButton;
