import { Palette } from "@mui/material";

export const COLORS = {
  black: "#212121",
  white: "#FFFFFF",
  smokeWhite: "#F6F6F6",
  snowWhite: "#FAFAFA",
  alabaster: "#FBFBFB",
  desertStorm: "#FBFBFA",
  catskillWhite: "#E9EAE8",
  lightGray: "rgba(0, 48, 32, 0.1)",
  deltaGray: "#90928A",
  lilyWhiteGray: "#EFF0EF",
  nurseGray: "#D2D3D0",
  nickelGray: "#C7C8C4",
  gunsmokeGray: "#797C72",
  signalGray: "#8F9189",
  blackSqueezeGray: "#F4F5F3",
  heavyMetalGray: "#4D5043",
  green: "#6ED224",
  chicagoGray: "#585B4F",
  christyGreen: "#5EAF21",
  suluGreen: "#ABEB86",
  hunterGreen: "#202414",
  yellowGreen: "#93DC28",
  chileanHelthGreen: "#F1FBE9",
  riceGreen: "#E9F8DE",
  kellyGreen: "#4FBF15",
  red: "#F44336",
  lightRed: "rgba(245, 28, 39, 0.05)",
  bud: "#A6ABA1",
  lemonGrass: "#90968A",
  orange: "#FFA600",
  sunsetOrange: "#F74952",
  papayaYellow: "#FFF2D9",
  bridalHealth: "#FFF9EE",
  salomieYellow: "#FFD380",
  kelp: "#414537",
  gainsboro: "#E0E0E0",
  blackHaze: "#E6EAE9",
  blackOlive: "#202D15",
};

declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Theme {
    palette: Palette & {
      colors: Record<keyof typeof COLORS, string>;
      button: {
        primaryContained: {
          hover: string;
          click: string;
        };
      };
    };
  }
}

export default {
  primary: {
    main: COLORS.green,
    contrastText: COLORS.white,
  },
  secondary: {
    main: COLORS.lightRed,
    contrastText: COLORS.red,
  },
  warning: {
    main: COLORS.orange,
  },
  success: {
    main: COLORS.kellyGreen,
  },
  colors: COLORS,
  button: {
    primaryContained: {
      hover:
        "linear-gradient(0deg, rgba(110, 210, 36, 0.8), rgba(110, 210, 36, 0.8)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
      click:
        "linear-gradient(0deg, rgba(110, 210, 36, 0.8), rgba(110, 210, 36, 0.8)), linear-gradient(0deg, #202414, #202414)",
    },
  },
  text: {
    primary: COLORS.heavyMetalGray,
  },
};
