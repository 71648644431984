import Badge from "components/common/Badge";
import Span from "components/common/Span";
import Icon from "components/Icon";

import { useGetAwaitingApprovalTotalCountQuery } from "store/api/builder/company/invoice/apiSlice";

import styles from "./styles";

const BuilderPendingInvoicesIcon = () => {
  const { data: unreadTotalInvoices } = useGetAwaitingApprovalTotalCountQuery(undefined, { pollingInterval: 5000 });

  return (
    <Span sx={styles.iconContainer}>
      <Icon width={24} height={18} name="file" />
      {Boolean(unreadTotalInvoices) && (
        <Span sx={styles.badgeContainer}>
          <Badge>{unreadTotalInvoices}</Badge>
        </Span>
      )}
    </Span>
  );
};

export default BuilderPendingInvoicesIcon;
