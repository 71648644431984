import { TFunction } from "i18next";

import { formatUSD } from "utils/numberUtils";

import { ISubcontractorInvoiceEarlyPaymentOption } from "./types";

export const label = (earlyPaymentOption: ISubcontractorInvoiceEarlyPaymentOption, t: TFunction) => {
  const formattedAmount = formatUSD(earlyPaymentOption.amount, 2);
  if (earlyPaymentOption.daysDelay === 0) {
    return t("subcontractorInvoice:paymentTerms.oneThreeLabel", { value: formattedAmount });
  }

  return t("subcontractorInvoice:paymentTerms.sevenTenLabel", { value: formattedAmount });
};
