import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params = {}) {
    const path = apiRoutes.siteApiV1CustomerCustomerBillingSettingsPath();

    return FetchHelpers.post(path, params);
  },
  paymentMethod(params = {}) {
    const path = apiRoutes.paymentMethodSiteApiV1CustomerCustomerBillingSettingsPath();

    return FetchHelpers.get(path, params);
  },
};
