import { SxStyles } from "theme";

const styles: SxStyles = {
  count: {
    height: 11,
    width: 11,
    borderRadius: 50,
    border: (theme) => `2px solid ${theme.palette.colors.white}`,
    display: "inline-block",
    backgroundColor: "colors.sunsetOrange",
  },
};

export default styles;
