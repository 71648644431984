export const setInitialRedirectUrl = (path: string) => {
  window.sessionStorage.setItem("initialRedirectUrl", path);
};

export const deleteInitialRedirectUrl = () => {
  window.sessionStorage.removeItem("initialRedirectUrl");
};

export const getInitialRedirectUrl = () => {
  return window.sessionStorage.getItem("initialRedirectUrl");
};

export const hasInitialRedirectUrl = () => {
  return Boolean(window.sessionStorage.getItem("initialRedirectUrl"));
};
