import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import UserPresenter from 'presenters/UserPresenter';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const UserTopMenu = props => {
  const { currentUser, onMenuOpen } = props;
  const classes = useStyles();

  return (
    <div className={classes.userPanel}>
      <Button className={classes.menuButton} onClick={onMenuOpen}>
        <span className={classes.userNameContainer}>
          <Icon name="customerAvatar" width={40} height={40} />
          <span className={classes.userName}>{UserPresenter.fullName(currentUser)}</span>
        </span>
      </Button>
    </div>
  );
};

UserTopMenu.propTypes = {
  currentUser: UserPresenter.shape().isRequired,
  onMenuOpen: PropTypes.func.isRequired,
};

export default UserTopMenu;
