import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  projectName: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  actionsContainer: {
    alignSelf: 'flex-start',
    flexShrink: 0,
    paddingTop: theme.spacing(3.5),
  },
}));
