import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

const SectionTitle = props => {
  const { className, children } = props;
  const classes = useStyles();

  return <div className={clsx(classes.sectionTitle, className)}>{children}</div>;
};

SectionTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SectionTitle.defaultProps = {
  className: null,
};

export default SectionTitle;
