import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { MIN_DATE_ERROR, MAX_DATE_ERROR } from 'constants/invoicePaymentDate';
import InvoicePresenter from 'presenters/InvoicePresenter';
import useInvoicePaymentDate from 'hooks/useInvoicePaymentDate';
import { formPaths } from 'forms/Builder/InvoiceResponseForm';
import DatePicker from 'components/DatePicker';

import useStyles from './useStyles';

const InvoicePaymentDate = props => {
  const { formData, invoice } = props;
  const classes = useStyles();
  const { values, setFieldValue } = formData;

  const handleDateChange = date => {
    setFieldValue(formPaths.projectedPaidAt, date);
  };

  const { minDate, maxDate, differenceWithToday, isExpiredDate } = useInvoicePaymentDate({
    createdDate: new Date(InvoicePresenter.createdAt(invoice)),
    selectedDay: values.projectedPaidAt,
    onDateChange: handleDateChange,
  });

  const maxExpiredDate = isExpiredDate ? minDate : maxDate;

  return (
    <>
      <Grid item>
        <DatePicker
          label="Invoice payment date"
          name={formPaths.projectedPaidAt}
          value={values.projectedPaidAt}
          format="MM/dd/yyyy"
          minDateMessage={MIN_DATE_ERROR}
          maxDateMessage={MAX_DATE_ERROR}
          minDate={minDate}
          maxDate={maxExpiredDate}
          disabled={isExpiredDate}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item>
        <div className={classes.projectPaidAtDifferentContainer}>
          <Typography>{differenceWithToday}</Typography>
        </div>
      </Grid>
    </>
  );
};

InvoicePaymentDate.propTypes = {
  formData: PropTypes.shape().isRequired,
  invoice: InvoicePresenter.shape().isRequired,
};

export default InvoicePaymentDate;
