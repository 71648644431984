import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { TABLE_CELL_VARIANT } from 'components/TableStatus';
import { useAdminProjectsActions, selectors } from 'store/AdminProjectsSlice';
import useLoading from 'hooks/useLoading';
import TableWithStatus from 'components/TableWithStatus';
import { MAX_COLS_COUNT, headers } from 'constants/adminProjects';
import { PROJECT_STATES } from 'presenters/ProjectPresenter';

import AdminProjectRows from '../AdminProjectRows';

const CompletedProjectsTable = () => {
  const { loadCompletedProjects } = useAdminProjectsActions();
  const { data, nextPage, totalCount, totalInformation } = useSelector(selectors.completed);
  const { func: sendLoadCompletedProjects, isPending } = useLoading(loadCompletedProjects);

  useEffect(() => {
    sendLoadCompletedProjects();
  }, []);

  return (
    <TableWithStatus
      isLoading={isPending}
      maxColsCount={MAX_COLS_COUNT}
      title="Completed"
      isData={!isEmpty(data)}
      count={totalCount}
      nextPage={nextPage}
      color={TABLE_CELL_VARIANT.gray}
      loadMore={loadCompletedProjects}
      headers={headers(PROJECT_STATES.completed)}
      rows={<AdminProjectRows projects={data} totalInformation={totalInformation} isCompleted />}
    />
  );
};

export default CompletedProjectsTable;
