import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40,
  },
  signInContainer: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    color: theme.palette.colors.deltaGray,
    display: 'flex',
  },
  signIn: {
    marginLeft: 4,
  },
  formContainer: {
    marginTop: 36,
    width: 320,
  },
  inputRoot: {
    '&:not(:first-child)': {
      marginTop: 28,
    },
  },
  submitRoot: {
    marginTop: 48,
    width: 220,
    height: 48,
  },
  terms: {
    marginTop: 16,
    fontSize: 12,
    color: theme.palette.colors.deltaGray,
    lineHeight: '16px',
    fontWeight: 600,
    maxWidth: 260,
  },
  termsLink: {
    fontSize: 12,
  },
}));
