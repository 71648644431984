import { FC, useEffect, useMemo, useState } from "react";

import { Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import muiSx from "mui-sx";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";

import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceSubcontractorNameCard from "components/common/InvoiceSubcontractorNameCard";
import NoWrap from "components/common/NoWrap";
import Span from "components/common/Span";
import TableLoadMore from "components/common/TableLoadMore";

import { InvoiceState } from "domain/invoice/enums";
import * as InvoicePresenter from "domain/invoice/presenter";
import * as InvoiceService from "domain/invoice/service";

import useBreakpoints from "hooks/useBreakpoints";

import { formatUSD } from "libs/currency";

import { useGetSubcontractorCompanyInvoicesQuery } from "store/api/subcontractor/company/invoice/apiSlice";
import {
  getSubcontractorInvoicesSorting,
  SUBCONTRACTOR_COMPANY_INVOICES_PER_PAGE,
} from "store/api/subcontractor/company/invoice/service";
import { SubcontractorCompanyInvoiceListItem } from "store/api/subcontractor/company/invoice/types";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import styles from "./styles";
import { IOpenInvoicesProps } from "./types";

const columnHelper = createColumnHelper<SubcontractorCompanyInvoiceListItem>();

const OpenInvoices: FC<IOpenInvoicesProps> = (props) => {
  const { t } = useTranslation(["common", "subcontractorInvoices"]);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { onStatusCountChange } = props;
  const history = useHistory();
  const { screenWiderThan } = useBreakpoints();
  const isDesktopLayout = screenWiderThan.md;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([{ id: "state", desc: false }]);
  const [subcontractorCompanyInvoices, setSubcontractorCompanyInvoices] = useState<
    Array<SubcontractorCompanyInvoiceListItem>
  >([]);

  const queryParameters = useMemo(() => {
    return {
      q: {
        stateIn: [InvoiceState.pending, InvoiceState.awaitingApproval],
        s: getSubcontractorInvoicesSorting(sorting),
      },
      perPage: SUBCONTRACTOR_COMPANY_INVOICES_PER_PAGE,
      page: currentPage,
    };
  }, [currentPage, sorting]);

  const { currentData: openData, isFetching } = useGetSubcontractorCompanyInvoicesQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (openData?.meta.totalCount) {
      onStatusCountChange("open", openData?.meta.totalCount);
    }
  }, [openData?.meta.totalCount]);

  useEffect(() => {
    if (openData?.invoices && queryParameters.page === 1) {
      setSubcontractorCompanyInvoices(openData.invoices);
    }
    if (openData?.invoices && queryParameters.page > 1) {
      setSubcontractorCompanyInvoices((invoices) => [...invoices, ...openData.invoices]);
    }
  }, [queryParameters, openData?.invoices]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("builder", {
        header: t("subcontractorInvoices:table.headers.builder") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceSubcontractorNameCard invoice={info.row.original} />,
      }),
      columnHelper.accessor("projectName", {
        header: t("subcontractorInvoices:table.headers.project") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceAssignedProjects invoice={info.row.original} />,
      }),
      columnHelper.accessor("name", {
        header: t("subcontractorInvoices:table.headers.invoiceDescription") ?? "",
        enableSorting: true,
        cell: (info) => <Typography noWrap={isDesktopLayout}>{info.row.original.name}</Typography>,
      }),
      columnHelper.accessor("state", {
        header: t("subcontractorInvoices:table.headers.status") ?? "",
        enableSorting: true,
        cell: (info) => {
          const invoice = info.row.original;
          return (
            currentUser && (
              <Span
                sx={muiSx(styles.status, {
                  sx: styles.actionRequired,
                  condition: InvoiceService.isPending(invoice) && !InvoiceService.isSelfPaid(invoice),
                })}
              >
                {InvoicePresenter.status(info.row.original, currentUser, t)}
              </Span>
            )
          );
        },
      }),
      columnHelper.accessor("totalAmount", {
        header: t("subcontractorInvoices:table.headers.invoiceAmount") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap>{formatUSD(info.row.original.totalAmount)}</NoWrap>,
        meta: { align: "right", width: "100px" },
      }),
      columnHelper.accessor("invoiceEarlyPayment.amount", {
        header: t("subcontractorInvoices:table.headers.acceptedAmount") ?? "",
        enableSorting: true,
        sortDescFirst: true,
        cell: (info) => {
          const { invoiceEarlyPayment } = info.row.original;
          return isNil(invoiceEarlyPayment) ? "—" : <NoWrap>{formatUSD(invoiceEarlyPayment.amount)}</NoWrap>;
        },
        meta: { align: "right", width: "100px" },
      }),
    ],
    [isDesktopLayout],
  );

  const table = useReactTable({
    data: subcontractorCompanyInvoices,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    enableMultiSort: false,
    meta: {
      getRowSx: (row: Row<SubcontractorCompanyInvoiceListItem>): SxProps<Theme> =>
        muiSx({
          sx: styles.rowHighlighted,
          condition: InvoiceService.isPending(row.original) && !InvoiceService.isSelfPaid(row.original),
        }),
    },
  });

  const handleLoadMore = () => {
    setCurrentPage((previousPage) => previousPage + 1);
  };

  const handleRowClick = (invoice: SubcontractorCompanyInvoiceListItem) => {
    history.push(AppRoutes.invoicePath(invoice.id));
  };

  return (
    <TableLoadMore<SubcontractorCompanyInvoiceListItem>
      table={table}
      onRowClick={handleRowClick}
      onLoadMore={handleLoadMore}
      loading={isFetching}
      meta={openData?.meta}
    />
  );
};

export default OpenInvoices;
