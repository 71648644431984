import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import InfiniteScroll from 'components/InfiniteScroll';
import SelectAllButton from 'components/SelectAllButton';
import TableRowsWithLoading from 'components/TableRowsWithLoading';
import UserPresenter from 'presenters/UserPresenter';

import MemberChoiceRows from './components/MemberChoiceRows';
import useStyles from './useStyles';

const MAX_COLS_COUNT = 2;

const MemberChoiceTable = props => {
  const { members, selectedMembers, onMembersSelect, isLoading, membersMeta, onMembersLoad } = props;
  const classes = useStyles();

  const handleMemberToggle = (member, event) => {
    if (event.target.checked) {
      onMembersSelect(selected => [...selected, member]);
      return;
    }

    onMembersSelect(selected => selected.filter(selectedMember => selectedMember.id !== member.id));
  };

  const handleSelectAll = () => {
    onMembersSelect(members);
  };

  const handleDeselectAll = () => {
    onMembersSelect([]);
  };

  return (
    <>
      <div className={classes.selectAllContainer}>
        {!isEmpty(members) && (
          <SelectAllButton
            isEveryItemSelected={members.length === selectedMembers.length}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleDeselectAll}
          />
        )}
      </div>
      <InfiniteScroll
        className={classes.tableContainer}
        nextPage={membersMeta.nextPage}
        isLoading={isLoading}
        onLoad={onMembersLoad}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRowsWithLoading
                isLoading={isLoading}
                maxColsCount={MAX_COLS_COUNT}
                isData={!isEmpty(members)}
                emptyText="You have no available company managers in your team."
                rows={
                  <MemberChoiceRows
                    members={members}
                    selectedMembers={selectedMembers}
                    onMemberToggle={handleMemberToggle}
                  />
                }
              />
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};

MemberChoiceTable.propTypes = {
  members: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  selectedMembers: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  onMembersSelect: PropTypes.func.isRequired,
  membersMeta: PropTypes.shape().isRequired,
  onMembersLoad: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

MemberChoiceTable.defaultProps = {
  isLoading: false,
};

export default MemberChoiceTable;
