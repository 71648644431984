import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  options(params) {
    const path = apiRoutes.optionsSiteApiV1BuilderProjectRegularPaymentsPath();

    return FetchHelpers.get(path, params);
  },
};
