import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import IncomingConnectionsRepository from 'repositories/subcontractor/company/IncomingConnectionsRepository';
import ConnectionsRepository from 'repositories/subcontractor/company/ConnectionsRepository';
import UserInvitationsRepository from 'repositories/subcontractor/company/UserInvitationsRepository';
import BuildersRepository from 'repositories/subcontractor/BuildersRepository';
import CompanyBuildersRepository from 'repositories/subcontractor/company/BuildersRepository';
import { STATES as USER_INVITATION_STATES } from 'presenters/UserInvitationPresenter';

const initialState = {
  connections: {
    data: [],
    meta: {},
  },
  incomingConnections: {
    data: [],
    meta: {},
  },
  pendingInvitations: {
    data: [],
    meta: {},
  },
};

const buildersSlice = createSlice({
  name: 'builders',
  initialState,
  reducers: {
    loadConnectionsSuccess(state, { payload }) {
      state.connections.data = concat(state.connections.data, payload.companyConnections);
      state.connections.meta = payload.meta;
    },
    loadIncomingConnectionsSuccess(state, { payload }) {
      state.incomingConnections.data = concat(state.incomingConnections.data, payload.companyConnections);
      state.incomingConnections.meta = payload.meta;
    },
    loadPendingInvitationsSuccess(state, { payload }) {
      state.pendingInvitations.data = concat(state.pendingInvitations.data, payload.userInvitations);
      state.pendingInvitations.meta = payload.meta;
    },
    resetContacts() {
      return initialState;
    },
  },
});

export const { actions } = buildersSlice;
export default buildersSlice.reducer;

export const useBuildersActions = () => {
  const dispatch = useDispatch();

  const loadConnections = async (page = 1, cancelToken) => {
    const { data } = await ConnectionsRepository.index({ page }, cancelToken);

    dispatch(actions.loadConnectionsSuccess(data));

    return data;
  };

  const loadIncomingConnections = async (page = 1, cancelToken) => {
    const { data } = await IncomingConnectionsRepository.index({ page }, cancelToken);

    dispatch(actions.loadIncomingConnectionsSuccess(data));
  };

  const acceptIncomingConnection = connectionId => {
    return IncomingConnectionsRepository.accept(connectionId);
  };

  const rejectIncomingConnection = connectionId => {
    return IncomingConnectionsRepository.reject(connectionId);
  };

  const loadPendingInvitations = async (page = 1, cancelToken) => {
    const params = { q: { stateEq: USER_INVITATION_STATES.pending }, page };
    const { data } = await UserInvitationsRepository.index(params, cancelToken);

    dispatch(actions.loadPendingInvitationsSuccess(data));

    return data;
  };

  const loadBuilders = async (nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont, customHeaders, cancelToken) => {
    const params = {
      q: { nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont },
    };
    const { data } = await BuildersRepository.index(params, customHeaders, cancelToken);

    return data;
  };

  const massInviteBuilders = builders => {
    const params = builders.map(builder => ({ email: builder.email }));
    return CompanyBuildersRepository.massInvite({ builders: params });
  };

  const resetBuilders = () => {
    dispatch(actions.resetContacts());
  };

  return {
    loadConnections,
    loadIncomingConnections,
    acceptIncomingConnection,
    rejectIncomingConnection,
    loadPendingInvitations,
    loadBuilders,
    massInviteBuilders,
    resetBuilders,
  };
};
