import * as NewInvoicePresenter from 'domain/invoice/presenter';

import { useRef } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import useBreakpoints from 'hooks/useBreakpoints';

import { INVOICE_FORM_VARIANTS } from 'constants/invoices';
import ContentBox from 'components/ContentBox';
import Icon from 'components/Icon';
import InvoicePresenter from 'presenters/InvoicePresenter';
import { downloadFileByUrl } from 'utils/DownloadHelpers';
import { scrollTo, endOfElement } from 'utils/domUtils';
import { getInvoiceTotal } from 'utils/invoiceUtils';
import { formatUSD } from 'libs/currency';

import InvoiceInboundTable from './components/InvoiceInboundTable';

import useStyles from './useStyles';

const InvoiceCard = props => {
  const { variant, invoice, contactsTitle, isPreviewButtonVisible, isLoading } = props;
  const classes = useStyles();
  const paperRef = useRef({});
  const { screenWiderThan } = useBreakpoints();

  const sumOfLineItems = getInvoiceTotal(InvoicePresenter.pricedLineItems(invoice));

  const handleAttachmentDownLoad = () => {
    downloadFileByUrl(InvoicePresenter.attachmentUrl(invoice));
  };

  const handlePreview = () => {
    const bottom = endOfElement(paperRef.current);
    scrollTo(bottom);
  };

  return (
    <Paper className={classes.invoicePaper} ref={paperRef}>
      {!isLoading ? (
        <Grid container spacing={screenWiderThan.md ? 6 : 2}>
          <Grid item xs={12} md={4} className={classes.informationContainer}>
            <ContentBox title="Project">
              {InvoicePresenter.assignedProjects(invoice).map(project => (
                <Typography key={project.id}>{project.name}</Typography>
              ))}
            </ContentBox>
            <ContentBox title="Payment terms">
              <div className={classes.paymentTermsText}>
                <Box mr={0.5}>
                  <Typography>{InvoicePresenter.formattedPaymentTerms(invoice)}</Typography>
                </Box>
                {InvoicePresenter.paymentTerms(invoice) > 0 && (
                  <Tooltip title={InvoicePresenter.paymentTermsDescription(invoice)}>
                    <Box className={classes.paymentTermsIconContainer}>
                      <Icon name="containedInfo" height={16} width={16} className={classes.infoIcon} />
                    </Box>
                  </Tooltip>
                )}
              </div>
            </ContentBox>
            {InvoicePresenter.note(invoice) && (
              <ContentBox title="Notes">
                <Typography>{InvoicePresenter.note(invoice)}</Typography>
              </ContentBox>
            )}
            {InvoicePresenter.contactEmail(invoice) && (
              <ContentBox title={contactsTitle}>
                <Typography>
                  <Box mr={1} component="span">
                    Email:
                  </Box>
                  <Link className={classes.builderLink} href={`mailto:${InvoicePresenter.contactEmail(invoice)}`}>
                    {InvoicePresenter.contactEmail(invoice)}
                  </Link>
                </Typography>
              </ContentBox>
            )}
            {InvoicePresenter.attachmentUrl(invoice) && (
              <ContentBox title="Attachments">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAttachmentDownLoad}
                  className={classes.downloadButton}
                >
                  Download Attachment
                </Button>
                {isPreviewButtonVisible && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handlePreview}
                    endIcon={<Icon name="arrowDown" width="14" height="14" />}
                  >
                    Preview
                  </Button>
                )}
              </ContentBox>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <InvoiceInboundTable lineItems={InvoicePresenter.pricedLineItems(invoice)} />
            <Grid container direction="row" justifyContent="space-between" marginTop={3}>
              <Grid item xs={5} md={7}>
                <ContentBox title="Issue date">{InvoicePresenter.formattedCreatedAt(invoice)}</ContentBox>
              </Grid>
              <Grid item xs={7} md={5}>
                <ContentBox title="Invoice Summary">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography className={classes.invoiceSummaryItem} component="div">
                        <Box fontWeight="fontWeightBold">
                          {variant === INVOICE_FORM_VARIANTS.subcontractor ? 'Invoice total' : 'Total'}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.invoiceSummaryAmountContainer}>
                      <Typography className={classes.invoiceSummaryItem} component="div">
                        <Box fontWeight="fontWeightBold">{formatUSD(sumOfLineItems)}</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  {invoice.invoiceEarlyPayment && (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className={classes.invoiceSummaryItem} component="div">
                          <Box fontWeight="fontWeightBold">Early Payment Total</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.invoiceSummaryAmountContainer}>
                        <Typography className={classes.invoiceSummaryItem} component="div">
                          <Box fontWeight="fontWeightBold">{NewInvoicePresenter.actualTotalAmount(invoice, 2)}</Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </ContentBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </Paper>
  );
};

InvoiceCard.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)),
  invoice: InvoicePresenter.shape().isRequired,
  contactsTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isPreviewButtonVisible: PropTypes.bool,
};

InvoiceCard.defaultProps = {
  variant: INVOICE_FORM_VARIANTS.builder,
  isLoading: false,
  isPreviewButtonVisible: false,
};

export default InvoiceCard;
