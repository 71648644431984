import * as InvoiceService from 'domain/invoice/service';

import { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import clsx from 'clsx';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import InvoicePresenter from 'presenters/InvoicePresenter';
import UserPresenter from 'presenters/UserPresenter';
import { formatUSD } from 'utils/numberUtils';
import Icon from 'components/Icon';
import InvoicePaymentDate from 'components/InvoicePaymentDate';

import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import InvoiceResponseRow from './components/InvoiceResponseRow';

import useStyles from './useStyles';

const InvoiceResponseApproveForm = props => {
  const {
    invoice,
    formData,
    apiErrors,
    isFormDisabled,
    isViewInvoiceButton,
    loadProjects,
    loadLineItems,
    isAmountEdit,
    onSplitInvoice,
    onRemoveSplits,
    onOpenRejectForm,
    onFieldValueSet,
    onRemoveButtonClick,
    onViewInvoiceClick,
    onInvoiceLineItemFormOpen,
  } = props;
  const classes = useStyles();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = formData;

  const isRemoveButtonVisible = values.splittedInvoices.length > 1;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fieldsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <div className={classes.invoicePaymentDateContainer}>
              <Grid container spacing={2} className={classes.fieldsRow}>
                {!UserPresenter.isBuilder(currentUser) && <InvoicePaymentDate formData={formData} invoice={invoice} />}
              </Grid>
            </div>
            {isAmountEdit && (
              <div className={classes.invoiceResponseRowsContainer}>
                {values.splittedInvoices.map((splittedInvoice, index) => (
                  <InvoiceResponseRow
                    key={splittedInvoice.key}
                    isDisabled={isFormDisabled}
                    isDeleteButtonVisible={isRemoveButtonVisible}
                    index={index}
                    splittedInvoice={splittedInvoice}
                    apiErrors={apiErrors}
                    touched={touched}
                    errors={errors}
                    setFieldError={setFieldError}
                    onLoadLineItems={loadLineItems}
                    onFieldValueSet={onFieldValueSet}
                    onLoadProjects={loadProjects}
                    onChange={handleChange}
                    onRemoveButtonClick={onRemoveButtonClick}
                    onInvoiceLineItemFormOpen={onInvoiceLineItemFormOpen}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={4} md={4} className={classes.buttonsContainer}>
            <div className={classes.stickyContainer}>
              <div className={classes.fixedButtonsContainer}>
                <Box mb={2}>
                  {InvoiceService.isAwaitingApproval(invoice) && (
                    <Button variant="contained" color="primary" type="submit" fullWidth disabled={isFormDisabled}>
                      Approve
                    </Button>
                  )}
                </Box>
                <Box className={classes.actionsContainer}>
                  {isViewInvoiceButton && (
                    <Button
                      color="primary"
                      disabled={isFormDisabled}
                      className={classes.viewButton}
                      onClick={onViewInvoiceClick}
                    >
                      View Invoice
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    className={classes.rejectButton}
                    disabled={isFormDisabled}
                    onClick={onOpenRejectForm}
                  >
                    Reject
                  </Button>
                </Box>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {isAmountEdit && (
        <DialogActions className={classes.actionButtons}>
          <Grid container spacing={2}>
            <Grid item xs={9} md={10}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={isFormDisabled}
                    className={classes.spitButton}
                    startIcon={<AddIcon />}
                    onClick={onSplitInvoice}
                  >
                    Split Invoice
                  </Button>
                  {isRemoveButtonVisible && (
                    <Button
                      className={classes.removeButton}
                      startIcon={
                        <div className={classes.removeButtonContainer}>
                          <Icon name="bin" width={16} height={16} className={classes.removeButton} />
                        </div>
                      }
                      variant="outlined"
                      color="secondary"
                      onClick={onRemoveSplits}
                      disabled={isFormDisabled}
                    >
                      Remove splits
                    </Button>
                  )}
                </Grid>
                {!isNil(values.neededAmount) && values.neededAmount !== 0 && (
                  <Grid item className={classes.neededAmountContainer}>
                    <Typography className={clsx({ [classes.neededAmountError]: errors.neededAmount })}>
                      {values.neededAmount > 0
                        ? `You need to add ${formatUSD(
                            values.neededAmount,
                            2,
                            'never',
                          )} more to match the invoice's total`
                        : `You need to reduce the amount by ${formatUSD(
                            values.neededAmount,
                            2,
                            'never',
                          )} to match the invoice's total`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </form>
  );
};

InvoiceResponseApproveForm.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  formData: PropTypes.shape().isRequired,
  isViewInvoiceButton: PropTypes.bool,
  apiErrors: PropTypes.shape().isRequired,
  isFormDisabled: PropTypes.bool.isRequired,
  loadProjects: PropTypes.func.isRequired,
  loadLineItems: PropTypes.func.isRequired,
  isAmountEdit: PropTypes.bool.isRequired,
  onSplitInvoice: PropTypes.func.isRequired,
  onRemoveSplits: PropTypes.func.isRequired,
  onOpenRejectForm: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func.isRequired,
  onViewInvoiceClick: PropTypes.func.isRequired,
  onInvoiceLineItemFormOpen: PropTypes.func.isRequired,
};

InvoiceResponseApproveForm.defaultProps = {
  isViewInvoiceButton: true,
};

export default memo(InvoiceResponseApproveForm);
