import * as CompanyService from "domain/company/service";

import { UserCompanyRole, UserRole } from "./enums";
import { IUser } from "./types";

export const isCustomer = (user: Pick<IUser, "role">) => {
  return user.role === UserRole.customer;
};

export const isCompanyOwner = (user: Pick<IUser, "role">) => {
  return user.role === UserRole.companyOwner;
};

export const isSystemAdmin = (user: Pick<IUser, "role">) => {
  return user.role === UserRole.systemAdmin;
};

export const isCompanyManager = (user: Pick<IUser, "companyRole">) => {
  return user.companyRole === UserCompanyRole.companyManager;
};

export const isCompanyAdmin = (user: Pick<IUser, "companyRole">) => {
  return user.companyRole === UserCompanyRole.companyAdmin;
};

export const isBuilder = (user: Pick<IUser, "company">) => {
  return user?.company ? CompanyService.isBuilderCompany(user.company) : false;
};

export const isSubcontractor = (user: Pick<IUser, "company">) => {
  return user?.company ? CompanyService.isSubcontractorCompany(user.company) : false;
};

export const isBuilderManager = (user: Pick<IUser, "companyRole" | "company">) => {
  return isBuilder(user) && isCompanyManager(user);
};

export const isSubcontractorManager = (user: Pick<IUser, "companyRole" | "company">) => {
  return isSubcontractor(user) && isCompanyManager(user);
};

export const isBuilderAdmin = (user: Pick<IUser, "companyRole" | "company">) => {
  return isBuilder(user) && isCompanyAdmin(user);
};

export const isSubcontractorAdmin = (user: Pick<IUser, "companyRole" | "company">) => {
  return isSubcontractor(user) && isCompanyAdmin(user);
};
