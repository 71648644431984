import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import Notification from 'components/Notification';

const COOLDOWN_TIME = 20000; // 20 seconds

const ResendInvite = props => {
  const { email, onInviteResend } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleCloseNotification = () => {
    setNotification(null);
  };

  const handleResendInvite = async () => {
    if (isDisabled) {
      return;
    }

    setIsDisabled(true);

    try {
      await onInviteResend({ email });

      setNotification({
        title: 'Invitation Resent',
        description: `An invitation has been resent to ${email}.`,
      });
      setTimeout(() => setIsDisabled(false), COOLDOWN_TIME);
    } catch {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Button variant="contained" onClick={handleResendInvite} disabled={isDisabled}>
        Resend Invite
      </Button>

      {notification && (
        <Notification
          open={!!notification}
          onClose={handleCloseNotification}
          title={notification.title}
          description={notification.description}
        />
      )}
    </>
  );
};

ResendInvite.propTypes = {
  email: PropTypes.string.isRequired,
  onInviteResend: PropTypes.func.isRequired,
};

export default ResendInvite;
