import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import CustomerInvoicesRepository from 'repositories/builder/company/project/CustomerInvoicesRepository';

const MAX_PER_PAGE = 5;

const initialState = {
  customerInvoices: {
    data: [],
    nextPage: null,
    totalCount: 0,
  },
};

const slice = createSlice({
  name: 'projectClientInvoices',
  initialState,
  reducers: {
    loadCustomerInvoicesSuccess(state, { payload }) {
      state.customerInvoices.data = concat(state.customerInvoices.data, payload.customerInvoices);
      state.customerInvoices.nextPage = payload.meta.nextPage;
      state.customerInvoices.totalCount = payload.meta.totalCount;
    },
    resetCustomerInvoices() {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useProjectCustomerInvoicesActions = () => {
  const dispatch = useDispatch();

  const loadCustomerInvoices = async (projectId, page = 1) => {
    const { data } = await CustomerInvoicesRepository.index(projectId, {
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadCustomerInvoicesSuccess(data));

    return Promise.resolve(data);
  };

  const createCustomerInvoice = async (projectId, params = { paymentDate: null, amount: null }) => {
    const sendParams = {
      customerInvoice: params,
    };
    await CustomerInvoicesRepository.create(projectId, sendParams);
  };

  const resetCustomerInvoices = () => {
    dispatch(actions.resetCustomerInvoices());
  };

  return {
    loadCustomerInvoices,
    resetCustomerInvoices,
    createCustomerInvoice,
  };
};

export const selectors = {
  customerInvoices: state => state.ProjectClientInvoicesSlice.customerInvoices,
  totalCount: state => state.ProjectClientInvoicesSlice.customerInvoices.totalCount,
};
