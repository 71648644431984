import common from "./common";
import components from "./components";
import userLayout from "./layouts/user";
import welcomeLayout from "./layouts/welcome";
import adminInvoices from "./pages/admin/invoices";
import builderInvoices from "./pages/builder/invoices";
import accountActivation from "./pages/guest/accountActivation";
import customerActivation from "./pages/guest/customerActivation";
import onboardingLanding from "./pages/onboarding/landing";
import subcontractorContacts from "./pages/subcontractor/contacts";
import subcontractorInvoice from "./pages/subcontractor/invoice";
import subcontractorInvoices from "./pages/subcontractor/invoices";
import validation from "./validation";

const en = {
  userLayout,
  welcomeLayout,
  accountActivation,
  customerActivation,
  onboardingLanding,
  builderInvoices,
  adminInvoices,
  subcontractorInvoices,
  subcontractorInvoice,
  subcontractorContacts,
  components,
  common,
  validation,
};

export default en;

export const ns = Object.keys(en);
