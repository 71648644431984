import { useState } from 'react';
import { isNil, path } from 'ramda';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useHistory } from 'react-router-dom';

import { useCreateSessionMutation } from 'store/api/session/apiSlice';

import GuestLayout from 'layouts/GuestLayout';
import { initialValues, validationSchema } from 'forms/SignInForm';
import PasswordTextField from 'components/PasswordTextField';
import AppRoutes from 'routes/AppRoutes';
import Link from 'components/Link';
import useFormik from 'hooks/useFormik';
import { sentencize } from 'utils/stringUtils';
import { getInitialRedirectUrl, deleteInitialRedirectUrl } from 'libs/initialRedirect';

import useStyles from './useStyles';

const SignIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState({});
  const [createSession] = useCreateSessionMutation();
  const { touched, errors, handleChange, values, isSubmitting, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    clearErrorsOnInput: true,
    onApiErrorsSet: setApiErrors,
    onSubmit: async valuesToSubmit => {
      try {
        await createSession({ user: valuesToSubmit });

        const redirectUrl = getInitialRedirectUrl();

        if (redirectUrl) {
          deleteInitialRedirectUrl();
          history.push(redirectUrl);
        }
      } catch (e) {
        setApiErrors(e);
      }
    },
  });

  const getPasswordErrors = () => {
    if (isNil(apiErrors.password)) {
      return touched.password && errors.password;
    }

    const errorText = path(['password', 0], apiErrors);

    return sentencize(errorText);
  };

  return (
    <GuestLayout>
      <div className={classes.root}>
        <Typography variant="h2">Sign into Funl</Typography>
        <div className={classes.signUpContainer}>
          Don&apos;t have an account? <Link to={AppRoutes.signUpPath()}>Sign Up</Link>
        </div>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <TextField
              classes={{ root: classes.inputRoot }}
              name="email"
              label="Email"
              onChange={handleChange}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            <div className={classes.resetPassword}>
              <Link to={AppRoutes.forgotPasswordPath()}>Reset password</Link>
            </div>
            <PasswordTextField
              name="password"
              label="Password"
              classes={{ root: classes.inputRoot }}
              onChange={handleChange}
              value={values.password}
              error={Boolean(apiErrors.password) || (touched.password && Boolean(errors.password))}
              helperText={getPasswordErrors()}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              classes={{ root: classes.submitRoot }}
              disabled={isSubmitting}
            >
              Sign In
            </Button>
          </form>
        </div>
      </div>
    </GuestLayout>
  );
};

export default SignIn;
