import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  proceed() {
    const path = apiRoutes.proceedSiteApiV1BuilderCompanyOnboardingLineItemsPath();

    return FetchHelpers.post(path);
  },
};
