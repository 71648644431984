import { TABLE_CELL_VARIANT } from 'components/TableStatus';

export const INVOICES_TABLE_STATUS = {
  open: 'open',
  approved: 'approved',
  closed: 'closed',
};

export const MAX_COLS_COUNT = 6;
export const MAX_AMOUNT_PAYMENT = 999999.99;

export const VARIANT_COLORS = {
  [INVOICES_TABLE_STATUS.open]: TABLE_CELL_VARIANT.green,
  [INVOICES_TABLE_STATUS.approved]: TABLE_CELL_VARIANT.orange,
  [INVOICES_TABLE_STATUS.closed]: TABLE_CELL_VARIANT.gray,
};

export const VARIANT_TITLE = {
  [INVOICES_TABLE_STATUS.open]: 'Open Invoices',
  [INVOICES_TABLE_STATUS.approved]: 'Approved Invoices',
  [INVOICES_TABLE_STATUS.closed]: 'Closed Invoices',
};

export const INVOICE_FORM_VARIANTS = {
  subcontractor: 'subcontractor',
  builder: 'builder',
};

export const GC_INVOICES_SORTING_RULE = {
  projectName: 'project_name',
  name: 'name',
  state: 'state',
  projectedPaidAt: 'projected_paid_at',
  totalAmount: 'total_amount',
};

export const SC_INVOICES_SORTING_RULE = {
  projectName: 'project_name',
  name: 'name',
  state: 'state',
  projectedPaidAt: 'projected_paid_at',
  totalAmount: 'total_amount',
};
