import DotBadge from "components/common/DotBadge";
import Span from "components/common/Span";
import Icon from "components/Icon";

import { useGetHasPendingConnectionsQuery } from "store/api/subcontractor/company/incomingConnection/apiSlice";

import styles from "./styles";

const SubcontractorContactsIcon = () => {
  const { data: hasPendingConnections } = useGetHasPendingConnectionsQuery(undefined, { pollingInterval: 5000 });

  return (
    <Span sx={styles.iconContainer}>
      <Icon width={24} height={18} name="couple" />
      {hasPendingConnections && (
        <Span sx={styles.badgeContainer}>
          <DotBadge />
        </Span>
      )}
    </Span>
  );
};

export default SubcontractorContactsIcon;
