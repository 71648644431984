import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import EarlyPaymentsRepository from 'repositories/subcontractor/company/invoice/EarlyPaymentsRepository';

const initialState = {
  earlyPayments: null,
};

const invoiceEarlyPaymentsSlice = createSlice({
  name: 'invoiceEarlyPayments',
  initialState,
  reducers: {
    setEarlyPayment(state, { payload }) {
      state.earlyPayments = payload;
    },
    resetEarlyPayments() {
      return initialState;
    },
  },
});

export const { actions } = invoiceEarlyPaymentsSlice;
export default invoiceEarlyPaymentsSlice.reducer;

export const useEarlyPaymentsActions = () => {
  const dispatch = useDispatch();

  const loadEarlyPayments = async ({ inboundLineItems = [0] }) => {
    const lineItemAmounts = inboundLineItems.map(item => item.amount || 0);
    const requestParams = {
      earlyPayment: {
        lineItemAmounts,
      },
    };
    const { data } = await EarlyPaymentsRepository.options(requestParams);
    dispatch(actions.setEarlyPayment(data));

    return data;
  };

  const resetEarlyPayments = () => {
    dispatch(actions.resetEarlyPayments());
  };

  return {
    loadEarlyPayments,
    resetEarlyPayments,
  };
};

export const selectors = {
  earlyPayments(state) {
    return state.InvoiceEarlyPaymentsSlice.earlyPayments;
  },
};
