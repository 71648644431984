import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const PROJECT_LINE_ITEMS_STATES = {
  opened: 'opened',
  closed: 'closed',
};

export const PROJECT_LINE_ITEMS_STATE_EVENTS = {
  [PROJECT_LINE_ITEMS_STATES.opened]: 'open',
  [PROJECT_LINE_ITEMS_STATES.closed]: 'close',
};

const PROJECT_LINE_ITEM_STATE_LABEL = {
  [PROJECT_LINE_ITEMS_STATES.opened]: 'Open',
  [PROJECT_LINE_ITEMS_STATES.closed]: 'Closed',
};

export const STATES = {
  draft: 'draft',
  active: 'active',
};

export default new Presenter(
  {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categoryName: PropTypes.string,
    accountCode: PropTypes.string,
    state: PropTypes.string,
    projectLineItemState: PropTypes.string,
  },
  {
    isDraft(lineItem) {
      return this.state(lineItem) === STATES.draft;
    },
    isOpen(lineItem) {
      return this.projectLineItemState(lineItem) === PROJECT_LINE_ITEMS_STATES.opened;
    },
    projectLineItemStateLabel(lineItem) {
      return PROJECT_LINE_ITEM_STATE_LABEL[this.projectLineItemState(lineItem)];
    },
  },
);
