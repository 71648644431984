import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    backgroundColor: "common.white",
    boxShadow: "0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)",
    borderRadius: "8px",
    padding: {
      xs: "24px",
      md: "48px 64px",
    },
  },
  tip: {
    marginTop: "8px",
    marginBottom: "24px",
    fontSize: "14px",
    color: "colors.gunsmokeGray",
  },
  chooseContainer: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    marginBottom: {
      xs: "24px",
      md: "48px",
    },
    gap: "24px",
  },
  buttonChoice: {
    padding: "29px 10px 33px",
    color: "colors.chicagoGray",
    textTransform: "none",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "14px",
    width: {
      md: "50%",
    },
  },
  buttonChoiceSelected: {
    border: (theme) => `2px solid ${theme.palette.colors.green} !important`,
    color: (theme) => `${theme.palette.colors.green} !important`,
  },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& svg": {
      alignSelf: "center",
      fill: (theme) => theme.palette.colors.deltaGray,
      marginBottom: "11px",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionButton: {
    width: {
      md: "auto",
    },
  },
};

export default styles;
