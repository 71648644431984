export default {
  activationForm: {
    accountInfo: "Account Info",
    canBeEdited: "All settings can be edited in your profile page.",
    fullName: "Full Name",
    password: "Password",
    continue: "Continue",
  },
  invoiceTabs: {
    open: "open",
    approved: "approved",
    closed: "closed",
  },
  tableLoadMore: {
    loadMore: "Load More",
    noItems: "No items",
  },
};
