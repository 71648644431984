import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: 24,
    width: '100%',
    height: 124,
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '33.3% 33.3% 33.3%',
    rowGap: 8,
    columnGap: 50,
  },
  leftContainer: {
    display: 'grid',
    gridTemplateColumns: '140px 30%',
  },
  rightContainer: {
    display: 'grid',
    gridTemplateColumns: '140px 20%',
  },
  label: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.colors.kelp,
  },
  value: {
    fontSize: 12,
    lineHeight: '20px',
    color: theme.palette.colors.kelp,
    fontWeight: 700,
  },
}));
