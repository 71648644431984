import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import CustomerInvoicePresenter from 'presenters/CustomerInvoicePresenter';
import Icon from 'components/Icon';

import useStyles from './useStyles';

const ProjectClientInvoicesTableRow = props => {
  const { customerInvoice } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        <span className={classes.number}>{CustomerInvoicePresenter.name(customerInvoice)}</span>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {CustomerInvoicePresenter.formattedCreatedAt(customerInvoice)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.cell, classes.amountCell)}>
        {CustomerInvoicePresenter.formattedUSDAmount(customerInvoice)}
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {CustomerInvoicePresenter.isPaid(customerInvoice) && (
          <Icon name="roundCheckbox" width={12} height={12} className={classes.paidIcon} />
        )}
        {CustomerInvoicePresenter.status(customerInvoice)}
      </TableCell>
    </TableRow>
  );
};

ProjectClientInvoicesTableRow.propTypes = {
  customerInvoice: CustomerInvoicePresenter.shape().isRequired,
};

export default ProjectClientInvoicesTableRow;
