import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    minWidth: 528,
  },
  titleContainer: {
    display: 'flex',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
    marginRight: theme.spacing(1),
    marginTop: 3,
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  infoContainer: {
    marginTop: theme.spacing(4),
  },
  label: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.colors.lemonGrass,
    textTransform: 'uppercase',
  },
  value: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(15),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmButton: {
    padding: '12px 24px',
  },
  rejectButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.colors.red,
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(13),
  },
}));
