import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { initialValues, validationSchema } from 'forms/Onboarding/CompanyForm';
import useFormik from 'hooks/useFormik';

import USAStatesAutocomplete from 'components/common/USAStatesAutocomplete';

import useStyles from './useStyles';

const CompanySetup = props => {
  const { onCurrentUserLoad, onCompanyCreate } = props;
  const classes = useStyles();
  const { isValid, handleChange, values, isSubmitting, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    initialErrors: initialValues,
    onSubmit: async valuesToSubmit => {
      await onCompanyCreate({ company: valuesToSubmit });
      onCurrentUserLoad();
    },
  });

  return (
    <div className={classes.root}>
      <Typography variant="h4">Company Info</Typography>
      <div className={classes.tip}>All settings can be edited in your profile page.</div>
      <form onSubmit={handleSubmit}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name="name"
          label="Company Name"
          onChange={handleChange}
          value={values.name}
        />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="address1"
          label="Address Line 1"
          onChange={handleChange}
          value={values.address1}
        />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="address2"
          label="Address Line 2"
          onChange={handleChange}
          value={values.address2}
        />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="city"
          label="City"
          onChange={handleChange}
          value={values.city}
        />
        <div className={classes.inlineFormContainer}>
          <div className={classes.stateContainer}>
            <div className={classes.inputRoot}>
              <USAStatesAutocomplete
                name="state"
                label="state"
                value={values.state}
                onChange={state => setFieldValue('state', state)}
              />
            </div>
          </div>
          <TextField
            classes={{ root: classes.inputRoot }}
            name="zipCode"
            label="Zip Code"
            onChange={handleChange}
            value={values.zipCode}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
            classes={{ root: classes.submitRoot }}
          >
            Next Step
          </Button>
        </div>
      </form>
    </div>
  );
};

CompanySetup.propTypes = {
  onCurrentUserLoad: PropTypes.func.isRequired,
  onCompanyCreate: PropTypes.func.isRequired,
};

export default CompanySetup;
