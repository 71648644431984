import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TransactionPresenter from 'presenters/TransactionPresenter';

import useStyles from './useStyles';

const EMPTY_DATA_SYMBOL = '-';

const AdminPaymentRow = props => {
  const { transaction } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.customerInvoiceName(transaction) || EMPTY_DATA_SYMBOL}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.companyName(transaction)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.customerName(transaction) || EMPTY_DATA_SYMBOL}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.projectName(transaction) || EMPTY_DATA_SYMBOL}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.source(transaction)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.state(transaction)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TransactionPresenter.formattedUSDAmount(transaction)}
      </TableCell>
    </TableRow>
  );
};

AdminPaymentRow.propTypes = {
  transaction: TransactionPresenter.shape().isRequired,
};

export default AdminPaymentRow;
