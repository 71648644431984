import PropTypes from 'prop-types';

import List from '@mui/material/List';

import CollapsedListItem from './components/CollapsedListItem';
import ExpandedListItem from './components/ExpandedListItem';
import useStyles from './useStyles';

const VARIANTS = {
  collapsed: 'collapsed',
  expanded: 'expanded',
};

const Navigation = props => {
  const { navItems, variant, onDrawerClose } = props;
  const classes = useStyles();

  const renderItems = () => {
    switch (variant) {
      case VARIANTS.collapsed:
        return navItems.map(item => <CollapsedListItem key={item.label} {...item} onDrawerClose={onDrawerClose} />);
      case VARIANTS.expanded:
        return navItems.map(item => <ExpandedListItem key={item.label} {...item} onDrawerClose={onDrawerClose} />);
      default:
        return null;
    }
  };

  return <List className={classes.menuList}>{renderItems()}</List>;
};

Navigation.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
  onDrawerClose: PropTypes.func.isRequired,
};

export default Navigation;
