import { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

import AddButton from 'components/AddButton';
import { useAdminPaymentsActions } from 'store/AdminPaymentsSlice';
import useLoading from 'hooks/useLoading';

import CreateTransactionForm from './components/CreateTransactionForm';

const SUCCESS_MESSAGE = 'The payment was created';

const CreateTransactionButton = props => {
  const { onCreate } = props;
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { createTransaction } = useAdminPaymentsActions();

  const { func: sendCreateTransaction } = useLoading(createTransaction, {
    isShowSuccessNotification: true,
    successMessage: SUCCESS_MESSAGE,
  });

  const handleFormShow = () => setIsDialogVisible(true);
  const handleDialogClose = () => setIsDialogVisible(false);
  const handleSubmit = async params => {
    await sendCreateTransaction(params);
    onCreate();
  };

  return (
    <>
      <AddButton variant="contained" onClick={handleFormShow}>
        Create New payment
      </AddButton>
      <Dialog open={isDialogVisible} onClose={handleDialogClose}>
        <CreateTransactionForm onClose={handleDialogClose} onSubmit={handleSubmit} />
      </Dialog>
    </>
  );
};

CreateTransactionButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default CreateTransactionButton;
