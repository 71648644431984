import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    paddingRight: 64,
  },
  tip: {
    marginTop: 6,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  topPanel: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalItems: {
    marginTop: 5,
    fontSize: 15,
  },
  textButton: {
    marginLeft: 22,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  tableContainer: {
    maxWidth: 570,
    height: 410,
  },
  submitRoot: {
    padding: '12px 24px',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32,
  },
}));
