import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  row: {
    '& $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: 14,
    paddingLeft: 0,
    paddingRight: 0,
    padding: '11px 0',
  },
}));
