import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  titleIcon: {
    color: theme.palette.colors.green,
  },
}));
