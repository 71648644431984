import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import TransactionsRepository from 'repositories/customer/TransactionsRepository';
import CustomerInvoicesRepository from 'repositories/customer/CustomerInvoicesRepository';
import CustomerBillingSettingsRepository from 'repositories/customer/CustomerBillingSettingsRepository';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import { CUSTOMER_INVOICE_STATES } from 'presenters/CustomerInvoicePresenter';
import { TRANSACTIONS_STATES } from 'presenters/TransactionPresenter';

const initialState = {
  customerInvoices: {
    data: [],
    nextPage: null,
  },
  transactions: {
    data: [],
    nextPage: null,
    totalCount: null,
  },
  paymentMethod: null,
};

const slice = createSlice({
  name: 'customerPayments',
  initialState,
  reducers: {
    loadCustomerInvoicesSuccess(state, { payload }) {
      state.customerInvoices.data = payload.customerInvoices;
      state.customerInvoices.nextPage = payload.meta.nextPage;
    },
    loadPaymentMethod(state, { payload }) {
      if (payload.ach) {
        state.paymentMethod = PAYMENT_METHODS.ach;
        return;
      }
      if (payload.bankAccount) {
        state.paymentMethod = PAYMENT_METHODS.bankAccount;
        return;
      }
      state.paymentMethod = PAYMENT_METHODS.check;
    },
    updateCustomerInvoice(state, { payload }) {
      const { id, invoiceState } = payload;
      const customerInvoiceIndex = state.customerInvoices.data.findIndex(invoice => invoice.id === id);
      state.customerInvoices.data[customerInvoiceIndex].state = invoiceState;
    },
    loadTransactionsSuccess(state, { payload }) {
      state.transactions.data = payload.transactions;
      state.transactions.nextPage = payload.meta.nextPage;
      state.transactions.totalCount = payload.meta.totalCount;
    },

    resetInvoices(state) {
      state.customerInvoices = initialState.customerInvoices;
    },
    resetTransactions(state) {
      state.transactions = initialState.transactions;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useCustomerPaymentsActions = () => {
  const dispatch = useDispatch();

  const loadCustomerInvoices = async () => {
    const params = {
      q: { stateIn: [CUSTOMER_INVOICE_STATES.pending, CUSTOMER_INVOICE_STATES.approved], s: 'id desc' },
    };
    const { data } = await CustomerInvoicesRepository.index(params);
    dispatch(actions.loadCustomerInvoicesSuccess(data));

    return data;
  };

  const approve = async id => {
    await CustomerInvoicesRepository.approve(id);
    dispatch(actions.updateCustomerInvoice({ id, invoiceState: CUSTOMER_INVOICE_STATES.approved }));
  };

  const loadPaymentMethod = async () => {
    const { data } = await CustomerBillingSettingsRepository.paymentMethod();
    dispatch(actions.loadPaymentMethod(data));
    return data;
  };

  const loadTransactions = async () => {
    const { data } = await TransactionsRepository.index({
      q: { stateEq: TRANSACTIONS_STATES.paid, s: 'created_at desc' },
    });
    dispatch(actions.loadTransactionsSuccess(data));
    return data;
  };

  const resetTransactions = () => {
    dispatch(actions.resetTransactions());
  };

  const resetInvoices = () => {
    dispatch(actions.resetInvoices());
  };

  return {
    loadCustomerInvoices,
    approve,
    loadPaymentMethod,
    loadTransactions,
    resetTransactions,
    resetInvoices,
  };
};

export const selectors = {
  customerInvoices: state => state.CustomerPaymentsSlice.customerInvoices,
  transactions: state => state.CustomerPaymentsSlice.transactions,
  paymentMethod: state => state.CustomerPaymentsSlice.paymentMethod,
};
