export const enum InvoiceState {
  pending = "pending",
  awaitingApproval = "awaiting_approval",
  approved = "approved",
  rejected = "rejected",
  paid = "paid",
  canceled = "canceled",
}

export const enum InvoiceKind {
  requested = "requested",
  selfPaid = "self_paid",
}

export enum InvoicePaymentKind {
  default = "default",
  earlyPayment = "early_payment",
}
