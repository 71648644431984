import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1BuilderCompanyLineItemsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  create(params) {
    const path = apiRoutes.siteApiV1BuilderCompanyLineItemsPath();

    return FetchHelpers.post(path, params);
  },
  update(id, params) {
    const path = apiRoutes.siteApiV1BuilderCompanyLineItemPath(id);

    return FetchHelpers.put(path, params);
  },
  destroy(id) {
    const path = apiRoutes.siteApiV1BuilderCompanyLineItemPath(id);

    return FetchHelpers.delete(path);
  },
  massCreate(params) {
    const path = apiRoutes.massCreateSiteApiV1BuilderCompanyLineItemsPath();

    return FetchHelpers.post(path, params);
  },
};
