import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiTableCellType {
  defaultProps: ComponentsProps["MuiTableCell"];
  styleOverrides: ComponentsOverrides["MuiTableCell"];
}

const MuiTableCell: IMuiTableCellType = {
  defaultProps: {},
  styleOverrides: {
    head: {
      padding: "0 10px 8px 10px",
      fontSize: 12,
      lineHeight: "16px",
      color: baseTheme.palette.colors.bud,
      border: "none",
      "&:last-child": {
        paddingRight: 0,
      },
    },
    body: {
      padding: "11px 5px",
      "&:first-of-type": {
        paddingLeft: "8px",
      },
      "&:last-child": {
        paddingRight: "8px",
      },
    },
  },
};

export default MuiTableCell;
