import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Icon from 'components/Icon';
import { useNewProjectActions } from 'store/NewProjectSlice';
import AppRoutes from 'routes/AppRoutes';
import useLoading from 'hooks/useLoading';

import ProjectSettings from './components/ProjectSettings';
import NewProjectForm from './components/NewProjectForm';
import useStyles from './useStyles';

const NewProject = props => {
  const { onFormHide } = props;
  const classes = useStyles();
  const [isSettingsShown, setIsSettingsShown] = useState(false);
  const { loadRegularPayments, loadCompanyManagers, createProject, changeProject, resetNewProject } =
    useNewProjectActions();
  const {
    project,
    regularPayments: { data: regularPayments },
    companyManagers: { data: companyManagers, meta: companyManagersMeta },
  } = useSelector(state => state.NewProjectSlice);
  const { func: sendLoadCompanyManagers, isNotFinished: isCompanyManagersLoading } = useLoading(loadCompanyManagers);
  const history = useHistory();

  useEffect(() => {
    sendLoadCompanyManagers();

    return resetNewProject;
  }, []);

  const handleProjectCreate = async params => {
    const {
      data: {
        project: { id },
      },
    } = await createProject(params);

    history.push(AppRoutes.projectDetailsPath(id));
  };

  const handleSettingsHide = () => {
    setIsSettingsShown(false);
  };

  if (isSettingsShown) {
    return (
      <ProjectSettings
        project={project}
        onFormHide={onFormHide}
        onProjectCreate={handleProjectCreate}
        onProjectChange={changeProject}
        regularPayments={regularPayments}
        companyManagers={companyManagers}
        onRegularPaymentsLoad={loadRegularPayments}
        onSettingsHide={handleSettingsHide}
        loadCompanyManagers={sendLoadCompanyManagers}
        companyManagersMeta={companyManagersMeta}
        isCompanyManagersLoading={isCompanyManagersLoading}
      />
    );
  }

  const handleNewProjectFormSubmit = params => {
    setIsSettingsShown(true);
    changeProject(params);
  };

  return (
    <Paper classes={{ root: classes.paper }}>
      <div className={classes.titleContainer}>
        <Icon name="outlinedPlus" width={26} height={26} className={classes.plusIcon} />
        <Typography variant="h3">New Project</Typography>
      </div>
      <div className={classes.formContainer}>
        <NewProjectForm onFormHide={onFormHide} onFormSubmit={handleNewProjectFormSubmit} project={project} />
      </div>
    </Paper>
  );
};

NewProject.propTypes = {
  onFormHide: PropTypes.func.isRequired,
};

export default NewProject;
