import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import useStyles from './useStyles';

const AutocompletePaperButton = props => {
  const { text, children, onClick } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.listContainer}>
      <div className={classes.buttonContainer}>
        <Button className={classes.button} color="primary" variant="text" onMouseDown={onClick}>
          {text}
        </Button>
      </div>
      {children}
    </Paper>
  );
};

AutocompletePaperButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

AutocompletePaperButton.defaultProps = {
  children: null,
};

export default AutocompletePaperButton;
