import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  nav: {
    width: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 232,
    },
    flexShrink: 0,
  },
  content: {
    width: '100%',
    padding: theme.spacing(5, 3, 0, 3),
    marginBottom: theme.spacing(3),
    paddingTop: '88px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '104px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '40px',
    },
  },
}));
