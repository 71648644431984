import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  checkIcon: {
    fill: theme.palette.colors.green,
  },
  sandglassIcon: {
    fill: theme.palette.colors.orange,
  },
  status: {
    marginLeft: 5,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
  },
}));
