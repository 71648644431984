import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import LineItemPresenter from 'presenters/LineItemPresenter';
import LineItemsTable from 'components/LineItemsTable';
import TemplateLineItems from 'components/TemplateLineItems';
import InfiniteScroll from 'components/InfiniteScroll';
import AddButton from 'components/AddButton';

import useStyles from './useStyles';

const ProjectLineItems = props => {
  const {
    onCompanyLineItemsLoad,
    onCompanyLineItemsReset,
    companyLineItems,
    isCompanyLineItemsLoading,
    companyLineItemsMeta,
    onProjectLineItemsLoad,
    projectLineItems,
    isProjectLineItemsLoading,
    projectLineItemsMeta,
    onProjectLineItemCreate,
    onProjectLineItemUpdate,
    onProjectLineItemDestroy,
    onProjectLineItemMassCreate,
  } = props;
  const [isNewItemFormShown, setIsNewItemFormShown] = useState(false);
  const [isTemplateModalShown, setIsTemplateModalShown] = useState(false);
  const classes = useStyles();

  const handleTemplateModalShow = () => {
    setIsTemplateModalShown(true);
  };

  const handleTemplateModalHide = () => {
    setIsTemplateModalShown(false);
  };

  const handleNewItemFormShow = () => {
    setIsNewItemFormShown(true);
  };

  const handleNewItemFormHide = () => {
    setIsNewItemFormShown(false);
  };

  const handleLineItemsMassCreate = async params => {
    const projectLineItemParams = params.map(({ id }) => ({ lineItemId: id }));
    await onProjectLineItemMassCreate(projectLineItemParams);
    handleTemplateModalHide();
  };

  const handleLineItemCreate = async params => {
    await onProjectLineItemCreate(params);
    handleNewItemFormHide();
  };

  return (
    <Paper classes={{ root: classes.root }}>
      <Typography variant="h4">Project Line Items And Accounting Codes</Typography>
      <div className={classes.toolbar}>
        <div className={classes.actions}>
          <AddButton variant="outlined" onClick={handleNewItemFormShow}>
            Add Item
          </AddButton>
          <Button classes={{ root: classes.textButton }} color="primary" onClick={handleTemplateModalShow}>
            Select From Template
          </Button>
        </div>
        <div className={classes.totalItems}>Total Items: {projectLineItemsMeta.totalCount}</div>
      </div>
      <InfiniteScroll
        nextPage={projectLineItemsMeta.nextPage}
        isLoading={isProjectLineItemsLoading}
        onLoad={onProjectLineItemsLoad}
      >
        <LineItemsTable
          lineItems={projectLineItems}
          isLoading={isProjectLineItemsLoading}
          onRemove={onProjectLineItemDestroy}
          onEdit={onProjectLineItemUpdate}
          onCreate={handleLineItemCreate}
          isNewItemFormActive={isNewItemFormShown}
          onNewItemFormHide={handleNewItemFormHide}
          variant="project"
        />
      </InfiniteScroll>
      <Dialog open={isTemplateModalShown} onClose={handleTemplateModalHide}>
        <TemplateLineItems
          lineItems={companyLineItems}
          isLoading={isCompanyLineItemsLoading}
          onHide={handleTemplateModalHide}
          onLineItemsCreate={handleLineItemsMassCreate}
          onLineItemsLoad={onCompanyLineItemsLoad}
          onUnmount={onCompanyLineItemsReset}
          meta={companyLineItemsMeta}
        />
      </Dialog>
    </Paper>
  );
};

ProjectLineItems.propTypes = {
  onCompanyLineItemsLoad: PropTypes.func.isRequired,
  onCompanyLineItemsReset: PropTypes.func.isRequired,
  companyLineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  isCompanyLineItemsLoading: PropTypes.bool.isRequired,
  companyLineItemsMeta: PropTypes.shape().isRequired,
  onProjectLineItemsLoad: PropTypes.func.isRequired,
  projectLineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  isProjectLineItemsLoading: PropTypes.bool.isRequired,
  projectLineItemsMeta: PropTypes.shape().isRequired,
  onProjectLineItemCreate: PropTypes.func.isRequired,
  onProjectLineItemUpdate: PropTypes.func.isRequired,
  onProjectLineItemDestroy: PropTypes.func.isRequired,
  onProjectLineItemMassCreate: PropTypes.func.isRequired,
};

export default ProjectLineItems;
