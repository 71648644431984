import { memo } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

import LinearProgress from 'components/LinearProgress';
import InvoicePresenter from 'presenters/InvoicePresenter';
import InvoiceResponseHeader from 'components/common/InvoiceResponseHeader';
import InvoiceLineItemCreateForm from 'components/InvoiceLineItemCreateForm';
import InvoiceResponseRejectForm from 'components/InvoiceResponseRejectForm';

import { useInvoiceResponseForm } from './hooks';
import InvoiceResponseApproveForm from './components/InvoiceResponseApproveForm';

import useStyles from './useStyles';

const InvoiceResponseForm = props => {
  const {
    invoice,
    isAmountEdit,
    isViewInvoiceButton,
    loadProjects,
    loadLineItems,
    approveInvoice,
    rejectInvoice,
    onLineItemItemCreate,
  } = props;

  const classes = useStyles();
  const {
    formData,
    apiErrors,
    isFormDisabled,
    isRejectFormOpen,
    isInvoiceLineItemFormOpen,
    creationLineItemProjectId,
    handleSplitInvoice,
    handleRemoveSplits,
    handleOpenRejectForm,
    handleFieldValueSet,
    handleReject,
    handleCancelRejectForm,
    handleRemoveButtonClick,
    handleViewInvoiceClick,
    handleInvoiceLineItemFormOpen,
    handleInvoiceLineItemFormClose,
    handleLineItemItemCreate,
  } = useInvoiceResponseForm({
    invoiceId: InvoicePresenter.id(invoice),
    totalAmount: InvoicePresenter.totalAmount(invoice),
    isAmountEdit,
    approveInvoice,
    rejectInvoice,
    onLineItemItemCreate,
  });

  return (
    <>
      <LinearProgress isVisible={isFormDisabled} />
      <div className={classes.invoiceResponseFormContainer}>
        {invoice && (
          <div className={classes.header}>
            <InvoiceResponseHeader invoice={invoice} name={InvoicePresenter.builderInvoiceTitle(invoice)} />
          </div>
        )}
        {isRejectFormOpen ? (
          <InvoiceResponseRejectForm
            isDisabled={isFormDisabled}
            onReject={handleReject}
            onCancel={handleCancelRejectForm}
          />
        ) : (
          <InvoiceResponseApproveForm
            invoice={invoice}
            formData={formData}
            apiErrors={apiErrors}
            isFormDisabled={isFormDisabled}
            isViewInvoiceButton={isViewInvoiceButton}
            isAmountEdit={isAmountEdit}
            loadProjects={loadProjects}
            loadLineItems={loadLineItems}
            onSplitInvoice={handleSplitInvoice}
            onRemoveSplits={handleRemoveSplits}
            onOpenRejectForm={handleOpenRejectForm}
            onFieldValueSet={handleFieldValueSet}
            onInvoiceLineItemFormOpen={handleInvoiceLineItemFormOpen}
            onRemoveButtonClick={handleRemoveButtonClick}
            onViewInvoiceClick={handleViewInvoiceClick}
          />
        )}
        <Dialog open={isInvoiceLineItemFormOpen} maxWidth="lg" onClose={handleInvoiceLineItemFormClose}>
          {creationLineItemProjectId && (
            <InvoiceLineItemCreateForm
              projectId={creationLineItemProjectId}
              onLineItemItemCreate={handleLineItemItemCreate}
              onClose={handleInvoiceLineItemFormClose}
            />
          )}
        </Dialog>
      </div>
    </>
  );
};

InvoiceResponseForm.propTypes = {
  invoice: InvoicePresenter.shape().isRequired,
  isViewInvoiceButton: PropTypes.bool,
  isAmountEdit: PropTypes.bool,
  loadProjects: PropTypes.func.isRequired,
  loadLineItems: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  rejectInvoice: PropTypes.func.isRequired,
  onLineItemItemCreate: PropTypes.func.isRequired,
};

InvoiceResponseForm.defaultProps = {
  isViewInvoiceButton: true,
  isAmountEdit: false,
};

export default memo(InvoiceResponseForm);
