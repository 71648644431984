import { ComponentsOverrides, ComponentsProps } from "@mui/material";

interface IMuiTabType {
  defaultProps: ComponentsProps["MuiTab"];
  styleOverrides: ComponentsOverrides["MuiTab"];
}

const MuiTab: IMuiTabType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: "11px 55px",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "12px",
    },
  },
};

export default MuiTab;
