import Paper from '@mui/material/Paper';

import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';

import TotalLoans from './components/TotalLoans';
import SelectableLoans from './components/SelectableLoans';

import useStyles from './useStyles';

const Loans = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="Loans"
          icon={<Icon className={classes.titleIcon} width={28} height={28} name="bankCheck" />}
        />
      </header>
      <Paper className={classes.loansContainer}>
        <div className={classes.loansContentContainer}>
          <div className={classes.contentContainer}>
            <TotalLoans />
          </div>
          <div>
            <SelectableLoans />
          </div>
        </div>
      </Paper>
    </main>
  );
};

export default Loans;
