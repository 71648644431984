import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    '&:hover $cell $binContainer': {
      visibility: 'visible',
    },
    '&:last-child $cell': {
      border: 'none',
    },
  },
  cell: {
    padding: '15px 10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectContainer: {
    width: 200,
  },
  role: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    display: 'flex',
  },
  binContainer: {
    position: 'relative',
    visibility: 'hidden',
  },
  binIconButton: {
    position: 'absolute',
    top: -20,
    right: -10,
  },
}));
