import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import CompanyConnectionPresenter from 'presenters/CompanyConnectionPresenter';

import useStyles from './useStyles';

const IncomingContactsTableRows = props => {
  const { incomingConnections, onConfirm, onReject, onRowClick } = props;
  const classes = useStyles();

  return incomingConnections.map(connection => (
    <TableRow key={CompanyConnectionPresenter.id(connection)} className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.nameCell)} onClick={() => onRowClick(connection)}>
        <div className={classes.companyName}>{CompanyConnectionPresenter.companyName(connection)}</div>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.actionsCell)}>
        <Button variant="outlined" color="primary" onClick={() => onConfirm(CompanyConnectionPresenter.id(connection))}>
          Confirm
        </Button>
        <Button className={classes.rejectButton} onClick={() => onReject(CompanyConnectionPresenter.id(connection))}>
          Reject
        </Button>
      </TableCell>
    </TableRow>
  ));
};

IncomingContactsTableRows.propTypes = {
  incomingConnections: PropTypes.arrayOf(CompanyConnectionPresenter.shape()).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default IncomingContactsTableRows;
