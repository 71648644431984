import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  headerCell: {
    fontSize: theme.typography.pxToRem(12),
    padding: '4px 16px 4px 0',
  },
  amountHeaderCell: {
    paddingRight: 60,
  },
}));
