import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  invoiceRejectReasonContainer: {
    overflow: 'hidden',
    margin: '8px 8px 32px',
  },
  invoiceRejectReasonContent: {
    marginTop: theme.spacing(1),
  },
  contentBox: {
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
