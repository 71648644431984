import { FC } from "react";

import muiSx from "mui-sx";
import { isNil } from "ramda";

import Div from "components/common/Div";
import Li from "components/common/Li";
import Ul from "components/common/Ul";

import { getFormOrderState } from "layouts/OnboardingLayout/service";
import { OnboardingLayoutVariant } from "layouts/OnboardingLayout/types";

import styles from "./styles";
import { IContentNavigationProps } from "./types";

const ContentNavigation: FC<IContentNavigationProps> = (props) => {
  const { children, currentUser, variant } = props;

  const currentFormOrderState = getFormOrderState(variant, currentUser);

  const getMenuItem = (order: number, label: string) => {
    if (isNil(currentFormOrderState)) {
      return null;
    }

    return {
      order,
      label,
      isActive: order === currentFormOrderState,
      isCompleted: order < currentFormOrderState,
    };
  };

  const getNavigation = () => {
    switch (variant) {
      case OnboardingLayoutVariant.builder: {
        return [
          getMenuItem(1, "General Information"),
          getMenuItem(2, "Payment Details"),
          getMenuItem(3, "Line Items"),
          getMenuItem(4, "Team"),
        ];
      }
      case OnboardingLayoutVariant.subcontractor: {
        return [getMenuItem(1, "General Information"), getMenuItem(2, "Payment Details"), getMenuItem(3, "Team")];
      }
      case OnboardingLayoutVariant.customer: {
        return [getMenuItem(1, "Confirm the Project"), getMenuItem(2, "Payment Methods")];
      }
      default: {
        return null;
      }
    }
  };

  const navigation = getNavigation();

  if (isNil(navigation)) {
    return <Div sx={styles.root}>{children}</Div>;
  }

  return (
    <Div sx={styles.root}>
      <Ul sx={styles.menu}>
        {navigation?.map((item) => {
          if (isNil(item)) {
            return null;
          }

          const sx = muiSx(
            styles.menuItem,
            {
              sx: styles.menuItemActive,
              condition: item.isActive,
            },
            {
              sx: styles.menuItemCompleted,
              condition: item.isCompleted,
            },
          );

          return (
            <Li key={item.order}>
              <Div sx={sx}>
                {item.order}. {item.label}
              </Div>
              {item.isActive && <Div sx={styles.content}>{children}</Div>}
            </Li>
          );
        })}
      </Ul>
    </Div>
  );
};

export default ContentNavigation;
