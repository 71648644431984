import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  deleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: 6,
  },
  deleteButton: {
    marginTop: 6,
  },
  option: {
    width: '100%',
    margin: '16px 16px 0 16px',
    paddingBottom: theme.typography.pxToRem(12),
    borderBottom: [1, 'solid', theme.palette.colors.lightGray],
  },
  categoryName: {
    fontSize: theme.typography.pxToRem(16),
  },
  lineItemOptions: {
    '& li:last-child $option': {
      border: 'none',
    },
  },
  moneyTextField: {
    '& .MuiFormHelperText-root': {
      left: 0,
    },
  },
}));
