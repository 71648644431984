import { useState } from 'react';
import { isNil } from 'ramda';

import { PAYMENT_METHODS } from 'constants/paymentMethods';

import PaymentMethods from './components/PaymentMethods';
import CheckDetails from './components/CheckDetails';
import BankDetails from './components/BankDetails';
import AchDetails from './components/AchDetails';

import useStyles from './useStyles';

const PaymentMethodSelect = () => {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = useState(null);

  const handlePaymentMethodSelected = method => {
    setPaymentMethod(method);
  };

  const handleBackButtonClick = () => {
    setPaymentMethod(null);
  };

  return (
    <div className={classes.paymentMethodSelectContainer}>
      {isNil(paymentMethod) && <PaymentMethods onPaymentMethodSelected={handlePaymentMethodSelected} />}
      {paymentMethod === PAYMENT_METHODS.check && <CheckDetails onBackButtonClick={handleBackButtonClick} />}
      {paymentMethod === PAYMENT_METHODS.bankAccount && <BankDetails onBackButtonClick={handleBackButtonClick} />}
      {paymentMethod === PAYMENT_METHODS.ach && <AchDetails onBackButtonClick={handleBackButtonClick} />}
    </div>
  );
};

export default PaymentMethodSelect;
