import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  paymentMethodSelectContainer: {
    paddingRight: theme.spacing(8),
  },
  header: {
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.colors.gunsmokeGray,
  },
  nextButtonContainer: {
    textAlign: 'right',
  },
  paymentMethodsContainer: {
    marginBottom: theme.spacing(6),
  },
  paymentMethodsList: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
    gap: theme.spacing(3),
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  paymentMethod: {
    width: 168,
    height: 150,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.colors.lilyWhiteGray,
    },
  },
  paymentMethodDescription: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    borderRadius: 3,
    border: `1px solid ${theme.palette.colors.nickelGray}`,
  },
  paymentMethodTitle: {
    fontWeight: 700,
    margin: '8px 0',
  },
  paymentMethodText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.colors.gunsmokeGray,
  },
  paymentMethodActive: {
    color: theme.palette.colors.green,
    border: `1px solid ${theme.palette.colors.green}`,
  },
  icon: {
    color: theme.palette.colors.signalGray,
  },
  activeIcon: {
    color: theme.palette.colors.green,
  },
  checkAddressContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.colors.lilyWhiteGray,
    border: `1px solid ${theme.palette.colors.nickelGray}`,
    borderRadius: 3,
  },
  checkAddressDescription: {
    margin: '24px 16px',
  },
  address: {
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 700,
  },
}));
