import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { TABLE_CELL_VARIANT } from 'components/TableStatus';
import { useAdminProjectsActions, selectors } from 'store/AdminProjectsSlice';
import useLoading from 'hooks/useLoading';
import TableWithStatus from 'components/TableWithStatus';
import { MAX_COLS_COUNT, headers } from 'constants/adminProjects';
import { PROJECT_STATES } from 'presenters/ProjectPresenter';

import AdminProjectRows from '../AdminProjectRows';

const ActiveProjectsTable = () => {
  const { loadActiveProjects, approveProject } = useAdminProjectsActions();
  const { data, nextPage, totalCount, totalInformation } = useSelector(selectors.active);
  const { func: sendLoadActiveProjects, isPending } = useLoading(loadActiveProjects);

  useEffect(() => {
    sendLoadActiveProjects();
  }, []);

  return (
    <TableWithStatus
      isLoading={isPending}
      maxColsCount={MAX_COLS_COUNT}
      title="Active"
      isData={!isEmpty(data)}
      count={totalCount}
      nextPage={nextPage}
      color={TABLE_CELL_VARIANT.green}
      loadMore={loadActiveProjects}
      headers={headers(PROJECT_STATES.active)}
      rows={<AdminProjectRows projects={data} totalInformation={totalInformation} approveProject={approveProject} />}
    />
  );
};

export default ActiveProjectsTable;
