/* eslint-disable sonarjs/no-duplicate-string */
import { SxStyles } from "theme";

const styles: SxStyles = {
  titleDescription: {
    marginTop: "16px",
  },
  menu: {
    marginTop: "64px",
    listStyleType: "none",
    padding: 0,
    maxWidth: "256px",
    display: {
      xs: "none",
      lg: "block",
    },
  },
  menuItem: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    padding: "16px 0 16px 24px",
    color: "colors.white",
    "&:not(:first-child)": {
      marginTop: "8px",
    },
    "& svg": {
      fill: (theme) => theme.palette.colors.white,
    },
  },
  activeMenuItem: {
    color: "colors.green",
    backgroundColor: "colors.white",
    borderRadius: "4px",
  },
  formContainer: {
    // padding: '48px 0 48px 64px',
  },
};

export default styles;
