import { SortingState } from "@tanstack/react-table";
import { isEmpty } from "ramda";

import { getDirection, getSortingParameter, isSortedByField } from "utils/tableSorting";

export const SUBCONTRACTOR_COMPANY_INVOICES_PER_PAGE = 9;

export const getSubcontractorInvoicesSorting = (sorting: SortingState): string => {
  if (isSortedByField(sorting, "state") || isEmpty(sorting)) {
    return ["status_for_subcontractor", getDirection(sorting)].join(" ");
  }

  return getSortingParameter(sorting);
};
