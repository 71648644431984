import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import ProjectPresenter, { PROJECT_STATES } from 'presenters/ProjectPresenter';
import TotalInformationPresenter from 'presenters/TotalInformationPresenter';

import ProjectsTotalInformation from './components/ProjectsTotalInformation';
import ProjectsTableHead from './components/ProjectsTableHead';
import ProjectsTableBody from './components/ProjectsTableBody';
import DraftProjectsTotalInformation from './components/DraftProjectsTotalInformation';

import useStyles from './useStyles';

const PROJECT_TOTAL_INFORMATION = [PROJECT_STATES.active, PROJECT_STATES.completed];

const ProjectsTable = props => {
  const { projects, variant, isLoading, nextPage, totalInformation, onProjectsLoad, count } = props;
  const classes = useStyles();
  const [sortingRule, setSortingRule] = useState();

  const handleProjectLoad = () => onProjectsLoad({ page: nextPage, sortingRule });

  useEffect(() => {
    if (!sortingRule) {
      return;
    }

    onProjectsLoad({ page: 1, sortingRule });
  }, [sortingRule]);

  return (
    <TableContainer className={classes.container}>
      <div className={classes.table}>
        <Table>
          <ProjectsTableHead variant={variant} projectsCount={count} onSortChange={setSortingRule} />
          <ProjectsTableBody
            variant={variant}
            projects={projects}
            isLoading={isLoading}
            nextPage={nextPage}
            onProjectsLoad={handleProjectLoad}
          />
        </Table>
      </div>
      {totalInformation && (
        <div className={classes.totalInformation}>
          {PROJECT_TOTAL_INFORMATION.includes(variant) && (
            <ProjectsTotalInformation totalInformation={totalInformation} />
          )}
          {variant === PROJECT_STATES.draft && <DraftProjectsTotalInformation totalInformation={totalInformation} />}
        </div>
      )}
    </TableContainer>
  );
};

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  count: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(Object.values(PROJECT_STATES)).isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
  totalInformation: TotalInformationPresenter.shape(),
  onProjectsLoad: PropTypes.func.isRequired,
};

ProjectsTable.defaultProps = {
  nextPage: null,
  totalInformation: null,
};

export default ProjectsTable;
