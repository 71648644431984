import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import MemberChoiceTable from 'components/MemberChoiceTable';
import Icon from 'components/Icon';
import UserPresenter from 'presenters/UserPresenter';
import { API_ERROR_TEXTS } from 'forms/errorsText';
import useLoading from 'hooks/useLoading';

import useStyles from './useStyles';

const ProjectMembersAssignment = props => {
  const { members, loadMembers, onMembersMassCreate, onClose, resetMembers, membersMeta } = props;
  const classes = useStyles();
  const [apiErrors, setApiErrors] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { isPending: isMembersLoading, func: onMembersLoad } = useLoading(loadMembers);
  const { isPending: isMemberMassCreating, func: sendOnMemberMassCreate } = useLoading(onMembersMassCreate);
  const isAddNewUserDisabled = isMemberMassCreating || isMembersLoading || isEmpty(selectedMembers);

  useEffect(() => {
    onMembersLoad();

    return resetMembers;
  }, []);

  const handleMembersSelect = newSelectedMembers => {
    setApiErrors({});
    setSelectedMembers(newSelectedMembers);
  };

  const handleSubmit = async () => {
    const selectedMembersIds = selectedMembers.map(({ id }) => ({ userId: id }));

    try {
      await sendOnMemberMassCreate(selectedMembersIds);
      onClose();
    } catch (error) {
      setApiErrors(error);
    }
  };

  return (
    <>
      {isMemberMassCreating && <LinearProgress />}
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Icon name="invitation" width={24} height={24} className={classes.invitationIcon} />
          <Typography variant="h3">Add New Users</Typography>
        </div>
        <div className={classes.tableContainer}>
          <MemberChoiceTable
            members={members}
            selectedMembers={selectedMembers}
            onMembersSelect={handleMembersSelect}
            isLoading={isMembersLoading}
            membersMeta={membersMeta}
            onMembersLoad={onMembersLoad}
          />
        </div>
        <div className={classes.errors}>{!isEmpty(apiErrors) && API_ERROR_TEXTS.unexpectedError}</div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose} disabled={isMemberMassCreating}>
            Cancel
          </Button>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={isAddNewUserDisabled}
            onClick={handleSubmit}
          >
            Add New Users
          </Button>
        </div>
      </div>
    </>
  );
};

ProjectMembersAssignment.propTypes = {
  members: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  loadMembers: PropTypes.func.isRequired,
  onMembersMassCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  resetMembers: PropTypes.func.isRequired,
  membersMeta: PropTypes.shape().isRequired,
};

export default ProjectMembersAssignment;
