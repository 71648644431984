import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  create(params, cancelToken) {
    const path = apiRoutes.siteApiV1BuilderCompanyCustomersPath();

    return FetchHelpers.post(path, params, cancelToken);
  },
};
