import { TFunction } from "i18next";
import { isNil } from "ramda";

import { addBusinessDays, addDays, now } from "libs/datetime";

import { InvoiceKind, InvoicePaymentKind, InvoiceState } from "./enums";
import { IInvoice } from "./types";

export const isSelfPaid = (invoice: Pick<IInvoice, "kind">): boolean => {
  return invoice.kind === InvoiceKind.selfPaid;
};

export const isPending = (invoice: Pick<IInvoice, "state">): boolean => {
  return invoice.state === InvoiceState.pending;
};

export const isRejected = (invoice: Pick<IInvoice, "state">): boolean => {
  return invoice.state === InvoiceState.rejected;
};

export const isApprovable = (invoice: Pick<IInvoice, "state" | "kind">): boolean => {
  return isPending(invoice) && !isSelfPaid(invoice);
};

export const isPaid = (invoice: Pick<IInvoice, "state">): boolean => {
  return invoice.state === InvoiceState.paid;
};

export const isApproved = (invoice: Pick<IInvoice, "state">): boolean => {
  return invoice.state === InvoiceState.approved;
};

export const isAwaitingApproval = (invoice: Pick<IInvoice, "state">): boolean => {
  return invoice.state === InvoiceState.awaitingApproval;
};

export const isEarlyPaymentKind = (invoice: Pick<IInvoice, "paymentKind">): boolean => {
  return invoice.paymentKind === InvoicePaymentKind.earlyPayment;
};

export const isDefaultPaymentKind = (invoice: Pick<IInvoice, "paymentKind">): boolean => {
  return invoice.paymentKind === InvoicePaymentKind.default;
};

export const isEarlyPaymentRequested = (invoice: Pick<IInvoice, "paymentKind" | "state">): boolean => {
  return isAwaitingApproval(invoice) && isEarlyPaymentKind(invoice);
};

export const getInvoiceEstimatedPaymentDate = (
  earlyPaymentId: number | null,
  invoice: Pick<IInvoice, "createdAt">,
): Date => {
  if (isNil(earlyPaymentId)) {
    return addDays(invoice.createdAt, 30);
  }

  return Number(earlyPaymentId) === 1 ? addBusinessDays(now(), 3) : addBusinessDays(now(), 10);
};

export const earlyPaymentTermsDays = (daysDelay: number | null, t: TFunction): string => {
  if (isNil(daysDelay)) {
    return "";
  }
  if (daysDelay === 0) {
    return t("builderInvoices:paymentTerms.oneThreeLabel");
  }

  return t("builderInvoices:paymentTerms.sevenTenLabel");
};
