import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  lastRow: {
    '& $cell': {
      border: 'none',
    },
  },
  cell: {
    fontSize: 14,
    paddingLeft: 0,
    paddingRight: 0,
    padding: '11px 0',
  },
  projectNameContainer: {
    display: 'flex',
  },
  projectName: {
    fontWeight: 700,
    lineHeight: '38px',
    marginLeft: 8,
  },
  noProjectsLabel: {
    color: theme.palette.colors.gunsmokeGray,
  },
  loadMore: {
    '& $cell': {
      border: 'none',
    },
    borderTop: [1, 'solid', theme.palette.colors.gainsboro],
  },
}));
