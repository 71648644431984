import { isEmpty, pickBy } from "ramda";

import { InvoiceKind, InvoiceState } from "domain/invoice/enums";

import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { IMeta, IPaginationParameters } from "types/api";

import { IInvoicePaymentTermsFormSubmitData } from "./schemas/paymentTerms";
import { SubcontractorCompanyInvoiceListItem } from "./types";

import type { PartialDeep } from "type-fest";

interface ISubcontractorCompanyInvoicesResponse {
  invoices: Array<SubcontractorCompanyInvoiceListItem>;
  meta: IMeta;
}

type GetSubcontractorCompanyInvoicesParameters = IPaginationParameters & {
  q: {
    stateIn: Array<InvoiceState>;
    kindEq: InvoiceKind;
    s: string;
  };
};

const omitParameters = (
  parameters: PartialDeep<GetSubcontractorCompanyInvoicesParameters>,
): PartialDeep<GetSubcontractorCompanyInvoicesParameters> => {
  return { ...parameters, q: pickBy((value) => !isEmpty(value), parameters.q) };
};

const subcontractorCompanyInvoiceApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getSubcontractorCompanyInvoices: builder.query<
      ISubcontractorCompanyInvoicesResponse,
      PartialDeep<GetSubcontractorCompanyInvoicesParameters>
    >({
      query: (parameters) => ({
        url: apiRoutes.siteApiV1SubcontractorCompanyInvoicesPath(),
        method: "GET",
        params: {
          ...omitParameters(parameters),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.invoices.map(({ id }) => ({ type: CacheTag.subcontractorCompanyInvoice as const, id })),
              { type: CacheTag.subcontractorCompanyInvoice, id: "LIST" },
            ]
          : [{ type: CacheTag.subcontractorCompanyInvoice, id: "LIST" }],
    }),
    updateSubcontractorCompanyInvoice: builder.mutation<
      void,
      { invoiceId: number; invoice: IInvoicePaymentTermsFormSubmitData }
    >({
      query: ({ invoiceId, invoice }) => ({
        url: apiRoutes.siteApiV1SubcontractorCompanyInvoicePath(invoiceId),
        method: "PUT",
        data: invoice,
      }),
      invalidatesTags: (_result, _error, argument) => [
        { type: CacheTag.subcontractorCompanyInvoice, id: argument.invoiceId },
      ],
    }),
    getPendingTotalCount: builder.query<number, void>({
      query: () => ({
        url: apiRoutes.pendingTotalCountSiteApiV1SubcontractorCompanyInvoicesPath(),
        method: "GET",
      }),
      transformResponse: (response: { pendingInvoicesCount: number }) => response.pendingInvoicesCount,
      providesTags: [CacheTag.subcontractorCompanyInvoicePendingTotalCount],
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetSubcontractorCompanyInvoicesQuery,
  useUpdateSubcontractorCompanyInvoiceMutation,
  useGetPendingTotalCountQuery,
} = subcontractorCompanyInvoiceApi;
