import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';

export default new Presenter(
  {
    totalContractValue: PropTypes.string,
    totalOverheadExpenses: PropTypes.string,
    totalProfit: PropTypes.string,
    totalThirdPartyExpenses: PropTypes.string,
  },
  {
    formattedUSDTotalContractValue(totalInformation, maximumFractionDigits = 2) {
      return formatUSD(this.totalContractValue(totalInformation), maximumFractionDigits);
    },
    formattedUSDTotalThirdPartyExpenses(totalInformation, maximumFractionDigits = 2) {
      return formatUSD(this.totalThirdPartyExpenses(totalInformation), maximumFractionDigits);
    },
    formattedUSDTotalOverheadExpenses(totalInformation, maximumFractionDigits = 2) {
      return formatUSD(this.totalOverheadExpenses(totalInformation), maximumFractionDigits);
    },
    formattedUSDTotalProfit(totalInformation, maximumFractionDigits = 2) {
      return formatUSD(this.totalProfit(totalInformation), maximumFractionDigits);
    },
  },
);
