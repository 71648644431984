import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyOwnerPresenter from 'presenters/CompanyOwnerPresenter';

export const STATES = {
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
};

export default new Presenter(
  {
    id: PropTypes.number,
    subcontractor: CompanyPresenter.shape(),
    builder: CompanyPresenter.shape(),
    state: PropTypes.oneOf(Object.values(STATES)),
  },
  {
    company(connection) {
      return this.subcontractor(connection) || this.builder(connection);
    },
    companyOwner(connection) {
      return CompanyPresenter.companyOwner(this.company(connection));
    },
    companyName(connection) {
      return CompanyPresenter.name(this.company(connection));
    },
    companyFullAddress(connection) {
      return CompanyPresenter.fullAddress(this.company(connection));
    },
    companyOwnerName(connection) {
      return CompanyOwnerPresenter.fullName(this.companyOwner(connection));
    },
    companyOwnerEmail(connection) {
      return CompanyOwnerPresenter.email(this.companyOwner(connection));
    },
  },
);
