import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  rejectButton: {
    textTransform: 'none',
  },
  viewButton: {
    marginRight: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldsContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: 300,
    padding: '0 24px 20px',
    overflowX: 'hidden',
  },
  actionButtons: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 3),
  },
  spitButton: {
    marginRight: theme.spacing(1),
  },
  fixedButtonsContainer: {
    position: 'sticky',
    top: 20,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
  },
  stickyContainer: {
    height: '100%',
  },
  neededAmountError: {
    color: theme.palette.colors.red,
  },
  neededAmountContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
  },
  removeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
  },
  removeButton: {
    fill: theme.palette.colors.red,
    color: theme.palette.colors.red,
  },
  invoicePaymentDateContainer: {
    position: 'sticky',
    backgroundColor: theme.palette.colors.white,
    paddingTop: 20,
    top: 0,
    zIndex: 2,
  },
  invoiceResponseRowsContainer: {
    marginTop: 28,
  },
}));
