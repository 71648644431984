import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  dialogContainer: {
    minWidth: 600,
    padding: theme.spacing(4, 3),
  },
  title: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  dialogTitleIcon: {
    marginRight: theme.spacing(1),
    marginTop: 3,
    minWidth: 20,
    fill: theme.palette.colors.green,
  },
  soldButtonContainer: {
    textAlign: 'right',
  },
  inviteButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cancelButton: {
    marginRight: 12,
  },
  fieldsContainer: {
    marginBottom: theme.spacing(4),
  },
  option: {
    width: '100%',
    margin: '16px 16px 0 16px',
    paddingBottom: theme.typography.pxToRem(12),
    borderBottom: [1, 'solid', theme.palette.colors.lightGray],
  },
  companyName: {
    fontSize: theme.typography.pxToRem(16),
  },
  optionsListBox: {
    '& li:last-child $option': {
      border: 'none',
    },
  },
  fieldContainer: {
    marginTop: theme.spacing(3),
  },
}));
