import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  autocompleteOption: {
    padding: '0 16px',
  },
  optionContainer: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.colors.lightGray}`,
  },
  option: {
    padding: '12px 0 11px',
  },
  optionName: {
    fontSize: 20,
  },
  optionAddress: {
    fontSize: 14,
    fontWeight: 500,
  },
  optionEmail: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.colors.green,
  },
  expandIcon: {
    marginRight: 10,
  },
  loadingIcon: {
    marginRight: 4,
  },
  clearIndicator: {
    marginRight: 4,
  },
  textField: {
    paddingRight: '0 !important',

    '& input': {
      paddingRight: `${theme.spacing(3)} !important`,
    },

    '& .MuiAutocomplete-endAdornment': {
      right: theme.spacing(3.5),
    },
  },
}));
