import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  plus: {
    marginLeft: -1,
    marginTop: 1,
    fontSize: 16,
    marginRight: 4,
  },
  plusIcon: {
    fill: theme.palette.colors.white,
    marginRight: 8,
    marginLeft: 4,
  },
  textButton: {
    textTransform: 'uppercase',
  },
  plusTextIcon: {
    fill: theme.palette.colors.green,
  },
}));
