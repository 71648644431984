import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import ProjectPresenter from 'presenters/ProjectPresenter';
import { useProjectActions } from 'store/ProjectSlice';
import Icon from 'components/Icon';
import LinearProgress from 'components/LinearProgress';
import useLoading from 'hooks/useLoading';
import { PROJECT_EVENTS } from 'presenters/ProjectPresenter';
import AppRoutes from 'routes/AppRoutes';

import useStyles from './useStyles';

const SUCCESS_MESSAGE = 'The project has been moved to Active';

const SoldButton = props => {
  const { project } = props;
  const classes = useStyles();
  const history = useHistory();
  const [isSoldDialogOpen, setIsSoldDialogOpen] = useState(false);
  const { updateProject } = useProjectActions();
  const { func: sendUpdateProject, isPending } = useLoading(updateProject, {
    isShowSuccessNotification: true,
    successMessage: SUCCESS_MESSAGE,
  });

  const handleSoldButtonClick = () => {
    setIsSoldDialogOpen(true);
  };

  const handleClose = () => {
    setIsSoldDialogOpen(false);
  };

  const handleSold = async () => {
    await sendUpdateProject(project.id, { state_event: PROJECT_EVENTS.activate });
    const link = AppRoutes.projectsPath();
    history.push(link);
  };

  return (
    <>
      <div className={classes.rowContainer}>
        <div className={classes.label}>Draft Project</div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.draftTip}>Funl won&apos;t permit lending on this project until it is sold.</div>
      </div>
      <div className={classes.rowContainer}>
        <Button variant="contained" color="primary" onClick={handleSoldButtonClick}>
          Project Has Been Sold
        </Button>
      </div>

      <Dialog open={isSoldDialogOpen} onClose={handleClose}>
        <LinearProgress isVisible={isPending} />
        <div className={classes.soldDialogContainer}>
          <div className={classes.soldTitle}>
            <Icon name="descriptionIcon" width={17} height={20} className={classes.dialogTitleIcon} />
            <Typography variant="h3">Are you ready to activate this project?</Typography>
          </div>
          <div className={classes.soldButtonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.soldAcceptButton}
              disabled={isPending}
              onClick={handleSold}
            >
              Yes
            </Button>
            <Button className={classes.cancelButton} variant="contained" onClick={handleClose} disabled={isPending}>
              No
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

SoldButton.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default SoldButton;
