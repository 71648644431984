export default {
  paymentTerms: {
    paymentTerms: "Payment Terms",
    acceptEarlyPayment: "Accept Early Payment?",
    estimatedPaymentDate: "Estimated Payment Date: {{ date }}",
    hasDayPaymentTerms: "{{companyName}} has 30 day payment terms",
    yesAcceptEarlyPayment: "Yes, accept early payment",
    noUseStandardPaymentTerms: "No, use standard payment terms",
    oneThreeLabel: "{{value}} available within 1-3 business days from builder approval",
    sevenTenLabel: "{{value}} available within 7-10 business days from builder approval",
    termsOfService:
      "I agree to a payment that is less than the total invoice amount. I acknowledge that this is the final payment for this invoice and will reconcile this in my accounting.",
    paymentTotal: "Payment Total",
    acceptPayment: "Accept Payment",
  },
};
