import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1AdminProjectsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
  show(id) {
    const path = apiRoutes.siteApiV1AdminProjectPath(id);

    return FetchHelpers.get(path);
  },
  approve(id) {
    const path = apiRoutes.approveSiteApiV1AdminProjectPath(id);

    return FetchHelpers.put(path);
  },
};
