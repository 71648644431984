import { configureStore } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'utils/axiosBaseQuery';

import UserLayoutSlice from './UserLayoutSlice';
import NewProjectSlice from './NewProjectSlice';
import ProjectsSlice from './ProjectsSlice';
import ProjectSlice from './ProjectSlice';
import LineItemsSettingsSlice from './LineItemsSettingsSlice';
import SubcontractorsSlice from './SubcontractorsSlice';
import BuilderTeamSettingsSlice from './BuilderTeamSettingsSlice';
import InvoicesSlice from './InvoicesSlice';
import SubcontractorTeamSettingsSlice from './SubcontractorTeamSettingsSlice';
import InvoiceSlice from './InvoiceSlice';
import PayableInvoicesSlice from './PayableInvoicesSlice';
import BuildersSlice from './BuildersSlice';
import PayableInvoiceSlice from './PayableInvoiceSlice';
import InvoiceEarlyPaymentsSlice from './InvoiceEarlyPaymentsSlice';
import IncomingPaymentsSlice from './IncomingPaymentsSlice';
import AdminProjectsSlice from './AdminProjectsSlice';
import AdminBuildersSlice from './AdminBuildersSlice';
import AdminSubcontractorsSlice from './AdminSubcontractorsSlice';
import AdminLoansSlice from './AdminLoansSlice';
import AdminProjectSlice from './AdminProjectSlice';
import ProjectClientInvoicesSlice from './ProjectClientInvoicesSlice';
import AdminInvoicesSlice from './AdminInvoicesSlice';
import AdminInvoiceSlice from './AdminInvoiceSlice';
import CustomerPaymentsSlice from './CustomerPaymentsSlice';
import CustomerOnboardingSlice from './CustomerOnboardingSlice';
import AdminPaymentsSlice from './AdminPaymentsSlice';
import BuilderInvoiceCreateSlice from './BuilderInvoiceCreateSlice';
import { CacheTag } from './types';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(CacheTag),
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

const { reducerPath, reducer, middleware } = apiContainer;

const store = configureStore({
  reducer: {
    UserLayoutSlice,
    NewProjectSlice,
    ProjectsSlice,
    ProjectSlice,
    LineItemsSettingsSlice,
    SubcontractorsSlice,
    BuilderTeamSettingsSlice,
    InvoicesSlice,
    SubcontractorTeamSettingsSlice,
    InvoiceSlice,
    PayableInvoicesSlice,
    BuildersSlice,
    PayableInvoiceSlice,
    InvoiceEarlyPaymentsSlice,
    IncomingPaymentsSlice,
    AdminProjectsSlice,
    AdminBuildersSlice,
    AdminSubcontractorsSlice,
    AdminLoansSlice,
    AdminProjectSlice,
    ProjectClientInvoicesSlice,
    AdminInvoicesSlice,
    AdminInvoiceSlice,
    CustomerPaymentsSlice,
    CustomerOnboardingSlice,
    AdminPaymentsSlice,
    BuilderInvoiceCreateSlice,
    [reducerPath]: reducer,
  },
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), middleware],
});

export default store;
