import PropTypes from 'prop-types';
import { usePlaidLink } from 'react-plaid-link';

import Button from '@mui/material/Button';

import useStyles from './useStyles';

const PlaidLink = props => {
  const { token, loadBankAccountToken, createStipeId, onSuccess } = props;
  const classes = useStyles();

  const handleSuccess = async (publicToken, metadata) => {
    const plaidAccountId = metadata.accounts.length && metadata.accounts[0].id;
    const stripeId = await loadBankAccountToken(publicToken, plaidAccountId);
    await createStipeId(stripeId);
    onSuccess();
  };

  const config = {
    token,
    onSuccess: handleSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <div className={classes.root}>
      <Button onClick={open} disabled={!ready} variant="contained" color="primary">
        Connect a bank account
      </Button>
    </div>
  );
};

PlaidLink.propTypes = {
  token: PropTypes.string.isRequired,
  loadBankAccountToken: PropTypes.func.isRequired,
  createStipeId: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

PlaidLink.defaultProps = {
  onSuccess: Function.prototype,
};

export default PlaidLink;
