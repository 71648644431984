import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: '32px 24px',
    width: 587,
  },
  titleContainer: {
    display: 'flex',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
  title: {
    marginLeft: 6,
    marginTop: -3,
  },
  tip: {
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  titleTip: {
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
    marginTop: 4,
    marginLeft: 30,
  },
  inputTip: {
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
    marginTop: 10,
    width: 240,
  },
  formContainer: {
    marginTop: 36,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: 48,
    },
  },
  label: {
    color: theme.palette.colors.black,
    textTransform: 'none',
    letterSpacing: -0.2,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '15px',
  },
  textField: {
    width: 240,
  },
  inputHelperText: {
    top: 40,
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  percentMarkupContainer: {
    display: 'flex',
    width: 240,
    marginBottom: 25,
  },
  percentMarkupInput: {
    width: 64,
  },
  percentSign: {
    marginLeft: 12,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.colors.deltaGray,
    lineHeight: '40px',
  },
  profitContainer: {
    marginTop: 40,
    paddingTop: 8,
    boxShadow: 'inset 0px 1px 0px rgba(0, 48, 32, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  profitTitle: {
    letterSpacing: -0.2,
    fontWeight: 700,
    fontSize: 20,
  },
  profitValues: {
    width: 240,
  },
  profitValueContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: 8,
    },
  },
  profitValue: {
    maxWidth: 120,
    wordWrap: 'break-word',
  },
  profitDeltaPositive: {
    color: theme.palette.colors.green,
  },
  profitDeltaNegative: {
    color: theme.palette.colors.red,
  },
  newProfit: {
    fontWeight: 700,
  },
  newProfitValue: {
    fontWeight: 700,
  },
  actions: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
