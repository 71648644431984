import { useState, memo } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

import AddButton from 'components/AddButton';

import CreateCustomerInvoiceForm from './components/CreateCustomerInvoiceForm';

const CreateInvoiceButton = props => {
  const { onInvoiceSend, projectName, isDisabled } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handledDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <AddButton onClick={handledDialogOpen} disabled={isDisabled}>
        Create Invoice
      </AddButton>
      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth onClose={handleDialogClose}>
        <CreateCustomerInvoiceForm title={projectName} onInvoiceSend={onInvoiceSend} onClose={handleDialogClose} />
      </Dialog>
    </>
  );
};

CreateInvoiceButton.propTypes = {
  projectName: PropTypes.string.isRequired,
  onInvoiceSend: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
CreateInvoiceButton.defaultProps = {
  isDisabled: false,
};

export default memo(CreateInvoiceButton);
