import { isNil } from "ramda";

import { IEarlyPayment } from "./types";

export const amount = (
  earlyPayments: Array<IEarlyPayment> | undefined,
  earlyPaymentId: number | null,
): number | null => {
  if (isNil(earlyPaymentId) || isNil(earlyPayments)) {
    return null;
  }

  const chosenEarlyPayment = earlyPayments.find((earlyPayment) => earlyPayment.earlyPaymentId === earlyPaymentId);

  return chosenEarlyPayment ? Number(chosenEarlyPayment.amount) : null;
};
