import { useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import CompanyConnectionPresenter from 'presenters/CompanyConnectionPresenter';
import SectionTitle from 'components/SectionTitle';

import IncomingContact from './components/IncomingContact';
import IncomingContactsTable from './components/IncomingContactsTable';
import useStyles from './useStyles';

const IncomingContacts = props => {
  const {
    sectionTitle,
    pageTitle,
    description,
    incomingConnections,
    isIncomingConnectionsLoading,
    onIncomingConnectionAccept,
    onIncomingConnectionReject,
  } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentIncomingConnection, setCurrentIncomingConnection] = useState(null);

  const handleDialogOpen = connection => {
    setIsDialogOpen(true);
    setCurrentIncomingConnection(connection);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setCurrentIncomingConnection(null);
  };

  const handleDialogConnectionAccept = async () => {
    await onIncomingConnectionAccept(currentIncomingConnection);
    handleDialogClose();
  };

  const handleDialogConnectionReject = async () => {
    await onIncomingConnectionReject(currentIncomingConnection);
    handleDialogClose();
  };

  return (
    <>
      <SectionTitle className={classes.sectionTitle}>{sectionTitle}</SectionTitle>
      <Paper className={classes.paper}>
        <Typography variant="h4">{pageTitle}</Typography>
        <div className={classes.description}>{description}</div>
        <div className={classes.tableContainer}>
          <IncomingContactsTable
            incomingConnections={incomingConnections}
            isIncomingConnectionsLoading={isIncomingConnectionsLoading}
            onConfirm={onIncomingConnectionAccept}
            onReject={onIncomingConnectionReject}
            onRowClick={handleDialogOpen}
          />
        </div>
      </Paper>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        {currentIncomingConnection && (
          <IncomingContact
            connection={currentIncomingConnection}
            onCancel={handleDialogClose}
            onConfirm={handleDialogConnectionAccept}
            onReject={handleDialogConnectionReject}
          />
        )}
      </Dialog>
    </>
  );
};

IncomingContacts.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  incomingConnections: PropTypes.arrayOf(CompanyConnectionPresenter.shape()).isRequired,
  isIncomingConnectionsLoading: PropTypes.bool.isRequired,
  onIncomingConnectionAccept: PropTypes.func.isRequired,
  onIncomingConnectionReject: PropTypes.func.isRequired,
};

export default IncomingContacts;
