import PaymentTokensRepository from 'repositories/customer/PaymentTokensRepository';
import CustomerBillingSettingsRepository from 'repositories/customer/CustomerBillingSettingsRepository';

export const usePaymentTokens = () => {
  const loadPlaidLinkToken = async () => {
    const {
      data: { linkToken },
    } = await PaymentTokensRepository.plaidLinkToken();
    return linkToken;
  };

  const loadBankAccountToken = async (plaidLinkPublicToken, plaidAccountId) => {
    const params = {
      bank_account: {
        plaidLinkPublicToken,
        plaidAccountId,
      },
    };
    const { data } = await PaymentTokensRepository.bankAccountToken(params);
    return data;
  };

  const createStipeId = async (stripeCustomerId = '') => {
    const params = {
      settings: {
        stripeCustomerId,
      },
    };
    await CustomerBillingSettingsRepository.create(params);
  };

  return {
    loadPlaidLinkToken,
    loadBankAccountToken,
    createStipeId,
  };
};
