import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import { INVOICE_FORM_VARIANTS } from 'constants/invoices';

import ProjectNameAutocomplete from './components/ProjectNameAutocomplete';

const InvoiceProject = props => {
  const { variant, formData } = props;
  const { values, touched, errors, handleChange } = formData;

  switch (variant) {
    case INVOICE_FORM_VARIANTS.builder:
      return <ProjectNameAutocomplete formData={formData} />;
    case INVOICE_FORM_VARIANTS.subcontractor:
      return (
        <TextField
          name="projectName"
          id="projectName"
          label="Project Name"
          onChange={handleChange}
          value={values.projectName}
          error={touched.projectName && Boolean(errors.projectName)}
          helperText={touched.projectName && errors.projectName}
        />
      );
    default:
      return null;
  }
};

InvoiceProject.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  formData: PropTypes.shape().isRequired,
};

export default InvoiceProject;
