import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  menuList: {
    padding: 0,
  },
  listItem: {
    padding: '7.5px 8px',
    minWidth: 152,
    fontWeight: 700,
    fontSize: 15,
    color: theme.palette.colors.deltaGray,
  },
  selectedItem: {
    color: theme.palette.colors.green,
    opacity: 1,
  },
  checkIcon: {
    fill: theme.palette.colors.green,
    marginRight: 8,
  },
  passedListItem: {
    color: theme.palette.colors.black,
  },
  order: {
    marginRight: 10,
  },
}));
