import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import AdminProjectsRepository from 'repositories/admin/ProjectsRepository';

const initialState = {
  project: {
    totalRevenue: 0,
    thirdPartyExpenses: 0,
    overheadExpenses: 0,
    name: '',
  },
};

const slice = createSlice({
  name: 'adminProject',
  initialState,
  reducers: {
    loadProjectSuccess(state, { payload }) {
      state.project = payload.project;
    },
    resetProject(state) {
      state.project = initialState.project;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminProjectActions = () => {
  const dispatch = useDispatch();

  const loadProject = async id => {
    const { data } = await AdminProjectsRepository.show(id);
    dispatch(actions.loadProjectSuccess(data));

    return data;
  };

  const resetProjectStats = () => {
    dispatch(actions.resetProject());
  };

  return {
    loadProject,
    resetProjectStats,
  };
};

export const selectors = {
  project: state => state.AdminProjectSlice.project,
};
