import { useEffect } from 'react';

import { isEmpty } from 'ramda';

import UserPresenter from 'presenters/UserPresenter';
import { useUserLayoutActions } from 'store/UserLayoutSlice';
import { getPlainProjectSubmenuNavItems } from 'utils/navigationUtils';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

const useProjectLayout = (projectId, project) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const isManager = UserPresenter.isManager(currentUser);

  const { setSidebarSubmenu } = useUserLayoutActions();

  const navItems = getPlainProjectSubmenuNavItems(project, projectId, isManager);

  useEffect(() => {
    return () => setSidebarSubmenu(null);
  }, []);

  useEffect(() => {
    if (!isEmpty(project)) {
      setSidebarSubmenu({ title: `Project: ${project.name}`, items: navItems });
    }
  }, [project]);
};

export default useProjectLayout;
