import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export default {
  fontFamily: "Lato",
  h1: {
    fontSize: 22,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: -1,

    [theme.breakpoints.up("md")]: {
      fontSize: 36,
      lineHeight: "36px",
    },
  },
  h2: {
    fontSize: 32,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "44px",
    letterSpacing: -1,
  },
  h3: {
    fontSize: 24,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: -0.2,
  },
  h4: {
    fontSize: 20,
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: -0.2,
  },
  body1: {
    fontSize: "14px",
    lineHeight: "1.25rem",
    letterSpacing: 0.2,
  },
  button: {
    fontSize: "14px",
    lineHeight: "16px",
  },
};
