import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const Confirmation = props => {
  const { email, onEmailResend } = props;
  const classes = useStyles();
  const [isEmailResent, setIsEmailResent] = useState(false);
  const [isEmailResentAvailable, setIsEmailResentAvailable] = useState(true);

  const handleEmailResend = () => {
    onEmailResend({ email });
    setIsEmailResent(true);
    setIsEmailResentAvailable(false);

    setTimeout(() => {
      setIsEmailResentAvailable(true);
    }, 5000);
  };

  const renderResendContainer = () => {
    if (isEmailResent) {
      return (
        <>
          <div className={classes.emailRepeatStatus}>A confirmation was resent.</div>
          {isEmailResentAvailable ? (
            <Button classes={{ root: classes.resendButtonRoot }} color="primary" onClick={handleEmailResend}>
              Resend confirmation email again
            </Button>
          ) : (
            'You can resend confirmation again in 5 sec.'
          )}
        </>
      );
    }

    return (
      <Button classes={{ root: classes.resendButtonRoot }} color="primary" onClick={handleEmailResend}>
        Resend confirmation email
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        A confirmation link has been sent
        <br />
        to your email address:
      </Typography>
      <div className={classes.emailContainer}>
        <div className={classes.checkIconContainer}>
          <Icon className={classes.checkIcon} name="checkMark" width={12} height={13} />
        </div>
        <div className={classes.email}>{email}</div>
      </div>
      <div className={classes.resendContainer}>{renderResendContainer()}</div>
    </div>
  );
};

Confirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailResend: PropTypes.func.isRequired,
};

export default Confirmation;
