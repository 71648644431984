import { memo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import useStyles from './useStyles';

const PageHeader = props => {
  const { text, description, icon } = props;

  const classes = useStyles();

  return (
    <div>
      <div className={classes.titleContainer}>
        {icon && icon}
        <Typography className={classes.title} variant="h1">
          {text}
        </Typography>
      </div>
      {description && <Typography className={classes.description}>{description}</Typography>}
    </div>
  );
};

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
};

PageHeader.defaultProps = {
  icon: null,
  description: null,
};

export default memo(PageHeader);
