export const editUser = (params = {}) => {
  const { user, members } = params;
  return members.map(member => {
    if (member.id === user.id) {
      return user;
    }

    return member;
  });
};
