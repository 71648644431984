import { useSubcontractorOnboardingActions } from 'store/SubcontractorOnboardingSlice';
import { useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';
import { useDeleteSessionMutation } from 'store/api/session/apiSlice';
import UserPresenter, { SUBCONTRACTOR_ONBOARDING_STATES } from 'presenters/UserPresenter';
import OnboardingLayout from 'layouts/OnboardingLayout';
import CompanySetup from 'components/Onboarding/CompanySetup';
import TeamSetup from 'components/Onboarding/TeamSetup';
import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

const Onboarding = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();
  const { createCompany, createUsers, checkUserAvailability } = useSubcontractorOnboardingActions();
  const [signOut] = useDeleteSessionMutation();

  const renderForm = () => {
    switch (UserPresenter.subcontractorOnboardingState(currentUser)) {
      case SUBCONTRACTOR_ONBOARDING_STATES.notStarted:
        return <CompanySetup onCurrentUserLoad={loadCurrentUser} onCompanyCreate={createCompany} />;
      case SUBCONTRACTOR_ONBOARDING_STATES.companyCreated:
        return (
          <TeamSetup
            onCurrentUserLoad={loadCurrentUser}
            onUsersCreate={createUsers}
            onUserAvailabilityCheck={checkUserAvailability}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OnboardingLayout onSignOut={signOut} currentUser={currentUser} variant="subcontractor">
      {renderForm()}
    </OnboardingLayout>
  );
};

export default Onboarding;
