import { useHistory } from 'react-router-dom';

import UsersRepository from 'repositories/admin/UsersRepository';

const useImpersonate = userId => {
  const history = useHistory();

  const impersonate = async () => {
    await UsersRepository.impersonate(userId);
    history.go(0);
  };

  const stopImpersonating = async () => {
    await UsersRepository.stopImpersonating();
    history.go(0);
  };

  return {
    impersonate,
    stopImpersonating,
  };
};

export default useImpersonate;
