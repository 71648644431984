import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";

import en, { ns } from "./en";

export const defaultNS = "common";
export const resources = { en } as const;

const i18next = createInstance();

// eslint-disable-next-line no-void
void i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en,
  },
  defaultNS,
  lng: "en",
  ns,
});

export default i18next;
