import { useHistory } from 'react-router-dom';

import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';

import InvoiceForm from 'components/InvoiceForm';
import AppRoutes from 'routes/AppRoutes';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';

const PayableInvoiceCreate = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { loadSubcontractors, createInvoice, createLineItem, loadLineItems } = useBuilderInvoiceCreateActions();
  const history = useHistory();

  const handleInvoiceConfirm = async invoice => {
    await createInvoice(invoice);
    history.push(AppRoutes.invoicesPath());
  };

  const loadSubcontractorsByBuilderId = nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont => {
    return loadSubcontractors(currentUser.company.id, nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont);
  };

  return (
    <InvoiceForm
      variant="builder"
      onCompaniesLoad={loadSubcontractorsByBuilderId}
      onConfirm={handleInvoiceConfirm}
      onLineItemItemCreate={createLineItem}
      onLineItemLoad={loadLineItems}
    />
  );
};

export default PayableInvoiceCreate;
