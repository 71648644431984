import Typography from '@mui/material/Typography';

import TotalInformationPresenter from 'presenters/TotalInformationPresenter';

import useStyles from './useStyles';

const DraftProjectsTotalInformation = props => {
  const { totalInformation } = props;
  const classes = useStyles();

  return (
    <div className={classes.totalInformationContainer}>
      <div>
        <Typography className={classes.totalText}>Total</Typography>
      </div>
      <div className={classes.totalProfit}>
        <Typography className={classes.totalText}>
          {TotalInformationPresenter.formattedUSDTotalContractValue(totalInformation)}
        </Typography>
      </div>
    </div>
  );
};

DraftProjectsTotalInformation.propTypes = {
  totalInformation: TotalInformationPresenter.shape().isRequired,
};

export default DraftProjectsTotalInformation;
