import * as yup from 'yup';
import { nanoid } from 'nanoid';

import { EMAIL, REQUIRED } from './errorsText';

const validationFields = {
  contacts: yup.array().of(
    yup.object().shape({
      email: yup.string().email(EMAIL).required(REQUIRED),
    }),
  ),
};

export const initialValues = {
  contacts: [
    {
      email: '',
      key: nanoid(),
    },
  ],
};

export const validationSchema = yup.object().shape(validationFields);
