import { FC, useMemo } from "react";

import { CircularProgress } from "@mui/material";
import { isNil } from "ramda";

import Icon from "components/Icon";

import { InvoiceKind, InvoiceState } from "domain/invoice/enums";
import * as InvoiceService from "domain/invoice/service";
import { IInvoice } from "domain/invoice/types";
import * as UserService from "domain/user/service";

import { useGetCurrentUserQuery } from "store/api/user/apiSlice";
import { CurrentUser } from "store/api/user/types";

import { IDocumentIconProps } from "./types";

const ICON_NAME = {
  [InvoiceState.pending]: "documentOpen",
  [InvoiceState.awaitingApproval]: "documentAwaitingApproval",
  [InvoiceState.rejected]: "documentRejected",
  [InvoiceState.approved]: "documentApproved",
  [InvoiceState.canceled]: "documentCanceled",
  [InvoiceState.paid]: "documentClosed",
  [InvoiceKind.selfPaid]: {
    [InvoiceState.pending]: "documentPendingApprovalBySystem",
    [InvoiceState.awaitingApproval]: "documentAwaitingApproval",
  },
};

const getInvoiceIconName = (
  invoice: Pick<IInvoice, "state" | "kind" | "paymentKind">,
  currentUser: CurrentUser,
): string => {
  if (InvoiceService.isSelfPaid(invoice) && InvoiceService.isPending(invoice)) {
    return ICON_NAME[InvoiceKind.selfPaid][InvoiceState.pending];
  }

  if (InvoiceService.isSelfPaid(invoice) && InvoiceService.isAwaitingApproval(invoice)) {
    return ICON_NAME[InvoiceKind.selfPaid][InvoiceState.pending];
  }

  if (UserService.isBuilder(currentUser) && InvoiceService.isEarlyPaymentRequested(invoice)) {
    return ICON_NAME[InvoiceState.awaitingApproval];
  }

  if (
    UserService.isSubcontractor(currentUser) &&
    InvoiceService.isPending(invoice) &&
    !InvoiceService.isSelfPaid(invoice)
  ) {
    return ICON_NAME[InvoiceState.awaitingApproval];
  }

  if (InvoiceService.isAwaitingApproval(invoice)) {
    return ICON_NAME[InvoiceState.pending];
  }

  return ICON_NAME[invoice.state];
};

const InvoiceDocumentIcon: FC<IDocumentIconProps> = (props) => {
  const { invoice } = props;
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isNil(currentUser)) {
    return null;
  }

  const iconName = useMemo(() => getInvoiceIconName(invoice, currentUser), [invoice, currentUser]);

  return <Icon name={iconName} width={40} height={40} />;
};

export default InvoiceDocumentIcon;
