import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import useFormik from 'hooks/useFormik';
import { validationSchema, initialValues, formPaths } from 'forms/Builder/Project/CreateInvoiceForm';
import Icon from 'components/Icon';
import DatePicker from 'components/DatePicker';
import MoneyTextField from 'components/MoneyTextField';
import LinearProgress from 'components/LinearProgress';

import useStyles from './useStyles';

const MIN_DATE = new Date();

const CreateCustomerInvoiceForm = props => {
  const { title, onInvoiceSend, onClose } = props;
  const classes = useStyles();
  const { errors, setFieldValue, values, handleSubmit, setFieldError, isSubmitting, handleChange } = useFormik({
    initialValues,
    validationSchema,
    clearErrorsOnInput: true,
    validateOnChange: false,
    onSubmit: async ({ projectedPaidAt, amount, name }) => {
      await onInvoiceSend({ projectedPaidAt: projectedPaidAt.toString(), amount, name });
      onClose();
    },
  });

  const handleDateChange = date => {
    setFieldValue(formPaths.projectedPaidAt, date);
  };

  const dateError = errors.projectedPaidAt;
  const handleDateError = error => {
    if (!values.projectedPaidAt) {
      return;
    }
    if (error !== dateError) {
      setFieldError(formPaths.projectedPaidAt, error);
    }
  };

  return (
    <>
      <LinearProgress isVisible={isSubmitting} />
      <form className={classes.root}>
        <div className={classes.titleContainer}>
          <Icon name="file" width={24} height={24} className={classes.invitationIcon} />
          <Typography variant="h3">{title}</Typography>
        </div>
        <div className={classes.fieldsContainer}>
          <div className={classes.datePicker}>
            <DatePicker
              label="Invoice payment date"
              value={values.projectedPaidAt}
              format="MM/dd/yyyy"
              minDate={MIN_DATE}
              helperText={dateError}
              error={Boolean(dateError)}
              onChange={handleDateChange}
              onError={handleDateError}
            />
          </div>
          <TextField
            name={formPaths.name}
            label="Name"
            className={classes.field}
            placeholder="Invoice name"
            value={values.name}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
          <MoneyTextField
            name={formPaths.amount}
            id={formPaths.amount}
            label="Amount"
            onChange={handleChange}
            value={values.amount}
            error={Boolean(errors.amount)}
            helperText={errors.amount}
          />
        </div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Send invoice
          </Button>
        </div>
      </form>
    </>
  );
};

CreateCustomerInvoiceForm.propTypes = {
  title: PropTypes.string.isRequired,
  onInvoiceSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateCustomerInvoiceForm;
