import { head } from 'ramda';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import UserPresenter from 'presenters/UserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import useStyles from './useStyles';

const ClientInformation = props => {
  const { project } = props;
  const classes = useStyles();

  const user = head(ProjectPresenter.customers(project));
  const userName = UserPresenter.fullName(user);
  const userEmail = UserPresenter.email(user);

  return (
    <div>
      <div className={classes.titleContainer}>
        {userName && <Typography className={classes.clientName}>{userName}</Typography>}
        {!UserPresenter.isCustomerOnboardingCompleted(user) && (
          <div className={classes.stateStatusContainer}>
            <span className={classes.stateStatus}>Pending Confirmation</span>
          </div>
        )}
      </div>
      <div>
        <Link href={`mailto:${userEmail}`} underline="hover" color="primary">
          {userEmail}
        </Link>
      </div>
    </div>
  );
};

ClientInformation.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default ClientInformation;
