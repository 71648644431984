import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const initialState = {
  sidebar: {
    submenu: null,
    tempSubmenu: null,
  },
  isLoading: false,
};

const userLayoutSlice = createSlice({
  name: 'userLayout',
  initialState,
  reducers: {
    setSidebarSubmenu(state, { payload }) {
      state.sidebar.submenu = payload;
    },
    setSidebarTempSubmenu(state, { payload }) {
      state.sidebar.tempSubmenu = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { actions } = userLayoutSlice;
export default userLayoutSlice.reducer;

export const useUserLayoutActions = () => {
  const dispatch = useDispatch();

  const setSidebarSubmenu = submenu => {
    dispatch(actions.setSidebarSubmenu(submenu));
  };

  const setSidebarTempSubmenu = submenu => {
    dispatch(actions.setSidebarTempSubmenu(submenu));
  };

  const setIsLoading = (isLoading = false) => {
    dispatch(actions.setIsLoading(isLoading));
  };

  return {
    setSidebarSubmenu,
    setSidebarTempSubmenu,
    setIsLoading,
  };
};
