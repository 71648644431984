import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import TableRowsWithLoading from 'components/TableRowsWithLoading';
import useLoading from 'hooks/useLoading';
import { useAdminLoansActions, selectors } from 'store/AdminLoansSlice';
import TransactionPresenter from 'presenters/TransactionPresenter';

import TotalOutgoingPaymentTransactionsTableRow from '../TotalOutgoingPaymentTransactionsTableRow';

import useStyles from './useStyles';

const MAX_COLS_COUNT = 2;

const ProjectsLoansTable = () => {
  const classes = useStyles();
  const { loadProjectsLoans, resetProjectsLoans } = useAdminLoansActions();
  const { data } = useSelector(selectors.projectsLoans);
  const { func: sendLoadProjectsLoans, isPending } = useLoading(loadProjectsLoans);

  useEffect(() => {
    sendLoadProjectsLoans();
    return resetProjectsLoans;
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.headerCell}>
              Name
            </TableCell>
            <TableCell align="right" className={classes.headerCell}>
              Total Loans
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowsWithLoading
            isLoading={isPending}
            rows={data.map(outgoingPaymentTransaction => (
              <TotalOutgoingPaymentTransactionsTableRow
                key={TransactionPresenter.id(outgoingPaymentTransaction)}
                outgoingPaymentTransaction={outgoingPaymentTransaction}
              />
            ))}
            isData={!isEmpty(data)}
            maxColsCount={MAX_COLS_COUNT}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsLoansTable;
