import PropTypes from 'prop-types';
import clsx from 'clsx';

import ListItem from '@mui/material/ListItem';

import { isBlank } from 'utils/storeUtils';
import { isPathActive } from 'utils/location';
import Link from 'components/Link';

import useStyles from './useStyles';

const ExpandedListItem = props => {
  const { path, icon, label, submenu, onDrawerClose } = props;
  const classes = useStyles();

  const linkPath = isBlank(submenu) ? path : submenu[0].path;

  return (
    <ListItem
      key={label}
      classes={{ root: classes.listItem, selected: classes.selectedItem }}
      selected={isPathActive(path)}
    >
      <Link to={linkPath} className={classes.expandedLink} onClick={() => onDrawerClose()}>
        {icon && <div className={classes.iconWrapper}>{icon}</div>}
        <div className={clsx(classes.listItemLabel, icon && classes.listItemLabelIndented)}>{label}</div>
      </Link>
    </ListItem>
  );
};

ExpandedListItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.shape()),
  icon: PropTypes.node,
  onDrawerClose: PropTypes.func.isRequired,
};
ExpandedListItem.defaultProps = {
  path: null,
  submenu: null,
  icon: null,
};

export default ExpandedListItem;
