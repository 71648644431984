import { apiRoutes } from "routes";

import { apiContainer } from "store";

import { CacheTag } from "store/types";

import { ISubcontractorInvoiceEarlyPaymentOption } from "./types";

type GetSubcontractorInvoiceEarlyPaymentOptionParameters = {
  earlyPayment: {
    lineItemAmounts: Array<number>;
  };
};

const subcontractorInvoiceEarlyPaymentOptionApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getSubcontractorInvoiceEarlyPaymentOptions: builder.query<
      Array<ISubcontractorInvoiceEarlyPaymentOption>,
      GetSubcontractorInvoiceEarlyPaymentOptionParameters
    >({
      query: (parameters) => ({
        url: apiRoutes.optionsSiteApiV1SubcontractorInvoiceEarlyPaymentsPath(),
        method: "GET",
        params: {
          ...parameters,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ earlyPaymentId }) => ({
                type: CacheTag.subcontractorInvoiceEarlyPaymentOption as const,
                id: earlyPaymentId,
              })),
              { type: CacheTag.subcontractorInvoiceEarlyPaymentOption, id: "LIST" },
            ]
          : [{ type: CacheTag.subcontractorInvoiceEarlyPaymentOption, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});
export const { useGetSubcontractorInvoiceEarlyPaymentOptionsQuery } = subcontractorInvoiceEarlyPaymentOptionApi;
