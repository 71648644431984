import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import TransactionsRepository from 'repositories/admin/TransactionsRepository';
import { LOANS_SHOW_OPTIONS } from 'constants/adminLoans';

const initialState = {
  totalLoansInformation: {
    totalAmount: 0,
    totalCustomersPaid: 0,
    notRepaid: 0,
  },
  selectedOption: LOANS_SHOW_OPTIONS.generalContractors,
  buildersCompanyLoans: {
    data: [],
  },
  subcontractorsCompanyLoans: {
    data: [],
  },
  projectsLoans: {
    data: [],
  },
};

const slice = createSlice({
  name: 'adminLoans',
  initialState,
  reducers: {
    loadTotalLoansInformationSuccess(state, { payload }) {
      state.totalLoansInformation.totalAmount = payload.totalAmount;
      state.totalLoansInformation.totalCustomersPaid = payload.totalCustomersPaid;
      state.totalLoansInformation.notRepaid = payload.totalAmount - payload.totalCustomersPaid;
    },
    resetTotalLoansInformation(state) {
      state.totalLoansInformation = initialState.totalLoansInformation;
    },
    setSelectedOption(state, { payload }) {
      state.selectedOption = payload;
    },
    loadBuildersCompanyLoansSuccess(state, { payload }) {
      state.buildersCompanyLoans.data = payload.data;
    },
    resetBuildersCompanyLoans(state) {
      state.buildersCompanyLoans = initialState.buildersCompanyLoans;
    },
    loadSubcontractorsCompanyLoansSuccess(state, { payload }) {
      state.subcontractorsCompanyLoans.data = payload.data;
    },
    resetSubcontractorsCompanyLoans(state) {
      state.subcontractorsCompanyLoans = initialState.subcontractorsCompanyLoans;
    },
    loadProjectsLoansSuccess(state, { payload }) {
      state.projectsLoans.data = payload.data;
    },
    resetProjectsLoans(state) {
      state.projectsLoans = initialState.projectsLoans;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;

export const useAdminLoansActions = () => {
  const dispatch = useDispatch();

  const loadTotalLoansInformation = async () => {
    const { data } = await TransactionsRepository.totalInformation();
    dispatch(actions.loadTotalLoansInformationSuccess(data));

    return data;
  };

  const resetTotalLoansInformation = () => {
    dispatch(actions.resetTotalLoansInformation());
  };

  const setSelectedOption = option => {
    dispatch(actions.setSelectedOption(option));
  };

  const loadBuildersCompanyLoans = async () => {
    const {
      data: { outgoingPaymentTransactions },
    } = await TransactionsRepository.buildersLoans();
    dispatch(actions.loadBuildersCompanyLoansSuccess({ data: outgoingPaymentTransactions }));

    return outgoingPaymentTransactions;
  };

  const resetBuildersCompanyLoans = () => {
    dispatch(actions.resetBuildersCompanyLoans());
  };

  const loadSubcontractorsCompanyLoans = async () => {
    const {
      data: { outgoingPaymentTransactions },
    } = await TransactionsRepository.subcontractorsLoans();
    dispatch(actions.loadSubcontractorsCompanyLoansSuccess({ data: outgoingPaymentTransactions }));

    return outgoingPaymentTransactions;
  };

  const resetSubcontractorsCompanyLoans = () => {
    dispatch(actions.resetSubcontractorsCompanyLoans());
  };

  const loadProjectsLoans = async () => {
    const {
      data: { outgoingPaymentTransactions },
    } = await TransactionsRepository.projectsLoans();
    dispatch(actions.loadProjectsLoansSuccess({ data: outgoingPaymentTransactions }));

    return outgoingPaymentTransactions;
  };

  const resetProjectsLoans = () => {
    dispatch(actions.resetProjectsLoans());
  };

  return {
    loadTotalLoansInformation,
    resetTotalLoansInformation,
    setSelectedOption,
    loadBuildersCompanyLoans,
    resetBuildersCompanyLoans,
    loadSubcontractorsCompanyLoans,
    resetSubcontractorsCompanyLoans,
    loadProjectsLoans,
    resetProjectsLoans,
  };
};

export const selectors = {
  totalLoansInformation: state => state.AdminLoansSlice.totalLoansInformation,
  selectedOption: state => state.AdminLoansSlice.selectedOption,
  buildersCompanyLoans: state => state.AdminLoansSlice.buildersCompanyLoans,
  subcontractorsCompanyLoans: state => state.AdminLoansSlice.subcontractorsCompanyLoans,
  projectsLoans: state => state.AdminLoansSlice.projectsLoans,
};
