import { useState } from 'react';

import GuestLayout from 'layouts/GuestLayout';
import { useAuthActions } from 'store/AuthSlice';

import Form from './components/Form';
import Confirmation from './components/Confirmation';

const SignUp = () => {
  const [isSignUpSucceed, setIsSignUpSucceed] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const { signUp, sendConfirmationEmail } = useAuthActions();

  const handleFormSubmit = async values => {
    await signUp(values);
    setIsSignUpSucceed(true);
    setSubmittedEmail(values.user.email);
  };

  const renderContent = () =>
    isSignUpSucceed ? (
      <Confirmation email={submittedEmail} onEmailResend={sendConfirmationEmail} />
    ) : (
      <Form onFormSubmit={handleFormSubmit} />
    );

  return <GuestLayout background="blocks">{renderContent()}</GuestLayout>;
};

export default SignUp;
