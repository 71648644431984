import { useHistory } from 'react-router-dom';

import { useGetCurrentUserQuery } from 'store/api/user/apiSlice';
import UserPresenter from 'presenters/UserPresenter';
import AppRoutes from 'routes/AppRoutes';

const useOnboardingRoleRedirection = () => {
  const history = useHistory();
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!UserPresenter.isStartedOnboarding(currentUser)) {
    return;
  }

  if (UserPresenter.isCustomer(currentUser)) {
    history.push(AppRoutes.onboardingCustomerPath());
    return;
  }

  if (UserPresenter.isBuilder(currentUser)) {
    history.push(AppRoutes.onboardingBuilderPath());
    return;
  }

  if (UserPresenter.isSubcontractor(currentUser)) {
    history.push(AppRoutes.onboardingSubcontractorPath());
  }
};

export default useOnboardingRoleRedirection;
