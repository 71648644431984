import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  title: {
    maxWidth: 425,
  },
  formContainer: {
    marginTop: 6,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 28,
  },
  nextButton: {
    width: 120,
    marginLeft: 12,
  },
}));
