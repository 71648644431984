import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const VARIANTS = {
  outlined: 'outlined',
  contained: 'contained',
  text: 'text',
};

const AddButton = props => {
  const { children, variant, fullWidth, ...restProps } = props;
  const { disabled } = restProps;
  const classes = useStyles();

  if (variant === VARIANTS.contained) {
    return (
      <Button variant="contained" color="primary" fullWidth={fullWidth} {...restProps}>
        <Icon name="containedPlus" width={16} height={16} className={classes.plusIcon} />
        {children}
      </Button>
    );
  }

  if (variant === VARIANTS.text) {
    return (
      <Button
        variant="text"
        color="primary"
        className={classes.textButton}
        fullWidth={fullWidth}
        startIcon={<Icon name="containedPlus" width={16} height={16} className={classes.plusTextIcon} />}
        {...restProps}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      fullWidth={fullWidth}
      startIcon={<Icon name={disabled ? 'addDisabled' : 'add'} width={16} height={16} />}
      {...restProps}
    >
      {children}
    </Button>
  );
};

AddButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  fullWidth: PropTypes.bool,
};
AddButton.defaultProps = {
  variant: VARIANTS.outlined,
  children: null,
  fullWidth: false,
};

export default AddButton;
