export const formatter = (params = {}, locale = 'en-US') => Intl.NumberFormat(locale, params);

export const formatUSD = (amount, maximumFractionDigits = 0, signDisplay = 'auto') =>
  formatter({
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
    signDisplay,
  }).format(amount);

export const getNumbersFromString = (string = '') => string.match(/\d+/gi).join('');
