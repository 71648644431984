import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  title: {
    marginTop: -3,
    color: theme.palette.colors.hunterGreen,
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.colors.gunsmokeGray,
  },
}));
