import { FC } from "react";

import { Skeleton } from "@mui/material";
import muiSx from "mui-sx";
import { isNil } from "ramda";

import Div from "components/common/Div";

import styles from "./styles";
import { IBuilderInvoicesTabProps } from "./types";

const Counter: FC<IBuilderInvoicesTabProps> = (props) => {
  const { label, count, type } = props;
  return (
    <Div sx={styles.label}>
      {label}
      <Div
        sx={muiSx(
          styles.count,
          { condition: type === "open", sx: styles.countOpen },
          { condition: type === "approved", sx: styles.countApproved },
          { condition: type === "closed", sx: styles.countClosed },
          { condition: isNil(count), sx: styles.countEmpty },
        )}
      >
        {isNil(count) ? (
          <Skeleton sx={styles.skeleton} variant="circular" animation="wave" width={16} height={16} />
        ) : (
          count
        )}
      </Div>
    </Div>
  );
};

export default Counter;
