import { useSelector } from 'react-redux';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { LOANS_SHOW_OPTIONS } from 'constants/adminLoans';
import { useAdminLoansActions, selectors } from 'store/AdminLoansSlice';

import GeneralContractorsLoansTable from './components/GeneralContractorsLoansTable';
import SubcontractorsLoansTable from './components/SubcontractorsLoansTable';
import ProjectsLoansTable from './components/ProjectsLoansTable';

import useStyles from './useStyles';

const SelectableLoans = () => {
  const classes = useStyles();
  const { setSelectedOption } = useAdminLoansActions();
  const selectedOption = useSelector(selectors.selectedOption);

  const handleSelect = event => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <div className={classes.selectContainer}>
        <Select variant="filled" name="projectLineItemState" value={selectedOption} onChange={handleSelect}>
          <MenuItem value={LOANS_SHOW_OPTIONS.generalContractors}>General Contractors</MenuItem>
          <MenuItem value={LOANS_SHOW_OPTIONS.projects}>Projects</MenuItem>
          <MenuItem value={LOANS_SHOW_OPTIONS.subcontractors}>Subcontractors</MenuItem>
        </Select>
      </div>
      <div>
        {selectedOption === LOANS_SHOW_OPTIONS.generalContractors && <GeneralContractorsLoansTable />}
        {selectedOption === LOANS_SHOW_OPTIONS.subcontractors && <SubcontractorsLoansTable />}
        {selectedOption === LOANS_SHOW_OPTIONS.projects && <ProjectsLoansTable />}
      </div>
    </div>
  );
};

export default SelectableLoans;
