import { memo } from 'react';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import PageHeader from 'components/PageHeader';

import useStyles from './useStyles';

const IncomingPaymentsHeader = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.titleContainer}>
        <PageHeader
          text="Incoming Payments"
          description="Here you can assign the incoming transactions from the clients to your projects."
          icon={<DescriptionOutlinedIcon className={classes.titleIcon} />}
        />
      </div>
    </header>
  );
};

export default memo(IncomingPaymentsHeader);
