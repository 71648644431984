import PropTypes from 'prop-types';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PdfViewer = props => {
  const { fileUrl } = props;
  const [numPagesState, setNumPagesState] = useState(null);

  const onDocumentLoadSuccess = pdf => {
    setNumPagesState(pdf.numPages);
  };

  return (
    <Document file={{ url: fileUrl }} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(Math.min(numPagesState, 5)), (el, index) => (
        <Page key={index} pageNumber={index + 1} />
      ))}
    </Document>
  );
};

PdfViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default PdfViewer;
