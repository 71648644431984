import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}, customHeaders = {}, cancelToken) {
    const path = apiRoutes.siteApiV1AdminSubcontractorsPath();

    return FetchHelpers.get(path, params, customHeaders, cancelToken);
  },
};
