import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  optionContainer: {
    width: '100%',
  },
  option: {
    padding: '12px 0 11px',
    width: '100%',
  },
}));
