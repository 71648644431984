import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  draftContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  draftIcon: {
    fill: theme.palette.colors.deltaGray,
  },
  draftLabel: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.palette.colors.deltaGray,
    lineHeight: theme.typography.pxToRem(20),
  },
  changeOrderButton: {
    marginRight: theme.spacing(1),
  },
  completeButton: {
    marginRight: theme.spacing(1),
  },
  newInvoiceButtonContainer: {
    display: 'inline-block',
  },
  popover: {
    marginTop: -10,
  },
}));
