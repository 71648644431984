import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    paddingRight: 64,
  },
  tip: {
    marginTop: 6,
    fontSize: 14,
    color: theme.palette.colors.gunsmokeGray,
  },
  actions: {
    marginTop: 40,
    width: 375,
  },
  buttonChoiceRoot: {
    textTransform: 'none',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    padding: '13px 25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:not(:first-child)': {
      marginTop: 16,
    },
  },
  disabledButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 360,
  },
  disabledButtonRoot: {
    padding: '12px 24px',
    fontSize: 14,
    lineHeight: '16px',
  },
}));
