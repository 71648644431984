import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    whiteSpace: {
      xs: "normal",
      md: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
};

export default styles;
