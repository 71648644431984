import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import LineItems from 'containers/Builder/LineItems';

import useStyles from './useStyles';

const LineItemsOnboarding = props => {
  const { onLineItemsOnboardingProceed } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tip}>This step can be done later in your account settings.</div>
      <LineItems />
      <div className={classes.submitContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={onLineItemsOnboardingProceed}
          classes={{ root: classes.submitRoot }}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
};

LineItemsOnboarding.propTypes = {
  onLineItemsOnboardingProceed: PropTypes.func.isRequired,
};

export default LineItemsOnboarding;
