import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: '20px 24px',
    width: '100%',
    height: 124,
  },
  draftRoot: {
    width: '100%',
    height: 124,
    padding: theme.spacing(2, 3),
  },
}));
