import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import { CurrentUser } from "store/api/user/types";

export const initialize = () => {
  LogRocket.init("qxk9iq/funl-web");
  setupLogRocketReact(LogRocket);
};

export const identify = (user: Pick<CurrentUser, "id" | "fullName" | "email">) => {
  LogRocket.identify(user.id.toString(), {
    name: user.fullName,
    email: user.email,
  });
};
