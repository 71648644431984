import { FC, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Divider, Grid, IconButton, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import muiSx from "mui-sx";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";

import Icon from "components/Icon";

import useImpersonate from "hooks/useImpersonate";

import { useDeleteSessionMutation } from "store/api/session/apiSlice";

import styles from "./styles";
import { ITopBarProps } from "./types";

const TopBar: FC<ITopBarProps> = (props) => {
  const { currentUser, onDrawerOpen, onDrawerClose, isDrawerOpen } = props;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("userLayout");
  const { stopImpersonating } = useImpersonate();
  const [signOut] = useDeleteSessionMutation();

  const open = Boolean(anchorElement);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchor = isNil(anchorElement) ? event.currentTarget : null;
    setAnchorElement(anchor);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleSignOut = async () => {
    handleClose();
    await signOut();
  };

  return (
    <>
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar>
          <Grid alignItems="center" justifyContent="space-between" container>
            <Grid item>
              <Grid alignItems="center" container>
                <Grid item>
                  {isDrawerOpen ? (
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={styles.actionButton}
                      onClick={() => onDrawerClose()}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={styles.actionButton}
                      onClick={() => onDrawerOpen()}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item sx={styles.logo}>
                  <Icon name="logo" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid alignItems="center" container sx={muiSx(styles.user, { sx: styles.userActive, condition: open })}>
                <Grid item>
                  <Icon name="avatar" width={24} height={24} />
                </Grid>
                <Grid item />
                <IconButton
                  size="small"
                  color="inherit"
                  aria-label="menu"
                  sx={styles.expandIcon}
                  id="current-user-menu-button"
                  aria-controls={open ? "current-user-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Menu
        id="current-user-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "current-user-menu-button",
        }}
      >
        <MenuItem>{currentUser.fullName}</MenuItem>
        <Divider />
        {currentUser.isImpersonated && <MenuItem onClick={stopImpersonating}>{t("stopImpersonating")}</MenuItem>}
        <MenuItem onClick={handleSignOut} sx={styles.logoutMenuItem}>
          <ListItemText>{t("logout")}</ListItemText>
          <LogoutIcon fontSize="small" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default TopBar;
