import { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import PageHeader from 'components/PageHeader';
import { useProjectCustomerInvoicesActions, selectors } from 'store/ProjectClientInvoicesSlice';
import ProjectPresenter from 'presenters/ProjectPresenter';
import useLoading from 'hooks/useLoading';

import ProjectClientInvoicesTable from './components/ProjectClientInvoicesTable';
import CreateInvoiceButton from './components/CreateInvoiceButton';

import useStyles from './useStyles';

const ProjectClientInvoices = props => {
  const { project } = props;
  const classes = useStyles();
  const { createCustomerInvoice } = useProjectCustomerInvoicesActions();
  const totalCount = useSelector(selectors.totalCount);
  const { id: projectId, name: projectName } = project;
  const { loadCustomerInvoices, resetCustomerInvoices } = useProjectCustomerInvoicesActions();
  const { func: sendLoadCustomerInvoices, isPending } = useLoading(loadCustomerInvoices);

  useEffect(() => {
    sendLoadCustomerInvoices(projectId);
    return resetCustomerInvoices;
  }, []);

  const handleLoadMore = page => loadCustomerInvoices(projectId, page);

  const handleInvoiceSend = async params => {
    await createCustomerInvoice(projectId, params);
    resetCustomerInvoices();
    sendLoadCustomerInvoices(projectId);
  };

  const isAnyClient = !isEmpty(ProjectPresenter.customers(project));
  const isProjectCompleted = ProjectPresenter.isCompleted(project);

  return (
    <div className={classes.root}>
      <PageHeader text="Client Payments" description="View your project payments." />
      <Paper className={classes.invoicesContainer}>
        <div className={classes.invoicesIndent}>
          {isAnyClient && (
            <>
              <div className={classes.invoicesTopArea}>
                <CreateInvoiceButton
                  projectName={projectName}
                  projectId={projectId}
                  onInvoiceSend={handleInvoiceSend}
                  isDisabled={isProjectCompleted}
                />
                <Typography className={classes.total}>Total: {totalCount}</Typography>
              </div>
              <ProjectClientInvoicesTable isLoading={isPending} loadMore={handleLoadMore} />
            </>
          )}
          {!isAnyClient && !isProjectCompleted && (
            <Typography className={classes.info}>Please, invite clients first. Then refresh page.</Typography>
          )}
          {!isAnyClient && isProjectCompleted && (
            <Typography className={classes.info}>
              This is a completed project. You can&apos;t invite clients.
            </Typography>
          )}
        </div>
      </Paper>
    </div>
  );
};

ProjectClientInvoices.propTypes = {
  project: ProjectPresenter.shape().isRequired,
};

export default memo(ProjectClientInvoices);
