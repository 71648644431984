import { getInboundLineItemsTotalAmount } from 'domain/inboundLineItem/service';

import PropTypes from 'prop-types';
import { isNil, equals } from 'ramda';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { DATE_FORMATS } from 'constants/date';
import { INVOICE_FORM_VARIANTS } from 'constants/invoices';
import Icon from 'components/Icon';
import LinearProgress from 'components/LinearProgress';
import InvoicePresenter from 'presenters/InvoicePresenter';
import { format } from 'utils/dateUtils';
import useLoading from 'hooks/useLoading';
import { selectors as earlyPaymentsSelectors } from 'store/InvoiceEarlyPaymentsSlice';
import { formatUSD } from 'libs/currency';

import LineItems from './components/LineItems';
import useStyles from './useStyles';

const SUCCESS_MESSAGE = 'Success';
const PAYMENT_TERMS_NOTE =
  'Note: By accepting early payment this invoice will be paid in full at an amount less than the total amount you entered for the invoice.';

const InvoiceConfirmation = props => {
  const { variant, invoice, confirmButtonText, setErrors, onConfirm, onCancel } = props;
  const earlyPayments = useSelector(earlyPaymentsSelectors.earlyPayments, equals);
  const classes = useStyles();
  const { func: sendInvoice, isPending } = useLoading(onConfirm, {
    isShowSuccessNotification: true,
    successMessage: SUCCESS_MESSAGE,
  });

  const currentDate = format(new Date(), DATE_FORMATS.issueDate);
  const handleSendInvoice = async () => {
    try {
      await sendInvoice(invoice);
    } catch (errors) {
      setErrors(errors);

      if (onCancel) {
        onCancel();
      }
    }
  };

  const earlyPaymentAmount = isNil(invoice.earlyPaymentId)
    ? null
    : earlyPayments.find(({ earlyPaymentId }) => earlyPaymentId === Number(invoice.earlyPaymentId)).amount;

  const totalAmount = getInboundLineItemsTotalAmount(invoice.inboundLineItems);

  return (
    <>
      <LinearProgress isVisible={isPending} />
      <div className={classes.root}>
        <div className={classes.title}>
          <Icon name="nameLogo" width={123} height={28} />
        </div>
        <Typography variant="h1" className={classes.name}>
          {InvoicePresenter.name(invoice)}
        </Typography>
        {invoice.earlyPaymentId && <Typography className={classes.noteText}>{PAYMENT_TERMS_NOTE}</Typography>}
        <div className={classes.generalInformation}>
          <div>
            <div className={classes.label}>For</div>
            <div className={classes.addressee}>
              <Link href={`mailto:${InvoicePresenter.builderEmail(invoice)}`} underline="hover" color="primary">
                {InvoicePresenter.builderEmail(invoice)}
              </Link>
            </div>
            <div>{InvoicePresenter.projectName(invoice)}</div>
          </div>
          <div>
            <div className={clsx(classes.label, classes.labelAlignRight)}>Issue Date</div>
            <div>{currentDate}</div>
          </div>
        </div>
        <div className={classes.notesContainer}>
          <div className={classes.label}>Notes</div>
          <div className={classes.notes}>{InvoicePresenter.note(invoice)}</div>
        </div>
        <div className={classes.tableContainer}>
          <LineItems lineItems={invoice.inboundLineItems} />
        </div>
        <div className={classes.summaryContainer}>
          <div className={classes.summary}>
            <div className={classes.label}>Summary</div>
            <div className={classes.summaryRow}>
              <div>{variant === INVOICE_FORM_VARIANTS.subcontractor ? 'Payment total' : 'Total'}</div>
              <div className={classes.summaryValue}>{formatUSD(earlyPaymentAmount || totalAmount)}</div>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onCancel} disabled={isPending}>
            Cancel
          </Button>
          <Button
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            onClick={handleSendInvoice}
            disabled={isPending}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </>
  );
};

InvoiceConfirmation.propTypes = {
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  invoice: InvoicePresenter.shape().isRequired,
  confirmButtonText: PropTypes.string,
  setErrors: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

InvoiceConfirmation.defaultProps = {
  confirmButtonText: 'Send Invoice 1',
};

export default InvoiceConfirmation;
