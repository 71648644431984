import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Icon from 'components/Icon';
import TransactionPresenter from 'presenters/TransactionPresenter';

import useStyles from './useStyles';

const PaymentHistoryTableRow = props => {
  const { transaction } = props;
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.projectName(transaction)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.companyName(transaction)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.cell, classes.invoiceCell)}>
        {TransactionPresenter.customerInvoiceName(transaction)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {TransactionPresenter.formattedCreatedAt(transaction)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {TransactionPresenter.formattedUSDAmount(transaction, 0)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <Icon name="roundCheckbox" width={12} height={12} className={classes.paidIcon} />
        <span className={classes.state}>{TransactionPresenter.state(transaction)}</span>
      </TableCell>
    </TableRow>
  );
};

PaymentHistoryTableRow.propTypes = {
  transaction: TransactionPresenter.shape().isRequired,
};

export default PaymentHistoryTableRow;
