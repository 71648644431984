import PropTypes from 'prop-types';

import ProjectPresenter from 'presenters/ProjectPresenter';
import LineItemPresenter from 'presenters/LineItemPresenter';

import ClientDetails from './components/ClientDetails';
import ProjectInfo from './components/ProjectInfo';
import ProjectLineItems from './components/ProjectLineItems';
import useStyles from './useStyles';

const ProjectDetails = props => {
  const {
    project,
    onCompanyLineItemsLoad,
    onCompanyLineItemsReset,
    companyLineItems,
    isCompanyLineItemsLoading,
    companyLineItemsMeta,
    onProjectLineItemsLoad,
    projectLineItems,
    isProjectLineItemsLoading,
    projectLineItemsMeta,
    onProjectLineItemCreate,
    onProjectLineItemUpdate,
    onProjectLineItemDestroy,
    onProjectLineItemMassCreate,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.topContainer}>
          <ClientDetails project={project} />
          <ProjectInfo project={project} />
        </div>
        <div className={classes.lineItemsContainer}>
          <ProjectLineItems
            onCompanyLineItemsLoad={onCompanyLineItemsLoad}
            onCompanyLineItemsReset={onCompanyLineItemsReset}
            companyLineItems={companyLineItems}
            isCompanyLineItemsLoading={isCompanyLineItemsLoading}
            companyLineItemsMeta={companyLineItemsMeta}
            onProjectLineItemsLoad={onProjectLineItemsLoad}
            projectLineItems={projectLineItems}
            isProjectLineItemsLoading={isProjectLineItemsLoading}
            projectLineItemsMeta={projectLineItemsMeta}
            onProjectLineItemCreate={onProjectLineItemCreate}
            onProjectLineItemUpdate={onProjectLineItemUpdate}
            onProjectLineItemDestroy={onProjectLineItemDestroy}
            onProjectLineItemMassCreate={onProjectLineItemMassCreate}
          />
        </div>
      </div>
    </div>
  );
};

ProjectDetails.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  onCompanyLineItemsLoad: PropTypes.func.isRequired,
  onCompanyLineItemsReset: PropTypes.func.isRequired,
  companyLineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  companyLineItemsMeta: PropTypes.shape().isRequired,
  isCompanyLineItemsLoading: PropTypes.bool.isRequired,
  onProjectLineItemsLoad: PropTypes.func.isRequired,
  projectLineItems: PropTypes.arrayOf(LineItemPresenter.shape()).isRequired,
  projectLineItemsMeta: PropTypes.shape().isRequired,
  isProjectLineItemsLoading: PropTypes.bool.isRequired,
  onProjectLineItemCreate: PropTypes.func.isRequired,
  onProjectLineItemUpdate: PropTypes.func.isRequired,
  onProjectLineItemDestroy: PropTypes.func.isRequired,
  onProjectLineItemMassCreate: PropTypes.func.isRequired,
};

export default ProjectDetails;
