import { useLocation } from "react-router-dom";

import ActivationForm from "components/common/ActivationForm";

import { useNotifications } from "hooks/useNotifications";

import WelcomeLayout from "layouts/WelcomeLayout";

import { useDeleteSessionMutation } from "store/api/session/apiSlice";
import { useActivateUserMutation } from "store/api/user/apiSlice";
import { serializeFormData, UserActivationFormData } from "store/api/user/schemas/activation";

import Sidebar from "./Sidebar";

const CustomerActivation = () => {
  const [deleteSession] = useDeleteSessionMutation();
  const { search } = useLocation();
  const { showErrorNotification } = useNotifications();
  const [activateUser] = useActivateUserMutation();

  const handleSubmit = async (formData: UserActivationFormData) => {
    const searchParameters = new URLSearchParams(search);
    const activationToken = searchParameters.get("activation_token") ?? "";

    const parameters = serializeFormData(formData, activationToken);

    try {
      await activateUser(parameters);
    } catch {
      showErrorNotification();
    }
  };

  return (
    <WelcomeLayout sidebarComponent={<Sidebar />} onSignOut={deleteSession} isLogOutShow={false}>
      <ActivationForm onSubmit={handleSubmit} />
    </WelcomeLayout>
  );
};

export default CustomerActivation;
