import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  listItem: {
    paddingTop: 4,
  },
  label: {
    fontFamily: 'Poppins',
    letterSpacing: 0,
  },
  progressContainer: {
    height: 110,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noteText: {
    color: theme.palette.colors.gunsmokeGray,
  },
}));
