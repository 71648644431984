import { SxStyles } from "theme";

const styles: SxStyles = {
  passwordVisibilityButton: {
    marginRight: -1,
  },
  visibilityIcon: {
    width: 18,
    height: 18,
    fill: "colors.deltaGray",
  },
  inputRootPassword: {
    letterSpacing: 2,
  },
};

export default styles;
