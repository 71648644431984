import { useState } from 'react';
import clsx from 'clsx';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import useStyles from './useStyles';

const PasswordTextField = props => {
  const classes = useStyles();
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordShown = () => {
    setIsPasswordShown(isShown => !isShown);
  };

  const inputRootClassNames = clsx({ [classes.inputRootPassword]: !isPasswordShown });

  return (
    <TextField
      type={isPasswordShown ? 'text' : 'password'}
      {...props}
      InputProps={{
        classes: { root: inputRootClassNames },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              classes={{ root: classes.passwordVisibilityButton }}
              aria-label="toggle password visibility"
              onClick={togglePasswordShown}
              size="large"
            >
              {isPasswordShown ? (
                <VisibilityOff classes={{ root: classes.visibilityIcon }} />
              ) : (
                <Visibility classes={{ root: classes.visibilityIcon }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
