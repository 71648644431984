import { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';

import useLoading from 'hooks/useLoading';
import { pathWithDots, getTouchedFieldApiError, getTouchedFieldFormikError } from 'utils/formUtils';
import MoneyTextField from 'components/MoneyTextField';
import DeleteButton from 'components/DeleteButton';
import AutocompletePaperButton from 'components/AutocompletePaperButton';
import { PROJECT_STATES, PROJECT_APPROVAL_STATES } from 'presenters/ProjectPresenter';

import InvoiceResponseAutocomplete from '../InvoiceResponseAutocomplete';

import useStyles from './useStyles';

const PROJECT_UNSELECTED_ERROR = 'Select Project';

const InvoiceResponseRow = props => {
  const {
    isDisabled,
    isDeleteButtonVisible,
    index,
    splittedInvoice,
    apiErrors,
    touched,
    errors,
    setFieldError,
    onFieldValueSet,
    onChange,
    onRemoveButtonClick,
    onLoadProjects,
    onLoadLineItems,
    onInvoiceLineItemFormOpen,
  } = props;
  const classes = useStyles();
  const [lineItemsOptions, setLineItemsOptions] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const projectFieldName = `splittedInvoices.${index}.project`;
  const lineItemFieldName = `splittedInvoices.${index}.lineItem`;
  const amountFieldName = `splittedInvoices.${index}.amount`;
  const projectIdFieldName = `${projectFieldName}.id`;
  const projectId = splittedInvoice.project.id;

  const { isPending: isLoadLineItemsPending, func: sendLoadLineItems } = useLoading(onLoadLineItems, {
    isAbortable: true,
  });
  const { isPending: isLoadProjectsPending, func: sendLoadProjects } = useLoading(onLoadProjects, {
    isAbortable: true,
  });

  const displayedValueSelectedOption = splittedInvoice.lineItem.accountCode
    ? `${splittedInvoice.lineItem.accountCode} ${splittedInvoice.lineItem.categoryName}`
    : '';

  const handleLoadProjects = async value => {
    const result = await sendLoadProjects({
      name: value,
      state: PROJECT_STATES.active,
      approvalState: PROJECT_APPROVAL_STATES.approved,
    });
    const { projects } = result;
    setProjectsOptions(projects || []);
  };

  const handleLoadLineItems = async () => {
    const { lineItems } = await sendLoadLineItems();
    setLineItemsOptions(lineItems);
  };

  const handleLineItemsChange = () => {
    if (!projectId) {
      setFieldError(projectIdFieldName, PROJECT_UNSELECTED_ERROR);
    }
  };

  const handleCreateLineItemClick = () => {
    onInvoiceLineItemFormOpen(index);
  };

  return (
    <Fade in={Boolean(splittedInvoice)}>
      <Grid container spacing={2} className={classes.fieldsRow}>
        <Grid item xs={4} lg={5}>
          <InvoiceResponseAutocomplete
            label="Select project"
            variant="project"
            displayedValueSelectedOption={splittedInvoice.project.name}
            options={projectsOptions}
            fieldName={projectFieldName}
            error={Boolean(apiErrors[projectFieldName]) || Boolean(pathWithDots(projectIdFieldName, errors))}
            helperText={pathWithDots(projectIdFieldName, apiErrors) || pathWithDots(projectIdFieldName, errors)}
            isLoading={isLoadProjectsPending}
            disabled={isDisabled}
            onLoad={handleLoadProjects}
            onFocus={() => handleLoadProjects('')}
            onFieldValueSet={onFieldValueSet(projectFieldName)}
          />
        </Grid>
        <Grid item xs={4}>
          <InvoiceResponseAutocomplete
            label="Select line item"
            variant="lineItem"
            displayedValueSelectedOption={displayedValueSelectedOption}
            acceptedSortingKeys={['accountCode', 'categoryNames']}
            options={lineItemsOptions}
            fieldName={lineItemFieldName}
            error={
              Boolean(apiErrors[lineItemFieldName]) ||
              Boolean(getTouchedFieldFormikError({ touched, errors, errorPath: lineItemFieldName }))
            }
            helperText={getTouchedFieldApiError({
              touched,
              errors,
              apiErrors,
              errorPath: `${lineItemFieldName}.id`,
              touchedPath: lineItemFieldName,
            })}
            disabled={isDisabled}
            isLoading={isLoadLineItemsPending}
            PaperComponent={paperProps =>
              splittedInvoice.project.id ? (
                <AutocompletePaperButton text="Create new item" onClick={handleCreateLineItemClick} {...paperProps} />
              ) : null
            }
            onChange={handleLineItemsChange}
            onLoad={handleLoadLineItems}
            onFocus={() => handleLoadLineItems('')}
            onFieldValueSet={onFieldValueSet(lineItemFieldName)}
          />
        </Grid>
        <Grid item xs={2}>
          <MoneyTextField
            label="Amount"
            value={splittedInvoice.amount}
            name={amountFieldName}
            disabled={isDisabled}
            error={
              Boolean(apiErrors[amountFieldName]) ||
              Boolean(getTouchedFieldFormikError({ touched, errors, errorPath: amountFieldName }))
            }
            helperText={getTouchedFieldApiError({
              touched,
              errors,
              apiErrors,
              errorPath: amountFieldName,
            })}
            onChange={onChange}
          />
        </Grid>
        {isDeleteButtonVisible && (
          <Grid item xs={2} lg={1}>
            <div className={classes.deleteButtonContainer}>
              <DeleteButton className={classes.deleteButton} onClick={onRemoveButtonClick(index)} />
            </div>
          </Grid>
        )}
      </Grid>
    </Fade>
  );
};

const splittedInvoicePropType = PropTypes.shape({
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  lineItem: PropTypes.shape({
    id: PropTypes.number,
    accountCode: PropTypes.string,
    categoryName: PropTypes.string,
  }),
  amount: PropTypes.string,
  key: PropTypes.string,
});

InvoiceResponseRow.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isDeleteButtonVisible: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  splittedInvoice: splittedInvoicePropType.isRequired,
  apiErrors: PropTypes.shape({ splittedInvoice: PropTypes.arrayOf(splittedInvoicePropType) }).isRequired,
  touched: PropTypes.shape({ splittedInvoice: PropTypes.arrayOf(splittedInvoicePropType) }).isRequired,
  errors: PropTypes.shape({ splittedInvoice: PropTypes.arrayOf(splittedInvoicePropType) }).isRequired,
  onLoadLineItems: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  onLoadProjects: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  onInvoiceLineItemFormOpen: PropTypes.func.isRequired,
};

export default InvoiceResponseRow;
