import { SxStyles } from "theme";

const styles: SxStyles = {
  iconContainer: {
    position: "relative",
    "& svg": {
      fill: "currentColor",
    },
  },
  badgeContainer: {
    position: "absolute",
    bottom: "-2px",
    right: "-2px",
  },
};

export default styles;
