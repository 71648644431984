import * as yup from 'yup';

import { REQUIRED } from 'forms/errorsText';

const validationFields = {
  amount: yup.number().required(REQUIRED),
  companyId: yup.number().required(REQUIRED),
  customerId: yup.number().required(REQUIRED),
};

export const initialValues = {
  amount: '',
  projectId: '',
  projectName: '',
  customerInvoiceId: '',
  customerInvoiceName: '',
  companyId: '',
  companyName: '',
  customerId: '',
  customerName: '',
};

export const validationSchema = yup.object().shape(validationFields);

export const formPaths = {
  amount: 'amount',
  projectId: 'projectId',
  customerInvoiceId: 'customerInvoiceId',
  companyId: 'companyId',
  projectName: 'projectName',
  customerInvoiceName: 'customerInvoiceName',
  companyName: 'companyName',
  customerId: 'customerId',
  customerName: 'customerName',
};
