import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import ProjectsRepository from 'repositories/customer/ProjectsRepository';
import OnboardingProjectsRepository from 'repositories/customer/onboarding/ProjectsRepository';
import OnboardingsRepository from 'repositories/customer/OnboardingsRepository';
import BankAccountRepository from 'repositories/customer/BankAccountRepository';

const initialState = {
  projects: {
    data: [],
  },
};

const customerOnboardingSlice = createSlice({
  name: 'customerOnboarding',
  initialState,
  reducers: {
    loadProjectsSuccess(state, { payload }) {
      state.projects.data = payload.projects;
    },
  },
});

export const { actions } = customerOnboardingSlice;
export default customerOnboardingSlice.reducer;

export const useCustomerOnboardingActions = () => {
  const dispatch = useDispatch();

  const confirmProject = () => {
    return OnboardingProjectsRepository.confirm();
  };

  const createBankAccount = async bankAccount => {
    const params = { bankAccount };
    const { data } = await BankAccountRepository.create(params);

    return data;
  };

  const loadProjects = async () => {
    const { data } = await ProjectsRepository.index();
    dispatch(actions.loadProjectsSuccess(data));

    return data;
  };

  const completeOnboarding = async () => {
    await OnboardingsRepository.complete();
  };

  return {
    confirmProject,
    createBankAccount,
    loadProjects,
    completeOnboarding,
  };
};

export const selectors = {
  projects: state => state.CustomerOnboardingSlice.projects,
};
