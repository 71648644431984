import { useState } from 'react';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

import IncomingPaymentPresenter from 'presenters/IncomingPaymentPresenter';
import { useIncomingPayments } from 'store/IncomingPaymentsSlice';
import { useUserLayoutActions } from 'store/UserLayoutSlice';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';
import AssignPayment from 'components/AssignPayment';

import useStyles from './useStyles';

const UnassignedIncomingPaymentsTableRows = props => {
  const { payment } = props;
  const classes = useStyles();
  const { updateIncomingPayment, moveToHistorical, loadCustomerInvoices } = useIncomingPayments();
  const [isEdit, setIsEdit] = useState(false);
  const { setIsLoading } = useUserLayoutActions();
  const { loadProjects } = useBuilderInvoiceCreateActions();

  const handleAssignPayment = async ({ projectId, projectName, customerInvoiceName, customerInvoiceId }) => {
    try {
      const id = IncomingPaymentPresenter.id(payment);
      await updateIncomingPayment(id, { projectId, customerInvoiceId });
      moveToHistorical(id, { projectName, customerInvoiceName });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignButtonClick = () => setIsEdit(true);
  const handleAssignClose = () => setIsEdit(false);

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {IncomingPaymentPresenter.payerName(payment)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {IncomingPaymentPresenter.date(payment)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.cell, classes.amountCell)}>
        {IncomingPaymentPresenter.formattedUSDTotalAmount(payment)}
      </TableCell>
      {isEdit ? (
        <TableCell align="left" className={classes.cell} colSpan={2}>
          <AssignPayment
            loadProjects={loadProjects}
            loadCustomerInvoices={loadCustomerInvoices}
            onAssigned={handleAssignPayment}
            onClose={handleAssignClose}
          />
        </TableCell>
      ) : (
        <TableCell align="left" className={classes.cell} colSpan={2}>
          <Button variant="text" color="primary" onClick={handleAssignButtonClick}>
            Assign payment
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

UnassignedIncomingPaymentsTableRows.propTypes = {
  payment: IncomingPaymentPresenter.shape().isRequired,
};

export default UnassignedIncomingPaymentsTableRows;
