import PropTypes from 'prop-types';

import MuiLinearProgress from '@mui/material/LinearProgress';

import useStyles from './useStyles';

const LinearProgress = props => {
  const { isVisible } = props;
  const classes = useStyles();

  return <div className={classes.container}>{isVisible && <MuiLinearProgress />}</div>;
};

LinearProgress.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default LinearProgress;
