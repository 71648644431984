import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 22,
  },
  textButton: {
    marginLeft: 23,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  totalItems: {
    fontSize: 15,
    lineHeight: '35px',
    color: theme.palette.colors.black,
  },
  lineItemsContainer: {
    minWidth: 630,
    minHeight: 600,
  },
}));
