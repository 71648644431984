import { pathOr, path } from 'ramda';
import { matchPath } from 'react-router-dom';
import queryString from 'query-string';

export const getUrlParam = param => {
  const { location } = window;
  const search = queryString.parse(location.search);

  return pathOr(null, [param], search);
};

export const getRouteParam = (props, parameterName) => {
  return path(['match', 'params', parameterName], props);
};

export const getCurrentPathName = () => {
  return path(['location', 'pathname'], window);
};

export const isPathActive = pathname => {
  const { location } = window;

  return Boolean(matchPath(location.pathname, { path: pathname }));
};

export const isAnyPathActive = pathnames => pathnames.some(isPathActive);
