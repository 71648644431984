import { useSelector } from 'react-redux';

import Icon from 'components/Icon';
import TeamSettingsPage from 'containers/TeamSettingsPage';
import { useSubcontractorTeamSettingsActions } from 'store/SubcontractorTeamSettingsSlice';
import { TEAM_SETTINGS_VARIANTS } from 'constants/TeamSettings';

import useStyles from './useStyles';

const TeamSettings = () => {
  const classes = useStyles();
  const { data: members, meta: membersMeta } = useSelector(state => state.SubcontractorTeamSettingsSlice.members);

  const { loadMembers, updateMember, destroyMember, createMember, resetSubcontractorTeamSettings } =
    useSubcontractorTeamSettingsActions();

  return (
    <main className={classes.root}>
      <TeamSettingsPage
        members={members}
        membersMeta={membersMeta}
        loadMembers={loadMembers}
        updateMember={updateMember}
        destroyMember={destroyMember}
        createMember={createMember}
        resetTeamSettings={resetSubcontractorTeamSettings}
        variant={TEAM_SETTINGS_VARIANTS.global}
        description="Create new Funl users and set their permissions here. This will provide your team with access to your
        projects, invoices and Funl account."
        headerProps={{
          text: 'User Management',
          icon: <Icon className={classes.titleIcon} width={28} height={28} name="gear" />,
        }}
      />
    </main>
  );
};

export default TeamSettings;
