import * as yup from 'yup';
import { nanoid } from 'nanoid';
// import { startOfDay, endOfDay } from 'date-fns';

// import { addDays } from 'date-fns';

// import { DAYS_AFTER_TODAY, DAYS_AFTER_CREATED } from 'constants/invoicePaymentDate';

import { REQUIRED, POSITIVE } from '../errorsText';

const validationFields = isAmountEdit => {
  const common = {
    projectedPaidAt: yup.date().nullable(true),
    // .required(REQUIRED)
    // .test({
    //   name: 'max',
    //   test: value => startOfDay(value).getTime() < endOfDay(addDays(new Date(), DAYS_AFTER_CREATED)).getTime(),
    // })
    // .test({
    //   name: 'min',
    //   test: value => endOfDay(value).getTime() > startOfDay(addDays(new Date(), DAYS_AFTER_TODAY)).getTime(),
    // }),
  };

  if (isAmountEdit) {
    return {
      splittedInvoices: yup.array().of(
        yup.object().shape({
          project: yup.object().shape({
            id: yup.string().required(REQUIRED),
          }),
          lineItem: yup.object().shape({
            id: yup.string().required(REQUIRED),
          }),
          amount: yup.number().positive(POSITIVE).required(REQUIRED),
        }),
      ),
      neededAmount: yup.number().equals([0]).nullable(true).required(),
      automaticallyApproved: yup.boolean(),
      ...common,
    };
  }
  return common;
};

export const initialValues = {
  splittedInvoices: [
    {
      project: {
        name: '',
      },
      lineItem: {
        accountCode: '',
        categoryName: '',
      },
      amount: '',
      key: nanoid(),
    },
  ],
  neededAmount: null,
  projectedPaidAt: null,
};

export const validationSchema = isAmountEdit => yup.object().shape(validationFields(isAmountEdit));

export const formPaths = {
  firstAmount: 'splittedInvoices[0].amount',
  neededAmount: 'neededAmount',
  projectedPaidAt: 'projectedPaidAt',
  getLineItemPath: index => `splittedInvoices.${index}.lineItem`,
  getProjectIdPath: index => `splittedInvoices.${index}.project.id`,
};
