import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  label: {
    padding: '8px 24px',
    border: '1px solid',
    borderRadius: 37,
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  activeLabel: {
    borderColor: theme.palette.colors.suluGreen,
    backgroundColor: theme.palette.colors.riceGreen,
    color: theme.palette.colors.kellyGreen,
  },
  prospectLabel: {
    borderColor: theme.palette.colors.salomieYellow,
    backgroundColor: theme.palette.colors.papayaYellow,
    color: theme.palette.colors.orange,
  },
  completedLabel: {
    borderColor: theme.palette.colors.nurseGray,
    backgroundColor: theme.palette.colors.blackSqueezeGray,
    color: theme.palette.colors.deltaGray,
  },
  count: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'center',
    width: 18,
    height: 18,
    borderRadius: '50%',
    display: 'inline-block',
    lineHeight: '18px',
    marginLeft: 3,
  },
  activeCount: {
    color: theme.palette.colors.riceGreen,
    backgroundColor: theme.palette.colors.kellyGreen,
  },
  prospectCount: {
    color: theme.palette.colors.papayaYellow,
    backgroundColor: theme.palette.colors.orange,
  },
  completedCount: {
    color: theme.palette.colors.blackSqueezeGray,
    backgroundColor: theme.palette.colors.deltaGray,
  },
  headerCell: {
    padding: '16px 0',
    '&:first-child': {
      minWidth: 232,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  activeSort: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));
