import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import DeleteButton from 'components/DeleteButton';
import MoneyTextField from 'components/MoneyTextField';

import useStyles from './useStyles';

const SubcontractorLineItems = props => {
  const { lineItem, index, formData, isDeletionDisabled, onLineItemRemove } = props;
  const { errors, handleChange, setFieldValue } = formData;
  const classes = useStyles();

  const getFieldName = name => `inboundLineItems[${index}][${name}]`;
  const getFieldHelperText = name => errors.inboundLineItems?.[index]?.[name];

  return (
    <Grid container>
      <Grid container item spacing={1} className={classes.root} xs={12}>
        <Grid item xs={12} lg={2}>
          <TextField
            name={getFieldName('accountCode')}
            label="ID"
            placeholder="Optional"
            value={lineItem.accountCode}
            onChange={handleChange}
            error={Boolean(getFieldHelperText('accountCode'))}
            helperText={getFieldHelperText('accountCode')}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <TextField
            name={getFieldName('categoryName')}
            label="Description"
            value={lineItem.categoryName}
            onChange={handleChange}
            error={Boolean(getFieldHelperText('categoryName'))}
            helperText={getFieldHelperText('categoryName')}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <MoneyTextField
            name={getFieldName('amount')}
            id={getFieldName('amount')}
            label="$ Amount"
            onChange={event => setFieldValue(getFieldName('amount'), Number.parseFloat(event.target.value))}
            value={lineItem.amount}
            error={Boolean(getFieldHelperText('amount'))}
            helperText={getFieldHelperText('amount')}
          />
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.deleteButtonContainer}>
          <DeleteButton
            isDisabled={isDeletionDisabled}
            className={classes.deleteButton}
            onClick={() => onLineItemRemove(lineItem)}
          />
        </div>
      </Grid>
    </Grid>
  );
};

SubcontractorLineItems.propTypes = {
  lineItem: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  formData: PropTypes.shape().isRequired,
  isDeletionDisabled: PropTypes.bool,
  onLineItemRemove: PropTypes.func.isRequired,
};

SubcontractorLineItems.defaultProps = {
  isDeletionDisabled: false,
};

export default SubcontractorLineItems;
