import PropTypes from 'prop-types';
import clsx from 'clsx';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const Menu = props => {
  const { step, project } = props;
  const classes = useStyles();

  const renderListItem = (order, label) => {
    if (order < step) {
      const rootClassNames = clsx(classes.listItem, classes.passedListItem);

      return (
        <ListItem classes={{ root: rootClassNames }}>
          <Icon width={15} height={15} name="containedCheckMark" className={classes.checkIcon} /> {label}
        </ListItem>
      );
    }

    if (order === step) {
      return (
        <ListItem classes={{ root: classes.listItem, selected: classes.selectedItem }} selected>
          <span className={classes.order}>{order}.</span>
          {label}
        </ListItem>
      );
    }

    return (
      <ListItem classes={{ root: classes.listItem }}>
        <span className={classes.order}>{order}.</span>
        {label}
      </ListItem>
    );
  };

  return (
    <List classes={{ root: classes.menuList }}>
      {renderListItem(1, 'Project Budget')}
      {renderListItem(2, 'Project Terms')}
      {renderListItem(3, 'Your Fee')}
      {project.sold && renderListItem(4, 'Team')}
    </List>
  );
};

Menu.propTypes = {
  project: PropTypes.shape().isRequired,
  step: PropTypes.number.isRequired,
};

export default Menu;
