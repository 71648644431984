import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  name: {
    fontSize: 15,
    fontWeight: 600,
    display: 'flex',
  },
  infoIcon: {
    fill: theme.palette.colors.nickelGray,
    verticalAlign: 'middle',
    marginTop: -4,
    marginLeft: 5,
  },
}));
