/* eslint-disable sonarjs/no-duplicate-string */
import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {},
  menu: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
  },
  menuItem: {
    display: {
      xs: "block",
      lg: "none",
    },
    marginBottom: "8px",
    padding: "16px 24px",
    color: "common.white",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
  },
  menuItemActive: {
    color: "colors.green",
    backgroundColor: "common.white",
    marginBottom: 0,
    borderRadius: "4px 4px 0 0",
  },
  menuItemCompleted: {
    color: "colors.green",
    backgroundColor: "common.white",
    borderRadius: "4px",
  },
  content: {
    marginBottom: {
      xs: "8px",
      lg: "0",
    },
  },
};

export default styles;
