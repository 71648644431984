import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader';
import MyContacts from 'containers/ContactsPage/MyContacts';
import { useBuildersActions } from 'store/BuildersSlice';
import { INVITATION_VARIANTS } from 'constants/invitationForm';
import useLoading from 'hooks/useLoading';

import useStyles from './useStyles';

const Builders = () => {
  const classes = useStyles();
  const shouldShowMyContacts = false;
  const { t } = useTranslation('subcontractorContacts', { keyPrefix: 'header' });

  const { loadConnections, loadPendingInvitations, loadBuilders, massInviteBuilders, resetBuilders } =
    useBuildersActions();
  const {
    connections: { data: connections, meta: connectionsMeta },
    pendingInvitations: { data: pendingInvitations, meta: pendingInvitationsMeta },
  } = useSelector(state => state.BuildersSlice);
  const { isPending: isConnectionsLoading, func: onConnectionsLoad } = useLoading(loadConnections, {
    isAbortable: true,
  });

  const { isPending: isPendingInvitationsLoading, func: onPendingInvitationsLoad } = useLoading(
    loadPendingInvitations,
    { isAbortable: true },
  );

  useEffect(() => {
    loadConnections();
    loadPendingInvitations();

    return () => {
      resetBuilders();
    };
  }, []);

  const reloadData = () => {
    resetBuilders();
    onPendingInvitationsLoad();
    onConnectionsLoad();
  };

  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <PageHeader
          text="Contacts"
          icon={<Icon className={classes.titleIcon} width={32} height={32} name="couple" />}
        />
        <div className={classes.tip}>{t('tip')}</div>
      </header>
      {shouldShowMyContacts && (
        <MyContacts
          connections={connections}
          isConnectionsLoading={isConnectionsLoading}
          connectionsMeta={connectionsMeta}
          pendingInvitations={pendingInvitations}
          isPendingInvitationsLoading={isPendingInvitationsLoading}
          pendingInvitationsMeta={pendingInvitationsMeta}
          toolBarButtonText="Invite New Contact"
          notificationInvitedName="General Contractor"
          invitationVariant={INVITATION_VARIANTS.builder}
          loadConnections={onConnectionsLoad}
          loadPendingInvitations={onPendingInvitationsLoad}
          loadContacts={loadBuilders}
          massInviteContacts={massInviteBuilders}
          resetContacts={reloadData}
        />
      )}
    </main>
  );
};

export default Builders;
