import { FC } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, TextField, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Div from "components/common/Div";
import PasswordTextField from "components/common/PasswordTextField";
import LinearProgress from "components/LinearProgress";

import { buildErrorMessage } from "libs/forms";

import { UserActivationFormData, validationSchema, defaultValues } from "store/api/user/schemas/activation";

import styles from "./styles";
import { IActivationFormProps } from "./types";

const ActivationForm: FC<IActivationFormProps> = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation(["components", "validation"]);

  const methods = useForm<UserActivationFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <Div sx={styles.activationFormContainer}>
      <LinearProgress isVisible={isSubmitting} />
      <Div sx={styles.formContainer}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Div sx={styles.header}>
              <Typography variant="h4">{t("components:activationForm.accountInfo")}</Typography>
              <Typography>{t("components:activationForm.canBeEdited")}</Typography>
            </Div>
            <Div sx={styles.form}>
              <TextField
                {...register("fullName")}
                label={t("components:activationForm.fullName")}
                autoComplete="username"
                sx={styles.textField}
                error={Boolean(errors.fullName)}
                helperText={buildErrorMessage(t, errors?.fullName)}
              />
              <PasswordTextField fieldName="password" label={t("activationForm.password")} />
            </Div>
            <Div sx={styles.actions}>
              <Button variant="contained" color="primary" disabled={isSubmitting} type="submit">
                {t("components:activationForm.continue")}
              </Button>
            </Div>
          </form>
        </FormProvider>
      </Div>
    </Div>
  );
};

export default ActivationForm;
