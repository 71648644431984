import { SxStyles } from "theme";

const styles: SxStyles = {
  appBar: {
    zIndex: {
      xs: 1301,
      sm: 1300,
    },
    display: {
      xs: "block",
      lg: "none",
    },
    height: {
      xs: 56,
      sm: "auto",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    marginRight: "16px",
  },
  user: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "1px",
      backgroundColor: "colors.nickelGray",
      height: {
        xs: "40px",
        sm: "48px",
      },
      left: "-16px",
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  userActive: {
    color: "colors.green",
  },
  expandIcon: {
    marginLeft: "8px",
  },
  logoutMenuItem: {
    color: "colors.green",
  },
};

export default styles;
