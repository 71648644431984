import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 6,
    padding: 0,
  },
  table: {
    margin: '8px 24px',
  },
  totalInformation: {
    backgroundColor: theme.palette.colors.alabaster,
    padding: '20px 24px',
    borderTop: [1, 'solid', theme.palette.colors.gainsboro],
  },
}));
