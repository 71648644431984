import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  row: {
    cursor: 'pointer',
  },
  nameCell: {
    padding: '13px 10px',
    fontSize: theme.typography.pxToRem(14),
    wordBreak: 'break-all',
  },
  actionCell: {
    width: 32,
    padding: 0,
  },
}));
