import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const CompleteProjectConfirmation = props => {
  const { isOpen, onClose, onProjectComplete } = props;
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <Icon className={classes.titleIcon} width={24} height={24} name="file" />
          <Typography className={classes.title} variant="h3">
            Are you sure? The project will be closed permanently.
          </Typography>
        </div>
        <div className={classes.actions}>
          <Button variant="contained" color="primary" className={classes.confirmButton} onClick={onProjectComplete}>
            Yes
          </Button>
          <Button variant="contained" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

CompleteProjectConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProjectComplete: PropTypes.func.isRequired,
};

export default CompleteProjectConfirmation;
