import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary,
    minWidth: 700,
  },
  invoiceContainer: {
    marginTop: theme.spacing(5),
  },
  invoiceResponseFormContainer: {
    marginTop: theme.spacing(5),
  },
  invoicePreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  downloadButtonContainer: {
    width: '100%',
    display: 'flex',
    margin: '32px 0',
    paddingRight: theme.spacing(4),
    justifyContent: 'flex-end',
  },
  preview: {
    marginBottom: '32px',
  },
  sectionTitle: {
    marginBottom: 12,
  },
}));
