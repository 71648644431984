import PropTypes from 'prop-types';
import React from 'react';
import ReactNumberFormat from 'react-number-format';

const NumberFormat = React.forwardRef((props, ref) => {
  const { onChange, ...restProps } = props;

  const handleValueChange = values => {
    onChange({
      target: {
        name: restProps.name,
        value: values.value,
      },
    });
  };

  return (
    <ReactNumberFormat
      {...restProps}
      getInputRef={ref}
      onValueChange={handleValueChange}
      thousandSeparator
      isNumericString
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      prefix="$"
    />
  );
});

NumberFormat.propTypes = {
  // inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  onChange: PropTypes.func.isRequired,
};

export default NumberFormat;
