import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.colors.white,
    boxShadow: '0px 1px 2px rgba(0, 48, 32, 0.12), 0px 0px 1px rgba(0, 48, 32, 0.3)',
    borderRadius: '0px 0px 8px 8px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '48px 64px',
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: '8px',
    },
  },
}));
