import PropTypes from 'prop-types';

import useLoading from 'hooks/useLoading';
import CompanyPresenter from 'presenters/CompanyPresenter';
import Autocomplete from 'components/Autocomplete';
import { INVOICE_FORM_VARIANTS } from 'constants/invoices';

import useStyles from './useStyles';

const getAutocompleteProps = (variant, formData, setFieldValue) => {
  const { values, errors, touched, handleChange } = formData;

  switch (variant) {
    case INVOICE_FORM_VARIANTS.builder:
      return {
        name: 'subcontractorEmail',
        id: 'subcontractorEmail',
        label: 'Subcontractor/Vendor Name',
        inputValue: values.subcontractorEmail,
        value: values.subcontractorEmail,
        fieldName: 'subcontractorEmail',
        error: touched.subcontractorEmail && Boolean(errors.subcontractorEmail),
        helperText: touched.subcontractorEmail && errors.subcontractorEmail,
        onFieldValueSet: (_, option) => {
          setFieldValue('subcontractorEmail', option);
        },
      };
    case INVOICE_FORM_VARIANTS.subcontractor:
      return {
        name: 'builderEmail',
        id: 'builderEmail',
        label: 'General Contractor',
        inputValue: values.builderEmail,
        value: values.builderEmail,
        fieldName: 'builderEmail',
        error: touched.builderEmail && Boolean(errors.builderEmail),
        helperText: touched.builderEmail && errors.builderEmail,
        isOnFieldValueSetWrap: false,
        onChange: e => {
          setFieldValue('builderName', null);
          handleChange(e);
        },
        onFieldValueSet: (_, option) => {
          setFieldValue('builderName', option.name);
          setFieldValue('builderEmail', option.companyOwner?.email);
        },
      };
    default:
      return null;
  }
};

const InvoiceAddressee = props => {
  const { companies, onCompaniesLoad, variant, isAddressDisabled, formData } = props;
  const { setFieldValue, handleChange } = formData;
  const classes = useStyles();
  const { func: sendOnCompaniesLoad, isPending } = useLoading(onCompaniesLoad);

  const autocompleteProps = getAutocompleteProps(variant, formData, setFieldValue);

  if (!isAddressDisabled) {
    return (
      <Autocomplete
        classes={{ root: classes.input }}
        options={companies}
        isLoading={isPending}
        disabled={isAddressDisabled}
        onLoad={sendOnCompaniesLoad}
        onChange={handleChange}
        {...autocompleteProps}
      />
    );
  }

  return null;
};

InvoiceAddressee.propTypes = {
  companies: PropTypes.arrayOf(CompanyPresenter.shape()).isRequired,
  formData: PropTypes.shape().isRequired,
  variant: PropTypes.oneOf(Object.values(INVOICE_FORM_VARIANTS)).isRequired,
  isAddressDisabled: PropTypes.bool.isRequired,
  onCompaniesLoad: PropTypes.func.isRequired,
};

export default InvoiceAddressee;
