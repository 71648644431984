import { PROJECT_STATES } from 'presenters/ProjectPresenter';

export const MAX_COLS_COUNT = 8;

export const headers = projectState => {
  const isCompleted = projectState === PROJECT_STATES.completed;

  return [
    {
      text: 'Builder',
      width: '8%',
    },
    {
      text: 'Start Date',
      width: '8%',
    },
    {
      text: 'Duration',
      width: '8%',
    },
    {
      text: 'End Date',
      width: '8%',
    },
    {
      text: 'Sales Price',
      width: '8%',
      align: 'right',
    },
    {
      text: 'COGS',
      width: '8%',
      align: 'right',
    },
    {
      text: 'Builder Fee',
      width: '8%',
      align: 'right',
    },
    {
      text: 'Profit',
      width: '8%',
      align: 'right',
    },
    !isCompleted && {
      text: 'Status',
      width: '10%',
      align: 'right',
    },
  ].filter(Boolean);
};
