import { memo } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

import useStyles from './useStyles';

const TableLoader = props => {
  const { colSpan } = props;

  const classes = useStyles();

  return (
    <TableRow classes={{ root: classes.row }}>
      <TableCell classes={{ root: classes.cell }} colSpan={colSpan} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};

TableLoader.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

export default memo(TableLoader);
