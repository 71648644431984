import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import humps from 'humps';

import RegularPaymentPresenter from 'presenters/RegularPaymentPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import UserPresenter from 'presenters/UserPresenter';
import Presenter from 'utils/PropTypesPresenter';
import { formatUSD } from 'utils/numberUtils';
import { format } from 'utils/dateUtils';

export const PROJECT_PRICINGS = {
  fixedPrice: 'fixed_price',
  costPlus: 'cost_plus',
};

export const PROJECT_STATES = {
  active: 'active',
  draft: 'draft',
  completed: 'completed',
};

export const PROJECT_APPROVAL_STATES = {
  pending: 'pending',
  orderChanged: 'order_changed',
  approved: 'approved',
  rejected: 'rejected',
};

const PROJECT_APPROVAL_STATES_LABELS = {
  [PROJECT_APPROVAL_STATES.pending]: 'Pending',
  [PROJECT_APPROVAL_STATES.orderChanged]: 'Order Changed',
  [PROJECT_APPROVAL_STATES.approved]: 'Approved',
  [PROJECT_APPROVAL_STATES.rejected]: 'Rejected',
};

export const PROJECT_EVENTS = {
  complete: 'complete',
  activate: 'activate',
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    pricing: PropTypes.string,
    state: PropTypes.string,
    approvalState: PropTypes.string,
    deposit: PropTypes.bool,
    thirdPartyExpenses: PropTypes.string,
    overheadExpenses: PropTypes.string,
    contractValue: PropTypes.string,
    percentMarkup: PropTypes.number,
    probability: PropTypes.number,
    duration: PropTypes.number,
    startedAt: PropTypes.string,
    endedAt: PropTypes.string,
    profit: PropTypes.string,
    realizedRevenue: PropTypes.string,
    regularPayment: RegularPaymentPresenter.shape(),
    users: PropTypes.arrayOf(UserPresenter.shape()),
    customers: PropTypes.arrayOf(UserPresenter.shape()),
    projectLoan: PropTypes.string,
    company: CompanyPresenter.shape(),
    totalRevenue: PropTypes.string,
  },
  {
    isCostPlus(project) {
      return this.pricing(project) === PROJECT_PRICINGS.costPlus;
    },
    isFixedPrice(project) {
      return this.pricing(project) === PROJECT_PRICINGS.fixedPrice;
    },
    isDraft(project) {
      return this.state(project) === PROJECT_STATES.draft;
    },
    isApproved(project) {
      return this.approvalState(project) === PROJECT_APPROVAL_STATES.approved;
    },
    approvalStateLabel(project) {
      return PROJECT_APPROVAL_STATES_LABELS[this.approvalState(project)];
    },
    camelizedApprovalState(project) {
      return humps.camelize(this.approvalState(project));
    },
    isCompleted(project) {
      return this.state(project) === PROJECT_STATES.completed;
    },
    feeToDate(project) {
      const totalPaid = RegularPaymentPresenter.totalPaid(this.regularPayment(project)) || 0;

      return Number(totalPaid);
    },
    formattedUSDFeeToDate(project, maximumFractionDigits = 2) {
      return formatUSD(this.feeToDate(project), maximumFractionDigits);
    },
    feeBalance(project) {
      return Number(this.overheadExpenses(project)) - this.feeToDate(project);
    },
    formattedUSDFeeBalance(project, maximumFractionDigits = 2) {
      return formatUSD(this.feeBalance(project), maximumFractionDigits);
    },
    targetMargin(project) {
      const overhead = Number(this.overheadExpenses(project));
      const profit = Number(this.profit(project));
      const contractValue = Number(this.contractValue(project));

      const targetMargin = ((overhead + profit) / contractValue) * 100;

      return Number.isSafeInteger(targetMargin) ? targetMargin : targetMargin.toFixed(2);
    },
    currentMargin(project) {
      const realizedRevenue = Number(this.realizedRevenue(project));
      const thirdPartyExpenses = Number(this.thirdPartyExpenses(project));

      if (realizedRevenue === 0) {
        return null;
      }

      const currentMargin = ((realizedRevenue - thirdPartyExpenses) / realizedRevenue) * 100;

      return Number.isSafeInteger(currentMargin) ? currentMargin : currentMargin.toFixed(2);
    },
    variance(project) {
      const currentMargin = this.currentMargin(project);
      if (isNil(currentMargin)) {
        return null;
      }

      return currentMargin - this.targetMargin(project);
    },
    percentageOfProfit(project) {
      const contractValue = Number(this.contractValue(project));
      const profit = Number(this.profit(project));
      const percent = (profit / contractValue) * 100;
      return `${Math.round(percent)}%`;
    },
    formattedStartedAt(project) {
      return format(this.startedAt(project));
    },
    formattedEndAt(project) {
      return format(this.endedAt(project));
    },
    formattedUSDContractValue(project, maximumFractionDigits = 2) {
      return formatUSD(this.contractValue(project), maximumFractionDigits);
    },
    formattedUSDThirdPartyExpenses(project, maximumFractionDigits = 2) {
      return formatUSD(this.thirdPartyExpenses(project), maximumFractionDigits);
    },
    formattedUSDOverheadExpenses(project, maximumFractionDigits = 2) {
      return formatUSD(this.overheadExpenses(project), maximumFractionDigits);
    },
    formattedUSDProfit(project, maximumFractionDigits = 2) {
      return formatUSD(this.profit(project), maximumFractionDigits);
    },
    formattedUSDProjectLoan(project, maximumFractionDigits = 2) {
      return formatUSD(this.projectLoan(project), maximumFractionDigits);
    },
    formattedUSDRealizedRevenue(project, maximumFractionDigits = 2) {
      return formatUSD(this.realizedRevenue(project), maximumFractionDigits);
    },
    companyName(project) {
      const company = this.company(project);
      return CompanyPresenter.name(company);
    },
    formattedUSDTotalRevenue(project, maximumFractionDigits = 2) {
      return formatUSD(this.totalRevenue(project), maximumFractionDigits) || 'No information';
    },
  },
);
