import { apiRoutes } from 'routes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  index(params = {}) {
    const path = apiRoutes.siteApiV1BuilderCompanyIncomingConnectionsPath();

    return FetchHelpers.get(path, params);
  },
  accept(id) {
    const path = apiRoutes.acceptSiteApiV1BuilderCompanyIncomingConnectionPath(id);

    return FetchHelpers.put(path);
  },
  reject(id) {
    const path = apiRoutes.rejectSiteApiV1BuilderCompanyIncomingConnectionPath(id);

    return FetchHelpers.put(path);
  },
};
