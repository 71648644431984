import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.colors.gunsmokeGray,
  },
}));
