import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import BuildersRepository from 'repositories/admin/BuildersRepository';

const MAX_PER_PAGE = 10;

const initialState = {
  builders: {
    users: [],
    nextPage: null,
  },
};

const projectsSlice = createSlice({
  name: 'adminBuilders',
  initialState,
  reducers: {
    loadBuildersSuccess(state, { payload }) {
      state.builders.users = concat(state.builders.users, payload.users);
      state.builders.nextPage = payload.meta.nextPage;
    },
    resetBuilders() {
      return initialState;
    },
  },
});

export const { actions } = projectsSlice;
export default projectsSlice.reducer;

export const useAdminBuildersActions = () => {
  const dispatch = useDispatch();

  const loadBuilders = async (page = 1) => {
    const { data } = await BuildersRepository.index({
      q: { s: ['companies.id asc', 'full_name asc'] },
      perPage: MAX_PER_PAGE,
      page,
    });
    dispatch(actions.loadBuildersSuccess(data));

    return data;
  };

  const resetBuilders = () => {
    dispatch(actions.resetBuilders());
  };

  return {
    loadBuilders,
    resetBuilders,
  };
};

export const selectors = {
  builders: state => state.AdminBuildersSlice.builders,
};
