import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import ConnectionsRepository from 'repositories/builder/company/ConnectionsRepository';
import IncomingConnectionsRepository from 'repositories/builder/company/IncomingConnectionsRepository';
import UserInvitationsRepository from 'repositories/builder/company/UserInvitationsRepository';
import SubcontractorsRepository from 'repositories/builder/SubcontractorsRepository';
import CompanySubcontractorsRepository from 'repositories/builder/company/SubcontractorsRepository';
import { STATES as USER_INVITATION_STATES } from 'presenters/UserInvitationPresenter';

const initialState = {
  connections: {
    data: [],
    meta: {},
  },
  incomingConnections: {
    data: [],
    meta: {},
  },
  pendingInvitations: {
    data: [],
    meta: {},
  },
};

const subcontractorsSlice = createSlice({
  name: 'subcontractors',
  initialState,
  reducers: {
    loadConnectionsSuccess(state, { payload }) {
      state.connections.data = concat(state.connections.data, payload.companyConnections);
      state.connections.meta = payload.meta;
    },
    loadIncomingConnectionsSuccess(state, { payload }) {
      state.incomingConnections.data = concat(state.incomingConnections.data, payload.companyConnections);
      state.incomingConnections.meta = payload.meta;
    },
    loadPendingInvitationsSuccess(state, { payload }) {
      state.pendingInvitations.data = concat(state.pendingInvitations.data, payload.userInvitations);
      state.pendingInvitations.meta = payload.meta;
    },
    resetSubcontractors() {
      return initialState;
    },
  },
});

export const { actions } = subcontractorsSlice;
export default subcontractorsSlice.reducer;

export const useSubcontractorsActions = () => {
  const dispatch = useDispatch();

  const loadConnections = async (page = 1) => {
    const { data } = await ConnectionsRepository.index({ page });

    dispatch(actions.loadConnectionsSuccess(data));

    return Promise.resolve(data);
  };

  const loadIncomingConnections = async (page = 1) => {
    const { data } = await IncomingConnectionsRepository.index({ page });

    dispatch(actions.loadIncomingConnectionsSuccess(data));
  };

  const acceptIncomingConnection = connectionId => {
    return IncomingConnectionsRepository.accept(connectionId);
  };

  const rejectIncomingConnection = connectionId => {
    return IncomingConnectionsRepository.reject(connectionId);
  };

  const loadPendingInvitations = async (page = 1) => {
    const { data } = await UserInvitationsRepository.index({ q: { stateEq: USER_INVITATION_STATES.pending }, page });
    dispatch(actions.loadPendingInvitationsSuccess(data));

    return Promise.resolve(data);
  };

  const loadSubcontractors = async nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont => {
    const { data } = await SubcontractorsRepository.index({
      q: { nameOrCompanyOwnerFullNameOrCompanyOwnerEmailCont },
    });

    return Promise.resolve(data);
  };

  const massInviteSubcontractors = params => {
    return CompanySubcontractorsRepository.massInvite({ subcontractors: params });
  };

  const reinviteSubcontractor = async email => {
    return CompanySubcontractorsRepository.reinvite(email);
  };

  const resetSubcontractors = () => {
    dispatch(actions.resetSubcontractors());
  };

  return {
    loadConnections,
    loadIncomingConnections,
    acceptIncomingConnection,
    rejectIncomingConnection,
    loadPendingInvitations,
    loadSubcontractors,
    massInviteSubcontractors,
    resetSubcontractors,
    reinviteSubcontractor,
  };
};
