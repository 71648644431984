import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
  headerDescription: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.colors.gunsmokeGray,
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.colors.gunsmokeGray,
  },
  paper: {
    minHeight: 720,
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  total: {
    fontSize: theme.typography.pxToRem(15),
    marginTop: theme.spacing(1),
  },
  tableContainer: {
    marginTop: theme.spacing(5),
  },
}));
