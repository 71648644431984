import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import baseTheme from "theme/baseTheme";

interface IMuiMenuItemType {
  defaultProps: ComponentsProps["MuiMenuItem"];
  styleOverrides: ComponentsOverrides["MuiMenuItem"];
}

const MuiMenuItem: IMuiMenuItemType = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: baseTheme.palette.colors.lilyWhiteGray,
      },
    },
  },
};

export default MuiMenuItem;
