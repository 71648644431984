import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const ACCEPT_TYPE = 'application/pdf';
const MAX_SIZE = 10485760; // 10 Megabytes

const Dropzone = props => {
  const { isDisabled, description, onDrop } = props;
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogOpen = () => setIsDialogOpen(true);

  const handleDialogClose = () => setIsDialogOpen(false);
  const handleCancel = () => {
    handleDialogClose();
  };

  const handleDrop = ([file]) => {
    if (!file) {
      handleDialogOpen();
      return;
    }
    handleCancel();
    onDrop([file]);
  };

  const dropZoneSettings = {
    onDrop: handleDrop,
    disabled: isDisabled,
    multiple: false,
    accept: ACCEPT_TYPE,
    maxSize: MAX_SIZE,
  };

  const { getRootProps: getDropZoneRootProps, getInputProps: getDropZoneInputProps } = useDropzone(dropZoneSettings);
  const { getInputProps: getButtonInputProps } = useDropzone(dropZoneSettings);

  return (
    <>
      <div>
        <div className={clsx(classes.root, isDisabled && classes.disabled)} {...getDropZoneRootProps()}>
          <input {...getDropZoneInputProps()} />
          <div className={classes.labelContainer}>
            <Icon name="picture" width={17} height={14} className={classes.icon} />
            <span className={classes.label}>Click here to attach a PDF</span>
          </div>
        </div>
        <Typography className={classes.descriptionText}>{description}</Typography>
      </div>
      <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="lg">
        <div className={classes.dialogContainer}>
          <div className={classes.dialogTitleContainer}>
            <Icon name="gear" className={classes.errorIcon} />
            <Typography variant="h3">File Upload Error</Typography>
          </div>
          <DialogContent className={classes.dialogContent}>
            <Typography>Upload failed. You can upload a file not larger than 10 MB.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained">
              Cancel
            </Button>
            <label htmlFor="attachment-button-file">
              <input id="attachment-button-file" {...getButtonInputProps()} />
              <Button variant="contained" color="primary" component="span">
                Upload another file
              </Button>
            </label>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

Dropzone.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default Dropzone;
