import Typography from '@mui/material/Typography';

import TotalInformationPresenter from 'presenters/TotalInformationPresenter';

import useStyles from './useStyles';

const ProjectsTotalInformation = props => {
  const { totalInformation } = props;
  const classes = useStyles();

  return (
    <div className={classes.totalInformationContainer}>
      <div className={classes.textContainer}>
        <Typography className={classes.totalText}>Total</Typography>
      </div>
      <div className={classes.totalSalesPrice}>
        <Typography className={classes.totalText}>
          {TotalInformationPresenter.formattedUSDTotalContractValue(totalInformation)}
        </Typography>
      </div>
      <div className={classes.totalGogs}>
        <Typography className={classes.totalText}>
          {TotalInformationPresenter.formattedUSDTotalThirdPartyExpenses(totalInformation)}
        </Typography>
      </div>
      <div className={classes.totalBuilderFee}>
        <Typography className={classes.totalText}>
          {TotalInformationPresenter.formattedUSDTotalOverheadExpenses(totalInformation)}
        </Typography>
      </div>
      <div className={classes.totalProfit}>
        <Typography className={classes.totalText}>
          {TotalInformationPresenter.formattedUSDTotalProfit(totalInformation)}
        </Typography>
      </div>
    </div>
  );
};

ProjectsTotalInformation.propTypes = {
  totalInformation: TotalInformationPresenter.shape().isRequired,
};

export default ProjectsTotalInformation;
