import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(5),
  },
  invoicesContainer: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(3, 3),
  },
  titleIcon: {
    fill: theme.palette.colors.green,
  },
}));
