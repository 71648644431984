import { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Icon from 'components/Icon';
import Link from 'components/Link';
import AppRoutes from 'routes/AppRoutes';

import useStyles from './useStyles';

const Confirmation = props => {
  const { email, onEmailResend } = props;
  const [isEmailResentAvailable, setIsEmailResentAvailable] = useState(true);
  const classes = useStyles();

  const handleEmailResend = () => {
    onEmailResend({ passwordReset: { email } });
    setIsEmailResentAvailable(false);

    setTimeout(() => {
      setIsEmailResentAvailable(true);
    }, 5000);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">We sent a recovery link to your email address:</Typography>
      <div className={classes.emailContainer}>
        <div className={classes.checkIconContainer}>
          <Icon className={classes.checkIcon} name="checkMark" width={12} height={13} />
        </div>
        <div className={classes.email}>{email}</div>
      </div>
      <div className={classes.actions}>
        <div>
          <Link to={AppRoutes.signInPath()}>Return to Sign In</Link>
        </div>
        <span className={classes.separator}>•</span>
        <div>
          {isEmailResentAvailable ? (
            <Button onClick={handleEmailResend} color="primary" className={classes.button}>
              Resend recovery link
            </Button>
          ) : (
            <div className={classes.emailRepeatStatus}>A recovery link was resent.</div>
          )}
        </div>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailResend: PropTypes.func.isRequired,
};

export default Confirmation;
