import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { useSnackbar } from 'notistack';

import { FETCH_STATUSES } from 'constants/fetch';
import { useUserLayoutActions } from 'store/UserLayoutSlice';

import useFetchStatus from './useFetchStatus';

const CANCEL_ERROR_TEXT = 'Cancelling in cleanup';

const useLoading = (
  func,
  {
    isShowSuccessNotification,
    successMessage = '',
    isShowFailedNotification,
    failedMessage = '',
    isShowLoading,
    isAbortable = false,
  } = {},
) => {
  const [status, setStatus] = useState(FETCH_STATUSES.idle);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setIsLoading } = useUserLayoutActions();
  const isUnmounted = useRef(false);
  const cancelTokenSource = useRef();

  const callAfterCheckMounted = setMountedState => {
    if (isUnmounted.current) {
      return;
    }
    setMountedState();
  };

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(CANCEL_ERROR_TEXT);
      }
    };
  }, []);

  const reset = () => {
    setStatus(FETCH_STATUSES.idle);
    setError(null);
  };

  const funcWithLoading = async (...params) => {
    if (isShowLoading) {
      callAfterCheckMounted(() => setIsLoading(true));
    }

    callAfterCheckMounted(() => {
      setError(null);
      setStatus(FETCH_STATUSES.pending);
    });

    cancelTokenSource.current = axios.CancelToken.source();
    try {
      const result = isAbortable ? await func(...params, {}, cancelTokenSource.current.token) : await func(...params);
      if (isShowSuccessNotification) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      callAfterCheckMounted(() => {
        setStatus(FETCH_STATUSES.fulfilled);
      });
      return result;
    } catch (err) {
      if (isShowFailedNotification) {
        enqueueSnackbar(failedMessage, { variant: 'error' });
      }
      callAfterCheckMounted(() => {
        setError(err);
        setStatus(FETCH_STATUSES.failed);
      });
      throw err;
    } finally {
      if (isShowLoading) {
        callAfterCheckMounted(() => setIsLoading(false));
      }
    }
  };

  const { isIdle, isPending, isFulfilled, isFailed } = useFetchStatus(status);

  return {
    func: funcWithLoading,
    status,
    error,
    reset,
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isNotFinished: isIdle || isPending,
    isFinished: isFulfilled || isFailed,
  };
};
export default useLoading;
