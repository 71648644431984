import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import Icon from "components/Icon";

import baseTheme from "theme/baseTheme";

interface IMuiSelectType {
  defaultProps: ComponentsProps["MuiSelect"];
  styleOverrides: ComponentsOverrides["MuiSelect"];
}

const MuiSelect: IMuiSelectType = {
  defaultProps: {
    // disableUnderline: true,
    IconComponent: (props) => <Icon name="arrow" {...props} />,
    MenuProps: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      // getContentAnchorEl: null,
    },
  },
  styleOverrides: {
    select: {
      width: "100%",
      padding: "10px 16px",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "20px",
      borderRadius: 3,
      backgroundColor: baseTheme.palette.colors.blackSqueezeGray,
      "&.MuiMenuItem-root": {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 600,
      },
    },
    icon: {
      fill: baseTheme.palette.colors.hunterGreen,
      position: "absolute",
      width: 23,
      height: 23,
      pointerEvents: "none",
      right: 7,
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
};

export default MuiSelect;
