export default {
  title: "Invoices ",
  createNewInvoice: "Create New Invoice",
  table: {
    headers: {
      builder: "Builder",
      project: "Project",
      invoiceDescription: "Invoice description",
      status: "Status",
      amount: "Amount",
      paymentDate: "Payment Date",
      expectedPaymentDate: "Expected Payment Date",
      acceptedAmount: "Accepted Amount",
      invoiceAmount: "Invoice Amount",
    },
  },
};
