import { useEffect, useMemo, useState } from "react";

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";

import Div from "components/common/Div";
import InvoiceAssignedProjects from "components/common/InvoiceAssignedProjects";
import InvoiceBuilderNameCard from "components/common/InvoiceBuilderNameCard";
import InvoiceLineItemNames from "components/common/InvoiceLineItemsNames";
import NoWrap from "components/common/NoWrap";
import TableLoadMore from "components/common/TableLoadMore";
import useAdminInvoicesCountByStatus from "components/pages/admin/Invoices/useAdminInvoicesCountByStatus";

import { InvoiceKind, InvoiceState } from "domain/invoice/enums";
import * as InvoicePresenter from "domain/invoice/presenter";

import { useGetAdminInvoicesQuery } from "store/api/admin/invoice/apiSlice";
import { ADMIN_INVOICES_PER_PAGE } from "store/api/admin/invoice/service";
import { AdminInvoiceListItem } from "store/api/admin/invoice/types";
import { useGetCurrentUserQuery } from "store/api/user/apiSlice";

import { getSortingParameter } from "utils/tableSorting";

const columnHelper = createColumnHelper<AdminInvoiceListItem>();

const ClosedInvoices = () => {
  const { t } = useTranslation(["common", "adminInvoices"]);
  const { data: currentUser } = useGetCurrentUserQuery();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [adminInvoices, setAdminInvoices] = useState<Array<AdminInvoiceListItem>>([]);

  const queryParameters = useMemo(() => {
    return {
      q: {
        stateIn: [InvoiceState.canceled, InvoiceState.paid, InvoiceState.rejected],
        kindIn: [InvoiceKind.selfPaid],
        s: getSortingParameter(sorting),
      },
      perPage: ADMIN_INVOICES_PER_PAGE,
      page: currentPage,
    };
  }, [currentPage, sorting]);

  const { currentData: closedData, isFetching } = useGetAdminInvoicesQuery(queryParameters, {
    refetchOnMountOrArgChange: true,
  });

  const { changeStatusCount } = useAdminInvoicesCountByStatus();

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (closedData?.invoices && queryParameters.page === 1) {
      setAdminInvoices(closedData.invoices);
    }
    if (closedData?.invoices && queryParameters.page > 1) {
      setAdminInvoices((invoices) => [...invoices, ...closedData.invoices]);
    }
  }, [queryParameters, closedData?.invoices]);

  useEffect(() => {
    if (closedData?.meta) {
      changeStatusCount("closed", closedData?.meta.totalCount);
    }
  }, [closedData]);

  const history = useHistory();

  const columns = useMemo(
    () => [
      columnHelper.accessor("kind", {
        header: t("adminInvoices:table.headers.subcontractor") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceBuilderNameCard invoice={info.row.original} />,
      }),
      columnHelper.accessor("projectName", {
        header: t("adminInvoices:table.headers.assignedProjects") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceAssignedProjects invoice={info.row.original} />,
      }),
      columnHelper.accessor("lineItems", {
        header: t("adminInvoices:table.headers.lineItems") ?? "",
        enableSorting: false,
        cell: (info) => <InvoiceLineItemNames invoice={info.row.original} />,
      }),
      columnHelper.accessor("state", {
        header: t("adminInvoices:table.headers.status") ?? "",
        enableSorting: true,
        cell: (info) => currentUser && <NoWrap>{InvoicePresenter.status(info.row.original, currentUser, t)}</NoWrap>,
      }),
      columnHelper.accessor("totalAmount", {
        header: t("adminInvoices:table.headers.amount") ?? "",
        enableSorting: true,
        cell: (info) => <NoWrap>{InvoicePresenter.formattedUSDTotalAmount(info.row.original)}</NoWrap>,
        meta: { align: "right", width: "100px" },
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: adminInvoices,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    manualSorting: true,
    enableMultiSort: false,
  });

  const handleLoadMore = () => {
    setCurrentPage((previousPage) => previousPage + 1);
  };

  const handleRowClick = (invoice: AdminInvoiceListItem) => {
    history.push(AppRoutes.adminInvoicePath(invoice.id));
  };

  return (
    <Div>
      <TableLoadMore<AdminInvoiceListItem>
        table={table}
        onRowClick={handleRowClick}
        onLoadMore={handleLoadMore}
        loading={isFetching}
        meta={closedData?.meta}
      />
    </Div>
  );
};

export default ClosedInvoices;
