import { SxStyles } from "theme";

const styles: SxStyles = {
  tabList: {
    boxShadow: (theme) => `inset 0px -1px 0px ${theme.palette.colors.blackHaze}`,
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  tab: {
    padding: "16px 24px",
  },
};

export default styles;
