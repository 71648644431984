import { SxStyles } from "theme";

const styles: SxStyles = {
  activationFormContainer: {
    width: "100%",
  },
  formContainer: {
    padding: "48px 64px",
  },
  header: {
    marginBottom: "24px",
  },
  form: {
    marginBottom: "32px",
  },
  textField: {
    marginBottom: "16px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default styles;
