import * as yup from 'yup';

import { REQUIRED, NUMBER, POSITIVE } from 'forms/errorsText';
import { PROJECT_PRICINGS } from 'presenters/ProjectPresenter';

const validationFields = {
  name: yup.string().required(REQUIRED),
  sold: yup.bool().when('deposit', {
    is: true,
    then: schema => schema.required(REQUIRED),
    otherwise: schema =>
      schema.when('pricing', {
        is: PROJECT_PRICINGS.costPlus,
        then: internalSchema => internalSchema.required(REQUIRED),
        otherwise: internalSchema => internalSchema.oneOf([false]).required(REQUIRED),
      }),
  }),
  pricing: yup.string().oneOf(Object.values(PROJECT_PRICINGS)),
  contractValue: yup.number().when('pricing', {
    is: PROJECT_PRICINGS.costPlus,
    then: schema => schema.nullable(),
    otherwise: schema => schema.typeError(NUMBER).positive(POSITIVE).required(REQUIRED),
  }),
  deposit: yup.bool().when('pricing', {
    is: PROJECT_PRICINGS.fixedPrice,
    then: schema => schema.required(REQUIRED),
    otherwise: schema => schema.oneOf([false]),
  }),
};

export const initialValues = {
  name: '',
  sold: true,
  pricing: PROJECT_PRICINGS.fixedPrice,
  contractValue: '',
  deposit: true,
};

export const validationSchema = yup.object().shape(validationFields);
