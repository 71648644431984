import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useLoading from 'hooks/useLoading';
import InvoiceForm from 'components/InvoiceForm';
import AppRoutes from 'routes/AppRoutes';
import { useSubcontractorInvoiceCreateActions } from 'store/SubcontractorInvoiceCreateSlice';
import { useInvoiceActions } from 'store/InvoiceSlice';
import { apiInvoiceToForm } from 'forms/Subcontractor/InvoiceForm';

const InvoiceUpdate = () => {
  const { loadBuilders, updateInvoice } = useSubcontractorInvoiceCreateActions();
  const { loadInvoice, resetInvoice } = useInvoiceActions();
  const history = useHistory();
  const routeParams = useParams();
  const invoiceId = Number(routeParams.id);
  const { data: invoice } = useSelector(state => state.InvoiceSlice.invoice);
  const { func: sendLoadInvoice } = useLoading(loadInvoice, { isShowLoading: true, isAbortable: true });

  useEffect(() => {
    sendLoadInvoice(invoiceId);
    return resetInvoice;
  }, [invoiceId]);

  const handleInvoiceConfirm = async invoiceToUpdate => {
    await updateInvoice({ id: invoiceId, params: invoiceToUpdate });
    history.push(AppRoutes.invoicesPath());
  };

  const initialValues = apiInvoiceToForm(invoice);

  return (
    <InvoiceForm
      variant="subcontractor"
      title="Update invoice"
      initialValues={initialValues}
      onCompaniesLoad={loadBuilders}
      onConfirm={handleInvoiceConfirm}
    />
  );
};

export default InvoiceUpdate;
