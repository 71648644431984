import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  soldDialogContainer: {
    maxWidth: 600,
    padding: theme.spacing(4, 3),
  },
  soldTitle: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  dialogTitleIcon: {
    marginRight: theme.spacing(1),
    marginTop: 3,
    minWidth: 20,
    fill: theme.palette.colors.green,
  },
  soldButtonContainer: {
    textAlign: 'right',
  },
  inviteButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cancelButton: {
    marginRight: 12,
  },
  emailField: {
    marginBottom: theme.spacing(4),
  },
}));
