import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 80,
    marginBottom: 40,
    maxWidth: 320,
    fontSize: 14,
    lineHeight: '16px',
  },
  emailContainer: {
    marginTop: 16,
    marginLeft: 3,
    display: 'flex',
  },
  checkIconContainer: {
    marginTop: 3,
    marginRight: 9,
  },
  checkIcon: {
    fill: theme.palette.colors.green,
  },
  email: {
    fontSize: 15,
    lineHeight: '20px',
  },
  emailRepeatStatus: {
    color: theme.palette.colors.deltaGray,
  },
  actions: {
    display: 'flex',
    marginTop: 48,
    alignItems: 'center',
  },
  separator: {
    margin: '0 16px',
    fontSize: 9,
    color: theme.palette.colors.nurseGray,
  },
  button: {
    padding: 0,
    transform: 'translateY(-1px)',
  },
}));
