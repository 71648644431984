import * as InvoiceService from 'domain/invoice/service';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useInvoiceActions } from 'store/InvoiceSlice';
import InvoicePresenter from 'presenters/InvoicePresenter';
import InvoiceCard from 'components/InvoiceCard';
import useLoading from 'hooks/useLoading';

import InvoicePageHeader from './components/InvoicePageHeader';
import PaymentTerms from './components/PaymentTerms';

import useStyles from './useStyles';

const Invoice = () => {
  const classes = useStyles();
  const { loadInvoice, resetInvoice } = useInvoiceActions();
  const routeParams = useParams();
  const invoiceId = Number(routeParams.id);
  const { data: invoice } = useSelector(state => state.InvoiceSlice.invoice);
  const { func: sendLoadInvoice, isNotFinished: isInvoiceLoading } = useLoading(loadInvoice);

  useEffect(() => {
    sendLoadInvoice(invoiceId);
    return resetInvoice;
  }, [invoiceId]);

  const handleInvoiceSubmit = () => sendLoadInvoice(invoiceId);

  return (
    <main className={classes.root}>
      <InvoicePageHeader invoiceName={InvoicePresenter.name(invoice)} />
      <div className={classes.invoiceContainer}>
        <InvoiceCard
          variant="subcontractor"
          invoice={invoice}
          isLoading={isInvoiceLoading}
          contactsTitle="Builder contacts"
        />
      </div>
      {InvoiceService.isPending(invoice) && <PaymentTerms invoice={invoice} onInvoiceSubmit={handleInvoiceSubmit} />}
    </main>
  );
};

export default Invoice;
