import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  topMenu: {
    width: 'inherit',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 21,
    boxShadow: 'inset 0px -1px 0px rgba(32, 36, 20, 0.1)',
  },
  logo: {
    fill: 'green',
  },
  navigation: {
    marginTop: 25,
  },
  userPanel: {
    padding: '7px 0',
    boxShadow: 'inset 0px 1px 0px rgba(32, 36, 20, 0.1)',
    display: 'flex',
  },
  avatar: {
    marginTop: -5,
    fill: theme.palette.colors.heavyMetalGray,
  },
  menuButton: {
    minWidth: 'unset',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
    padding: 11,
    paddingTop: 20,
  },
  userNameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  userName: {
    color: theme.palette.colors.heavyMetalGray,
    marginLeft: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 145,
    textAlign: 'left',
  },
  icon: {
    fill: 'currentColor',
  },
  gearIcon: {
    marginLeft: 2,
  },
}));
