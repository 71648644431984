import * as yup from 'yup';

import { EMAIL, REQUIRED, PHONE } from 'forms/errorsText';
import { getNumbersFromString } from 'utils/numberUtils';

const isPhoneNumberValid = phoneNumber => phoneNumber.length === 11;

const validationFields = {
  bankName: yup.string().required(REQUIRED),
  contactPerson: yup.string().required(REQUIRED),
  emailAddress: yup.string().email(EMAIL).required(REQUIRED),
  phoneNumber: yup
    .string()
    .test('length', PHONE, phoneNumber => {
      if (phoneNumber) {
        const phone = getNumbersFromString(phoneNumber);
        return isPhoneNumberValid(phone);
      }
      return false;
    })
    .required(REQUIRED),
};

export const initialValues = {
  bankName: '',
  contactPerson: '',
  emailAddress: '',
  phoneNumber: '',
};

export const validationSchema = yup.object().shape(validationFields);

export const formPaths = {
  bankName: 'bankName',
  contactPerson: 'contactPerson',
  emailAddress: 'emailAddress',
  phoneNumber: 'phoneNumber',
};
