import { memo } from 'react';

import Paper from '@mui/material/Paper';

import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';

import OpenInvoicesTable from './components/OpenInvoicesTable';
import PaymentHistoryTable from './components/PaymentHistoryTable';

import useStyles from './useStyles';

const Payments = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <PageHeader text="Payments" icon={<Icon width={32} height={32} name="file" className={classes.titleIcon} />} />
      </div>

      <Paper className={classes.invoicesContainer}>
        <div className={classes.container}>
          <OpenInvoicesTable />
        </div>
      </Paper>

      <Paper>
        <div className={classes.container}>
          <PaymentHistoryTable />
        </div>
      </Paper>
    </div>
  );
};

export default memo(Payments);
