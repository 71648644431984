import { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';

import InvoicePresenter from 'presenters/InvoicePresenter';
import { useBuilderInvoiceCreateActions } from 'store/BuilderInvoiceCreateSlice';
import InvoiceResponseForm from 'components/InvoiceResponseForm';

const InvoiceResponseDialog = props => {
  const { loadProjects, loadLineItems, approveInvoice, rejectInvoice, onClose } = props;
  const { open, invoice } = useSelector(state => state.PayableInvoicesSlice.responseDialog);
  const { createLineItem } = useBuilderInvoiceCreateActions();

  return (
    <Dialog fullWidth scroll="paper" open={open} maxWidth="lg" onClose={onClose}>
      {invoice && (
        <InvoiceResponseForm
          invoice={invoice}
          isAmountEdit={!InvoicePresenter.isAuthored(invoice)}
          loadProjects={loadProjects}
          loadLineItems={loadLineItems}
          approveInvoice={approveInvoice}
          rejectInvoice={rejectInvoice}
          onLineItemItemCreate={createLineItem}
        />
      )}
    </Dialog>
  );
};

InvoiceResponseDialog.propTypes = {
  loadProjects: PropTypes.func.isRequired,
  loadLineItems: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  rejectInvoice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(InvoiceResponseDialog);
