import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const Notification = props => {
  const { title, description, ...snackBarProps } = props;
  const classes = useStyles();

  return (
    <Snackbar {...snackBarProps} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} message="Text">
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <Icon name="outlinedCheckMark" width={29} heigh={29} className={classes.checkIcon} />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
    </Snackbar>
  );
};

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Notification;
