export default {
  title: "Invoices ",
  table: {
    headers: {
      subcontractor: "Subcontractor",
      project: "Project",
      assignedProjects: "Assigned Project(s)",
      lineItems: "Line Item(s)",
      invoiceName: "Invoice name",
      status: "Status",
      amount: "Amount",
    },
  },
};
