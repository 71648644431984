import { SxProps } from "@mui/system";

interface IMuiTabPanelType {
  styleOverrides: {
    root: SxProps;
  };
}

const MuiTabPanel: IMuiTabPanelType = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default MuiTabPanel;
