import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  autocompleteRoot: {
    '&:not(:last-child)': {
      marginTop: 0,
    },
    width: '100%',
  },
  option: {
    width: '100%',
    margin: '16px 16px 0 16px',
    paddingBottom: theme.typography.pxToRem(12),
    borderBottom: [1, 'solid', theme.palette.colors.lightGray],
  },
  optionName: {
    fontSize: theme.typography.pxToRem(16),
  },
  lineItemOptions: {
    '& li:last-child $option': {
      border: 'none',
    },
  },
  actions: {
    display: 'flex',
    color: theme.palette.colors.white,
  },
  assignProjectActionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkIconButton: {
    padding: theme.spacing(1),
    minWidth: 48,
    height: 32,
  },
  crossIconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: 48,
    height: 32,
  },
  checkIcon: {
    fill: theme.palette.colors.white,
  },
  crossIcon: {
    fill: theme.palette.colors.lemonGrass,
  },
  autocompleteInput: {
    padding: '6px !important',
  },
}));
