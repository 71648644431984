import { useEffect } from 'react';

import { useIncomingPayments } from 'store/IncomingPaymentsSlice';
import { MINUTE } from 'constants/time';

const useBuilderUnreadCounts = () => {
  const { loadUnreadIncomingPaymentsCount } = useIncomingPayments();

  useEffect(() => {
    loadUnreadIncomingPaymentsCount();

    const intervalId = setInterval(() => {
      loadUnreadIncomingPaymentsCount();
    }, MINUTE);
    return () => clearInterval(intervalId);
  }, []);
};

export default useBuilderUnreadCounts;
