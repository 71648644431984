import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.colors.gunsmokeGray,
  },
  nextButtonContainer: {
    textAlign: 'right',
  },
  backButton: {
    minWidth: theme.spacing(1),
    padding: 0,
  },
  backButtonContainer: {
    marginBottom: theme.spacing(4),
  },
}));
