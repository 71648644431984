import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  paymentsContainer: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 36,
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    padding: theme.spacing(2, 3),
  },
}));
