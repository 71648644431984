import * as InvoiceService from 'domain/invoice/service';

import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Typography, Paper, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import clsx from 'clsx';

import { DAYS_AFTER_CREATED } from 'constants/invoicePaymentDate';

import { useEarlyPaymentsActions } from 'store/InvoiceEarlyPaymentsSlice';
import { formatDate } from 'libs/datetime';

import EarlyPayments from './components/EarlyPayments';

import useStyles from './useStyles';

const PaymentTerms = props => {
  const { formData } = props;
  const { resetEarlyPayments } = useEarlyPaymentsActions();
  const classes = useStyles();
  const {
    values: { inboundLineItems, builderName, termsOfService, paymentKind, earlyPaymentId },
    setFieldValue,
  } = formData;

  const handleAcceptButtonClick = () => setFieldValue('paymentKind', 'early_payment');

  const setEarlyPaymentId = id => {
    setFieldValue('earlyPaymentId', id);
  };

  const handleStandardTermsButtonClick = () => {
    setEarlyPaymentId(null);
    resetEarlyPayments();

    setFieldValue('termsOfService', false);
    setFieldValue('paymentKind', 'default');
  };

  const isButtonsDisabled = useMemo(
    () => !inboundLineItems.filter(item => Number.isInteger(parseInt(item.amount, 10))).length,
    [inboundLineItems],
  );

  useEffect(() => {
    if (isButtonsDisabled) {
      setEarlyPaymentId(null);
      resetEarlyPayments();
    }
    return resetEarlyPayments;
  }, [isButtonsDisabled]);

  const estimatedPaymentDate = InvoiceService.getInvoiceEstimatedPaymentDate(earlyPaymentId, { createdAt: new Date() });

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Payment Terms
      </Typography>

      <Paper className={classes.content}>
        <div className={classes.description}>
          <Typography className={classes.contentHeader}>Accept Early Payment?</Typography>
          <Typography className={classes.text}>Estimated Payment Date: {formatDate(estimatedPaymentDate)}</Typography>
          {builderName && (
            <Typography className={classes.text}>
              {builderName} has {DAYS_AFTER_CREATED} days payment terms
            </Typography>
          )}
        </div>
        <div className={classes.actions}>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              className={clsx(classes.button, { [classes.buttonActive]: paymentKind === 'early_payment' })}
              disabled={isButtonsDisabled}
              onClick={handleAcceptButtonClick}
            >
              Yes, accept early payment
            </Button>
            <Button
              variant="outlined"
              className={clsx(classes.button, { [classes.buttonActive]: paymentKind === 'default' })}
              disabled={isButtonsDisabled}
              onClick={handleStandardTermsButtonClick}
            >
              No, use standard payment terms
            </Button>
          </div>
          {paymentKind === 'early_payment' && !isButtonsDisabled && (
            <EarlyPayments formData={formData} onEarlyPaymentIdChange={setEarlyPaymentId} />
          )}
        </div>
      </Paper>

      {paymentKind === 'early_payment' && !isButtonsDisabled && (
        <Paper className={classes.agreement}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsOfService}
                  onChange={event => setFieldValue('termsOfService', event.target.checked)}
                />
              }
              label="I agree to a payment that is less than the total invoice amount. I acknowledge that this is the final payment for this invoice and will reconcile this in my accounting."
            />
          </FormGroup>
        </Paper>
      )}
    </div>
  );
};

PaymentTerms.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default PaymentTerms;
