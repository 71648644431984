import { SxStyles } from "theme";

const styles: SxStyles = {
  root: {
    whiteSpace: {
      xs: "break-spaces",
      md: "nowrap",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default styles;
