import { DatePicker as KeyboardDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';

import Icon from 'components/Icon';

import useStyles from './useStyles';

const DatePicker = props => {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <KeyboardDatePicker
        {...props}
        renderInput={params => <TextField {...params} />}
        disablePast
        components={{
          OpenPickerIcon: () => <Icon name="calendar" width={20} height={20} className={classes.icon} fill="#797C72" />,
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
