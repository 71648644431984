import { useBuilderOnboardingActions } from 'store/BuilderOnboardingSlice';
import { useGetCurrentUserQuery, useLazyGetCurrentUserQuery } from 'store/api/user/apiSlice';
import { useDeleteSessionMutation } from 'store/api/session/apiSlice';
import UserPresenter, { BUILDER_ONBOARDING_STATES } from 'presenters/UserPresenter';
import OnboardingLayout from 'layouts/OnboardingLayout';
import CompanySetup from 'components/Onboarding/CompanySetup';
import TeamSetup from 'components/Onboarding/TeamSetup';

import LineItemsSetup from './components/LineItemsSetup';

const Onboarding = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [loadCurrentUser] = useLazyGetCurrentUserQuery();
  const { createCompany, proceedLineItemsOnboarding, createUsers, checkUserAvailability } =
    useBuilderOnboardingActions();
  const [signOut] = useDeleteSessionMutation();

  const renderForm = () => {
    switch (UserPresenter.builderOnboardingState(currentUser)) {
      case BUILDER_ONBOARDING_STATES.notStarted:
        return <CompanySetup onCurrentUserLoad={loadCurrentUser} onCompanyCreate={createCompany} />;
      case BUILDER_ONBOARDING_STATES.companyCreated:
        return (
          <LineItemsSetup
            onCurrentUserLoad={loadCurrentUser}
            onLineItemsOnboardingProceed={proceedLineItemsOnboarding}
          />
        );
      case BUILDER_ONBOARDING_STATES.lineItemsCreated:
        return (
          <TeamSetup
            onCurrentUserLoad={loadCurrentUser}
            onUsersCreate={createUsers}
            onUserAvailabilityCheck={checkUserAvailability}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OnboardingLayout onSignOut={signOut} currentUser={currentUser} variant="builder">
      {renderForm()}
    </OnboardingLayout>
  );
};

export default Onboarding;
