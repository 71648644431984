import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import useStyles from './useStyles';

const SetupOptions = props => {
  const { onManualSetupChoose, onLineItemsOnboardingProceed } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tip}>
        This will be your template for all projects. You can adjust line items for each project.
      </div>
      <div className={classes.actions}>
        <Button variant="outlined" onClick={onManualSetupChoose} classes={{ root: classes.buttonChoiceRoot }}>
          I will set up line items right now (approx. 20 minutes)
        </Button>
        <Button variant="outlined" onClick={onLineItemsOnboardingProceed} classes={{ root: classes.buttonChoiceRoot }}>
          I will set up line items later in my account settings
        </Button>
      </div>
      <div className={classes.disabledButtonContainer}>
        <Button disabled variant="contained" classes={{ root: classes.disabledButtonRoot }}>
          Continue
        </Button>
      </div>
    </div>
  );
};

SetupOptions.propTypes = {
  onManualSetupChoose: PropTypes.func.isRequired,
  onLineItemsOnboardingProceed: PropTypes.func.isRequired,
};

export default SetupOptions;
