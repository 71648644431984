import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  collapsedPaper: {
    width: 64,
    padding: '94px 8px 0',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '110px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '46px',
    },
    boxShadow: 'inset -1px 0px 0px rgba(0, 48, 32, 0.1)',
  },
  expandedPaper: {
    width: 232,
    padding: '88px 16px 0 16px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '104px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '40px',
    },
    boxShadow: 'inset -1px 0px 0px rgba(0, 48, 32, 0.1)',
  },
  menuPaper: ({ isCustomer }) => ({
    width: 200,
    marginTop: isCustomer ? 70 : -70,
  }),
}));
