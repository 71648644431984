import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  totalInformationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalText: {
    fontWeight: 700,
  },
  totalProfit: {
    textAlign: 'right',
  },
}));
