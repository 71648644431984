import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Icon from 'components/Icon';
import AppRoutes from 'routes/AppRoutes';
import ProjectPresenter from 'presenters/ProjectPresenter';

import useStyles from './useStyles';

const AdminProjectRow = props => {
  const { project, iconName, isCompleted } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleRowClick = () => {
    const link = AppRoutes.adminProjectPath(ProjectPresenter.id(project));
    history.push(link);
  };

  return (
    <TableRow className={classes.row} onClick={handleRowClick}>
      <TableCell align="left" className={classes.cell}>
        <div className={classes.invoiceNameContainer}>
          <Icon name={iconName} width={38} height={38} />
          <span className={classes.invoiceName}>{ProjectPresenter.name(project)}</span>
        </div>
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ProjectPresenter.companyName(project)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ProjectPresenter.formattedStartedAt(project)}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ProjectPresenter.duration(project)} days
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {ProjectPresenter.formattedEndAt(project)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {ProjectPresenter.formattedUSDContractValue(project)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {ProjectPresenter.formattedUSDThirdPartyExpenses(project)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {ProjectPresenter.formattedUSDOverheadExpenses(project)}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {ProjectPresenter.formattedUSDProfit(project)} ({ProjectPresenter.percentageOfProfit(project)})
      </TableCell>
      {!isCompleted && (
        <TableCell align="right" className={classes.cell}>
          <span className={clsx(classes.approvalState, classes[ProjectPresenter.camelizedApprovalState(project)])}>
            {ProjectPresenter.approvalStateLabel(project)}
          </span>
        </TableCell>
      )}
    </TableRow>
  );
};

AdminProjectRow.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  iconName: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
};

AdminProjectRow.defaultProps = {
  isCompleted: false,
};

export default AdminProjectRow;
