import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';

import ProjectPresenter from 'presenters/ProjectPresenter';
import Link from 'components/Link';
import AppRoutes from 'routes/AppRoutes';

import useStyles from './useStyles';

const LinkedTableCell = props => {
  const { project, children, ...restProps } = props;
  const classes = useStyles();

  return (
    <TableCell {...restProps}>
      <Link classes={{ root: classes.link }} to={AppRoutes.projectDetailsPath(project.id)}>
        {children}
      </Link>
    </TableCell>
  );
};

LinkedTableCell.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkedTableCell;
