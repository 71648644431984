import { ComponentsOverrides, ComponentsProps } from "@mui/material";

import Icon from "components/Icon";

import baseTheme from "theme/baseTheme";

interface IMuiRadioType {
  defaultProps: ComponentsProps["MuiRadio"];
  styleOverrides: ComponentsOverrides["MuiRadio"];
}

const MuiRadio: IMuiRadioType = {
  defaultProps: {
    checkedIcon: <Icon name="radio" width={18} height={18} fill={baseTheme.palette.colors.green} />,
    icon: <Icon name="uncheckedRadio" width={16} height={16} />,
    color: "primary",
  },
  styleOverrides: {
    root: {
      padding: 9,
      "&.Mui-checked": {
        padding: 8,
      },
    },
  },
};

export default MuiRadio;
