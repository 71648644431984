import * as yup from 'yup';

import { REQUIRED } from 'forms/errorsText';

const validationFields = {
  accountCode: yup.string().required(REQUIRED),
  categoryName: yup.string().required(REQUIRED),
};

export const initialValues = {
  accountCode: '',
  categoryName: '',
};

export const validationSchema = yup.object().shape(validationFields);
