import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { concat } from 'ramda';

import TemplateLineItemsRepository from 'repositories/builder/company/TemplateLineItemsRepository';
import LineItemsRepository from 'repositories/builder/company/LineItemsRepository';
import { BUILDER_COMPANY_LINE_ITEMS_PER_PAGE } from 'store/api/builder/company/invoice/constants';

const initialState = {
  templateLineItems: {
    data: [],
    meta: {},
  },
  lineItems: {
    data: [],
    meta: {},
  },
};

const lineItemsSettingsSlice = createSlice({
  name: 'lineItemsSettings',
  initialState,
  reducers: {
    loadTemplateLineItemsSuccess(state, { payload }) {
      state.templateLineItems.data = concat(state.templateLineItems.data, payload.templateLineItems);
      state.templateLineItems.meta = payload.meta;
    },
    loadLineItemsSuccess(state, { payload }) {
      state.lineItems.data = concat(state.lineItems.data, payload.lineItems);
      state.lineItems.meta = payload.meta;
    },
    addLineItem(state, { payload }) {
      state.lineItems.data = [payload, ...state.lineItems.data];
    },
    editLineItem(state, { payload }) {
      state.lineItems.data = state.lineItems.data.map(item => {
        if (item.id === payload.lineItem.id) {
          return payload.lineItem;
        }

        return item;
      });
    },
    removeLineItem(state, { payload }) {
      state.lineItems.data = state.lineItems.data.filter(item => item.id !== payload);
    },
    resetTemplateLineItems(state) {
      state.templateLineItems = initialState.templateLineItems;
    },
    resetLineItemsSettings() {
      return initialState;
    },
  },
});

export const { actions } = lineItemsSettingsSlice;
export default lineItemsSettingsSlice.reducer;

export const useLineItemsSettingsActions = () => {
  const dispatch = useDispatch();

  const loadTemplateLineItems = async (page = 1) => {
    const { data } = await TemplateLineItemsRepository.index({ q: { s: 'category_name asc' }, page });
    dispatch(actions.loadTemplateLineItemsSuccess(data));

    return Promise.resolve(data);
  };

  const loadLineItems = async (page = 1) => {
    const { data } = await LineItemsRepository.index({ q: { s: 'id desc' }, page });
    dispatch(actions.loadLineItemsSuccess(data));

    return Promise.resolve(data);
  };

  const loadActiveLineItems = async (page = 1) => {
    const { data } = await LineItemsRepository.index({
      q: { s: 'id desc', stateEq: 'active' },
      perPage: BUILDER_COMPANY_LINE_ITEMS_PER_PAGE,
      page,
    });

    return Promise.resolve(data);
  };

  const createLineItem = async params => {
    await LineItemsRepository.create(params);
    dispatch(actions.addLineItem(params));
  };

  const updateLineItem = async params => {
    const { data } = await LineItemsRepository.update(params.id, params);
    dispatch(actions.editLineItem(data));
  };

  const destroyLineItem = async id => {
    await LineItemsRepository.destroy(id);
    dispatch(actions.removeLineItem(id));
  };

  const massCreateLineItems = params => {
    return LineItemsRepository.massCreate(params);
  };

  const resetTemplateLineItems = () => {
    dispatch(actions.resetTemplateLineItems());
  };

  const resetLineItemsSettings = () => {
    dispatch(actions.resetLineItemsSettings());
  };

  return {
    loadTemplateLineItems,
    loadLineItems,
    loadActiveLineItems,
    createLineItem,
    updateLineItem,
    destroyLineItem,
    massCreateLineItems,
    resetTemplateLineItems,
    resetLineItemsSettings,
  };
};
